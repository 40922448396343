import styled from '@emotion/styled'
import { AccountOwnerTag } from 'icons/AccountOwnerTag'
import { UserIconSmall } from 'icons/UserIconSmall'
import React, { VFC } from 'react'
import { Text } from 'ui-kit/Text'

import { RemoveButton } from './RemoveButton'

const Container = styled.div`
  display: flex;
`

const Image = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  object-fit: cover;
`

const ImageDiv = styled.div``

const Content = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const TextStyled = styled(Text)`
  margin: 2px 0;
  display: flex;
  align-items: center;
`

const SvgContainer = styled.div`
  display: flex;
`

export const ProfileRow: VFC<{
  readonly profileDetails: any
  readonly withRemoveButton?: boolean
  readonly onRemove?: () => void
}> = ({ profileDetails, withRemoveButton, onRemove }) => {
  return (
    <Container>
      {profileDetails?.user?.avatar ? (
        <Image src={profileDetails?.user?.avatar} alt="" />
      ) : (
        <ImageDiv>
          <UserIconSmall />
        </ImageDiv>
      )}
      <Content>
        <TextStyled size="smallTextAlt">
          <div>
            {profileDetails?.user?.first_name} {profileDetails?.user?.last_name}
          </div>
          <SvgContainer>{profileDetails?.role === 'owner' ? <AccountOwnerTag /> : null}</SvgContainer>
        </TextStyled>

        <TextStyled size="smallTextAlt" color="secondaryAlt">
          {new Date(profileDetails.updated_date).toDateString()}
        </TextStyled>
      </Content>
      {withRemoveButton && onRemove ? <RemoveButton onClick={onRemove} /> : null}
    </Container>
  )
}
