import styled from '@emotion/styled'
import { TextStyled } from './styles'
import Checkmark from 'icons/Checkmark'
import { css } from '@emotion/react'
import { theme } from '../../../model/ThemeProvider/theme'
import { CrossIcon } from '../../../icons/Cross'

const ConfirmationContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  height: 56px;
  left: 0px;
  margin-bottom: 32px;
  background: #29cc6a;
  width: 375px;
`

const WarningContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  width: 350px;
  height: 56px;
  left: 0px;
  margin-bottom: 32px;
  background: #ff6960;
  overflow-wrap: anywhere;
`

const iconProps = {
  width: 10,
  height: 15,
  color: theme.colors.white,
  css: css({
    marginRight: 5,
    verticalAlign: 'middle',
  }),
}

type NotificationBadgeProps = {
  readonly onClick: () => void
  readonly errorMsgFromInvite: string
  readonly showEmailInviteSent: boolean
  readonly showSuccessfullyJoined: boolean
  readonly showError: boolean
}

export function NotificationBadge({
  onClick,
  errorMsgFromInvite,
  showEmailInviteSent,
  showSuccessfullyJoined,
  showError,
}: NotificationBadgeProps) {
  return (
    <>
      {showEmailInviteSent ? (
        <ConfirmationContainer>
          <TextStyled size="smallTextAlt" color="white">
            Email Invite sent!
          </TextStyled>
          <Checkmark {...iconProps} />
        </ConfirmationContainer>
      ) : null}
      {showSuccessfullyJoined ? (
        <ConfirmationContainer>
          <TextStyled size="smallTextAlt" color="white">
            Successfully joined!
          </TextStyled>
          <Checkmark {...iconProps} />
        </ConfirmationContainer>
      ) : null}
      {showError ? (
        <WarningContainer>
          <TextStyled size="smallTextAlt" color="white">
            {errorMsgFromInvite}
          </TextStyled>
          <div style={{ display: 'inherit', cursor: 'pointer' }} onClick={onClick}>
            <CrossIcon onClick={onClick} {...iconProps} />
          </div>
        </WarningContainer>
      ) : null}
    </>
  )
}
