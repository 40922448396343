import dayjs from 'dayjs'
import * as R from 'ramda'

import { birthdayDayjsFormats } from './birthdayDayjsFormats'
import { stringToFeetInchesArray } from './stringToFeetInchesArray'
import { validate } from './validate'
import {
  maxCharacterCount,
  minWeight,
  maxWeight,
  specialCharactersRe,
  ValidationRequirement,
} from './validation-constraints'

export const conformValue = (validationRequirement?: ValidationRequirement, val?: string | null) => {
  if (validationRequirement === 'birthday') {
    return validate(validationRequirement, val) ? dayjs(val, birthdayDayjsFormats).format('MM-DD-YYYY') : val
  } else if (validationRequirement === 'weight') {
    const valInt = parseInt((val || '')?.replace(/\D/gi, ''))
    return isNaN(valInt) ? val : `${R.compose(R.min<number>(maxWeight), R.max<number>(minWeight))(valInt)} lbs.`
  } else if (validationRequirement === 'height') {
    const feetInches = stringToFeetInchesArray(val)
    return val && Array.isArray(feetInches) && feetInches.length ? `${feetInches[0]}' ${feetInches[1]}"` : val
  } else if (validationRequirement === 'gender') {
    const maleOptions = ['m', 'male', 'man']
    const femaleOptions = ['w', 'female', 'woman']

    const valCleaned = val!.replace(/\d/gi, '').replace(specialCharactersRe, '').trim().toLowerCase()

    if (maleOptions.includes(valCleaned)) {
      return 'male'
    } else if (femaleOptions.includes(valCleaned)) {
      return 'female'
    } else {
      return 'not-specified'
    }
  } else if (validationRequirement === 'non-empty-alpha') {
    return val!.replace(/\d/gi, '').replace(specialCharactersRe, '').trim().slice(0, maxCharacterCount)
  } else if (validationRequirement === 'phone') {
    return val
  } else if (validationRequirement === 'postal-code') {
    return val!.replace(specialCharactersRe, '').trim().slice(0, maxCharacterCount)
  } else {
    return String(val).replace(specialCharactersRe, '').trim().slice(0, maxCharacterCount)
  }
}
