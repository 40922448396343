import { RouteProps } from 'react-router'
import { Route } from 'react-router-dom'

const titleDecorator = (title?: string) => (title ? `${title} - FORME` : 'FORME')

export type TitledRouteProps = Pick<RouteProps, 'path' | 'exact' | 'render'> & {
  readonly title?: string | ((params: Record<string, string | undefined>) => string)
}

export const TitledRoute = ({ title, ...props }: TitledRouteProps) => (
  <Route
    {...props}
    render={(params) => {
      document.title = titleDecorator(typeof title === 'function' ? title(params.match.params) : title)
      return props.render?.(params)
    }}
  />
)
