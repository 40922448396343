import styled from '@emotion/styled'
import { useDialogManager } from 'lib/dialog-manager'
import React, { VFC } from 'react'
import { useDispatch, useSelector } from 'store'
import { setUserWantsToBeLoggedIn } from 'store/user'

import HeaderModal from '../../../components/HeaderModal'
import { Fade } from '../../../components/styled/containers'
import { FamilyPlanProfilesDialog } from '../../../dialogs/FamilyPlanProfilesDialog'
import { H3 } from '../../../ui-kit'
import { RegularDialog, RegularDialogProps } from '../../../ui-kit/RegularDialog'
import { padding } from '../../../ui-kit/utils'
import BusinessModal from '../../BusinessModal'
import MembershipStatus from '../../MembershipStatus'
import PaymentInfo from '../../PaymentInfo'
import ResetPassword from '../../ResetPassword'

import { SettingsLink } from './SettingsLink'
import { StudiosModal } from './StudiosModal'

const SettingsGroup = styled.div({
  marginBottom: 32,
})

export const SettingsModal: VFC<RegularDialogProps> = (props) => {
  const dispatch = useDispatch()
  const { showDialog, hideCurrentDialog } = useDialogManager()
  const { has_active_membership } = useSelector((state) => state.user)
  const membershipType = useSelector((state) => state.membership.membership?.class.type)
  const isCommercial = membershipType === 'commercial'

  const appEnvName = process.env.REACT_APP_ENV_NAME || ''
  const environmentName = appEnvName.charAt(0).toLocaleUpperCase() + appEnvName.slice(1)
  const appVersionArr = process.env.REACT_APP_VERSION?.split('-')[0]?.slice(1).split('.')
  const appVersion = appVersionArr ? `Version ${appVersionArr[0]}.${appVersionArr[1]} (${appVersionArr[2]})` : null

  return (
    <RegularDialog {...props}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Settings" onClickControlLeft={hideCurrentDialog} />
      <Fade css={padding('26px 6% 62px')}>
        <SettingsGroup>
          <H3>Account</H3>
          {has_active_membership && isCommercial ? (
            <SettingsLink onClick={() => showDialog(BusinessModal)}>Business</SettingsLink>
          ) : null}
          <SettingsLink onClick={() => showDialog(MembershipStatus)}>Membership</SettingsLink>
          <SettingsLink onClick={() => showDialog(PaymentInfo)}>Payment Info</SettingsLink>
          {has_active_membership && !isCommercial ? (
            <SettingsLink onClick={() => showDialog(FamilyPlanProfilesDialog)}>Profiles</SettingsLink>
          ) : null}
          {has_active_membership && isCommercial ? (
            <SettingsLink onClick={() => showDialog(StudiosModal)}>Studios</SettingsLink>
          ) : null}
        </SettingsGroup>
        <SettingsGroup>
          <H3>Support</H3>
          <SettingsLink href="https://forme.helpscoutdocs.com" target="_blank">
            Help
          </SettingsLink>
          <SettingsLink href="https://shop.formelife.com/" target="_blank">
            Shop
          </SettingsLink>
        </SettingsGroup>
        <SettingsGroup>
          <H3>Policies</H3>
          <SettingsLink href="https://formelife.com/pages/terms-of-use" target="_blank">
            Terms of Service
          </SettingsLink>
          <SettingsLink href="https://formelife.com/privacy" target="_blank">
            Privacy Policy
          </SettingsLink>
          {!isCommercial ? (
            <SettingsLink href="https://formelife.com/warranty-and-return" target="_blank">
              Warranty & Returns Policy
            </SettingsLink>
          ) : null}
        </SettingsGroup>
        <SettingsGroup>
          <H3>Actions</H3>
          <SettingsLink onClick={() => showDialog(ResetPassword)}>Reset Password</SettingsLink>
          <SettingsLink onClick={() => dispatch(setUserWantsToBeLoggedIn(false))}>Sign Out</SettingsLink>
        </SettingsGroup>
        <SettingsGroup>
          <H3>Version</H3>
          <div>
            {appVersion} | {environmentName}
          </div>
        </SettingsGroup>
      </Fade>
    </RegularDialog>
  )
}
