import { useDialogManager } from 'lib/dialog-manager'
import React, { useState, VFC } from 'react'
import { useDispatch, useSelector } from 'store'

import HeaderModal from '../components/HeaderModal'
import {
  Fade,
  FadeMain,
  HeaderGroup,
  constrain360,
  paddingVerticalContainerDefault,
} from '../components/styled/containers'
import { paddingTop } from '../components/styled/spacing'
import { blockAndDim } from '../components/styled/utility'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'

import MembershipSetup from './MembershipSetup'
import { setPaymentMethod } from '../store/billing'
import { getMembership } from '../store/membership'

const DISPLAY_STATE = {
  error: 'error',
  loading: 'loading',
  fetchingExistingMembership: 'fetchingExistingMembership',
  waitingForPaymentMethod: 'waitingForPaymentMethod',
  creatingMembership: 'creatingMembership',
  finished: 'finished',
}

const MembershipSetupContainer: VFC<{ readonly isPartOfDialog?: boolean }> = ({ isPartOfDialog }) => {
  const dispatch = useDispatch()
  const errorMessage = useSelector((state) => state.error.errorMessage)
  const paymentMethod = useSelector((state) => state.billing.paymentMethod)
  const { isMembershipStatusKnown, userHasMembership } = useSelector((state) => state.membership)

  const require_cc = useSelector((state) => state.user.require_cc)

  const [isUpdatingPaymentCreatingAndGettingMembership, setIsUpdatingPaymentCreatingAndGettingMembership] =
    useState(false)

  const [fetchingExistingMembership, setFetchingExistingMembership] = useState(false)

  const onPaymentMethodCaptured = async (paymentMethodId: string) => {
    setIsUpdatingPaymentCreatingAndGettingMembership(true)
    setFetchingExistingMembership(true)
    try {
      await dispatch(setPaymentMethod(paymentMethodId))
      await dispatch(getMembership())
      // when apiGetMembership() succeeds, userHasMembership will trigger the displayState to update
    } catch (e) {
      // something went wrong; this will revert back to waitingForPaymentState
      setIsUpdatingPaymentCreatingAndGettingMembership(false)
    } finally {
      setFetchingExistingMembership(false)
    }
  }

  const needPaymentMethod = require_cc && !paymentMethod

  const finished = userHasMembership && !needPaymentMethod
  const creatingMembership = isUpdatingPaymentCreatingAndGettingMembership
  const waitingForPaymentState = (isMembershipStatusKnown && !userHasMembership) || needPaymentMethod

  const displayState = errorMessage
    ? DISPLAY_STATE.error
    : finished
    ? DISPLAY_STATE.finished
    : creatingMembership
    ? DISPLAY_STATE.creatingMembership
    : waitingForPaymentState
    ? DISPLAY_STATE.waitingForPaymentMethod
    : fetchingExistingMembership
    ? DISPLAY_STATE.fetchingExistingMembership
    : DISPLAY_STATE.loading

  return (
    <div css={[blockAndDim(displayState === DISPLAY_STATE.creatingMembership)]}>
      <MembershipSetup
        displayState={displayState}
        onPaymentMethodCaptured={onPaymentMethodCaptured}
        isPartOfDialog={isPartOfDialog}
      />
    </div>
  )
}

export const MembershipSetupModal: VFC<RegularDialogProps> = (props) => {
  const { hideCurrentDialog } = useDialogManager()

  return (
    <RegularDialog {...props}>
      <HeaderModal
        controlLeft="ArrowLeft"
        headerTitle="Membership Setup"
        onClickControlLeft={() => {
          setTimeout(() => {
            hideCurrentDialog()
          }, 0)
        }}
      />
      <Fade>
        <div css={[constrain360, paddingVerticalContainerDefault]}>
          <MembershipSetupContainer isPartOfDialog />
        </div>
      </Fade>
    </RegularDialog>
  )
}

export const MembershipSetupFullPage = () => (
  <FadeMain extraTopPadding>
    <HeaderGroup mainHeading="Membership Setup" />
    <div css={paddingTop('lg')}>
      <MembershipSetupContainer />
    </div>
  </FadeMain>
)
