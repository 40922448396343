import { useEffect, useState } from 'react'

import { getPrograms, Program } from '../../api/programs/getPrograms'
import { getTrainers, Trainer } from '../../api/trainers/getTrainers'
import { WorkoutFilter, getWorkoutFilters } from '../../api/workout-filters/getWorkoutFilters'
import { getWorkoutSessions, WorkoutSession } from '../../api/workout-sessions/getWorkoutSessions'
import { INSTRUCTORS_CATEGORY, PROGRAMS_CATEGORY } from '../../constants'
// Prod data with instructors and programs
// import featuredFilters from '../../mock/workoutFilters.json';

export type PciApiState = {
  data: {
    featuredCategories: {
      name: string
      shortDescription: string
      items: Program[] | Trainer[] | WorkoutSession[]
      condition: WorkoutFilter['condition']
    }[]
    instructors: Trainer[]
    keyedInstructors: Record<string, Trainer>
    programs: Program[]
    workoutSessions: WorkoutSession[]
    workoutSessionsWithoutProgramClasses: WorkoutSession[]
    keyedWorkoutSessions: Record<string, WorkoutSession>
  }
  isFetching: boolean
  errorMessage: string | null
}

export const usePCIApi = () => {
  const [pciData, setPCIData] = useState<PciApiState>({
    data: {
      featuredCategories: [],
      instructors: [],
      keyedInstructors: {},
      programs: [],
      workoutSessions: [],
      workoutSessionsWithoutProgramClasses: [],
      keyedWorkoutSessions: {},
    },
    isFetching: false,
    errorMessage: null,
  })

  useEffect(() => {
    const fetchPstData = async () => {
      try {
        setPCIData((state) => ({
          ...state,
          isFetching: true,
        }))

        // Note: the services are still called "trainer," but the UI uses "instructor."
        // A couple places in the codebase also use 'trainer' object keys in API data, ex. Classes.js
        const programApiPromise = getPrograms()
        const trainerApiPromise = getTrainers()
        const workoutSessionApiPromise = getWorkoutSessions().then((_) => _.data)
        const workoutFilterPromise = getWorkoutFilters()

        const [programApiResponse, trainerApiResponse, workoutSessionApiResponse, featuredFilters] = await Promise.all([
          programApiPromise,
          trainerApiPromise,
          workoutSessionApiPromise,
          workoutFilterPromise,
        ])

        if (!programApiResponse?.data || !trainerApiResponse?.data || !featuredFilters?.data) {
          throw new Error('useApiPST error')
        }

        const availableCategories = new Set(featuredFilters.data.map((_) => _.condition.category))
        const filteredClasses = workoutSessionApiResponse.filter((_) => availableCategories.has(_.category))

        /*
         * derived data
         */
        // defensive since classes can exist with a instructor id that points to a non-existent instructor
        const knownInstructorIds = new Set(trainerApiResponse.data.map(({ id }) => id))
        const classesWithKnownInstructors = filteredClasses.filter(({ trainer }) => knownInstructorIds.has(trainer?.id))
        const classesWithKnownInstructorsWithoutProgramClasses = classesWithKnownInstructors.filter(
          ({ is_in_program }) => !is_in_program,
        )

        const programs = programApiResponse.data.data.filter(({ is_lift }) => !is_lift)

        const featuredCategories = featuredFilters.data
          .map((filter) => {
            const { name, condition, short_description } = filter
            const conditions = Object.entries(condition) as [
              keyof WorkoutSession,
              WorkoutSession[keyof WorkoutSession],
            ][]
            let items
            switch (name) {
              case INSTRUCTORS_CATEGORY:
                items = trainerApiResponse.data
                break
              case PROGRAMS_CATEGORY:
                items = programs
                break
              default:
                items = classesWithKnownInstructorsWithoutProgramClasses.filter((workout) =>
                  conditions.every(([key, value]) => String(workout[key]) === String(value)),
                )
            }

            return {
              name,
              shortDescription: short_description,
              items,
              condition,
            }
          })
          .filter(({ items }) => items.length > 0)

        const data = {
          featuredCategories,
          instructors: trainerApiResponse.data,
          keyedInstructors: trainerApiResponse.data.reduce(
            (acc, instructor) => ({
              ...acc,
              [instructor.id]: instructor,
            }),
            {},
          ),
          programs,
          workoutSessions: classesWithKnownInstructors,
          workoutSessionsWithoutProgramClasses: classesWithKnownInstructorsWithoutProgramClasses,
          keyedWorkoutSessions: classesWithKnownInstructors.reduce(
            (acc, workout) => ({
              ...acc,
              [workout.id]: workout,
            }),
            {},
          ),
        }

        setPCIData((prevPstApiData) => ({
          ...prevPstApiData,
          data,
          isFetching: false,
        }))
      } catch (e) {
        setPCIData((prevPstApiData) => ({
          ...prevPstApiData,
          isFetching: false,
          errorMessage: 'An error occurred when trying to access the Classes data.',
        }))
        throw e
      }
    }
    fetchPstData()
  }, [setPCIData])

  return pciData
}
