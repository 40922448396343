import { IdToken } from '@auth0/auth0-react'
import axiosLib from 'axios'

export const axios = axiosLib.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  timeout: 60000,
  headers: {
    'cache-control': 'no-store, no-cache, must-revalidate',
  },
})

export const axios_V2 = axiosLib.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  timeout: 60000,
  headers: {
    'cache-control': 'no-store, no-cache, must-revalidate',
  },
})

export const addAccessTokenInterceptor = (getIdTokenClaims: () => Promise<IdToken | undefined>) => {
  axios.interceptors.request.use(async (config) => {
    const resp = await getIdTokenClaims()
    if (!config.headers) config.headers = {}
    config.headers.Authorization = `Bearer ${resp?.__raw ?? null}`
    return config
  })
}
