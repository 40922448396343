import { FamilyPlanProfilesDialog } from 'dialogs/FamilyPlanProfilesDialog'
import { useDialogManager } from 'lib/dialog-manager'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'store'

import { routing } from '../model/Routing/routing'
import { MembershipSetupModal } from '../views'
import MembershipStatus from '../views/MembershipStatus'
import { SettingsModal } from '../views/Modals'
import PaymentInfo from '../views/PaymentInfo'
import ResetPassword from '../views/ResetPassword'

const useModalLaunching = () => {
  const {
    location: { pathname },
  } = useHistory()
  const { showDialog } = useDialogManager()

  const { isMembershipStatusKnown, userHasMembership } = useSelector((state) => state.membership)

  const [isInitialLaunch, setIsInitialLaunch] = useState(true)

  useEffect(() => {
    if (isInitialLaunch) {
      setIsInitialLaunch(false)

      switch (pathname) {
        case routing.profile.membership.pattern:
          showDialog(SettingsModal)
          showDialog(MembershipStatus)
          isMembershipStatusKnown && !userHasMembership && showDialog(MembershipSetupModal)
          break
        case routing.profile.settings.pattern:
          mixpanel.track('settings')
          showDialog(SettingsModal)
          break
        case routing.profile.paymentInfo.pattern:
          showDialog(SettingsModal)
          showDialog(PaymentInfo)
          break
        case routing.profile.resetPassword.pattern:
          showDialog(SettingsModal)
          showDialog(ResetPassword)
          break
        case routing.profile.profiles.pattern:
          showDialog(SettingsModal)
          showDialog(FamilyPlanProfilesDialog)
          break
        default:
        // do nothing
      }
    }
  }, [isInitialLaunch, isMembershipStatusKnown, showDialog, setIsInitialLaunch, userHasMembership, pathname])
}

export default useModalLaunching
