import styled from '@emotion/styled'

export const UnstyledList = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,
})

export const UnstyledListItem = styled.li({
  padding: 0,
})
