import { css, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { ChangeEvent, useEffect, useState, VFC } from 'react'
import PhoneInput from 'react-phone-number-input'

import { AngleBracketRight } from '../../icons'
import { InputLabel, InputContainer, Body3 } from '../../ui-kit'
import { validate } from '../../utils/utils'
import { ValidationRequirement } from '../../utils/various/validation-constraints'

import { FadeIn } from './containers'
import { isShowingBase, isShowingFast, positionRelative, pseudoFocusLightBackground } from './utility'

import 'react-phone-number-input/style.css'

type InputChange = {
  name?: string
  value?: string
}

const formPaddingBottom = 3
const customSelectDropdownFlyoutHeight = 190

const inputText = (theme: Theme) =>
  css({
    display: 'block',
    width: '100%',
    height: 24,
    padding: 0,

    fontSize: '1rem',
    lineHeight: 24 / 16,
    color: theme.textColors.primary,
    background: 'transparent',

    border: 'none',
    outline: 'none',

    '&:-webkit-autofill::first-line, &:-webkit-autofill': {
      fontSize: '1rem',
      backgroundColor: 'transparent !important',
      WebkitBoxShadow: `0 0 0 50px ${theme.backgroundPage} inset`,
    },
  })

export const ValidationContainer = styled(Body3)<{
  isInvalidShowing?: boolean
}>(({ isInvalidShowing, theme }) => ({
  display: isInvalidShowing ? 'block' : 'none',
  padding: '0 10px',
  marginBottom: 20,
  color: theme.colors.red2,

  '&:first-letter': {
    textTransform: 'uppercase',
  },
}))

type InputProps = {
  readonly autocomplete?: string
  readonly disabled?: boolean
  readonly forcedValidationMessage?: string
  readonly id?: string
  readonly isForceInvalid?: boolean
  readonly isForceNotPristine?: boolean
  readonly labeltext?: string
  readonly onBlurCallback: (e: ChangeEvent<HTMLInputElement>) => void
  readonly validationMessage?: string
  readonly validationRequirement?: ValidationRequirement
  readonly placeholder?: string
  readonly val: string
}

export const FormInput: VFC<
  InputProps & {
    readonly onChangeCallback: (e: ChangeEvent<HTMLInputElement>) => void
  }
> = ({
  autocomplete,
  disabled,
  forcedValidationMessage,
  id,
  isForceInvalid,
  isForceNotPristine,
  labeltext,
  onBlurCallback,
  onChangeCallback,
  placeholder,
  val,
  validationMessage,
  validationRequirement,
}) => {
  const [isPristine, setIsPristine] = useState(true)
  const theme = useTheme()

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    // leaving a field empty will not "break" the pristine state
    val && setIsPristine(false)
    ;(val || !isPristine) && onBlurCallback(e)
  }

  const isValid = !isForceInvalid && validate(validationRequirement, val)

  const isInvalidShowing = (isForceNotPristine || !isPristine || isForceInvalid) && !isValid
  return (
    <>
      <InputContainer isInvalidShowing={isInvalidShowing}>
        <InputLabel isInvalidShowing={isInvalidShowing} htmlFor={id}>
          {labeltext}
        </InputLabel>
        <input
          autoComplete={autocomplete}
          className="testing-form-input"
          css={inputText(theme)}
          disabled={disabled}
          id={id}
          maxLength={24}
          name={id}
          onBlur={handleBlur}
          onChange={onChangeCallback}
          placeholder={placeholder}
          type="text"
          value={val}
        />
      </InputContainer>
      <FadeIn isIn={isInvalidShowing}>
        <ValidationContainer id={`testing-validation-${id}`} isInvalidShowing={isInvalidShowing}>
          {isForceInvalid ? forcedValidationMessage : validationMessage}
        </ValidationContainer>
      </FadeIn>
    </>
  )
}

/*
 * Custom Birthdate "guided" text input
 */
export const BirthdateInput: VFC<InputProps & { readonly onChangeCallback: (e: InputChange) => void }> = ({
  autocomplete,
  disabled,
  forcedValidationMessage,
  id,
  isForceInvalid,
  isForceNotPristine,
  labeltext,
  onBlurCallback,
  onChangeCallback,
  val,
  validationMessage,
  validationRequirement,
}) => {
  const theme = useTheme()
  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsPristine(false)
    onBlurCallback(e)
  }
  const [isPristine, setIsPristine] = useState(true)

  const isValid = !isForceInvalid && validate(validationRequirement, val)

  const isInvalidShowing = (isForceNotPristine || !isPristine || isForceInvalid) && !isValid

  const defaultGuideText = 'mm-dd-yyyy'

  const deriveGuideTextFromVal = (formVal: string, guideText: string) => {
    const numSeparators = formVal.replace(/[^-]/g, '').length
    const valGroups = formVal.split('-')
    const firstGroup = valGroups[0]?.match(/[01]?\d/)
    const numFirstGroup = (!!firstGroup && firstGroup[0]?.length) || 0
    const secondGroup = valGroups[1]?.match(/[0123]?\d/)
    const numSecondGroup = (!!secondGroup && secondGroup[0]?.length) || 0
    const thirdGroup = valGroups[2]?.match(/[12]\d?\d?\d?/)
    const numThirdGroup = (!!thirdGroup && thirdGroup[0]?.length) || 0

    const numGuideTextCharsToRemove =
      numSeparators === 2 ? 6 + numThirdGroup : numSeparators === 1 ? 3 + numSecondGroup : numFirstGroup
    const numGuideTextCharsToKeep = guideText.length - numGuideTextCharsToRemove
    const remainingGuideTextChars = numGuideTextCharsToKeep ? guideText.substr(-Math.abs(numGuideTextCharsToKeep)) : ''
    const inputTextPlusGuideText = formVal + remainingGuideTextChars

    return inputTextPlusGuideText
  }
  const [guideText, setGuideText] = useState(!val ? defaultGuideText : deriveGuideTextFromVal(val, defaultGuideText))

  useEffect(() => {
    // every time the value changes, update the guide text
    setGuideText(deriveGuideTextFromVal(val, defaultGuideText))
  }, [setGuideText, val])

  return (
    <>
      <InputContainer isInvalidShowing={isInvalidShowing}>
        <InputLabel isInvalidShowing={isInvalidShowing} htmlFor={id}>
          {labeltext}
        </InputLabel>
        <div css={positionRelative}>
          <input
            autoComplete={autocomplete}
            className="testing-form-input"
            css={inputText(theme)}
            disabled={disabled}
            id={id}
            maxLength={24}
            name={id}
            onBlur={handleBlur}
            onChange={(evt) => {
              // if all the user did was remove a dash at the end...
              if (val.substr(-1) === '-' && val.substr(0, val.length - 1) === evt.target.value) {
                // ...then remove the dash and don't worry about anything else...
                onChangeCallback({ name: id, value: evt.target.value })
                return
              }

              // ...otherwise, split up the input and put dashes in for them.

              // 1 - start by removing unwanted characters (which prevents entering them, basically)
              const conformedInput = evt.target.value
                .trim() // remove leading/trailing whitespace
                .replace(/^[^\d]/, '') // remove any leading non-digits
                .replace(/\/+/g, '-')
                .replace(/ +/g, '')
                .replace(/[^\d-]/g, '')
                .replace(/-+/g, '-')

              // 2 - then split them into "groups"
              // ex. '12' is just [12], '12-1' is [12] [1], '12-10-1980' is [12] [10] [1980]
              const valGroups = conformedInput.split('-')
              const firstGroup = valGroups[0]?.match(/[01]?\d/)
              const secondGroup = valGroups[1]?.match(/[0123]?\d/)
              const thirdGroup = valGroups[2]?.match(/[12]\d?\d?\d?/)

              // 3 - add in (or keep) dashes
              const newValue = !!thirdGroup
                ? `${firstGroup}-${secondGroup}-${thirdGroup}`
                : !!secondGroup && secondGroup[0]?.length === 2
                ? `${firstGroup}-${secondGroup}-`
                : !!secondGroup && evt.target.value.substr(-1) === '-'
                ? `${firstGroup}-${secondGroup}-`
                : !!secondGroup
                ? `${firstGroup}-${secondGroup}`
                : !!firstGroup && firstGroup[0]?.length === 2 && !!secondGroup
                ? `${firstGroup[0]}-${secondGroup[0]}`
                : !!firstGroup && firstGroup[0]?.length === 2
                ? `${firstGroup[0]}-`
                : conformedInput

              onChangeCallback({ name: id, value: newValue })
            }}
            type="text"
            value={val}
          />
          <input
            autoComplete={autocomplete}
            className="testing-form-input"
            css={[
              inputText(theme),
              {
                color: theme.textColors.primary,
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 0,
                pointerEvents: 'none',
              },
            ]}
            disabled
            id={id}
            maxLength={24}
            name={id}
            onBlur={handleBlur}
            // onChange={onChangeCallback}
            type="text"
            value={guideText}
          />
        </div>
      </InputContainer>
      <FadeIn isIn={isInvalidShowing}>
        <ValidationContainer id={`testing-validation-${id}`} isInvalidShowing={isInvalidShowing}>
          {isForceInvalid ? forcedValidationMessage : validationMessage}
        </ValidationContainer>
      </FadeIn>
    </>
  )
}

/*
 * Custom Select Dropdown and related components
 */
export const CustomSelectBlocker = styled.div<{ isShowing: boolean }>(
  {
    zIndex: 2,
    position: 'fixed',
    top: '-1000%', // extend beyond modal & viewport edges
    bottom: '-1000%',
    left: '-1000%',
    right: '-1000%',
    overflow: 'hidden',
  },
  ({ isShowing }) => isShowingBase(isShowing),
)

const FormCustomSelectContainer = styled(InputContainer)({
  position: 'relative',
})

const CustomDropdownHitArea = styled.div({
  position: 'absolute',
  top: 0,
  bottom: -formPaddingBottom,
  left: 0,
  width: '100%',
  cursor: 'pointer',
})

const CustomSelectDropdownUl = styled.ul<{ isSelectDropdownShowing?: boolean }>(
  ({ theme }) => ({
    position: 'absolute',
    top: 24,
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    maxHeight: customSelectDropdownFlyoutHeight,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 3,
    backgroundColor: theme.colors.white,
    boxShadow: `0px 2px 4px ${theme.colors.blackAlpha25}`,
  }),
  ({ isSelectDropdownShowing }) => isShowingFast(isSelectDropdownShowing),
)

CustomSelectDropdownUl.propTypes = {
  isSelectDropdownShowing: PropTypes.bool.isRequired,
}

const CustomOptionLi = styled.li<{ isSelected?: boolean }>(({ isSelected, theme }) => ({
  background: isSelected ? theme.backgroundBrand : 'transparent',
  padding: '0 16px',
  height: 44,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    background: theme.backgroundBrand,
  },
}))

CustomOptionLi.propTypes = {
  isSelected: PropTypes.bool.isRequired,
}

const InputHitAreaContainer = styled.div(({ theme }) => ({
  position: 'relative',

  '& > input:disabled, & > input': {
    color: theme.textColors.primary,
    opacity: 1,
  },
}))

export const FormCustomSelect: VFC<{
  readonly handleCustomDropdownChange: (id: string, option: string) => void
  readonly id: string
  readonly isForceNotPristine?: boolean
  readonly isSelectDropdownShowing?: boolean
  readonly labeltext: string
  readonly onChangeCallback: (e: ChangeEvent<HTMLInputElement>) => void
  readonly options: string[]
  readonly placeholder?: string
  readonly toggleIsSelectDropdownShowing: () => void
  readonly val: string
  readonly validationMessage?: string
  readonly validationRequirement?: ValidationRequirement
}> = ({
  handleCustomDropdownChange,
  id,
  isForceNotPristine,
  isSelectDropdownShowing,
  labeltext,
  onChangeCallback,
  options,
  toggleIsSelectDropdownShowing,
  val,
  validationMessage,
  validationRequirement,
  placeholder,
}) => {
  const theme = useTheme()

  const [isPristine, setIsPristine] = useState(true)
  const isValid = validate(validationRequirement, val)
  const isInvalidShowing = (isForceNotPristine || !isPristine) && !isValid

  return (
    <>
      <FormCustomSelectContainer isInvalidShowing={isInvalidShowing} className="testing-form-input-container">
        <InputLabel isInvalidShowing={isInvalidShowing} htmlFor={id}>
          {labeltext}
        </InputLabel>

        <InputHitAreaContainer>
          <input
            css={inputText(theme)}
            id={id}
            name={id}
            placeholder={placeholder}
            onChange={onChangeCallback}
            type="text"
            value={val}
            disabled
          />
          <CustomDropdownHitArea
            className="testing-form-dropdown"
            css={pseudoFocusLightBackground(theme)}
            onClick={() => {
              if (isSelectDropdownShowing) {
                setIsPristine(false)
              }
              toggleIsSelectDropdownShowing?.()
            }}
            tabIndex={0}
            role="button"
          >
            <AngleBracketRight
              strokeColor={theme.textColors.primary}
              width={6}
              css={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'rotate(90deg)',
                transformOrigin: 'top left',
              }}
            />
          </CustomDropdownHitArea>
        </InputHitAreaContainer>

        <CustomSelectDropdownUl isSelectDropdownShowing={isSelectDropdownShowing}>
          {options.map(
            (option) =>
              option && (
                <CustomOptionLi
                  className="testing-form-dropdown-option"
                  isSelected={val === option}
                  onClick={() => {
                    handleCustomDropdownChange(id, option)
                  }}
                  key={option}
                  tabIndex={-1}
                >
                  {option}
                </CustomOptionLi>
              ),
          )}
        </CustomSelectDropdownUl>
      </FormCustomSelectContainer>
      <FadeIn isIn={isInvalidShowing}>
        <ValidationContainer isInvalidShowing={isInvalidShowing}>{validationMessage}</ValidationContainer>
      </FadeIn>
    </>
  )
}

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputInput {
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
    background: transparent;
    border: none;
    outline: none;
  }
`

export const FormPhoneInput: VFC<
  Omit<InputProps, 'onBlurCallback'> & {
    readonly onChangeCallback: (e: InputChange) => void
    readonly onBlurCallback: (e: InputChange) => void
  }
> = ({
  val,
  onChangeCallback,
  onBlurCallback,
  id,
  labeltext,
  isForceInvalid,
  forcedValidationMessage,
  validationMessage,
  validationRequirement,
  isForceNotPristine,
  autocomplete,
}) => {
  const [isPristine, setIsPristine] = useState(true)

  const isValid = !isForceInvalid && validate(validationRequirement, val)

  const isInvalidShowing = (isForceNotPristine || !isPristine || isForceInvalid) && !isValid

  return (
    <>
      <InputContainer isInvalidShowing={isInvalidShowing}>
        <InputLabel isInvalidShowing={isInvalidShowing} htmlFor={id}>
          {labeltext}
        </InputLabel>
        <StyledPhoneInput
          autoComplete={autocomplete}
          defaultCountry="US"
          value={val}
          onChange={(value) => {
            onChangeCallback({
              name: id,
              value,
            })
          }}
          onBlur={() => {
            setIsPristine(false)
            onBlurCallback({ value: val, name: id })
          }}
        />
      </InputContainer>
      <FadeIn isIn={isInvalidShowing}>
        <ValidationContainer id={`testing-validation-${id}`} isInvalidShowing={isInvalidShowing}>
          {isForceInvalid ? forcedValidationMessage : validationMessage}
        </ValidationContainer>
      </FadeIn>
    </>
  )
}
