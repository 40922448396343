import dayjs from 'dayjs'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { birthdayDayjsFormats } from './birthdayDayjsFormats'
import { stringToSplitNumbersArray } from './stringToSplitNumbersArray'
import { maxAge, maxWeight, minAge, minWeight, ValidationRequirement } from './validation-constraints'

export const validate = (validationRequirement?: ValidationRequirement, val?: string | null) => {
  if (validationRequirement === 'optional') {
    return true
  }

  if (!val) return false

  if (validationRequirement === 'birthday') {
    const parsed = dayjs(val, birthdayDayjsFormats)
    const isValid = parsed.isValid()

    if (!isValid) {
      return false
    }

    // dayjs toNow returns format 'X years' so take only X
    const yearsToNow = dayjs().diff(parsed, 'year')
    return yearsToNow >= minAge && yearsToNow <= maxAge
  }

  if (validationRequirement === 'weight') {
    const valInt = parseInt(String(val).replace(/\D/gi, ''))
    return !isNaN(valInt) && minWeight <= valInt && valInt <= maxWeight
  }

  if (validationRequirement === 'height') {
    return !!stringToSplitNumbersArray(val).length
  }

  if (validationRequirement === 'postal-code') {
    // we use US 5-digit zip codes
    return !!val.match(/^[0-9]{5}$/)
  }

  if (validationRequirement === 'phone') {
    return val && isValidPhoneNumber(val)
  }

  // default validation is non-empty
  return !!val
}
