import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { UnstyledList, UnstyledListItem } from '../components/UnstyledList'
import { VerticalContainer } from '../components/VerticalContainer'
import { bp } from '../components/styled/breakpoints'
import { Fade } from '../components/styled/containers'
import {
  blockAndDim,
  transitionMedium,
  textAlignCenter,
  marginBottom,
  marginTop,
  marginLeft,
} from '../components/styled/utility'
import { routing } from '../model/Routing/routing'
import { RadioButton, NextPrevButton, RadioLabel, Body1, H3, Subhead } from '../ui-kit'
import history from '../utils/history'
import { useDispatch, useSelector } from '../store'
import { setSurveyResults } from '../store/onboarding'

const InputStyled = styled.input`
  width: 345px;
  font-size: 16px;
  border-radius: 5px;
  border: solid 0.2px lightgrey;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-bottom: 16px;
  margin-top: 16px;
  margin-left: 40px;
`

const OnboardingFitnessAssessment = ({ match: { params } }: RouteComponentProps<{ questionNumber: string }>) => {
  const dispatch = useDispatch()
  const questionNumber = Number(params.questionNumber)
  const theme = useTheme()

  const questionsData = useSelector((state) => state.onboarding.steps)
  const answersData = useSelector((state) => state.onboarding.results)

  const [hasError, setErrorStatus] = useState(false)
  const questionStepId = questionsData?.filter((ques) => ques.order === questionNumber)[0]?.step_id
  useEffect(() => {
    const questionNumberInt = questionNumber
    if (isNaN(questionNumberInt) || questionNumberInt < 1 || questionNumberInt > questionsData.length) {
      history.push(
        routing.onboarding.fitnessAssessment.generatePath({
          questionNumber: 1,
        }),
      )
    }
  }, [questionNumber, questionsData])

  // local form state starts with answers from API onboarding/
  const [formState, setFormState] = useState(answersData || [])
  const [resultSelected, setResultSelected] = useState<Record<string, string>>()
  const [otherText, setOtherText] = useState('')

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = target
    const optionName = name.split('-')[0]
    setErrorStatus(false)

    if (questionStepId === optionName && optionName) {
      if (!questionTypeMultiSelect) {
        setResultSelected({ step_id: optionName, result: value })
      } else {
        setResultSelected({
          step_id: optionName,
          result: resultSelected?.result ? `${resultSelected?.result}, ${value}` : value,
        })
      }
      // general form state is kept locally
      formState[questionNumber - 1] = {
        step_id: optionName,
        result: resultSelected?.result && questionTypeMultiSelect ? `${resultSelected?.result}, ${value}` : value,
        result_detail: questionTypeMultiSelect
          ? {
              other: resultSelected?.result?.includes('9') ? otherText : '',
              results:
                resultSelected?.result && questionTypeMultiSelect ? [`${resultSelected?.result}, ${value}`] : [value],
            }
          : undefined,
      }
      setFormState(formState)
    }
  }

  const handleOtherTextChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setOtherText(target.value)
  }

  const [isPosting, setIsPosting] = useState(false)

  useEffect(
    () =>
      history.listen(() => {
        // reset isPosting
        setIsPosting(false)
      }),
    [],
  )

  const onClickNext = () => {
    setResultSelected({ step_id: '', result: '' })

    if (!formState![questionNumber - 1]) {
      return setErrorStatus(true)
    }

    const questionNumberInt = questionNumber
    const atEndOfQuestionSet = questionNumberInt >= questionsData.length

    // PUT may or may not be called, logic whether to call it follows underneath
    const putOnboarding = () => {
      try {
        // use isPosting to block user interactions
        // e.g. prevent Next click during network delays
        setIsPosting(true)
        dispatch(setSurveyResults({ results: formState || [] }))

        if (atEndOfQuestionSet) {
          // reached end of question set, advance to curating after PATCH
          history.push(routing.onboarding.curating.generatePath())
        } else {
          // not at end of question set; continue to next question
          history.push(
            routing.onboarding.fitnessAssessment.generatePath({
              questionNumber: questionNumberInt,
            }),
          )
        }
      } catch (e) {
        // TODO API error handling #740
        setIsPosting(false)
      }
    }

    // only PUT if the new answer doesn't match their old API answer data
    if (atEndOfQuestionSet) {
      // either the answer has changed or user is finished with questions
      putOnboarding()
    } else {
      // no answer change, proceed to next question without PUT
      history.push(
        routing.onboarding.fitnessAssessment.generatePath({
          questionNumber: questionNumberInt + 1,
        }),
      )
    }
  }

  const questionTypeMultiSelect = questionsData.find((ques) => ques.order === questionNumber)?.type === 'multi_select'
  const questionTitle = questionsData.find((ques) => ques.order === questionNumber)?.question
  const questionOptions = questionsData.find((ques) => ques.order === questionNumber)?.additional_fields

  return (
    <Fade>
      <VerticalContainer
        css={{
          padding: '70px 32px 36px',
          maxWidth: 420,
          [bp.sm]: {
            paddingTop: 52,
          },
        }}
      >
        <div>
          <header css={[textAlignCenter, marginBottom('5rem')]}>
            <H3
              css={{
                [bp.sm]: {
                  fontSize: '1.75rem',
                  lineHeight: 1.5,
                },
              }}
            >
              Fitness Profile Survey
            </H3>
            <Subhead css={{ color: theme.textColors.secondary }}>
              {`Question ${questionNumber} of ${questionsData.length}`}
            </Subhead>
          </header>
          <div css={[blockAndDim(isPosting), transitionMedium]}>
            <Body1 css={[marginBottom('0.5rem')]}>{questionTitle}</Body1>
            <Body1
              css={[
                {
                  color: theme.colors.red2,
                  opacity: hasError ? 1 : 0,
                  height: hasError ? 32 : 0,
                },
                transitionMedium,
              ]}
            >
              Please select an answer
            </Body1>
            <UnstyledList css={[marginTop(38), marginBottom(38)]}>
              {questionOptions?.options.map((option) => (
                <UnstyledListItem key={`question-${option.id}`} css={[marginBottom(28)]}>
                  <RadioLabel>
                    <RadioButton
                      checked={
                        formState[questionNumber - 1]?.step_id === questionStepId &&
                        formState[questionNumber - 1]?.result.includes(option.id)
                      }
                      id={option.id}
                      name={`${questionStepId}-${option.id}`}
                      value={option.id}
                      onChange={handleChange}
                      multiple={questionTypeMultiSelect}
                    />
                    <Body1 css={[marginLeft('1rem')]}>{option.text}</Body1>
                  </RadioLabel>
                  {questionTypeMultiSelect && option.id === '9' && resultSelected?.result?.indexOf('9') !== -1 ? (
                    <InputStyled
                      value={otherText}
                      onChange={handleOtherTextChange}
                      placeholder="Enter more info."
                      id="otherText"
                    />
                  ) : null}
                </UnstyledListItem>
              ))}
            </UnstyledList>
          </div>
        </div>
        <NextPrevButton onClick={onClickNext} text="Next" fullWidth />
      </VerticalContainer>
    </Fade>
  )
}

export default OnboardingFitnessAssessment
