import styled from '@emotion/styled'

export const TabsHead = styled.div<{
  borderSize?: number
  fullWidth?: boolean
}>(({ theme, borderSize = 3, fullWidth }) => ({
  display: 'flex',
  position: 'relative',
  width: fullWidth ? '100%' : 'auto',
  paddingBottom: borderSize,

  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,

    width: '100%',
    height: borderSize,

    borderRadius: borderSize / 2,
    backgroundColor: theme.textColors.ternary,
  },
}))
