import React, { VFC } from 'react'

import { IconProps } from './types'

const Camera: VFC<IconProps> = (props) => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M92 76C92 78.1217 91.1571 80.1566 89.6568 81.6569C88.1566 83.1571 86.1217 84 84 84H12C9.87827 84 7.84344 83.1571 6.34315 81.6569C4.84285 80.1566 4 78.1217 4 76V32C4 29.8783 4.84285 27.8434 6.34315 26.3431C7.84344 24.8429 9.87827 24 12 24H28L36 12H60L68 24H84C86.1217 24 88.1566 24.8429 89.6568 26.3431C91.1571 27.8434 92 29.8783 92 32V76Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 68C56.8366 68 64 60.8366 64 52C64 43.1634 56.8366 36 48 36C39.1634 36 32 43.1634 32 52C32 60.8366 39.1634 68 48 68Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Camera
