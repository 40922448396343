import { axios } from 'system/axios'

import { WorkoutViewEntity } from '../entities/WorkoutViewEntity'

export type WorkoutSession = WorkoutViewEntity

export const getWorkoutSessions = () =>
  axios.get<WorkoutSession[]>('/v2/workout-session', {
    baseURL: process.env.REACT_APP_API_URL,
    params: {
      s: { is_in_program: { $in: [false, true] } },
    },
  })
