import styled from '@emotion/styled'

export const Button1 = styled.span(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '1.125rem', // 18px
  lineHeight: '24px', // in pixels because all buttons use same line-height
  letterSpacing: 2,
  fontWeight: 500,
  textTransform: 'uppercase',
}))
