import { Switch, useHistory } from 'react-router-dom'

import AuthenticatedRoutesInterior from './AuthenticatedRoutesInterior'
import AuthenticatedRoutesOnboarding from './AuthenticatedRoutesOnboarding'
import AppContainer from './components/AppContainer'
import HeaderMainDesktop from './components/HeaderMainDesktop'
import HeaderMainMobile from './components/HeaderMainMobile'
import NavMenu from './components/NavMenu'
import PrivateRoute from './components/PrivateRoute'
import { desktopOnly, mobileOnly } from './components/styled/utility'
import { useRouting, useWindowResize } from './hooks'
import { DialogRenderRoot } from './lib/dialog-manager'
import { PCIProvider, NavigationProvider } from './model'
import { routing } from './model/Routing/routing'
import { useSelector } from './store'
import { hasCompletedOnboardingSelector } from './store/user'
import { AddCardStep } from './views/onboarding/AddCardStep'

const AppAuthenticated = () => {
  const {
    location: { pathname },
  } = useHistory()

  useWindowResize()

  const require_cc = useSelector((state) => state.user.require_cc)
  const hasCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const paymentMethod = useSelector((state) => state.billing.paymentMethod)

  // Don't display anything until routing to the appropriate page first
  const readyToDisplay = useRouting()
  const completedOnboardingAndHasCC = hasCompletedOnboarding && (!require_cc || paymentMethod)

  return readyToDisplay ? (
    <PCIProvider>
      <AppContainer haveSandBackground={routing.home.isActive(pathname)}>
        <DialogRenderRoot />
        {completedOnboardingAndHasCC ? (
          <NavigationProvider>
            <HeaderMainMobile css={mobileOnly} />
            <HeaderMainDesktop css={desktopOnly} />
            <NavMenu />
          </NavigationProvider>
        ) : null}
        {completedOnboardingAndHasCC ? (
          <AuthenticatedRoutesInterior />
        ) : hasCompletedOnboarding ? (
          <Switch>
            <PrivateRoute path={routing.onboarding.addCard.pattern} component={AddCardStep} title="Add card" />
          </Switch>
        ) : (
          <AuthenticatedRoutesOnboarding />
        )}
      </AppContainer>
    </PCIProvider>
  ) : null
}

export default AppAuthenticated
