import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'

import { setToTheLocalStorage } from '../utils'

import { billingSlice } from './billing'
import { errorSlice } from './error'
import { inviteSlice } from './invite'
import { membershipSlice } from './membership'
import { userSlice } from './user'
import { onboardingSlice } from './onboarding'

const reducer = combineReducers({
  user: userSlice.reducer,
  billing: billingSlice.reducer,
  error: errorSlice.reducer,
  membership: membershipSlice.reducer,
  invite: inviteSlice.reducer,
  onboarding: onboardingSlice.reducer,
})

const lsKeyMap = {
  user: 'userState',
}

const middleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)

  const state = store.getState()

  Object.entries(lsKeyMap).forEach(([stateKey, lsKey]) => {
    setToTheLocalStorage(lsKey, JSON.stringify(state[stateKey]))
  })

  return result
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
})

export type RootState = ReturnType<typeof reducer>

type TDispatch = typeof store.dispatch
export const useDispatch = (): TDispatch => useReduxDispatch<TDispatch>()

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
