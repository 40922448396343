import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { initiateUserLogin, mergeUserProfile } from 'store/user'

import { TIMING } from '../../constants'
import { useSuccessMessageQueryParams } from '../../hooks'
import { routing } from '../../model/Routing/routing'

export const useResetEmailPresenter = () => {
  const {
    location: { pathname, search },
    push,
  } = useHistory()

  const dispatch = useDispatch()

  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
  const { message, isSucceed, hasFailed } = useSuccessMessageQueryParams(search)

  const [isFadingOut, setIsFadingOut] = useState(false)

  const title = isSucceed ? 'Your password has been changed' : 'There was a problem with password reset'

  /*
   * Immediate redirects (prior to initial page load)
   */
  useEffect(() => {
    if (!isSucceed && !hasFailed) {
      // if auth0 is not providing a 'success' search param, then leave this page
      push(routing.home.generatePath())
    }
    if (isSucceed && !isLoading && isAuthenticated) {
      /*
       * if the user is authenticated and reset password was successful,
       * then log them out (which sends them to Launch), and have Launch
       * send them back here with the same params (via redirectAfterLogoutLocation)
       */
      dispatch(
        mergeUserProfile({
          redirectAfterLogoutLocation: pathname + search,
        }),
      )
      logout({ returnTo: window.location.origin })
    }
  }, [isSucceed, hasFailed, isAuthenticated, isLoading, logout, pathname, search, push, dispatch])

  const userCanViewPage = !isAuthenticated || (isAuthenticated && hasFailed)

  const isIn = !isLoading && userCanViewPage && !isFadingOut

  const onClick = () => {
    setIsFadingOut(true)
    setTimeout(() => {
      if (isAuthenticated && hasFailed) {
        push(routing.home.generatePath())
      } else {
        dispatch(initiateUserLogin())
        loginWithRedirect({ fragment: 'signIn' })
      }
    }, TIMING.medium)
  }

  return {
    isIn,
    title,
    message,
    onClick,
  }
}
