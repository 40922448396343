import React, { VFC } from 'react'

import { IconProps } from './types'

const FormeLogo: VFC<IconProps & { readonly color?: string; readonly isMobile?: boolean }> = ({
  color,
  isMobile = false,
  ...props
}) => (
  <svg
    width={isMobile ? 220 : 140}
    height={isMobile ? 32 : 26}
    viewBox="0 0 140 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={{ transform: 'scale(1)' }}
    {...props}
  >
    {isMobile === false && (
      <>
        <path
          d="M21.3652 2.19631L21.5915 2.25674C23.1092 2.65228 23.9812 3.94876 23.9812 5.15185V23.9344H27.8278V13.2329H36.4481V11.876H27.8278V3.24009H38.1535V1.88867H21.3652V2.19631Z"
          fill={color}
        />
        <path
          d="M52.7788 1.41699C47.6132 1.41699 42.0889 4.42197 42.0889 12.8491C42.0889 20.0237 46.0845 24.3087 52.7788 24.3087C59.4731 24.3087 63.4687 20.0237 63.4687 12.8491C63.4632 4.42197 57.9444 1.41699 52.7788 1.41699ZM52.7788 23.177C48.579 23.177 46.0679 19.315 46.0679 12.8546C46.0679 6.48206 48.6397 2.52669 52.7788 2.52669C56.9179 2.52669 59.4897 6.48206 59.4897 12.8491C59.4897 19.315 56.9786 23.177 52.7788 23.177Z"
          fill={color}
        />
        <path
          d="M86.3332 8.40953C86.3332 4.20146 82.9447 1.88867 77.3541 1.88867H67.1885V2.19631L67.4534 2.22927C68.9986 2.42155 69.8044 3.66859 69.8044 4.79477V23.9234H73.651V3.24009H76.6698C79.6113 3.24009 82.6412 4.10807 82.6412 8.40953C82.6412 10.4037 81.6974 12.1726 79.8376 13.0077C78.2647 13.7108 76.6036 13.7877 75.8916 13.7877C75.6212 13.7877 75.4612 13.7767 75.4557 13.7767L74.6775 13.7273L84.0981 23.9289H88.4525L80.5385 15.3369C82.5253 14.848 86.3332 13.145 86.3332 8.40953Z"
          fill={color}
        />
        <path
          d="M139.555 3.24009V1.88867H122.767V2.19631L122.987 2.25674C124.533 2.68524 125.383 3.59168 125.383 4.80576V23.9289H139.555V22.572H129.229V13.2329H137.85V11.876H129.229V3.24009H139.555Z"
          fill={color}
        />
        <path
          d="M105.842 17.7382L98.2594 2.05958L98.1546 1.88379H92.0342V2.19692L92.2549 2.25735C93.7947 2.68585 94.6446 3.58679 94.6501 4.79538V23.9295H96.4327V7.27847L104.634 24.2756L113.033 6.61924V23.9295H116.88V1.88928H113.298L105.842 17.7382Z"
          fill={color}
        />
      </>
    )}
    <path
      d="M5.41504 3.77425H9.97358C10.6469 3.77425 11.1988 4.32361 11.1988 4.99382V15.9645H13.9858V4.99382C13.9858 2.7909 12.1866 1 9.97358 1H5.41504"
      fill={color}
    />
    <path
      d="M5.41504 3.77425H9.97358C10.6469 3.77425 11.1988 4.32361 11.1988 4.99382V15.9645H13.9858V4.99382C13.9858 2.7909 12.1866 1 9.97358 1H5.41504"
      stroke={color}
      strokeWidth="0.4504"
      strokeMiterlimit="10"
    />
    <path
      d="M9.5652 22.2255H5.00666C4.33336 22.2255 3.78148 21.6762 3.78148 21.006V9.7002H1V21.006C1 23.2089 2.79913 24.9998 5.01218 24.9998H9.57071"
      fill={color}
    />
    <path
      d="M9.5652 22.2255H5.00666C4.33336 22.2255 3.78148 21.6762 3.78148 21.006V9.7002H1V21.006C1 23.2089 2.79913 24.9998 5.01218 24.9998H9.57072"
      stroke={color}
      strokeWidth="0.4504"
      strokeMiterlimit="10"
    />
  </svg>
)

export default FormeLogo
