import styled from '@emotion/styled'

import { Body2 } from '../Typography'
import { size } from '../utils'

import { DayContainer } from './DayContainer'
import { Color } from '../../model/ThemeProvider/theme'

const Date = styled(Body2)<{ isDimmed?: boolean }>(({ isDimmed, theme }) => ({
  color: isDimmed ? theme.colors.blackAlpha50 : theme.colors.black,
  lineHeight: 1,
  marginBottom: 5,
}))

const WorkoutDotContainer = styled.div({
  display: 'flex',
  gap: 2,
  height: 6,
})

const COLORS_MAP: Record<string, Color> = {
  Recovery: 'green',
  Mind: 'magenta',
}

const WorkoutDot = styled.div<{ topLevelCategory?: string }>(({ topLevelCategory = '', theme }) => {
  const dotColor = theme.colors[COLORS_MAP[topLevelCategory] || 'orange']

  return {
    display: 'block',
    background: topLevelCategory && dotColor,
    border: `1px solid ${topLevelCategory ? dotColor : theme.textColors.secondary}`,
    borderRadius: 1,
    boxSizing: 'border-box',
  }
}, size(6))

export const Day = ({
  date,
  dayClassesArePlanned,
  dayClassesFinished,
  isSelected,
  isToday,
  onClick,
  ...props
}: any) => {
  const isDimmed = dayClassesFinished?.length === 0 && !dayClassesArePlanned

  return (
    <DayContainer
      className={`testing-day${isToday ? '-today' : ''}`}
      data-qa={isSelected ? 'is-selected' : ''}
      isSelected={isSelected}
      isToday={isToday}
      onClick={onClick}
      {...props}
    >
      <Date isDimmed={isDimmed}>{date}</Date>
      <WorkoutDotContainer>
        {dayClassesFinished
          ? dayClassesFinished.map((topLevelCategory: string) => (
              <WorkoutDot key={topLevelCategory} topLevelCategory={topLevelCategory} />
            ))
          : dayClassesArePlanned && <WorkoutDot />}
      </WorkoutDotContainer>
    </DayContainer>
  )
}
