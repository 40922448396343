import styled from '@emotion/styled'

export const Overline1 = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '1rem',
  lineHeight: 1,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
}))
