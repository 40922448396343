import React, { createContext, FC, useContext } from 'react'

import { usePCIApi, PciApiState } from './usePCIApi'

// NOTE: use only for tests PCIContext.Provider to simplify hooks mocking ;)
export const PCIContext = createContext<PciApiState['data'] | null>(null)
export const usePCIContext = () => useContext(PCIContext)

export const PCIProvider: FC = ({ children }) => {
  const { data } = usePCIApi()
  return <PCIContext.Provider value={data}>{children}</PCIContext.Provider>
}
