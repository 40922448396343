import styled from '@emotion/styled'
import React, { VFC } from 'react'

import { getFirstLetter } from '../../utils'

import { CircleWithInitials } from './CircleWithInitials'

const AvatarImage = styled.img({
  height: '100%',
  objectFit: 'cover',
  minWidth: '100%',
})

export const Avatar: VFC<{
  readonly image?: string
  readonly onError?: () => void
  readonly firstName?: string
  readonly lastName?: string
  readonly isWhite?: boolean
  readonly isLarge?: boolean
  readonly className?: string
}> = ({ image, onError, firstName, lastName, isWhite, isLarge, className }) => {
  if (!image) {
    return (
      <CircleWithInitials isWhite={isWhite} isLarge={isLarge} className={className}>
        {`${getFirstLetter(firstName)}${getFirstLetter(lastName)}`}
      </CircleWithInitials>
    )
  }

  return <AvatarImage onError={onError} src={image} alt="Profile photo" className={className} />
}
