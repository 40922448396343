import styled from '@emotion/styled'

import { size } from '../utils'

export const AlertCircle = styled.span(
  ({ theme }) => ({
    display: 'block',
    position: 'absolute',
    top: '9%',
    right: -12,
    borderRadius: '50%',
    background: theme.colors.red2,
  }),
  size(7),
)
