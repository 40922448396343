import { CATEGORY_MAPPER } from './category-mapper'

// unless it is something we know it will shorter the category, otherwise it's 'active'
// TODO Use it instead of the current
export const categoryToTopLevelCategoryPlusCustoms = (category?: string) => {
  const defaultCategory = CATEGORY_MAPPER.active

  if (!category) {
    return defaultCategory
  }

  return Object.values(CATEGORY_MAPPER).reduce((accumulatedCategory, possibleCategory) =>
    accumulatedCategory !== defaultCategory
      ? accumulatedCategory
      : !!category.match(new RegExp(possibleCategory, 'gi'))
      ? possibleCategory
      : defaultCategory,
  )
}
