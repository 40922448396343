import { useDialogManager } from 'lib/dialog-manager'
import { useSelector } from 'store'
import { hasCompletedOnboardingSelector } from 'store/user'

import { Button, ButtonSection, ButtonSolid } from '../components/styled/button'
import { Fade } from '../components/styled/containers'
import { paddingTop, paddingVertical } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import { textAlignCenter } from '../components/styled/utility'
import { routing } from '../model/Routing/routing'
import { Button3 } from '../ui-kit'
import { subscriptionPrice } from '../utils/constants'
import history from '../utils/history'

import CollectCreditCard from './CollectCreditCard'

const DISPLAY_STATE = {
  error: 'error',
  loading: 'loading',
  fetchingExistingMemberships: 'fetchingExistingMemberships',
  waitingForPaymentMethod: 'waitingForPaymentMethod',
  creatingMembership: 'creatingMembership',
  finished: 'finished',
}

const MembershipSetup = ({
  displayState,
  onPaymentMethodCaptured,
  isPartOfDialog,
}: {
  readonly displayState: string
  readonly onPaymentMethodCaptured: (paymentMethodId: string) => void
  readonly isPartOfDialog?: boolean
}) => {
  const { hideCurrentDialog } = useDialogManager()

  const hasCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)

  switch (displayState) {
    case DISPLAY_STATE.error:
      return (
        <Fade>
          <P css={[textAlignCenter, paddingVertical('md')]}>There was an error during the process.</P>
          {!hasCompletedOnboarding && (
            <ButtonSection>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setTimeout(() => {
                    history.push(routing.onboarding.welcome.generatePath())
                  }, 0)
                }}
              >
                Continue
              </Button>
            </ButtonSection>
          )}
        </Fade>
      )
    case DISPLAY_STATE.loading:
      return (
        <Fade>
          <P css={[textAlignCenter, paddingVertical('md')]}>Loading...</P>
        </Fade>
      )
    case DISPLAY_STATE.fetchingExistingMemberships:
    case DISPLAY_STATE.waitingForPaymentMethod:
      return (
        <Fade>
          <P>Your FORME membership gives you access to our catalog of On Demand content.</P>
          <P>
            If you purchased a FORME Studio or FORME Lift your membership will begin once you sign into your hardware.
          </P>
          <P>
            If you did not purchase hardware and are training only with the FORME app, your membership will begin
            immediately upon submitting this form.
          </P>
          <CollectCreditCard
            css={paddingTop('lg')}
            onPaymentMethodCaptured={onPaymentMethodCaptured}
            optionalMarkupAboveButton={
              <P css={paddingTop('md')}>
                By tapping “{isPartOfDialog ? 'Submit' : 'Next'}” and signing up for a FORME membership, you accept
                FORME Terms of Service and understand that your subscription will be for an ongoing service with
                recurring monthly payments of {subscriptionPrice}, until cancellation of your membership. You may cancel
                your membership at anytime by visiting your Membership page.
              </P>
            }
            submitButtonText={isPartOfDialog ? 'Submit' : 'Next'}
          />
        </Fade>
      )
    case DISPLAY_STATE.creatingMembership:
      return (
        <Fade>
          <P css={[textAlignCenter, paddingVertical('md')]}>Creating membership...</P>
        </Fade>
      )
    case DISPLAY_STATE.finished:
      return (
        <Fade>
          <P css={[textAlignCenter, paddingVertical('md')]}>Membership created!</P>
          <ButtonSection>
            {!isPartOfDialog ? (
              <Button
                onClick={() => {
                  setTimeout(() => {
                    history.push(routing.home.generatePath())
                  }, 0)
                }}
              >
                Next
              </Button>
            ) : (
              <ButtonSolid
                onClick={() => {
                  setTimeout(() => {
                    hideCurrentDialog()
                  }, 0)
                }}
              >
                <Button3>Next</Button3>
              </ButtonSolid>
            )}
          </ButtonSection>
        </Fade>
      )
    default:
      return <P css={[paddingVertical('lg'), textAlignCenter]}>Unexpected state : {displayState}</P>
  }
}

export default MembershipSetup
