import styled from '@emotion/styled'
import { bp } from '../../components/styled/breakpoints'
import { Filters } from '../../icons'
import { Button3 } from '../Typography'

import { ButtonContainer } from './ButtonContainer'
import { PureButton } from './PureButton'

const FilterButton = styled(PureButton)({
  padding: 6,
})

const ButtonText = styled(Button3)({
  display: 'none',

  [bp.sm]: {
    display: 'block',
    marginRight: 10,
  },
})

export const FiltersButton = ({ onClick }: { readonly onClick: () => void }) => (
  <FilterButton onClick={onClick}>
    <ButtonContainer>
      <ButtonText>Filter</ButtonText>
      <Filters />
    </ButtonContainer>
  </FilterButton>
)
