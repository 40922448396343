import styled from '@emotion/styled'

export const H2 = styled.h3(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '1.75rem',
  lineHeight: 1.4285,
  fontWeight: 700,
  margin: 0,
  padding: 0,
}))
