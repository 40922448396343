type StudioRole = 'consumer' | 'commercial' | 'development' | 'retail_formelife' | 'retail_barrys' | 'retail_preview'

export enum StudioType {
  studio = 'studio',
  studio_lift = 'studio_lift',
}

export const studioTypeMap = {
  [StudioType.studio]: 'Studio',
  [StudioType.studio_lift]: 'Lift',
}

export type Studio = {
  created_date?: string
  has_actual_keys?: boolean
  id: string
  install_date?: string
  lift_id?: string | null
  lift_only_mode?: boolean
  lift_registration_date?: null
  product_type: 'studio' | 'studio_lift'
  profile_id?: string
  public_key?: string
  registration_version?: number
  roles?: StudioRole[]
  updated_date?: string
}

type Feature = 'PT' | 'TPI' | 'VOD' | 'CPS' | 'ASSESSMENTS' | '.default'

export type MembershipProfile = {
  allowedClients: 'android_phone' | 'android_tablet' | 'ios_phone' | 'ios_tablet' | 'studio' | 'studio_lift' | 'web'
  created_date: string
  detach_date: string | null
  featureTags: {
    membershipFeatures?: Feature[]
    personalFeatures?: Feature[]
  }
  id: string
  membershipId: string
  role: 'owner' | 'guest' | 'admin'
  studios: Studio[]
  updated_date: string
  userId: string
}
