import { css } from '@emotion/react'

import { SPACING } from '../../constants'

import { bp } from './breakpoints'

export const responsiveRowGap = (size: keyof typeof SPACING.verticalBase) =>
  css({
    rowGap: `${SPACING.verticalBase[size]}em`,

    [bp.xxs]: {
      rowGap: `${SPACING.verticalBase[size] * SPACING.multipliers.vertical.xs}em`,
    },
    [bp.sm]: {
      rowGap: `${SPACING.verticalBase[size] * SPACING.multipliers.vertical.sm}em`,
    },
    [bp.md]: {
      rowGap: `${SPACING.verticalBase[size] * SPACING.multipliers.vertical.md}em`,
    },
    [bp.lg]: {
      rowGap: `${SPACING.verticalBase[size] * SPACING.multipliers.vertical.lg}em`,
    },
    [bp.xl]: {
      rowGap: `${SPACING.verticalBase[size] * SPACING.multipliers.vertical.xl}em`,
    },
  })

export const responsiveColumnGap = (size: keyof typeof SPACING.horizontalBase) =>
  css({
    columnGap: `${SPACING.horizontalBase[size]}em`,

    [bp.xxs]: {
      columnGap: `${SPACING.horizontalBase[size] * SPACING.multipliers.horizontal.xs}em`,
    },
    [bp.sm]: {
      columnGap: `${SPACING.horizontalBase[size] * SPACING.multipliers.horizontal.sm}em`,
    },
    [bp.md]: {
      columnGap: `${SPACING.horizontalBase[size] * SPACING.multipliers.horizontal.md}em`,
    },
    [bp.lg]: {
      columnGap: `${SPACING.horizontalBase[size] * SPACING.multipliers.horizontal.lg}em`,
    },
    [bp.xl]: {
      columnGap: `${SPACING.horizontalBase[size] * SPACING.multipliers.horizontal.xl}em`,
    },
  })

const marginSizer = (
  baseSize: number,
  multiplier: typeof SPACING.multipliers.horizontal,
  { top, right, bottom, left }: { top?: boolean; right?: boolean; bottom?: boolean; left?: boolean },
  isMobileOnly?: boolean,
  isDesktopOnly?: boolean,
) =>
  css({
    marginTop: !isDesktopOnly && !isMobileOnly && top ? `${baseSize}em` : undefined,
    marginRight: !isDesktopOnly && !isMobileOnly && right ? `${baseSize}em` : undefined,
    marginLeft: !isDesktopOnly && !isMobileOnly && left ? `${baseSize}em` : undefined,
    marginBottom: !isDesktopOnly && !isMobileOnly && bottom ? `${baseSize}em` : undefined,

    [bp.xxs]: {
      marginTop: !isDesktopOnly && !isMobileOnly && top ? `${baseSize * multiplier.xs}em` : undefined,
      marginRight: !isDesktopOnly && !isMobileOnly && right ? `${baseSize * multiplier.xs}em` : undefined,
      marginLeft: !isDesktopOnly && !isMobileOnly && left ? `${baseSize * multiplier.xs}em` : undefined,
      marginBottom: !isDesktopOnly && !isMobileOnly && bottom ? `${baseSize * multiplier.xs}em` : undefined,
    },
    [bp.xsMax]: {
      marginTop: isMobileOnly && top ? `${baseSize}em` : undefined,
      marginRight: isMobileOnly && right ? `${baseSize}em` : undefined,
      marginBottom: isMobileOnly && bottom ? `${baseSize}em` : undefined,
      marginLeft: isMobileOnly && left ? `${baseSize}em` : undefined,
    },
    [bp.sm]: {
      marginTop: !isMobileOnly && top ? `${baseSize * multiplier.sm}em` : undefined,
      marginRight: !isMobileOnly && right ? `${baseSize * multiplier.sm}em` : undefined,
      marginBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.sm}em` : undefined,
      marginLeft: !isMobileOnly && left ? `${baseSize * multiplier.sm}em` : undefined,
    },
    [bp.md]: {
      marginTop: !isMobileOnly && top ? `${baseSize * multiplier.md}em` : undefined,
      marginRight: !isMobileOnly && right ? `${baseSize * multiplier.md}em` : undefined,
      marginBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.md}em` : undefined,
      marginLeft: !isMobileOnly && left ? `${baseSize * multiplier.md}em` : undefined,
    },
    [bp.lg]: {
      marginTop: !isMobileOnly && top ? `${baseSize * multiplier.lg}em` : undefined,
      marginRight: !isMobileOnly && right ? `${baseSize * multiplier.lg}em` : undefined,
      marginBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.lg}em` : undefined,
      marginLeft: !isMobileOnly && left ? `${baseSize * multiplier.lg}em` : undefined,
    },
    [bp.xl]: {
      marginTop: !isMobileOnly && top ? `${baseSize * multiplier.xl}em` : undefined,
      marginRight: !isMobileOnly && right ? `${baseSize * multiplier.xl}em` : undefined,
      marginBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.xl}em` : undefined,
      marginLeft: !isMobileOnly && left ? `${baseSize * multiplier.xl}em` : undefined,
    },
  })
export const marginTop = (size: keyof typeof SPACING.verticalBase, isMobileOnly = false, isDesktopOnly = false) =>
  marginSizer(
    SPACING.verticalBase[size],
    SPACING.multipliers.vertical,
    {
      top: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )
export const marginBottom = (size: keyof typeof SPACING.verticalBase) =>
  marginSizer(SPACING.verticalBase[size], SPACING.multipliers.vertical, {
    bottom: true,
  })
export const marginVertical = (size: keyof typeof SPACING.verticalBase) =>
  marginSizer(SPACING.verticalBase[size], SPACING.multipliers.vertical, {
    top: true,
    bottom: true,
  })
export const marginHorizontal = (
  size: keyof typeof SPACING.verticalBase,
  isMobileOnly = false,
  isDesktopOnly = false,
) =>
  marginSizer(
    SPACING.horizontalBase[size],
    SPACING.multipliers.horizontal,
    {
      left: true,
      right: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )

export const marginRight = (size: keyof typeof SPACING.verticalBase) =>
  marginSizer(SPACING.horizontalBase[size], SPACING.multipliers.horizontal, {
    right: true,
  })
export const marginLeftRightAuto = css({
  marginLeft: 'auto',
  marginRight: 'auto',
})

const paddingSizer = (
  baseSize: number,
  multiplier: typeof SPACING.multipliers.horizontal,
  { top, right, bottom, left }: { top?: boolean; right?: boolean; bottom?: boolean; left?: boolean },
  isMobileOnly?: boolean,
  isDesktopOnly?: boolean,
  emOrPx = 'em',
) =>
  css({
    paddingTop: !isDesktopOnly && !isMobileOnly && top ? `${baseSize}${emOrPx}` : undefined,
    paddingRight: !isDesktopOnly && !isMobileOnly && right ? `${baseSize}${emOrPx}` : undefined,
    paddingBottom: !isDesktopOnly && !isMobileOnly && bottom ? `${baseSize}${emOrPx}` : undefined,
    paddingLeft: !isDesktopOnly && !isMobileOnly && left ? `${baseSize}${emOrPx}` : undefined,

    [bp.xxs]: {
      paddingTop: !isDesktopOnly && !isMobileOnly && top ? `${baseSize * multiplier.xs}${emOrPx}` : undefined,
      paddingRight: !isDesktopOnly && !isMobileOnly && right ? `${baseSize * multiplier.xs}${emOrPx}` : undefined,
      paddingBottom: !isDesktopOnly && !isMobileOnly && bottom ? `${baseSize * multiplier.xs}${emOrPx}` : undefined,
      paddingLeft: !isDesktopOnly && !isMobileOnly && left ? `${baseSize * multiplier.xs}${emOrPx}` : undefined,
    },
    [bp.xsMax]: {
      paddingTop: isMobileOnly && top ? `${baseSize}${emOrPx}` : undefined,
      paddingRight: isMobileOnly && right ? `${baseSize}${emOrPx}` : undefined,
      paddingBottom: isMobileOnly && bottom ? `${baseSize}${emOrPx}` : undefined,
      paddingLeft: isMobileOnly && left ? `${baseSize}${emOrPx}` : undefined,
    },
    [bp.sm]: {
      paddingTop: !isMobileOnly && top ? `${baseSize * multiplier.sm}${emOrPx}` : undefined,
      paddingRight: !isMobileOnly && right ? `${baseSize * multiplier.sm}${emOrPx}` : undefined,
      paddingBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.sm}${emOrPx}` : undefined,
      paddingLeft: !isMobileOnly && left ? `${baseSize * multiplier.sm}${emOrPx}` : undefined,
    },
    [bp.md]: {
      paddingTop: !isMobileOnly && top ? `${baseSize * multiplier.md}${emOrPx}` : undefined,
      paddingRight: !isMobileOnly && right ? `${baseSize * multiplier.md}${emOrPx}` : undefined,
      paddingBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.md}${emOrPx}` : undefined,
      paddingLeft: !isMobileOnly && left ? `${baseSize * multiplier.md}${emOrPx}` : undefined,
    },
    [bp.lg]: {
      paddingTop: !isMobileOnly && top ? `${baseSize * multiplier.lg}${emOrPx}` : undefined,
      paddingRight: !isMobileOnly && right ? `${baseSize * multiplier.lg}${emOrPx}` : undefined,
      paddingBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.lg}${emOrPx}` : undefined,
      paddingLeft: !isMobileOnly && left ? `${baseSize * multiplier.lg}${emOrPx}` : undefined,
    },
    [bp.xl]: {
      paddingTop: !isMobileOnly && top ? `${baseSize * multiplier.xl}${emOrPx}` : undefined,
      paddingRight: !isMobileOnly && right ? `${baseSize * multiplier.xl}${emOrPx}` : undefined,
      paddingBottom: !isMobileOnly && bottom ? `${baseSize * multiplier.xl}${emOrPx}` : undefined,
      paddingLeft: !isMobileOnly && left ? `${baseSize * multiplier.xl}${emOrPx}` : undefined,
    },
  })
export const paddingTop = (size: keyof typeof SPACING.verticalBase, isMobileOnly = false, isDesktopOnly = false) =>
  paddingSizer(
    SPACING.verticalBase[size],
    SPACING.multipliers.vertical,
    {
      top: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )
// eslint-disable-next-line import/no-unused-modules
export const paddingTopPx = (sizeInPx: number, isMobileOnly = false, isDesktopOnly = false) =>
  paddingSizer(
    sizeInPx,
    SPACING.multipliers.vertical,
    {
      top: true,
    },
    isMobileOnly,
    isDesktopOnly,
    'px',
  )
export const paddingBottom = (size: keyof typeof SPACING.verticalBase, isMobileOnly = false, isDesktopOnly = false) =>
  paddingSizer(
    SPACING.verticalBase[size],
    SPACING.multipliers.vertical,
    {
      bottom: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )
export const paddingVertical = (size: keyof typeof SPACING.verticalBase, isMobileOnly = false, isDesktopOnly = false) =>
  paddingSizer(
    SPACING.verticalBase[size],
    SPACING.multipliers.vertical,
    {
      top: true,
      bottom: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )

export const paddingHorizontal = (
  size: keyof typeof SPACING.verticalBase,
  isMobileOnly = false,
  isDesktopOnly = false,
) =>
  paddingSizer(
    SPACING.horizontalBase[size],
    SPACING.multipliers.horizontal,
    {
      left: true,
      right: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )

export const paddingLeft = (
  size: keyof typeof SPACING.verticalBase,
  isMobileOnly = false,
  isDesktopOnly = false,
  emOrPx = 'em',
) =>
  paddingSizer(
    SPACING.horizontalBase[size],
    SPACING.multipliers.horizontal,
    {
      left: true,
    },
    isMobileOnly,
    isDesktopOnly,
    emOrPx,
  )
// eslint-disable-next-line import/no-unused-modules
export const paddingLeftPx = (sizeInPx: number, isMobileOnly = false, isDesktopOnly = false) =>
  paddingSizer(
    sizeInPx,
    SPACING.multipliers.horizontal,
    {
      left: true,
    },
    isMobileOnly,
    isDesktopOnly,
    'px',
  )
export const paddingRight = (size: keyof typeof SPACING.verticalBase, isMobileOnly = false, isDesktopOnly = false) =>
  paddingSizer(
    SPACING.horizontalBase[size],
    SPACING.multipliers.horizontal,
    {
      right: true,
    },
    isMobileOnly,
    isDesktopOnly,
  )
