import { createAsyncThunk } from '@reduxjs/toolkit'
import { axios } from 'system/axios'

import { MembershipCommercialDetail } from '../../api/entities/MembershipEntity'
import { Membership } from '../../api/membership/getMembership'

export const updateCommercial = createAsyncThunk(
  'updateCommercial',
  async ({ id, data }: { id: string; data: MembershipCommercialDetail }) => {
    const response = await axios.patch<Membership>(`/membership/${id}/commercial/`, data, {
      baseURL: `${process.env.REACT_APP_API_URL}/v2`,
    })

    return response.data
  },
)

export const getMembership = createAsyncThunk('getMembership', async () => {
  const response = await axios.get<{ data: Membership[] }>(`/membership`, {
    baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  })

  return response.data.data[0] || null
})
