import styled from '@emotion/styled'

import { Body4 } from '../../ui-kit'
import { size, lineHeight } from '../utils'
import { Color } from '../../model/ThemeProvider/theme'

const LEGEND_DATA: { color: Color; text: string }[] = [
  {
    color: 'orange',
    text: 'Active',
  },
  {
    color: 'green',
    text: 'Recovery',
  },
  {
    color: 'magenta',
    text: 'Mind',
  },
]

const LegendContainer = styled.div({
  display: 'flex',
})

const LegendItem = styled.div({
  paddingRight: 18,
  display: 'flex',
  alignItems: 'center',
})

const Square = styled.div<{ color: Color }>(
  ({ color, theme }) => ({
    background: color ? theme.colors[color] : undefined,
    borderRadius: 1,
    marginRight: 5,
  }),
  size(8),
)

export const Legend = () => {
  return (
    <LegendContainer>
      {LEGEND_DATA.map(({ color, text }) => (
        <LegendItem key={color}>
          <Square color={color} />
          <Body4 css={lineHeight(1)}>{text}</Body4>
        </LegendItem>
      ))}
    </LegendContainer>
  )
}
