import { useDialogManager } from 'lib/dialog-manager'
import { toMs } from 'lib/toMs'
import React, { useState, useEffect, useCallback, VFC, useMemo } from 'react'

import HeaderModal from '../components/HeaderModal'
import { Fade, constrain360, paddingVerticalContainerDefault } from '../components/styled/containers'
import { paddingTop } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import { blockAndDim } from '../components/styled/utility'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'

import CollectCreditCard from './CollectCreditCard'
import { useDispatch } from '../store'
import { setPaymentMethod } from '../store/billing'

const DISPLAY_STATE = {
  loading: 'loading',
  waitingForPaymentMethod: 'waitingForPaymentMethod',
  updatingPaymentMethod: 'updatingPaymentMethod',
  finished: 'finished',
}

const UpdatePaymentMethod: VFC<RegularDialogProps> = (props) => {
  const dispatch = useDispatch()

  const { hideCurrentDialog } = useDialogManager()

  const [displayState, setDisplayState] = useState(DISPLAY_STATE.loading)
  const [paymentMethodUpdated, setPaymentMethodUpdated] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading) {
      return
    }

    if (paymentMethodUpdated) {
      setDisplayState(DISPLAY_STATE.finished)
      setTimeout(hideCurrentDialog, toMs({ seconds: 1 }))
      return
    }

    // we're waiting for the user to enter their credit card
    setDisplayState(DISPLAY_STATE.waitingForPaymentMethod)
  }, [setDisplayState, paymentMethodUpdated, hideCurrentDialog, loading])

  const onPaymentMethodCaptured = useCallback(
    async (paymentMethodId: string) => {
      setLoading(true)
      setDisplayState(DISPLAY_STATE.updatingPaymentMethod)
      try {
        await dispatch(setPaymentMethod(paymentMethodId))
        setPaymentMethodUpdated(true)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    },
    [dispatch],
  )

  const creditCardCss = useMemo(() => paddingTop('md'), [])

  return (
    <RegularDialog {...props}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Update Payment" onClickControlLeft={hideCurrentDialog} />

      <Fade>
        <div css={[constrain360, paddingVerticalContainerDefault]}>
          <div css={[blockAndDim(loading)]}>
            {(() => {
              switch (displayState) {
                case DISPLAY_STATE.loading:
                  return <P>Loading...</P>
                case DISPLAY_STATE.waitingForPaymentMethod:
                  return (
                    <CollectCreditCard
                      css={creditCardCss}
                      onPaymentMethodCaptured={onPaymentMethodCaptured}
                      submitButtonText="Save"
                    />
                  )
                case DISPLAY_STATE.updatingPaymentMethod:
                  return <P>Updating payment method...</P>
                case DISPLAY_STATE.finished:
                  return <P>Finished!</P>
                default:
                  return <P>unexpected state :( {displayState}</P>
              }
            })()}
          </div>
        </div>
      </Fade>
    </RegularDialog>
  )
}

export default UpdatePaymentMethod
