import styled from '@emotion/styled'

export const Subhead = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.heading,
  fontSize: '1.5rem',
  lineHeight: 1.2,
  fontWeight: 500,
  letterSpacing: 1,
  textTransform: 'uppercase',
  margin: 0,
  padding: 0,
}))
