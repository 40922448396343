import styled from '@emotion/styled'

export const PureButton = styled.button({
  border: 0,
  padding: 0,
  margin: 0,
  background: 'transparent',
  cursor: 'pointer',

  '&:focus': {
    outline: 0,
  },
})
