import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect, useState } from 'react'

const useIsEmailVerified = () => {
  const { getIdTokenClaims } = useAuth0()
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | null | undefined>(null) // null | false | true

  const getToken = useCallback(async () => {
    const token = await getIdTokenClaims({})
    setIsEmailVerified(token.email_verified)
  }, [getIdTokenClaims])

  useEffect(() => {
    // isEmailVerified === null && getToken();
    getToken()
  }, [getToken])

  // const recheckEmailVerification = () => {
  //   getToken();
  // };

  return { isEmailVerified }
}
export default useIsEmailVerified
