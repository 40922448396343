import { lockBody, releaseBody } from 'lib/body-lock'
import { toMs } from 'lib/toMs'
import { nanoid } from 'nanoid'
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

export type DialogProps = { isShown: boolean; onClose: () => void }

type DialogComponent = FC<DialogProps>

type DialogState = {
  Component: DialogComponent
  key: string
  isClosed?: boolean
}

type DialogManagerContextType = {
  dialogs: DialogState[]
  showDialog: (Component: DialogComponent) => void
  hideCurrentDialog: () => void
}

const DialogManagerContext = createContext<DialogManagerContextType>({
  dialogs: [],
  showDialog: () => {},
  hideCurrentDialog: () => {},
})

const DESTROY_TIMEOUT = toMs({ seconds: 1 })

export const DialogManagerProvider: FC = ({ children }) => {
  const [dialogs, setDialogs] = useState<DialogState[]>([])

  useEffect(() => {
    if (dialogs.filter((_) => !_.isClosed).length > 0) {
      lockBody()
    } else {
      releaseBody()
    }
  }, [dialogs])

  const showDialog = useCallback(
    (Component: DialogComponent) => setDialogs((state) => [{ key: nanoid(), Component }, ...state]),
    [],
  )

  const hideCurrentDialog = useCallback(() => {
    setDialogs((state) => {
      const currentDialog = state.find((d) => !d.isClosed)
      return state.map((dialog) => (dialog.key === currentDialog?.key ? { ...dialog, isClosed: true } : dialog))
    })
    setTimeout(() => {
      setDialogs((state) => state.filter((d) => !d.isClosed))
    }, DESTROY_TIMEOUT)
  }, [])

  const value = useMemo(() => ({ dialogs, showDialog, hideCurrentDialog }), [dialogs, showDialog, hideCurrentDialog])

  return <DialogManagerContext.Provider value={value}>{children}</DialogManagerContext.Provider>
}

export const DialogRenderRoot = () => {
  const { dialogs, hideCurrentDialog } = useContext(DialogManagerContext)
  const dialogToShow = dialogs.find((d) => !d.isClosed)
  return (
    <>
      {dialogs.map(({ Component, key }) => (
        <Component key={key} isShown={key === dialogToShow?.key} onClose={hideCurrentDialog} />
      ))}
    </>
  )
}

export const useDialogManager = () => {
  const { showDialog, hideCurrentDialog } = useContext(DialogManagerContext)
  return useMemo(() => ({ showDialog, hideCurrentDialog }), [hideCurrentDialog, showDialog])
}
