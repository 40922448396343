import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { justifyContentCenter, overflowHidden } from '../../../components/styled/utility'
import { H5, Body3, Overline3, Avatar } from '../../../ui-kit'
import { CATEGORY_MAPPER } from '../../../ui-kit/Calendar/utils/utils'
import {
  alignItemsCenter,
  borderRadiusRound,
  displayFlex,
  flex,
  fontWeight,
  height,
  letterSpacing,
  marginLeft,
  marginRight,
  marginTop,
  minWidth,
  noLineHeight,
  noMargin,
  noPadding,
  positionRelative,
  textTransform,
  textTransformUppercase,
  width,
} from '../../../ui-kit/utils'
import { msToMinutes } from '../../../utils/utils'
import { Color } from '../../../model/ThemeProvider/theme'

const CATEGORY_MAPPER_LOCAL: Record<string, Color> = {
  [CATEGORY_MAPPER.active]: 'orange',
  [CATEGORY_MAPPER.live]: 'orange',
  [CATEGORY_MAPPER.solo]: 'orange',
  [CATEGORY_MAPPER['custom session']]: 'orange',
  [CATEGORY_MAPPER.recovery]: 'green',
  [CATEGORY_MAPPER.mind]: 'magenta',
}

const ListItem = styled(Link)(({ theme }) => ({
  display: 'block',
  boxShadow: `0px 1px 2px 0px ${theme.colors.blackAlpha20}`,
  borderRadius: theme.variables.borderRadiusMedium,
  backgroundColor: theme.background,
  padding: '16px',
}))

const ClassKind = styled(Overline3)<{ tlcColor: Color }>(({ tlcColor, theme }) => [
  {
    color: theme.colors[tlcColor],
  },
  textTransformUppercase,
])

const ClassName = H5.withComponent('p')

const ProfileMetric2Label = styled.p([
  {
    fontSize: 8,
    lineHeight: 1,
    color: 'rgba(102, 102, 102, 1)',
  },
  letterSpacing(-1),
  textTransform('uppercase'),
  noPadding(),
  noMargin(),
])

const ProfileMetric2Value = styled.span(({ theme }) => ({
  fontSize: 24,
  lineHeight: 1,
  color: theme.textColors.primary,
  fontWeight: 'bold',
}))

const ProfileMetric2Abbr = styled.span(({ theme }) => [
  {
    fontSize: 16,
    lineHeight: '17.6px',
    color: theme.textColors.primary,
  },
  letterSpacing(-4),
  textTransform('lowercase'),
  fontWeight(500),
])

/**
 * @param {'Lift VOD' | 'Custom Session' | 'Live Session'}
 *  Lift VOD = “with [Instructor/ Trainer Name]”
 *  Custom Session = “for [Member name]”
 *  Live Session = “with [Trainer]”
 *  Solo Session = “with [Instructor/ Trainer Name]”
 *
 *  thus, we treat “with [Instructor/ Trainer Name]” as default value and change
 *  it only when custom session happens
 * */
const getAccompaniedText = ({
  type,
  instructor,
  profileFirstName,
}: {
  type: string
  instructor: {
    instructorFirstName: string
    instructorLastName: string
  }
  profileFirstName: string
}) => {
  if (!instructor.instructorFirstName && !instructor.instructorLastName) return ''

  const mappedInstructor = {
    firstName: instructor.instructorFirstName || '',
    lastName: instructor.instructorLastName || '',
  }

  const defaultValue = `with ${mappedInstructor.firstName} ${mappedInstructor.lastName}`
  const textMap: Record<string, string> = {
    'custom session': `for ${profileFirstName}`,
    // TODO maybe we need separate trainer and instructor here
    'live session': defaultValue,
  }

  return textMap[type] || defaultValue
}

const label = {
  time: 'Active Time',
  cal: 'Calories',
  weight: 'Weight Moved',
}

const labelSuffix = {
  time: 'min',
  cal: '',
  weight: 'lbs',
}

// {
//   "calories_used": 0,
//   "avg_heart_rate": 0,
//   "peak_heart_rate": 0,
//   "min_heart_rate": 0,
//   "heart_rate_samples": [],
//   "total_sets": 15,
//   "elapsed_time": 620000,
//   "weight_lifted": 0
// }
/**
 * @param {'time'|'cal'|'weight'} type
 * @param {number} value
 * @param {*} props
 * */
const Metric = ({
  type,
  value,
  ...props
}: {
  readonly type: 'time' | 'cal' | 'weight'
  readonly value: number
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <section css={{ flexBasis: 105 }} {...props}>
    <ProfileMetric2Label>{label[type]}</ProfileMetric2Label>
    <p css={[noPadding(), noLineHeight(), noMargin(), marginTop(4)]}>
      <ProfileMetric2Value css={marginRight(2)}>{value}</ProfileMetric2Value>
      <ProfileMetric2Abbr>{labelSuffix[type]}</ProfileMetric2Abbr>
    </p>
  </section>
)

const EMPTY_OBJECT = {}
const EMPTY_ARRAY: any[] = []

type ClassListItemNewProps = {
  readonly metrics: any
  readonly avatars: any[]
  readonly name: any
  readonly instructorFirstName: any
  readonly instructorLastName: any
  readonly topLevelCategory: any
  readonly to: any
  readonly type: any
  readonly profileFirstName: any
  readonly keyedPersonalSessions: any
  readonly personalWorkoutId: any
}

export const ClassListItemNew = ({
  metrics = EMPTY_OBJECT,
  avatars = EMPTY_ARRAY,
  name,
  instructorFirstName,
  instructorLastName,
  // timeDescription,
  topLevelCategory,
  to,
  type,
  profileFirstName,
  keyedPersonalSessions,
  personalWorkoutId,
  ...props
}: ClassListItemNewProps) => {
  const categoryColor = CATEGORY_MAPPER_LOCAL[topLevelCategory] || 'orange'
  const isMetricsSet = Boolean(
    Object.values(metrics).filter((met) => (Array.isArray(met) ? met.length : Boolean(met))).length,
  )
  const onClick = useCallback((e) => !to && e.preventDefault(), [to])

  if (type === CATEGORY_MAPPER.live) {
    name = 'Live 1:1' // keyedPersonalSessions[personalWorkoutId]?.name;
  }

  return (
    <ListItem to={to} onClick={onClick} {...props}>
      <section css={[displayFlex]}>
        {!!avatars.length && (
          <header
            css={[
              minWidth(56),
              marginRight(10),
              displayFlex,

              {
                '> *:nth-of-type(1)': positionRelative,
                '> *:nth-of-type(n+2)': [marginLeft(-16)],
              },
            ]}
          >
            {avatars.map((avatar) => (
              <div
                css={[
                  width(56),
                  height(56),
                  borderRadiusRound,
                  displayFlex,
                  justifyContentCenter,
                  alignItemsCenter,
                  overflowHidden,
                  (theme) => ({
                    backgroundColor: theme.colors.mainPageBackground,
                  }),
                ]}
                key={uuid()}
              >
                <Avatar {...avatar} />
              </div>
            ))}
          </header>
        )}
        <article css={flex(1)}>
          <ClassKind tlcColor={categoryColor}>{topLevelCategory}</ClassKind>
          <ClassName title={keyedPersonalSessions[personalWorkoutId]?.name}>{name}</ClassName>
          <Body3>
            {getAccompaniedText({
              type,
              instructor: { instructorFirstName, instructorLastName },
              profileFirstName,
            })}
          </Body3>
        </article>
      </section>

      {isMetricsSet ? (
        <footer css={[marginTop(32), displayFlex]}>
          {!!metrics.elapsed_time && <Metric type="time" value={msToMinutes(metrics.elapsed_time)} />}
          {!!metrics.calories_used && <Metric type="cal" value={Math.round(metrics.calories_used)} />}
          {!!metrics.weight_lifted && <Metric type="weight" value={metrics.weight_lifted} />}
        </footer>
      ) : null}
    </ListItem>
  )
}
