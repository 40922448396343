import { FadeMain } from './styled/containers'
import { P } from './styled/typography'

const Error = ({ errorMessage = 'Sorry, an error has occurred.' }) => (
  <FadeMain wide>
    <P color="whiteAlpha50" css={{ marginTop: '5em', textAlign: 'center' }}>
      {errorMessage}
    </P>
  </FadeMain>
)

export default Error
