import styled from '@emotion/styled'

import { textOverflowEllipsis } from '../../../ui-kit/utils'

export const ProfileName = styled.h1(
  () => ({
    fontSize: 38,
    margin: 0,
  }),
  textOverflowEllipsis,
)
