import styled from '@emotion/styled'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setUserWantsToBeLoggedIn } from 'store/user'

import { useNavigationContext } from '../model'
import { ModalBlocker } from '../views/Modals/components'

import { bp } from './styled/breakpoints'
import { FadeIn } from './styled/containers'
import { SubHeadingSmall } from './styled/typography'
import { hoverOpacityFast, pseudoFocus } from './styled/utility'

// Blocker is zIndex 2, NavMenu & Modal are 3
const NavMenuContainer = styled.div<{ isNavOpen?: boolean }>(({ isNavOpen, theme }) => ({
  position: 'absolute',
  top: 0,
  height: '100vh',
  background: theme.colors.white,
  zIndex: 3,
  textAlign: 'right',
  overflowY: isNavOpen ? 'auto' : undefined,
  overflowX: 'hidden',
  opacity: isNavOpen ? 1 : 0.6,
  // slide in from right
  right: isNavOpen ? 0 : -theme.variables.navMenuWidth,
  transition: theme.transitions.allFast3eio,

  [bp.md]: {
    right: isNavOpen ? 0 : -theme.variables.navMenuWidthMd - 20, // TODO what is this 20?
  },
}))

const CloseXContainerOuter = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: theme.variables.headerHeightMobile,
  [bp.sm]: {
    height: theme.variables.headerHeightDesktop,
  },
}))

const CloseXContainerInner = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    width: 80,
    cursor: 'pointer',
  },
  hoverOpacityFast,
  pseudoFocus,
)
const CloseXInner = styled.div({
  width: 21,
  height: 13,
  position: 'relative',
  alignSelf: 'center',
})
const CloseXLine = styled.span(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  display: 'block',
  width: '100%',
  height: '2px',
  background: theme.colors.sand,

  '&:first-of-type': {
    transform: 'translateY(-50%) rotate(45deg)',
  },
  '&:last-of-type': {
    transform: 'translateY(-50%) rotate(-45deg)',
  },
}))

const NavMenuLinkContainer = styled.div<{ mobileOnly?: boolean }>(({ mobileOnly, theme }) => ({
  boxSizing: 'border-box',
  width: theme.variables.navMenuWidth,
  cursor: 'pointer',
  background: 'rgba(255, 255, 255, 0.95)',

  [bp.sm]: {
    display: mobileOnly ? 'none' : undefined,
  },
  [bp.md]: {
    width: theme.variables.navMenuWidthMd,
  },
}))
const LinkWrapper = styled(Link)<{ extralight?: boolean; light?: boolean }>(
  ({ extralight, light, theme }) => ({
    display: 'block',

    h2: {
      margin: 0,
      padding: '14px 24px',
      color: light ? theme.colors.blackAlphaX5 : extralight ? theme.colors.blackAlphaX2 : theme.colors.black,

      [bp.sm]: {
        padding: '26px 24px',
      },
    },
    '&:hover': {
      color: theme.colors.black,
      background: '#f6f6f6',
    },
  }),
  pseudoFocus,
)
const NavMenuA = LinkWrapper.withComponent('a')

const NavMenuLinkExternal: FC<{
  readonly light?: boolean
  readonly href?: string
  readonly target?: string
  readonly onClick?: () => void
}> = ({ children, href, light, target, onClick }) => {
  return (
    <NavMenuLinkContainer>
      <NavMenuA to="" tabIndex={-1} light={light} href={href} target={target} onClick={onClick}>
        {children}
      </NavMenuA>
    </NavMenuLinkContainer>
  )
}

const NavMenu = () => {
  const { hideNavigation, isNavOpen } = useNavigationContext()
  const dispatch = useDispatch()

  return (
    <>
      <FadeIn isIn={isNavOpen} zIndex={3} transition="allFast3eio">
        <ModalBlocker onClick={hideNavigation} />
      </FadeIn>
      <NavMenuContainer isNavOpen={isNavOpen}>
        <CloseXContainerOuter>
          <CloseXContainerInner onClick={hideNavigation} tabIndex={-1} role="button">
            <CloseXInner>
              <CloseXLine />
              <CloseXLine />
            </CloseXInner>
          </CloseXContainerInner>
        </CloseXContainerOuter>
        <NavMenuLinkExternal light href="https://shop.formelife.com/" target="_blank">
          <SubHeadingSmall>Shop</SubHeadingSmall>
        </NavMenuLinkExternal>
        <NavMenuLinkExternal light href="https://forme.helpscoutdocs.com" target="_blank">
          <SubHeadingSmall>Support</SubHeadingSmall>
        </NavMenuLinkExternal>
        <NavMenuLinkExternal light onClick={() => dispatch(setUserWantsToBeLoggedIn(false))}>
          <SubHeadingSmall>Sign Out</SubHeadingSmall>
        </NavMenuLinkExternal>
      </NavMenuContainer>
    </>
  )
}

export default NavMenu
