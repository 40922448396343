import styled from '@emotion/styled'

const BlankContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vh;
`

const Blank = () => <BlankContainer />

export default Blank
