import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useDialogManager } from 'lib/dialog-manager'
import mixpanel from 'mixpanel-browser'
import * as R from 'ramda'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState, VFC } from 'react'
import { useDispatch, useSelector } from 'store'
import { mergeUserProfile } from 'store/user'

import { patchOwnProfile } from '../api/profile/patchOwnProfile'
import { useConfigDictionaryQuery } from '../queries/useConfigDictionaryQuery'
import { NextPrevButton, Button3 } from '../ui-kit'
import { timezones } from '../utils/constants'
import history from '../utils/history'
import {
  birthdayDayjsFormats,
  capitalizeFirstLetterOfString,
  capitalizeFirstLetterOfStrings,
  conformValue,
  formHeightToServerHeight,
  serverHeightToFormHeight,
  validate,
} from '../utils/utils'
import { ValidationRequirement } from '../utils/various/validation-constraints'

import HeaderModal from './HeaderModal'
import { ButtonSection, ButtonSolid } from './styled/button'
import { BirthdateInput, CustomSelectBlocker, FormInput, FormCustomSelect, FormPhoneInput } from './styled/form'
import { blockAndDim } from './styled/utility'

type FormState = Record<
  string,
  {
    val: string | undefined
    validationRequirement?: ValidationRequirement
    validationMessage?: string
    isSelectDropdownShowing?: boolean
    placeholder?: string
    options?: string[]
  }
>

const AccountForm: VFC<{
  readonly onSuccess?: () => void
  readonly isPartOfDialog?: boolean
}> = ({ onSuccess, isPartOfDialog }) => {
  const dispatch = useDispatch()
  dayjs.extend(customParseFormat)

  const { data: configDictionaryData } = useConfigDictionaryQuery()
  const { hideCurrentDialog } = useDialogManager()

  /*
   * isModalShowing is used to close & fade out the modal, which means that the
   * conditional HeaderModal disappears immediately as the modal fades out.
   * We want the HeaderModal to "persist" during the fade out, so we won't let the local,
   * persisted value of isModalShowing to change from true back to false.
   * Once this component thinks it's a modal, it stays a modal.
   */
  const [persistedIsModalV2Showing, setPersistedIsModalV2Showing] = useState(false)
  useEffect(() => {
    isPartOfDialog && !persistedIsModalV2Showing && setPersistedIsModalV2Showing(true)
  }, [isPartOfDialog, persistedIsModalV2Showing])

  const { date_of_birth, first_name, gender, height, last_name, timezone, weight, zip_code, phone } = useSelector(
    (state) => state.user,
  )
  const membershipType = useSelector((state) => state.membership.membership?.class.type)
  const isOwner = useSelector((state) => state.user.membershipProfiles?.[0]?.owner)
  const isCommercialOwner = isOwner && membershipType === 'commercial'

  const [forceAllNotPristine, setForceAllNotPristine] = useState(false)

  const initialFormState = useMemo<FormState>(
    () => ({
      first_name: {
        val: first_name ?? '',
        validationRequirement: isCommercialOwner ? 'optional' : 'non-empty-alpha',
        validationMessage: 'please enter your first name',
      },
      last_name: {
        val: last_name ?? '',
        validationRequirement: isCommercialOwner ? 'optional' : 'non-empty-alpha',
        validationMessage: 'please enter your last name',
      },
      phone: {
        val: phone ?? '',
        validationRequirement: 'phone',
        validationMessage: 'Phone number invalid',
      },
      timezone: {
        isSelectDropdownShowing: false,
        options: ['', ...timezones],
        val:
          first_name && timezone // don't permit timezone auto-set
            ? timezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const initialNonCommercialFormState = useMemo<FormState>(
    () => ({
      ...initialFormState,
      date_of_birth: {
        val: (validate('birthday', date_of_birth) ? conformValue('birthday', date_of_birth) : date_of_birth)!,
        validationRequirement: isCommercialOwner ? 'optional' : 'birthday',
        validationMessage: 'format must be mm-dd-yyyy and you must be between 18 and 130 years old',
      },
      height: {
        val: (() => {
          const value = height && typeof height === 'number' ? serverHeightToFormHeight(height) : height
          return value !== null && value !== undefined ? String(value) : undefined
        })(),
        validationRequirement: isCommercialOwner ? 'optional' : 'height',
        validationMessage: 'please enter your height (2-9 feet)',
      },
      weight: {
        val: weight ? String(weight) : '',
        validationRequirement: isCommercialOwner ? 'optional' : 'weight',
        validationMessage: 'please enter your weight (50-400 lbs)',
      },
      gender: {
        isSelectDropdownShowing: false,
        placeholder: 'Loading...',
        options: [],
        validationRequirement: isCommercialOwner ? 'optional' : undefined,
        val: undefined,
      },
      zip_code: {
        val: zip_code,
        validationRequirement: isCommercialOwner ? 'optional' : 'postal-code',
        validationMessage: 'please enter your 5 digit zip code',
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // note: server is auto-setting new user gender to male - workaround below
  // note: server is auto-setting timezone to "America/Los_Angeles" - workaround below
  // note: server terminology is 'gender', client terminology is 'sex'
  // note: server treats height as inches, and form treats it as `[ft]' [in]"`
  const [formState, setFormState] = useState<FormState>(
    isCommercialOwner ? initialFormState : initialNonCommercialFormState,
  )

  useEffect(() => {
    if (configDictionaryData && !isCommercialOwner) {
      setFormState((state) => ({
        ...state,
        gender: {
          ...state.gender,
          placeholder: undefined,
          options: capitalizeFirstLetterOfStrings(configDictionaryData.data.user_gender || []),
          val: first_name && gender ? capitalizeFirstLetterOfString(gender) : '', // don't permit gender auto-set
        },
      }))
    }
  }, [configDictionaryData, first_name, gender, isCommercialOwner])

  const handleBlur = ({ value, name }: { value?: string; name?: string }) => {
    name &&
      setFormState({
        ...formState,
        [name]: {
          ...formState[name],
          val: conformValue(formState[name]?.validationRequirement, value)!,
        },
      })
  }

  const handleChange = ({ value, name }: { value?: string; name?: string }) => {
    name &&
      setFormState({
        ...formState,
        [name]: {
          ...formState[name],
          val: value,
        },
      })
  }

  const handleBlurEvent = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target

    handleBlur({ value, name })
  }

  const handleChangeEvent = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target

    handleChange({ value, name })
  }

  const closeDropdowns = () =>
    setFormState((state) =>
      R.map(
        (x) =>
          x.isSelectDropdownShowing
            ? {
                ...x,
                isSelectDropdownShowing: false,
              }
            : x,
        state,
      ),
    )

  const handleToggleSelectDropdown = (dropdownKey: string) =>
    setFormState(
      R.mapObjIndexed(
        (x, key) =>
          key === dropdownKey // toggle the target
            ? {
                ...x,
                isSelectDropdownShowing: !x.isSelectDropdownShowing,
              }
            : x.isSelectDropdownShowing // close other selects
            ? {
                ...x,
                isSelectDropdownShowing: false,
              }
            : x, // ignore the rest
        formState,
      ),
    )

  const handleCustomDropdownChange = (dropdownKey: string, newDropdownValue: string) =>
    setFormState({
      ...formState,
      [dropdownKey]: {
        ...formState[dropdownKey],
        val: newDropdownValue,
        isSelectDropdownShowing: false,
      },
    })

  const [isPosting, setIsPosting] = useState(false)

  useEffect(
    () =>
      history.listen(() => {
        // reset isPosting
        setIsPosting(false)
      }),
    [],
  )

  const onClickContinue = useCallback(() => {
    const patchUserMe = async () => {
      try {
        // use isPosting to block user interactions
        // e.g. prevent repeat clicks during network delays
        setIsPosting(true)

        /*
         * "Convenience" timezones are the ones at the top that most people are familiar with.
         * However, these are not "real" timezones in the sense that they are not part of
         * the tz (or IANA) time zone database. If a user chooses a convenience timezone, then
         * when they save their latest profile changes, the convenience timezone will be changed
         * to the "real" timezone that best represents that choice:
         *
         * - US/Pacific (GMT -08:00)  ->  America/Los_Angeles
         * - US/Mountain (GMT -07:00) ->  America/Denver
         * - US/Central (GMT -06:00)  ->  America/Chicago
         * - US/Eastern (GMT -05:00)  ->  America/New_York
         */
        const adjustConvenienceTimezones = (selectedTimezone: string) => {
          switch (selectedTimezone) {
            case 'US/Pacific (GMT -08:00)':
              return 'America/Los_Angeles'
            case 'US/Mountain (GMT -07:00)':
              return 'America/Denver'
            case 'US/Central (GMT -06:00)':
              return 'America/Chicago'
            case 'US/Eastern (GMT -05:00)':
              return 'America/New_York'
            default:
              return selectedTimezone
          }
        }

        const commercialPayload = {
          first_name: formState.first_name?.val,
          last_name: formState.last_name?.val,
          phone: formState.phone?.val,
          timezone: adjustConvenienceTimezones(formState.timezone?.val || ''),
          onboarding_profile_completed: true, // <-- will trigger routing
        }

        const patchPayload = isCommercialOwner
          ? commercialPayload
          : {
              date_of_birth: dayjs(formState.date_of_birth?.val, birthdayDayjsFormats).format(),
              gender: formState.gender?.val!.toLowerCase(),
              height: formHeightToServerHeight(formState.height?.val),
              weight: parseInt(formState.weight?.val || ''),
              zip_code: formState.zip_code?.val,
              ...commercialPayload,
            }

        const response = await patchOwnProfile(patchPayload)

        mixpanel.track('profileEdit')

        // AppAuthenticated.js passes onboarding_profile_completed to useRouting
        dispatch(mergeUserProfile(response.data))
        setIsPosting(false)
        isPartOfDialog ? hideCurrentDialog() : onSuccess?.()
      } catch (e) {
        alert(`Sorry, there was a validation error on the server: ${(e as Error).message}`)
        setIsPosting(false)
      }
    }

    const isEverythingValid = Object.values(formState)
      .map((_) => validate(_.validationRequirement, _.val))
      .every((_) => Boolean(_) === true)

    if (isEverythingValid) {
      patchUserMe()
    } else {
      setForceAllNotPristine(true)
      setIsPosting(false)
    }
  }, [formState, isCommercialOwner, dispatch, isPartOfDialog, hideCurrentDialog, onSuccess])

  const aSelectDropdownIsShowing = Object.values(formState).some((_) => _.isSelectDropdownShowing === true)

  return (
    <>
      <CustomSelectBlocker isShowing={aSelectDropdownIsShowing} onClick={closeDropdowns} />
      {persistedIsModalV2Showing ? (
        <HeaderModal controlLeft="ArrowLeft" headerTitle="Profile" onClickControlLeft={hideCurrentDialog} />
      ) : null}
      <div
        css={[
          blockAndDim(isPosting),
          { maxWidth: 420, margin: '0 auto' },
          persistedIsModalV2Showing && {
            padding: '16px 6% 32px',
          },
        ]}
      >
        <FormInput
          id="first_name"
          labeltext="First Name"
          autocomplete="given-name"
          val={formState.first_name?.val || ''}
          onBlurCallback={handleBlurEvent}
          onChangeCallback={handleChangeEvent}
          validationMessage={formState.first_name?.validationMessage}
          validationRequirement={formState.first_name?.validationRequirement}
          isForceNotPristine={forceAllNotPristine}
        />
        <FormInput
          id="last_name"
          labeltext="Last Name"
          autocomplete="family-name"
          val={formState.last_name?.val || ''}
          onBlurCallback={handleBlurEvent}
          onChangeCallback={handleChangeEvent}
          validationMessage={formState.last_name?.validationMessage}
          validationRequirement={formState.last_name?.validationRequirement}
          isForceNotPristine={forceAllNotPristine}
        />
        <FormPhoneInput
          id="phone"
          labeltext="Phone"
          autocomplete="phone"
          val={formState.phone?.val || ''}
          onBlurCallback={handleBlur}
          onChangeCallback={handleChange}
          validationMessage={formState.phone?.validationMessage}
          validationRequirement={formState.phone?.validationRequirement}
          isForceNotPristine={forceAllNotPristine}
        />
        {isCommercialOwner ? null : (
          <>
            <BirthdateInput
              id="date_of_birth"
              labeltext="Birthdate"
              autocomplete="bday"
              placeholder="mm-dd-yyyy"
              val={formState.date_of_birth?.val || ''}
              onBlurCallback={handleBlurEvent}
              onChangeCallback={handleChange}
              validationMessage={formState.date_of_birth?.validationMessage}
              validationRequirement={formState.date_of_birth?.validationRequirement}
              isForceNotPristine={forceAllNotPristine}
            />
            <FormInput
              id="height"
              labeltext="Height"
              autocomplete="off"
              val={formState.height?.val || ''}
              onBlurCallback={handleBlurEvent}
              onChangeCallback={handleChangeEvent}
              validationMessage={formState.height?.validationMessage}
              validationRequirement={formState.height?.validationRequirement}
              isForceNotPristine={forceAllNotPristine}
            />
            <FormInput
              id="weight"
              labeltext="Weight"
              autocomplete="off"
              val={formState.weight?.val || ''}
              onBlurCallback={handleBlurEvent}
              onChangeCallback={handleChangeEvent}
              validationMessage={formState.weight?.validationMessage}
              validationRequirement={formState.weight?.validationRequirement}
              isForceNotPristine={forceAllNotPristine}
            />
            <FormCustomSelect
              handleCustomDropdownChange={handleCustomDropdownChange}
              id="gender"
              isForceNotPristine={forceAllNotPristine}
              isSelectDropdownShowing={formState.gender?.isSelectDropdownShowing}
              labeltext="Sex"
              placeholder={formState.gender?.placeholder}
              onChangeCallback={handleChangeEvent}
              options={formState.gender?.options || []}
              toggleIsSelectDropdownShowing={() => handleToggleSelectDropdown('gender')}
              val={formState.gender?.val || ''}
            />
          </>
        )}
        <FormCustomSelect
          handleCustomDropdownChange={handleCustomDropdownChange}
          id="timezone"
          isForceNotPristine={forceAllNotPristine}
          isSelectDropdownShowing={formState.timezone?.isSelectDropdownShowing}
          labeltext="Timezone"
          onChangeCallback={handleChangeEvent}
          options={formState.timezone?.options || []}
          toggleIsSelectDropdownShowing={() => handleToggleSelectDropdown('timezone')}
          val={formState.timezone?.val || ''}
        />
        {isCommercialOwner ? null : (
          <FormInput
            id="zip_code"
            labeltext="Home Zip"
            autocomplete="postal-code"
            val={formState.zip_code?.val || ''}
            onBlurCallback={handleBlurEvent}
            onChangeCallback={handleChangeEvent}
            validationMessage={formState.zip_code?.validationMessage}
            validationRequirement={formState.zip_code?.validationRequirement}
            isForceNotPristine={forceAllNotPristine}
          />
        )}

        {persistedIsModalV2Showing ? (
          <ButtonSection>
            <ButtonSolid onClick={onClickContinue} fullWidth>
              <Button3>Save</Button3>
            </ButtonSolid>
          </ButtonSection>
        ) : (
          <div css={{ marginTop: 40 }}>
            <NextPrevButton onClick={onClickContinue} fullWidth large text="Create Account" />
          </div>
        )}
      </div>
    </>
  )
}

export default AccountForm
