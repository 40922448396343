import React, { createContext, useCallback, useContext, useEffect, useReducer, FC, useMemo } from 'react'

import { isViewportMobile } from '../components/styled/breakpoints'

type ReducerAction = { type: string; payload?: any }

type Actions = {
  setIsMobile: (isMobile: boolean) => void
  setInnerWidth: (innerWidth: number) => void
}

type State = {
  areInteractionsBlocked: boolean
  arrowLeftTo: string | null
  headerSubtitle: string
  headerTitle: string
  isAlternateHeader: boolean
  isHeaderInitialized: boolean
  isMobile: boolean
  xTo: string | null
  innerWidth: number
  dispatch: React.Dispatch<ReducerAction>
  actions: Actions
}

export const UIContext = createContext<State | undefined>(undefined)
export const useUIContext = () => useContext(UIContext)

export const UIProvider: FC = ({ children }) => {
  const initialIsMobile = isViewportMobile(window.innerWidth)

  const initialState = {
    areInteractionsBlocked: false,
    arrowLeftTo: null,
    headerSubtitle: '',
    headerTitle: '',
    isAlternateHeader: false,
    isHeaderInitialized: false,
    isMobile: initialIsMobile,
    xTo: null,
    innerWidth: window.innerWidth,
  }

  const headerResetState = {
    arrowLeftTo: null,
    headerSubtitle: '',
    isAlternateHeader: false,
    xTo: null,
  }

  function reducer(state: State, action: ReducerAction) {
    switch (action.type) {
      case 'setCurrentViewportSize':
        return {
          ...state,
          isMobile: action.payload,
        }
      case 'updateHeader':
        // header "initializes" after first update
        return {
          ...state,
          isHeaderInitialized: true,
          ...headerResetState,
          ...action.payload,
        }
      case 'updateInnerWidth':
        return {
          ...state,
          innerWidth: action.payload,
        }
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const setIsMobile = useCallback(
    (isMobile) =>
      dispatch({
        type: 'setCurrentViewportSize',
        payload: isMobile,
      }),
    [dispatch],
  )

  const setInnerWidth = useCallback(
    (innerWidth) =>
      dispatch({
        type: 'updateInnerWidth',
        payload: innerWidth,
      }),
    [dispatch],
  )

  const contextValue = useMemo(
    () => ({ ...state, actions: { setIsMobile, setInnerWidth }, dispatch }),
    [setInnerWidth, setIsMobile, state],
  )

  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
}

// dispatch helpers
export const useUISetPageTitle = (newTitle: string) => {
  const { dispatch, headerTitle } = useContext(UIContext)!

  useEffect(() => {
    let isMounted = true

    if (isMounted && newTitle !== headerTitle) {
      dispatch({
        type: 'updateHeader',
        payload: { headerTitle: newTitle },
      })
    }

    return () => {
      isMounted = false
    }
  }, [dispatch, newTitle, headerTitle])
}

export const useUISetPageTitleAndArrowLeft = (newTitle: string, arrowLeftTo: string) => {
  const { dispatch, headerTitle } = useContext(UIContext)!

  useEffect(() => {
    let isMounted = true

    if (isMounted && (!newTitle || newTitle !== headerTitle)) {
      dispatch({
        type: 'updateHeader',
        payload: {
          headerTitle: newTitle,
          arrowLeftTo,
        },
      })
    }

    return () => {
      isMounted = false
    }
  }, [arrowLeftTo, dispatch, newTitle, headerTitle])
}

export const useUISetPageTitleAndSubtitleXOut = (
  newTitle: string,
  newSubtitle: string,
  xTo: string,
  isAlternateHeader = false,
) => {
  const { dispatch, headerTitle } = useContext(UIContext)!

  useEffect(() => {
    let isMounted = true

    if (isMounted && newTitle !== headerTitle) {
      dispatch({
        type: 'updateHeader',
        payload: {
          headerTitle: newTitle,
          headerSubtitle: newSubtitle,
          xTo,
          isAlternateHeader,
        },
      })
    }

    return () => {
      isMounted = false
    }
  }, [xTo, dispatch, headerTitle, isAlternateHeader, newSubtitle, newTitle])
}
