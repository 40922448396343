import React, { VFC } from 'react'

import { IconProps } from './types'

const FormeLogoText: VFC<IconProps> = ({ ...props }) => (
  <svg width="213" height="42" viewBox="0 0 213 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 1.4312L0.407779 1.54207C3.14288 2.26778 4.71433 4.63639 4.71433 6.84373V41.3045H11.6466V21.6702H27.182V19.1807H11.6466V3.34625H30.2552V0.856689H0V1.4312Z"
      fill="white"
    />
    <path
      d="M56.6121 0C47.3028 0 37.347 5.51332 37.347 20.9748C37.347 34.1382 44.5478 42 56.6121 42C68.6764 42 75.8772 34.1382 75.8772 20.9748C75.8772 5.51332 65.9214 0 56.6121 0ZM56.6121 39.9136C49.0433 39.9136 44.518 32.8279 44.518 20.9748C44.518 9.29302 49.1528 2.036 56.6121 2.036C64.0715 2.036 68.7063 9.29302 68.7063 20.9748C68.7063 32.838 64.1809 39.9136 56.6121 39.9136Z"
      fill="white"
    />
    <path
      d="M117.082 12.8307C117.082 5.11004 110.975 0.866699 100.9 0.866699H82.5897V1.43113L83.0671 1.49161C85.852 1.84438 87.304 4.13236 87.304 6.19859V41.2944H94.2363V3.34618H99.6767C104.978 3.34618 110.438 4.93869 110.438 12.8307C110.438 16.4895 108.737 19.735 105.386 21.267C102.551 22.5571 99.5573 22.6982 98.2743 22.6982C97.787 22.6982 97.4985 22.6781 97.4886 22.6781L96.0862 22.5874L113.064 41.3044H120.911L106.649 25.5406C110.229 24.6435 117.082 21.519 117.082 12.8307Z"
      fill="white"
    />
    <path
      d="M212.999 3.34625V0.856689H182.744V1.42112L183.142 1.532C185.926 2.31817 187.458 3.98124 187.458 6.20874V41.2944H212.999V38.8049H194.39V21.6702H209.926V19.1807H194.39V3.34625H212.999Z"
      fill="white"
    />
    <path
      d="M152.241 29.9452L138.576 1.17922L138.387 0.856689H127.357V1.42112L127.755 1.532C130.53 2.31817 132.061 3.97116 132.071 6.18858V41.2944H135.284V10.7545L150.063 41.9395L165.201 9.53488V41.2944H172.133V0.856689H165.678L152.241 29.9452Z"
      fill="white"
    />
  </svg>
)

export default FormeLogoText
