import { categoryToTopLevelCategory } from '../../ui-kit/Calendar/utils/utils'

import { msToMinutes } from './msToMinutes'

// TLC is TopLevelCategory: 'active'| 'recovery' | 'mind' |
export const reduceCalendarDataToTLCElapsedMin = (
  acc: Record<string, number>,
  { elapsed_time, workout_category }: { elapsed_time: string | number | null; workout_category?: string | null },
) => {
  const topLevelCategory = categoryToTopLevelCategory(workout_category || undefined)
  const elapsedTime = typeof elapsed_time === 'number' ? elapsed_time : elapsed_time ? parseInt(elapsed_time) : 0
  const elapsedMinutes = isNaN(elapsedTime) ? 0 : msToMinutes(elapsedTime)

  return {
    ...acc,
    [topLevelCategory]: acc[topLevelCategory] ? acc[topLevelCategory]! + elapsedMinutes : elapsedMinutes,
  }
}
