import { useHistory } from 'react-router-dom'

import { VerticalContainer } from '../../components/VerticalContainer'
import { bp } from '../../components/styled/breakpoints'
import { Fade } from '../../components/styled/containers'
import { marginBottom, textAlignCenter } from '../../components/styled/utility'
import { routing } from '../../model/Routing/routing'
import { Body3, H3, H4, NextPrevButton } from '../../ui-kit'

export const FeedAlgorithmStep = () => {
  const { push } = useHistory()

  const onContinue = () =>
    push(
      routing.onboarding.fitnessAssessment.generatePath({
        questionNumber: 1,
      }),
    )

  return (
    <Fade>
      <VerticalContainer
        css={{
          padding: '110px 20px 36px',
          maxWidth: 420,
          [bp.sm]: {
            paddingTop: 85,
          },
        }}
      >
        <div css={[textAlignCenter, marginBottom(264)]}>
          <H3 css={[marginBottom(100)]}>WELCOME</H3>
          <H4 css={[marginBottom('1rem')]}>Let’s feed the on-demand algorithm!</H4>
          <Body3>Answer a few more questions so we can better personalize your on-demand content.</Body3>
        </div>
        <NextPrevButton css={{ margin: 0 }} onClick={onContinue} fullWidth large text="GET STARTED" />
      </VerticalContainer>
    </Fade>
  )
}
