import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const display = (dis: CSSProperties['display']) =>
  css({
    display: dis,
  })

export const displayBlock = display('block')

export const displayInlineBlock = display('inline-block')

export const displayFlex = display('flex')
