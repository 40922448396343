import { Theme } from '@emotion/react'

import { TRANSITIONS } from '../../constants'

const fontFamilyFallback = 'Helvetica, sans-serif'

const colors = {
  beige: '#e2d9cd',
  bone: '#e3dcd0',
  boneAlphaX30: '#e3dcd030',
  boneAlpha85: 'rgb(227, 220, 208, 0.85)',
  black: '#000',
  black60: 'rgba(0, 0, 0, 0.6)',
  blackAlpha10: 'rgba(0, 0, 0, 0.1)',
  blackAlpha15: 'rgba(0, 0, 0, 0.15)',
  blackAlpha20: 'rgba(0, 0, 0, 0.2)',
  blackAlpha25: 'rgba(0, 0, 0, 0.25)',
  blackAlpha50: 'rgba(0, 0, 0, 0.5)',
  blackAlpha75: 'rgba(0, 0, 0, 0.75)',
  blackAlphaX2: '#0002',
  blackAlphaX5: '#0005',
  gray14: '#141414',
  gray29: '#292929',
  gray3d: '#3d3d3d',
  gray5f: '#5f5f5f', // #fff at 35%
  gray7c: '#7c7c7c',
  grayAlertBanner: '#656060',
  grayBf: '#bfbfbf', // #fff at 75%
  grayGradientBottom: '#1a1a1a',
  grayGradientTop: '#424141',
  green: '#62e457',
  greenBrightTransparent: 'rgba(150,233,193,0.1)', // #96e9c1
  magenta: '#e5467f',
  orange: '#e38f51',
  red2: '#ff6960',
  red: '#e02929',
  sand: '#c8ad94',
  sandAlpha00: '#c8ad9400',
  sandAlphaX33: '#c8ad9433',
  sandAlphaX44: '#c8ad9444',
  sandAlphaX66: '#c8ad9466',
  sandLight: '#f2f0ed',
  sandLightAlt: '#e9e8e6',
  sandMedium: '#d2c5b9',
  white: '#fff',
  whiteAlpha15: 'rgba(255, 255, 255, 0.15)',
  whiteAlpha25: 'rgba(255, 255, 255, 0.25)',
  whiteAlpha35: 'rgba(255, 255, 255, 0.35)',
  whiteAlpha50: 'rgba(255, 255, 255, 0.5)',
  whiteAlpha75: 'rgba(255, 255, 255, 0.75)',
  whiteAlphaX15: '#ffffff15',
  whiteAlphaX1: '#fff1',
  whiteAlphaX25: '#ffffff25',
  whiteAlphaX2: '#fff2',
  whiteAlphaX35: '#ffffff35',
  whiteAlphaX4: '#fff4',
  whiteAlphaX6: '#fff6',
  whiteAlphaX8: '#fff8',
  whiteAlphaXe: '#fffe',
  progress: '#e9a695',
  mainPageBackground: '#F5F0EB',
  grayAlpha20: 'rgba(209, 209, 209, 0.2)',
}

export type Color = keyof typeof colors

const textColorsDark = {
  primary: colors.white,
  secondary: colors.whiteAlphaX8,
  secondaryAlt: colors.whiteAlphaX8,
  ternary: colors.whiteAlpha15,
  white: colors.white,
}

// "magic numbers"
const variables = {
  borderRadius: 5,
  borderRadiusPGLarge: 24,
  borderRadiusPGSmall: 8,
  borderRadiusLarge: 16,
  borderRadiusInset: 12,
  borderRadiusMedium: 8,
  borderRadiusSmall: 2,
  borderWidth: 1,
  cardLockupSmallTypeMultiplier: 0.76,
  headerHeightDesktop: 72,
  headerHeightMobile: 60,
  modalWidth: 375,
  modalWidthDesktop: 645,
  navMenuWidth: 260,
  navMenuWidthMd: 300,
}

export const theme = {
  variables,
  brandColors: {
    davysGray: '#5a5a5c',
    vistaWhite: '#FBF8F5',
    bone: '#E3DCD0',
    desertSand: '#e8cfb4',
    darkVanilla: '#d9bfaf',
    tumbleweed: '#d1aa87',
    white35Solid: '#595959',
  },
  colors,
  textColors: {
    primary: '#000',
    secondary: '#666',
    secondaryAlt: '#808080',
    ternary: '#ccc',
    white: '#fff',
  },
  radioColors: {
    fill: '#000',
    path: '#fff',
  },
  gradients: {
    horizontalToBlack: `linear-gradient(to right, transparent, ${colors.black})`,
    blackRightEdge: `linear-gradient(to right, transparent, ${colors.black} 60%)`,
    blackLeftEdge: `linear-gradient(to left, transparent, ${colors.black} 60%)`,
    verticalToBlack: `linear-gradient(transparent, ${colors.black})`,
  },
  shadows: {
    drop: `0 8px 20px ${colors.blackAlphaX5}`,
    glow: `0 0 65px ${colors.sandAlphaX44}`,
    glowSubtle: `0 0 40px ${colors.sandAlphaX33}`,
  },
  transitions: TRANSITIONS,
  transitionDefault: 'allMedium',
  typography: {
    family: {
      default: `'HCo Gotham SSm', ${fontFamilyFallback}`,
      heading: `Kaneda, ${fontFamilyFallback}`,
    },
    letterSpacing: {
      condensed: '-0.03em',
      semiCondensed: '0.01em',
      regular: '0.03em',
      heading: '0.05em',
      extended: '0.068em',
      extraExtended: '0.08em',
      extraExtraExtended: '0.15em',
      extraExtraExtraExtended: '0.22em',
      button: '0.05em',
    },
    lineHeight: {
      extraExtraCondensed: 0.9,
      extraCondensed: 1.1,
      semiCondensed: 1.3,
      condensed: 1.4,
      main: 1.5,
    },
    shadows: {
      drop: `0 2px 0 ${colors.whiteAlphaX15}`,
    },
    size: {
      base: 16,
      body0: 28,
      body2: 16,
      body3: 14,
      body4: 12,
      buttonText: 15,
      formRadioLabel: 15,
      h1: 23, // 32|2
      h1alt: 25, // 28|2 - 32|2
      h1large: 32,
      h1special: 28,
      h2: 28,
      h3: 21,
      h5: 14.5,
      mBody1: 14,
      numericTextSmall: 15,
      subHeading: 24, // 24|1 - 24|1
      subHeadingAlt: 12, // 16|-0.5 - 20|-0.5
      subHeadingLarge: 26, // 24|1 - 24|1
      subHeadingSmall: 19,
      subheadingXSmall: 13,
      Overline2: 9,
      Overline2Desktop: 14,
      Metric1: 60,
      Metric2: 32,

      lockups: {
        singleClass: {
          h1: 21,
          h2: 9,
          h3: 9,
          h1small: 19.5,
          h2small: 8,
          h3small: 8,
        },
      },

      p: 12.5,
      smallText: 10,
      smallTextAlt: 12,
      PAlt: 13,
      textsm: 12.5,
    },
  },
}

export const light = {
  ...theme,
  background: colors.white,
  backgroundPage: colors.white,
  backgroundBrand: theme.brandColors.bone,
  backgroundBrandLight: theme.brandColors.vistaWhite,
  textColor: colors.black,
  textColorSecondary: '#4D4D4D',
  textColorHover: colors.black,
  linkColor: '#4D4D4D',
  linkColorHover: colors.black,
  headerLink: theme.brandColors.white35Solid,
  headerLinkHover: colors.black,
  headerLinkActive: colors.black,
}

export type AppTheme = typeof light

export const dark: AppTheme = {
  ...theme,
  colors: {
    ...colors,
    mainPageBackground: 'transparent',
  },
  background: colors.black,
  backgroundPage: colors.black,
  backgroundBrand: theme.brandColors.bone,
  backgroundBrandLight: theme.brandColors.vistaWhite,
  textColor: colors.white,
  textColors: textColorsDark,
  textColorSecondary: theme.brandColors.tumbleweed,
  textColorHover: colors.black,
  linkColor: colors.white,
  linkColorHover: colors.white,
  headerLink: theme.brandColors.white35Solid,
  headerLinkHover: colors.black,
  headerLinkActive: colors.black,
}

export type PlainColorKeys = keyof Omit<Theme['colors'], 'text' | 'radio'>
