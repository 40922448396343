import { createAsyncThunk } from '@reduxjs/toolkit'

import { RootState } from '..'
import { getOwnProfile, getProfileExtended } from '../../api/profile/getOwnProfile'

export const getCurrentUser = createAsyncThunk('getCurrentUser', async () => {
  const response = await getOwnProfile()

  return response.data
})

export const getUserExtended = createAsyncThunk('getNextBillingDate', async (_, { getState }) => {
  const state = getState() as RootState
  const id = state.user.membershipProfiles?.[0]?.id

  if (!id) {
    return Promise.reject()
  }

  const response = await getProfileExtended(id)

  return response.data
})
