import { css } from '@emotion/react'
import React, { memo } from 'react'

import { bp } from '../../../components/styled/breakpoints'
import { ProgressMetricsGroup } from '../../../ui-kit'

import { Column, ProgressBlock } from './Progress'
import { ProgressHeader } from './ProgressHeader'

const progressBlockCss = css({
  marginBottom: 32,
  [bp.sm]: {
    marginBottom: 0,
  },
})

export const TLCProgressList = memo(({ tlcAggregateMetrics }: { readonly tlcAggregateMetrics: any[] }) => (
  <Column>
    <ProgressHeader tlcAggregateMetrics={tlcAggregateMetrics} />
    <ProgressBlock css={progressBlockCss}>
      <ProgressMetricsGroup metrics={tlcAggregateMetrics} withMinutesWord />
    </ProgressBlock>
  </Column>
))
