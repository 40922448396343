import styled from '@emotion/styled'

import { fontWeight, letterSpacing } from '../utils'

export const H5 = styled.h5(({ theme }) => [
  {
    fontFamily: theme.typography.family.default,
    fontSize: '1rem',
    lineHeight: '20px',
    margin: 0,
    padding: 0,
  },
  // In design, it says 400, but it does not look like 400 in fact
  fontWeight('bold'),
  letterSpacing(2),
])
