import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const textOverflowEllipsis = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const noLineHeight = () =>
  css({
    lineHeight: 'initial',
  })

export const lineHeight = (lh: CSSProperties['lineHeight']) =>
  css({
    lineHeight: lh,
  })

export const letterSpacing = (lc: number) => {
  const withMinus = lc < 0
  // '2'.padStart(2, "0"); // '02'
  // '33'.padStart(2, "0"); // '33'
  const letterSpacingProperLength = String(Math.abs(lc)).padStart(2, '0')
  return css({
    letterSpacing: withMinus ? `-.${letterSpacingProperLength}em` : `.${letterSpacingProperLength}em`,
  })
}

export const textTransform = (transform: CSSProperties['textTransform']) =>
  css({
    textTransform: transform,
  })

export const textTransformUppercase = textTransform('uppercase')

export const fontWeight = (fw: CSSProperties['fontWeight']) =>
  css({
    fontWeight: fw,
  })

export const fontSize = (fsz: CSSProperties['fontSize']) =>
  css({
    fontSize: fsz,
  })

export const textAlign = (ta: CSSProperties['textAlign']) =>
  css({
    textAlign: ta,
  })

export const textAlignCenter = textAlign('center')

export const verticalAlignMiddle = css({ verticalAlign: 'middle' })
