import { createAsyncThunk } from '@reduxjs/toolkit'
import { axios } from '../../system/axios'
import { StripePaymentMethod } from './types'

export const getPaymentMethod = createAsyncThunk('getPaymentMethod', async () => {
  const response = await axios.get<StripePaymentMethod[]>('/stripe/payment-method')

  return response.data[0] ? response.data[0] : null
})

export const setPaymentMethod = createAsyncThunk('setPaymentMethod', async (paymentMethodId: string) => {
  const response = await axios.post<StripePaymentMethod>('/stripe/payment-method', {
    payment_method_id: paymentMethodId,
  })

  return response.data
})

export const removePaymentMethod = createAsyncThunk('removePaymentMethod', async (paymentMethodId: string) => {
  const response = await axios.delete<StripePaymentMethod>('/stripe/payment-method', {
    data: { payment_method_id: paymentMethodId },
  })

  return !!response.data
})

export const createStripeSetupIntent = createAsyncThunk('createStripeSetupIntent', async () => {
  const response = await axios.post<{ client_secret: string }>('/stripe/setup-intent-capture', null)

  return response.data.client_secret
})
