import dayjs from 'dayjs'

/*
 * Calendar, day & time utilities
 */
// gets datetime in UTC format and converts it to date including timezone
// for example
// 2022-06-14T02:10:10.100Z and America/Los_Angeles -> 2022-06-13
// 2022-06-14T02:10:10.100Z and Europe/Madrid -> 2022-06-14
export const simplifyDate = (date: string, timezone: string) => {
  const dateWithTimezone = new Date(date).toLocaleDateString('en-US', {
    timeZone: timezone,
  })
  return dayjs(dateWithTimezone).format('YYYY-MM-DD')
}
