import { useTheme } from '@emotion/react'
import React, { VFC } from 'react'

import { IconProps } from './types'

export const RadioButton: VFC<IconProps & { readonly fillColor?: string; readonly pathColor?: string }> = ({
  fillColor,
  pathColor,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="12" fill={fillColor || theme.radioColors.fill} />
      <path
        d="M16.5 9.25L10.3125 16L7.5 12.9318"
        stroke={pathColor || theme.radioColors.path}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
