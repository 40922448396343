import { createSlice } from '@reduxjs/toolkit'
import { OnboardingStep, Result } from './types'
import { getSurveyState, setSurveyResults } from './actions'

type State = {
  steps: OnboardingStep[]
  results: Result[]
}

const initialState: State = {
  steps: [],
  results: [],
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurveyState.fulfilled, (state, action) => {
      state.results = action.payload.results
      state.steps = action.payload.steps
    })

    builder.addCase(setSurveyResults.fulfilled, (state, action) => {
      state.results = action.payload
    })
  },
})
