import React, { createContext, FC, useCallback, useMemo } from 'react'

import { usePersistedReducer } from './usePersistedReducer'

type ContextValue = {
  activeDeepLink: string
  actions: {
    setDeepLink: (path: string) => void
    resetDeepLink: () => void
  }
}

export const DeepLinksContext = createContext<ContextValue | undefined>(undefined)

/*
 * Currently, /activate, /membership, and /membership-setup are being used interchangeably.
 * The associated routing logic (found in useRouting.js) is:
 *   - user must be authenticated
 *   - user must have completed Onboarding > Create Account
 */
export const deepLinks = {
  activate: '/activate',
  membership: '/membership',
  membershipSetup: '/membership-setup',
}

export const deepLinkPathnames = Object.values(deepLinks)

export const isDeepLinkToActivateFlow = (path: string) =>
  [deepLinks.activate, deepLinks.membership, deepLinks.membershipSetup].includes(path)

export const DeepLinksProvider: FC = ({ children }) => {
  const initialState = {
    activeDeepLink: '',
  }

  function reducer(state: Omit<ContextValue, 'actions'>, action: any) {
    switch (action.type) {
      case 'resetDeepLink':
        return initialState
      case 'setDeepLink':
        return {
          ...state,
          activeDeepLink: deepLinkPathnames.includes(action.payload) ? action.payload : '',
        }
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = usePersistedReducer(reducer, 'deepLinksState', initialState)

  const resetDeepLink = useCallback(() => {
    dispatch({
      type: 'resetDeepLink',
    })
  }, [dispatch])

  const setDeepLink = useCallback(
    (pathname) =>
      dispatch({
        type: 'setDeepLink',
        payload: pathname,
      }),
    [dispatch],
  )

  const contextValue = useMemo(
    () => ({
      actions: { resetDeepLink, setDeepLink },
      ...state,
    }),
    [resetDeepLink, setDeepLink, state],
  )

  return <DeepLinksContext.Provider value={contextValue}>{children}</DeepLinksContext.Provider>
}
