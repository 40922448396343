import { useDialogManager } from 'lib/dialog-manager'
import React, { FC, useCallback, useRef, useState } from 'react'
import ReactS3Uploader, { S3Response } from 'react-s3-uploader'
import { useDispatch, useSelector } from 'store'
import { updateImage } from 'store/user'

import { removeAvatar } from '../../../api/profile/removeAvatar'
import { uploadAvatar } from '../../../api/profile/uploadAvatar'
import HeaderModal from '../../../components/HeaderModal'
import { ButtonSolid, ButtonSection } from '../../../components/styled/button'
import { Fade } from '../../../components/styled/containers'
import { HeaderControlTextLinkContainer } from '../../../components/styled/header'
import { TextLinkSpan } from '../../../components/styled/typography'
import { blockAndDim } from '../../../components/styled/utility'
import { MAX_PHOTO_FILE_SIZE } from '../../../constants'
import { Button3, Metric2 } from '../../../ui-kit'
import { RegularDialog, RegularDialogProps } from '../../../ui-kit/RegularDialog'
import { padding, textAlignCenter, marginBottom, textTransformUppercase, letterSpacing } from '../../../ui-kit/utils'

import { CustomFileInputContainer } from './CustomFileInputContainer'
import { Info } from './Info'
import { Photo } from './Photo'
import { fixPhotoOrientation } from './fixPhotoOrientation'

export const PhotoModal: FC<RegularDialogProps> = (props) => {
  const { hideCurrentDialog } = useDialogManager()

  const dispatch = useDispatch()
  const { image } = useSelector((state) => state.user)

  const [isPosting, setIsPosting] = useState(false)
  const [filename, setFilename] = useState<string | null>(null)
  const [feedback, setFeedback] = useState<string | null>(null)
  const [imgSrcError, setImgSrcError] = useState(false)

  const uploadedImgLinkRef = useRef('')

  const resetState = () => {
    setFeedback(null)
    setFilename(null)
  }

  const onError = () => {
    setFeedback('An error occurred.')
  }
  const onProgress = () => {
    // override default library function that contains console.logs
  }
  const onFinish = () => {
    dispatch(updateImage(uploadedImgLinkRef.current))
  }

  const postAvatar = async (callback: (params: S3Response) => void) => {
    try {
      // use isPosting to block user interactions
      // e.g. prevent repeat clicks during network delays
      setIsPosting(true)

      const { data } = await uploadAvatar()

      callback(data as S3Response)
    } catch (e) {
      alert(`Sorry, there was an error on the server: ${e}`)
      setIsPosting(false)
      resetState()
    }
  }

  const getSignedUrl = (file: File, callback: (params: S3Response) => void) => {
    const supportedFileTypes = ['image/png', 'image/jpeg', 'image/gif']

    if (!supportedFileTypes.includes(file.type)) {
      setFeedback('Unsupported image format.')
      return
    } else if (file.size > MAX_PHOTO_FILE_SIZE) {
      setFeedback('Maximum file size 25MB.')
      return
    } else {
      setFeedback(null)
      setImgSrcError(false)

      setFilename(file.name)
      // setIsFilenameShowing(true);

      postAvatar(callback)
    }
  }

  const onImageError = useCallback(() => dispatch(updateImage(null)), [dispatch])

  const onDeleteButtonClick = useCallback(() => {
    const deleteAvatar = async () => {
      try {
        await removeAvatar()
        dispatch(updateImage(null))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('DELETE AVATAR ERROR: ', error)
      }
    }

    deleteAvatar()
  }, [dispatch])

  const preprocess = (file: File, callback: (file: File) => void) => {
    fixPhotoOrientation(file, (fixedFile: File) => {
      callback(fixedFile)
      uploadedImgLinkRef.current = URL.createObjectURL(fixedFile)
    })
  }

  return (
    <RegularDialog {...props}>
      <HeaderModal
        controlRight={
          <HeaderControlTextLinkContainer className="testing-done" onClick={hideCurrentDialog}>
            <TextLinkSpan color="sand">Done</TextLinkSpan>
          </HeaderControlTextLinkContainer>
        }
      />
      <Fade css={[blockAndDim(isPosting), padding('26px 6% 62px')]}>
        <Photo
          uploadedProfilePhoto={image}
          imgSrcError={imgSrcError}
          onError={onImageError}
          onDeleteButtonClick={onDeleteButtonClick}
        />
        <Metric2 css={[textAlignCenter, marginBottom(56), textTransformUppercase, letterSpacing(2)]}>
          Profile Photo
        </Metric2>
        <ButtonSection>
          <CustomFileInputContainer>
            <ButtonSolid>
              <Button3>Upload Photo</Button3>
            </ButtonSolid>
            <ReactS3Uploader
              accept="image/*"
              className="my-uploader"
              preprocess={preprocess}
              contentDisposition="auto"
              getSignedUrl={getSignedUrl}
              onError={onError}
              onFinish={onFinish}
              onProgress={onProgress}
              signingUrlWithCredentials
              uploadRequestHeaders={{}}
            />
          </CustomFileInputContainer>
        </ButtonSection>
        <Info info={filename} />
        <Info info={feedback} />
      </Fade>
    </RegularDialog>
  )
}
