import styled from '@emotion/styled'
import { Text } from 'ui-kit/Text'
import { Container, TextStyled } from './styles'
import { Profile } from '../../../api/membership/getFamilyPlanProfiles'

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 325px;
  height: auto;
  left: 24px;
  top: 182px;
  padding-bottom: 38px;
`

const HeaderText = styled(Text)`
  width: 327px;
  height: 29px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`

type HeaderProps = {
  readonly isAssigning: boolean
  readonly isAccountOwner: boolean
  readonly isMembershipActive: boolean
  readonly profiles?: Profile[]
  readonly inviteUptoProfileCount: number
}

export function Header({
  isAssigning,
  isAccountOwner,
  isMembershipActive,
  profiles,
  inviteUptoProfileCount,
}: HeaderProps) {
  return (
    <Container>
      {isAssigning ? (
        <Description>
          <HeaderText>Add Profile</HeaderText>
          <TextStyled size="smallTextAlt">
            Please note that all 1:1 Live training expenses incurred by guests without their own credit card on file
            will be billed to your account. Any guest expense will be itemized on your invoice.
          </TextStyled>
        </Description>
      ) : (
        <Description>
          <HeaderText>Profiles</HeaderText>
          {isAccountOwner && inviteUptoProfileCount > 0 && isMembershipActive ? (
            <TextStyled size="smallTextAlt">
              Invite up to {inviteUptoProfileCount - (profiles || []).length + 1} additional profiles to your account.
            </TextStyled>
          ) : null}
          {isAccountOwner && inviteUptoProfileCount > 0 && !isMembershipActive ? (
            <TextStyled size="smallTextAlt">You can manage profiles once your Membership is active.</TextStyled>
          ) : null}
        </Description>
      )}
    </Container>
  )
}
