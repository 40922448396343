import { Dispatch, Reducer, ReducerAction, ReducerState, useEffect, useReducer } from 'react'

import { getFromTheLocalStorage, setToTheLocalStorage, parseToJSON, stringifyFromJSON } from '../utils'

export function usePersistedReducer<R extends Reducer<any, any>>(
  reducer: R,
  key: string,
  initialState: ReducerState<R>,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] {
  const init = parseToJSON(getFromTheLocalStorage(key)) || initialState
  const [state, dispatch] = useReducer(reducer, init)

  useEffect(() => {
    setToTheLocalStorage(key, stringifyFromJSON(state))
  }, [key, state])

  return [state, dispatch]
}
