import { useTheme } from '@emotion/react'
import { useDialogManager } from 'lib/dialog-manager'
import PropTypes from 'prop-types'
import React, { VFC } from 'react'

import { ArrowLeft, X } from '../icons'

import { HeaderCenter, HeaderContainer, HeaderControlContainer, HeaderControlInnerA } from './styled/header'
import { marginBottom } from './styled/spacing'
import { textAlignCenter } from './styled/utility'

const HeaderModal: VFC<{
  controlLeft?: string
  controlRight?: React.ReactNode
  headerSubtitle?: string
  headerTitle?: string
  isAlternateHeader?: boolean
  onClickControlLeft?: () => void
}> = ({ controlLeft, controlRight, headerSubtitle, headerTitle, isAlternateHeader, onClickControlLeft }) => {
  const { hideCurrentDialog } = useDialogManager()
  const theme = useTheme()

  return (
    <div css={[marginBottom('sm'), textAlignCenter]}>
      <HeaderContainer>
        <HeaderControlContainer>
          {controlLeft === 'ArrowLeft' ? (
            <HeaderControlInnerA onClick={onClickControlLeft || hideCurrentDialog}>
              <ArrowLeft
                className="testing-arrow-left"
                theme={theme}
                css={{
                  maxWidth: 24,
                }}
              />
            </HeaderControlInnerA>
          ) : controlLeft === 'X' ? (
            <HeaderControlInnerA onClick={onClickControlLeft || hideCurrentDialog}>
              <X
                css={{
                  width: 15.5,
                  height: 15.5,
                }}
                color={theme.textColor}
              />
            </HeaderControlInnerA>
          ) : null}
        </HeaderControlContainer>

        {headerTitle ? (
          <HeaderCenter
            headerSubtitle={headerSubtitle}
            headerTitle={headerTitle}
            isAlternateHeader={!!isAlternateHeader}
          />
        ) : null}

        <HeaderControlContainer css={{ textAlign: 'right' }}>{controlRight}</HeaderControlContainer>
      </HeaderContainer>
    </div>
  )
}

HeaderModal.propTypes = {
  controlLeft: PropTypes.string,
  controlRight: PropTypes.node,
  headerSubtitle: PropTypes.string,
  headerTitle: PropTypes.string,
  isAlternateHeader: PropTypes.bool,
  onClickControlLeft: PropTypes.func,
}

export default HeaderModal
