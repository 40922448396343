import { axios } from 'system/axios'

import { HistoryWorkoutEntity } from '../entities/HistoryWorkoutEntity'

type TrainerResponse = {
  id: number
  first_name: number
  last_name: number
}

type UserResponseShort = {
  id: string
  first_name: string
  last_name: string
  image: string | null
}

type PlanWorkoutResponse = {
  id: string
  name: string
  category: string
  owner: UserResponseShort
  customer: UserResponseShort
  trainer: TrainerResponse
}

export type WorkoutsHistoryResponseItem = HistoryWorkoutEntity & {
  plan_workout?: PlanWorkoutResponse
}

export type HistoryDateRange = [start: string, end: string]

export const getHistoryWorkouts = (userId: string, dateRange: HistoryDateRange) =>
  axios.get<{ data: WorkoutsHistoryResponseItem[] }>(`/user/${userId}/history-workout`, {
    params: { s: { start_time: { $between: dateRange } } },
  })
