import styled from '@emotion/styled'

import { Subhead, SubheadLarge } from '../Typography'
import { ComponentProps, ReactNode } from 'react'

const Circle = styled.div<{ isWhite?: boolean }>(({ isWhite, theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: isWhite ? theme.colors.white : theme.colors.sand,
}))

type CircleWithInitialsProps = {
  readonly isWhite?: boolean
  readonly isLarge?: boolean
  readonly children: ReactNode
} & ComponentProps<typeof Circle>

export const CircleWithInitials = ({ isWhite, isLarge, children, ...props }: CircleWithInitialsProps) => (
  <Circle isWhite={isWhite} {...props}>
    {isLarge ? <SubheadLarge>{children}</SubheadLarge> : <Subhead>{children}</Subhead>}
  </Circle>
)
