import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { FC, useEffect, useState } from 'react'

import { TRANSITIONS } from '../../constants'

import { bp } from './breakpoints'
import { paddingBottom, paddingTop, paddingVertical } from './spacing'
import { H1, SubHeadingSmall } from './typography'
import { flexVertical, show, textAlignCenter } from './utility'

// default transition is 'allMedium' (see utility.js's cssTransition)
export const FadeIn = styled.div<{
  isIn?: boolean
  zIndex?: number
  transition?: keyof typeof TRANSITIONS
}>(
  ({ zIndex }) => ({ position: 'relative', zIndex }),
  ({ isIn, transition }) => show(isIn, transition),
)

export const Fade: FC<{
  readonly delay?: number
  readonly transition?: keyof typeof TRANSITIONS
  readonly className?: string
}> = ({ children, delay = 0, transition, ...props }) => {
  const [isIn, setIsIn] = useState(false)
  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      isMounted && setIsIn(true)
    }, delay)
    return () => {
      isMounted = false
    }
  }, [delay])
  return (
    <FadeIn transition={transition} isIn={isIn} {...props}>
      {children}
    </FadeIn>
  )
}

export const constrainMobile360 = css({
  [bp.xsMax]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 360,
  },
})

export const constrain360 = css({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 360,

  [bp.sm]: {
    padding: '0 9%',
  },
})

export const constrainWidth = ({ doNotConstrainWidth, wide }: { doNotConstrainWidth?: boolean; wide?: boolean }) =>
  css({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: doNotConstrainWidth ? '100%' : '88%',

    [bp.sm]: {
      width: doNotConstrainWidth ? '100%' : wide ? '76%' : '50%',
    },
    [bp.md]: {
      width: doNotConstrainWidth ? '100%' : wide ? '68%' : '48%',
    },
    [bp.lg]: {
      width: doNotConstrainWidth ? '100%' : wide ? '62%' : '40%',
      maxWidth: doNotConstrainWidth ? '100%' : wide ? '1200px' : '50em',
    },
  })

export const paddingVerticalContainerDefault = css(paddingTop('xs'), paddingBottom('lg'))
// Main is the outermost content layout container.
// It excludes HeaderMain & HeaderModal but includes HeaderPage.
// It is vertical padding and "auto-constrain"
// Override constraint settings with  constrainWidth props
// ex. <Main>, <Main wide>, <FadeMain extraTopPadding>, <Main doNotConstrainWidth>
// width constraints are narrow (default), doNotConstrainWidth, and wide
export const Main = styled.div<{
  doNotConstrainWidth?: boolean
  wide?: boolean
  extraTopPadding?: boolean
}>(
  (props) => constrainWidth(props),
  ({ extraTopPadding }) => (extraTopPadding ? paddingVertical('lg') : paddingVerticalContainerDefault),
)
export const FadeMain: FC<{
  readonly delay?: number
  readonly transition?: keyof typeof TRANSITIONS
  readonly doNotConstrainWidth?: boolean
  readonly wide?: boolean
  readonly extraTopPadding?: boolean
}> = ({ children, transition, ...props }) => (
  <Fade transition={transition}>
    <Main {...props}>{children}</Main>
  </Fade>
)

// this is like Main, but does not auto-constrain width
export const sparseContentContainerInner = css([
  flexVertical,
  textAlignCenter,
  {
    minHeight: '63vh',
  },
])

// TODO remove or come up with real reason to have a semantic Section
export const Section = styled.section<{ center?: boolean }>(({ center }) => ({
  position: 'relative',
  textAlign: center ? 'center' : undefined,
}))

export const HeaderGroup: FC<{ readonly mainHeading: string; readonly subHeading?: string }> = ({
  mainHeading,
  subHeading,
  ...props
}) => (
  <div css={textAlignCenter} {...props}>
    <H1 css={paddingBottom('xxs')}>{mainHeading}</H1>
    {subHeading ? <SubHeadingSmall noMarginTop>{subHeading}</SubHeadingSmall> : null}
  </div>
)
