import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, VFC } from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

import { TitledRoute, TitledRouteProps } from './TitledRoute'

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  title,
  location,
}: {
  readonly component: VFC<RouteComponentProps<any>>
} & RouteComponentProps<any> &
  TitledRouteProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    // TODO-EB: this hook is called a lot of times since there's a lot of PrivateRoute
    //   components rendered at the same time. This should be unwanted and be fixed.
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: location.pathname },
        })
      }
    }
    fn()
  }, [isAuthenticated, loginWithRedirect, path, location])

  return (
    <TitledRoute
      title={title}
      path={path}
      exact={exact}
      render={(props: RouteComponentProps) => (isAuthenticated ? <Component {...props} /> : null)}
    />
  )
}

export default withRouter(PrivateRoute)
