import styled from '@emotion/styled'
import { patchOwnProfile } from 'api/profile/patchOwnProfile'
import { routing } from 'model/Routing/routing'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { mergeUserProfile } from 'store/user'

import { ArrowNextStep } from '../icons'
import { H3 } from '../ui-kit'

import { bp } from './styled/breakpoints'
import { ButtonUnstyled } from './styled/button'

export const Container = styled.div({
  marginBottom: 50,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [bp.md]: {
    marginBottom: 80,
  },
  [bp.lg]: {
    marginBottom: 90,
  },
})

const BackIcon = styled(ArrowNextStep)`
  transform: rotate(180deg);
`

export const PageHeader: FC<{
  readonly withBackButton?: boolean
  readonly withSkipButton?: boolean
}> = ({ withBackButton, withSkipButton, children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const patchProfile = async () => {
    await patchOwnProfile({
      require_cc: false,
    }).then((res) => {
      dispatch(
        mergeUserProfile({
          ...res.data,
          require_cc: false,
        }),
      )
      history.push(routing.onboarding.welcome.generatePath())
    })
  }
  return (
    <Container>
      <div>
        {withBackButton ? (
          <ButtonUnstyled>
            <BackIcon onClick={() => history.goBack()} />
          </ButtonUnstyled>
        ) : null}
      </div>
      <H3>{children}</H3>
      <div>
        {withSkipButton ? (
          <ButtonUnstyled onClick={() => patchProfile()} style={{ paddingBottom: 5, color: '#666' }}>
            SKIP
          </ButtonUnstyled>
        ) : null}
      </div>
      <div />
    </Container>
  )
}
