import styled from '@emotion/styled'
import { ButtonSolid } from '../../components/styled/button'
import { blockAndDim, blockPointerEvents } from '../../components/styled/utility'
import { usePleaseVerifyEmailPresenter } from '../../presenter'
import { EmailPagesContainer, EmailPagesTitleBlock, Button3, Button4, PureButton } from '../../ui-kit'
import { marginBottom, width } from '../../ui-kit/utils'

const SecondaryButton = styled(PureButton)(({ theme }) => ({
  transition: theme.transitions.allFast2,

  '&:hover': {
    color: theme.colors.sand,
  },
}))

export const PleaseVerifyEmail = () => {
  const { isPosting, isFadingOut, onPrimaryButtonClick, onSecondaryButtonClick } = usePleaseVerifyEmailPresenter()

  return (
    <EmailPagesContainer isIn={!isFadingOut} css={[blockAndDim(isPosting), blockPointerEvents(isFadingOut)]}>
      <EmailPagesTitleBlock
        title="Please Verify Your Email Address"
        message="Go to your email in order to confirm your account."
      />
      <div css={width('100%')}>
        <ButtonSolid onClick={onPrimaryButtonClick} fullWidth large css={marginBottom('2rem')}>
          <Button3>Continue</Button3>
        </ButtonSolid>
        <SecondaryButton onClick={onSecondaryButtonClick}>
          <Button4>Resend Email</Button4>
        </SecondaryButton>
      </div>
    </EmailPagesContainer>
  )
}
