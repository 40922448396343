import styled from '@emotion/styled'
import React, { FC, MouseEventHandler } from 'react'

import { bp } from './breakpoints'
import { marginBottom, marginTop, paddingBottom, paddingHorizontal } from './spacing'
import { SmallCaps } from './typography'
import {
  hoverOpacityFast,
  pseudoFocusLightBackground,
  textAlignCenter,
  width100,
  cursorPointer,
  transitionFast,
} from './utility'

export const ButtonSection = styled.div(marginBottom('md'), marginTop('lg'), textAlignCenter)

// 'unstyled' as in, remove default browser button-ish styles.
// Semantically, you want a button, but you don't want it
// to be styled like a button in any way.
export const ButtonUnstyled = styled.button(
  {
    border: 0,
    padding: 0,
    background: 'transparent',
  },
  ({ theme }) => pseudoFocusLightBackground(theme),
)

export const ButtonTextLink = styled(ButtonUnstyled)`
  font-family: 'HCo Gotham SSm', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.28px;
  border-bottom: 1px solid;
  cursor: pointer;
`

const buttonMinWidth = 160
export const Button = styled.button<{ danger?: boolean; inactive?: boolean }>(
  ({ danger, inactive, theme }) => ({
    minWidth: buttonMinWidth,
    margin: '1em auto',
    padding: '14px 16px',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: `1px solid ${danger ? theme.colors.red : theme.textColors.primary}`,
    borderBottom: `1px solid ${danger ? theme.colors.red : theme.textColors.primary}`,
    borderRadius: 0,
    background: 'transparent',
    outline: 'none',
    cursor: inactive ? 'not-allowed' : 'pointer',
    pointerEvents: inactive ? 'none' : undefined,
    opacity: danger ? 0.9 : inactive ? 0.7 : 1,

    // text
    color: danger ? theme.colors.red : theme.textColors.primary,
    fontSize: theme.typography.size.buttonText,
    fontWeight: 500,
    letterSpacing: theme.typography.letterSpacing.regular,

    '&:hover': {
      color: !inactive ? (danger ? theme.colors.red : theme.textColors.primary) : undefined,
      borderTop: !inactive ? `1px solid ${danger ? theme.colors.red : theme.textColors.primary}` : undefined,
      borderBottom: !inactive ? `1px solid ${danger ? theme.colors.red : theme.textColors.primary}` : undefined,
      opacity: danger ? 1 : undefined,
      minWidth: buttonMinWidth + 6,
    },
  }),
  transitionFast,
)

const ButtonSolidInner = styled.button<{ large?: boolean; inactive?: boolean }>(
  ({ inactive, theme, large }) => ({
    minWidth: buttonMinWidth,
    padding: '0 24px',
    border: 0,
    borderRadius: theme.variables.borderRadiusPGSmall,
    background: theme.backgroundBrand,
    outline: 'none',
    pointerEvents: inactive ? 'none' : undefined,
    opacity: inactive ? 0.7 : 0.9,
    height: large ? 56 : 48,

    color: theme.textColor,

    '&:hover': {
      color: !inactive ? theme.textColorHover : undefined,
      opacity: 0.99,
    },
    [bp.xxs]: {
      fontSize: theme.typography.size.buttonText,
    },
  }),
  transitionFast,
)

export const ButtonSolid: FC<
  {
    readonly fullWidth?: boolean
    readonly large?: boolean
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ fullWidth, children, large, ...props }) => (
  <ButtonSolidInner css={[fullWidth && width100]} large={large} {...props}>
    {children}
  </ButtonSolidInner>
)

export const SubButtonTextLink: FC<{
  readonly onClick: MouseEventHandler<HTMLButtonElement>
}> = ({ children, onClick }) => (
  <div>
    <ButtonUnstyled
      onClick={onClick}
      css={[
        paddingHorizontal('sm'),
        paddingBottom('xs'),
        cursorPointer,
        hoverOpacityFast,
        {
          position: 'relative',
          top: -10,
        },
      ]}
    >
      <SmallCaps>{children}</SmallCaps>
    </ButtonUnstyled>
  </div>
)
