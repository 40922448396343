import { TitledRoute } from './components/TitledRoute'
import { routing } from './model/Routing/routing'
import { ResetEmail, VerifyEmail } from './views'

export const renderUniversalRoutes = () => [
  <TitledRoute
    key={routing.resetEmail.pattern}
    path={routing.resetEmail.pattern}
    exact
    render={() => <ResetEmail />}
    title="Reset Password"
  />,
  <TitledRoute
    key={routing.verifyEmail.pattern}
    path={routing.verifyEmail.pattern}
    exact
    render={() => <VerifyEmail />}
    title="Verify Email"
  />,
]
