import styled from '@emotion/styled'

import { Calendar } from '../../../icons'
import { Body2, H3 } from '../../../ui-kit'
import { alignItemsCenter, displayFlexColumn, padding, textAlignCenter, width } from '../../../ui-kit/utils'

const Header = styled.header(({ theme }) => ({
  backgroundColor: theme.background,
  borderRadius: '50%',
  height: 136,
  width: 136,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

type EmptyClassesCalendarProps = {
  readonly date: string
  readonly showCompleteSessionLink: boolean
  readonly showCompleteSessionMsg: boolean
}

const EmptyClassesCalendar = ({ date, showCompleteSessionLink, showCompleteSessionMsg }: EmptyClassesCalendarProps) => (
  <section css={[displayFlexColumn, alignItemsCenter, textAlignCenter]}>
    <Header>
      <Calendar />
    </Header>
    <article css={width(350)}>
      {showCompleteSessionMsg ? (
        <H3 css={padding('32px 0 8px')}>
          No completed sessions
          <br />
          found for {date}
        </H3>
      ) : null}
      {showCompleteSessionLink ? <Body2>Complete a session to see it here</Body2> : null}
    </article>
  </section>
)

export default EmptyClassesCalendar
