import { useHistory } from 'react-router-dom'
import { DownloadAppLink } from 'views/DownloadApp/DownloadAppLink'

import { VerticalContainer } from '../../components/VerticalContainer'
import { bp } from '../../components/styled/breakpoints'
import { Fade } from '../../components/styled/containers'
import { marginBottom, textAlignCenter } from '../../components/styled/utility'
import { routing } from '../../model/Routing/routing'
import { Body3, H3, H4, NextPrevButton } from '../../ui-kit'

export const DownloadAppStep = () => {
  const { push } = useHistory()

  const onContinue = () => push(routing.onboarding.feedAlgorithm.generatePath())

  return (
    <Fade>
      <VerticalContainer
        css={{
          padding: '110px 20px 36px',
          maxWidth: 420,
          [bp.sm]: {
            paddingTop: 85,
          },
        }}
      >
        <div css={[textAlignCenter, marginBottom(264)]}>
          <H3 css={[marginBottom(100)]}>ACTIVATE ACCOUNT</H3>
          <H4 css={[marginBottom('1rem')]}>Your intro session with your trainer match is now confirmed</H4>
          <Body3>In order to join your first session, please download the FORME Personal Home Fitness app.</Body3>
          <div style={{ display: 'inline-block', paddingTop: 20 }}>
            <DownloadAppLink />
          </div>
        </div>
        <NextPrevButton css={{ margin: 0 }} onClick={onContinue} fullWidth large text="Skip" />
      </VerticalContainer>
    </Fade>
  )
}
