import React, { VFC } from 'react'

import { IconProps } from './types'

export const CrossIcon: VFC<IconProps> = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2264 4.2738C12.4433 4.49076 12.4433 4.84252 12.2264 5.05948L5.55972 11.7261C5.34276 11.9431 4.991 11.9431 4.77405 11.7261C4.55709 11.5092 4.55709 11.1574 4.77405 10.9405L11.4407 4.2738C11.6577 4.05684 12.0094 4.05684 12.2264 4.2738Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.77405 4.2738C4.991 4.05684 5.34276 4.05684 5.55972 4.2738L12.2264 10.9405C12.4433 11.1574 12.4433 11.5092 12.2264 11.7261C12.0094 11.9431 11.6577 11.9431 11.4407 11.7261L4.77405 5.05948C4.55709 4.84252 4.55709 4.49076 4.77405 4.2738Z"
      fill="white"
    />
  </svg>
)
