import styled from '@emotion/styled'

export const Body4 = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '0.75rem',
  lineHeight: 1.33,
  fontWeight: 400,
  margin: 0,
  padding: 0,
}))
