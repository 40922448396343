import { useDialogManager } from 'lib/dialog-manager'
import React, { useState, VFC } from 'react'
import { useSelector } from 'store'

import HeaderModal from '../components/HeaderModal'
import { ButtonSection, ButtonSolid } from '../components/styled/button'
import { Fade, constrain360, paddingVerticalContainerDefault } from '../components/styled/containers'
import { paddingTop, paddingVertical } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import { blockAndDim, textAlignCenter } from '../components/styled/utility'
import { MINIMUM_API_DELAY } from '../constants'
import { Button3 } from '../ui-kit'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'
import { useApiCancel } from '../hooks'

const DISPLAY_STATE = {
  waitingForUserConfirmation: 'waitingForUserConfirmation',
  canceling: 'canceling',
  apiCancelError: 'apiCancelError',
  finished: 'finished',
}

const CancelMembership: VFC<RegularDialogProps & { readonly onCancel: () => void }> = (props) => {
  const apiCancel = useApiCancel()

  const [displayState, setDisplayState] = useState(DISPLAY_STATE.waitingForUserConfirmation)

  const errorMessage = useSelector((state) => state.error.errorMessage)
  const user = useSelector((state) => state.user)
  const primaryMembership = user.membershipProfiles[0]?.membership
  const { hideCurrentDialog } = useDialogManager()

  const [isCanceling, setIsCanceling] = useState(false)

  const handleSubmit = async () => {
    try {
      setIsCanceling(true)
      await apiCancel(primaryMembership!.id!)
      setTimeout(() => {
        setDisplayState(DISPLAY_STATE.finished)
        setIsCanceling(false)
        props.onCancel()
      }, MINIMUM_API_DELAY)
    } catch (e) {
      setDisplayState(DISPLAY_STATE.apiCancelError)
      setIsCanceling(false)
    }
  }
  const valid_through_date = user.membershipProfiles[0]?.nextBillingDate
  const validThroughDate = valid_through_date ? valid_through_date : '...'

  return (
    <RegularDialog {...props}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Cancel Membership" onClickControlLeft={hideCurrentDialog} />

      <div css={[constrain360, paddingVerticalContainerDefault, blockAndDim(isCanceling)]}>
        {(() => {
          switch (displayState) {
            case DISPLAY_STATE.waitingForUserConfirmation:
              return (
                <Fade>
                  {valid_through_date ? (
                    <P css={paddingTop('sm')} color="whiteAlpha50">
                      Your membership is current until {validThroughDate}. Thank you for your membership!
                    </P>
                  ) : null}
                  {!valid_through_date && (
                    <P css={paddingTop('sm')} color="whiteAlpha50">
                      Thank you for your membership!
                    </P>
                  )}
                  <P>Tapping below will cancel your membership and you will not be billed again.</P>
                  {valid_through_date ? (
                    <P>
                      Please enjoy your membership until it ends on {validThroughDate}. You may also restore your
                      membership at any time.
                    </P>
                  ) : null}
                  {!valid_through_date && <P>You may also restore your membership at any time.</P>}

                  <ButtonSection>
                    <ButtonSolid onClick={handleSubmit}>
                      <Button3>Confirm Cancelation</Button3>
                    </ButtonSolid>
                  </ButtonSection>
                </Fade>
              )
            case DISPLAY_STATE.canceling:
              return (
                <Fade>
                  <P css={[paddingVertical('lg'), textAlignCenter]}>Canceling membership...</P>
                </Fade>
              )
            case DISPLAY_STATE.apiCancelError:
              return (
                <Fade>
                  <P css={[paddingVertical('lg'), textAlignCenter]}>{errorMessage}</P>
                  <ButtonSection>
                    <ButtonSolid onClick={hideCurrentDialog}>
                      <Button3>Back</Button3>
                    </ButtonSolid>
                  </ButtonSection>
                </Fade>
              )
            case DISPLAY_STATE.finished:
              return (
                <Fade>
                  <P css={[paddingVertical('lg'), textAlignCenter]}>Your membership has been canceled.</P>
                  <ButtonSection>
                    <ButtonSolid onClick={hideCurrentDialog}>
                      <Button3>Back</Button3>
                    </ButtonSolid>
                  </ButtonSection>
                </Fade>
              )
            default:
              return <P css={[paddingVertical('lg'), textAlignCenter]}>Unexpected state : {displayState}</P>
          }
        })()}
      </div>
    </RegularDialog>
  )
}

export default CancelMembership
