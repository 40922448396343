// @TODO remove this after complete redesign
export const SPACING = {
  multipliers: {
    horizontal: {
      xs: 1,
      sm: 1.18,
      md: 1.32,
      lg: 1.4,
      xl: 1.5,
    },
    vertical: {
      xs: 1,
      sm: 1.1,
      md: 1.2,
      lg: 1.3,
      xl: 1.4,
    },
  },
  horizontalBase: {
    xxs: 0.3,
    xs: 0.5,
    sm: 0.7,
    md: 1.1,
    lg: 1.8,
    xl: 3,
    xxl: 4,
  },
  verticalBase: {
    xxs: 0.1,
    xs: 0.32,
    sm: 0.56,
    md: 0.9,
    lg: 1.8,
    xl: 3.6,
    xxl: 8.2,
  },
  vertical: {
    xxs: '0.1em',
    xs: '0.32em',
    sm: '0.56em',
    md: '0.9em',
    lg: '1.8em',
    xl: '3.6em',
    xxl: '8.2em',
  },
}

// @TODO remove this after complete redesign
export const MULTIPLIERS = {
  body: {
    xs: 1.03,
    sm: 1.06,
    md: 1.08,
    lg: 1.1,
    xl: 1.15,
    xxl: 1.2,
  },
  heading: {
    xs: 1.05,
    sm: 1.08,
    md: 1.2,
    lg: 1.24,
    xl: 1.32,
    xxl: 1.44,
  },
}

export const TIMING = {
  fast1: 115,
  fast2: 152,
  fast3: 205,
  medium: 360,
  slow: 2050,
  slow2: 5760,
}

export const CURVES = {
  sCurve: 'cubic-bezier(0.41,0.23,0.31,0.47)',
  sCurve2: 'cubic-bezier(0.3, 0.8, 0.1, 0.95)',
}

export const TRANSITIONS = {
  allFast1: `all ${TIMING.fast1}ms ${CURVES.sCurve}`,
  allFast2: `all ${TIMING.fast2}ms  ${CURVES.sCurve}`,
  allFast3eio: `all ${TIMING.fast3}ms ease-in-out`,
  allMedium: `all ${TIMING.medium}ms ${CURVES.sCurve}`,
  slow: `all ${TIMING.slow}ms ${CURVES.sCurve2}`,
  slow2: `all ${TIMING.slow2}ms ${CURVES.sCurve2}`,
}

export const MINIMUM_API_DELAY = 680
