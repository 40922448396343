import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import mixpanel from 'mixpanel-browser'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'store'
import { clearUserData, initiateUserLogin, mergeUserProfile } from 'store/user'

import { bp } from '../components/styled/breakpoints'
import { Button, ButtonSection, ButtonSolid } from '../components/styled/button'
import { Fade, FadeIn, constrain360, sparseContentContainerInner } from '../components/styled/containers'
import { marginTop, paddingVertical } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import {
  displayBlock,
  displayFlex,
  displayNone,
  height100,
  justifyContentCenter,
  objectFitCover,
  positionAbsoluteAll,
  width100,
} from '../components/styled/utility'
import { TIMING } from '../constants'
import { DeepLinksContext, deepLinkPathnames } from '../hooks'
import { FormeLogoText } from '../icons'
import bgImageMobile from '../images/landing-image-mobile.jpg'
import { skipToLoginFlag } from '../system/external-values'
import { Button2, Button3 } from '../ui-kit'
import history from '../utils/history'

const imgCover = css(width100, height100, objectFitCover, positionAbsoluteAll)
const LaunchContainer = styled.div(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  background: theme.colors.black,
}))
const BgImgContainer = styled.div([
  {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,

    [bp.sm]: displayNone,
  },
])
const RectangleContainer = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  left: 0,
  height: '100vh',
  boxSizing: 'border-box',

  [bp.sm]: {
    padding: '6.7vh 0 7vh',
  },
})
const BlackRectangleBg = styled.div({
  boxSizing: 'border-box',
  padding: '12vh 0 14vh',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const Launch = () => {
  useEffect(() => {
    mixpanel.reset()
  }, [])

  /*
   * There is some extra complexity on intial page fade in due to a crazy Auth0 +
   * Safari bug. From the Univeral Template (in Safari), when the user clicks the
   * browser back button, Safari immediately shows this Launch page mostly as it
   * was rendered before, but does not run any of this React code again at all.
   * (For instance, `console.log('hi')` will not show up.) However, for some
   * reason, the FadeIn component is reset to opacity 0.

   * Due to this, we only want the conditional fade logic (which is
   * !isRedirectingToSignIn && !redirectAfterLogoutLocation &&
   * !userIsGoingToRedirect) if the component's code is run; we want to exclude
   * this logic when the user returns from the Universal Template by clicking the
   * browser back button on Safari. See <Fadein isIn={<this part>}>. However,
   * since we always want to make sure that the page gets a fade in for regular
   * circumstances, this is wrapped with a "regular" <Fade> component.
   */
  const [isConditionalFadeIncluded, setIsConditionalFadeIncluded] = useState(true)
  useEffect(() => {
    setIsConditionalFadeIncluded(false)
  }, [setIsConditionalFadeIncluded])

  const { loginWithRedirect, logout, error } = useAuth0()
  const urlParams = new URLSearchParams(history.location.search)
  const auth0ResponseMessage = urlParams.get('error_description')
  const dispatch = useDispatch()
  const { isRedirectingToSignIn, redirectAfterLogoutLocation } = useSelector((state) => state.user)
  const {
    actions: { setDeepLink },
  } = useContext(DeepLinksContext)!

  const { pathname } = useLocation()
  const genesisPathParam = deepLinkPathnames.includes(pathname) ? `&genesisPath=${pathname}` : ''
  const signInFragment = `page=signIn${genesisPathParam}`
  const createAccountFragment = `page=createAccount${genesisPathParam}`

  useEffect(() => {
    dispatch(clearUserData())
    setDeepLink(pathname)
  }, [pathname, setDeepLink, error, dispatch])

  const [isIn, setIsIn] = useState(false)
  const [areInteractionsBlocked, setAreInteractionsBlocked] = useState(false)
  const [userIsGoingToRedirect, setUserIsGoingToRedirect] = useState(false)

  useEffect(() => {
    /*
     * isRedirectingToSignIn persists after the auth0 logout.
     * Now we know to redirect to Auth0 Sign In.
     * setUserIsGoingToRedirect is used to prevent Launch content from flashing/displaying
     */
    if (isRedirectingToSignIn && !error) {
      setUserIsGoingToRedirect(true)
      loginWithRedirect({ fragment: signInFragment })
    }
    if (redirectAfterLogoutLocation) {
      setUserIsGoingToRedirect(true)
      history.push(redirectAfterLogoutLocation)
    }
  }, [
    error,
    isRedirectingToSignIn,
    loginWithRedirect,
    redirectAfterLogoutLocation,
    setUserIsGoingToRedirect,
    signInFragment,
  ])

  const goToLogInPage = useCallback(() => {
    if (!areInteractionsBlocked) {
      setAreInteractionsBlocked(true)
      setIsIn(false)
      setTimeout(() => {
        setAreInteractionsBlocked(false)
        dispatch(initiateUserLogin())
        loginWithRedirect({
          fragment: signInFragment,
          appState: { targetUrl: window.location.search },
        })
      }, TIMING.medium)
    }
  }, [areInteractionsBlocked, dispatch, loginWithRedirect, signInFragment])

  const skipToLoginPageRequested = urlParams.get(skipToLoginFlag) === 'true'

  useEffect(() => {
    if (skipToLoginPageRequested) goToLogInPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (skipToLoginPageRequested) return null

  if (error) {
    return (
      <Fade>
        <FadeIn isIn={!(isRedirectingToSignIn || userIsGoingToRedirect)}>
          <div css={[constrain360, paddingVertical('lg')]}>
            <div css={sparseContentContainerInner}>
              <P css={marginTop('xxl')}>{auth0ResponseMessage || error.message}</P>
            </div>
            <ButtonSection>
              <Button
                onClick={() => {
                  /*
                   * Persistence is required since the logout() below will erase transient state.
                   * This logout step is important, because a user in a not-fully-logged-out
                   * and also-in-an-error-state will keep redirecting back to members-web-app.
                   * This is why we persist isRedirectingToSignIn to ensure a full logout
                   * prior to redirecting the user back to Auth0 Sign In (in the useEffect).
                   */
                  dispatch(
                    mergeUserProfile({
                      isRedirectingToSignIn: true,
                    }),
                  )
                  logout({ returnTo: window.location.origin })
                }}
              >
                Continue
              </Button>
            </ButtonSection>
          </div>
        </FadeIn>
      </Fade>
    )
  }

  return (
    <Fade>
      <FadeIn
        isIn={
          isConditionalFadeIncluded ||
          (isIn && !isRedirectingToSignIn && !redirectAfterLogoutLocation && !userIsGoingToRedirect)
        }
      >
        <LaunchContainer>
          <BgImgContainer>
            <img
              css={[
                imgCover,
                {
                  objectPosition: '100% 10%',
                },
              ]}
              src={bgImageMobile}
              alt=""
              onLoad={() => {
                setIsIn(true)
                setIsConditionalFadeIncluded(true)
              }}
            />
          </BgImgContainer>

          <section css={[displayFlex, justifyContentCenter, height100]}>
            <video muted autoPlay loop controls={false} css={[objectFitCover, width100, height100]}>
              <source src="https://static.formelife.net/videos/FOR-MAR-034_03.mp4" />
            </video>
          </section>

          <RectangleContainer>
            <BlackRectangleBg>
              <FormeLogoText
                css={{
                  margin: '0 auto',
                  maxWidth: '53%',
                }}
              />

              <div>
                <ButtonSolid
                  id="testing-create-account"
                  onClick={() => {
                    if (!areInteractionsBlocked) {
                      setAreInteractionsBlocked(true)
                      setIsIn(false)
                      setTimeout(() => {
                        setAreInteractionsBlocked(false)
                        dispatch(initiateUserLogin())
                        loginWithRedirect({
                          fragment: createAccountFragment,
                        })
                      }, TIMING.medium)
                    }
                  }}
                >
                  <Button3>Create an Account</Button3>
                </ButtonSolid>
                <Button2
                  as="button"
                  id="testing-sign-in"
                  css={[
                    displayBlock,
                    {
                      border: '0 none',
                      background: 'transparent',
                      margin: '32px auto 0',
                      color: 'white',
                    },
                  ]}
                  onClick={goToLogInPage}
                >
                  SIGN IN
                </Button2>
              </div>
            </BlackRectangleBg>
          </RectangleContainer>
        </LaunchContainer>
      </FadeIn>
    </Fade>
  )
}

export default Launch
