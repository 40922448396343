export const minAge = 18
export const maxAge = 120
export const maxCharacterCount = 24
export const minWeight = 50
export const maxWeight = 400
export const minFeet = 3
export const maxFeet = 7

// eslint-disable-next-line no-useless-escape
export const specialCharactersRe = /[`~!@#$%^&*()_|+\=?;:'".<>\{\}\[\]\\\/]/gi

export type ValidationRequirement =
  | 'optional'
  | 'birthday'
  | 'weight'
  | 'height'
  | 'postal-code'
  | 'country'
  | 'gender'
  | 'non-empty-alpha'
  | 'phone'
