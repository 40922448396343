import styled from '@emotion/styled'

import { size } from '../utils'

type DayContainerType = {
  isSelected?: boolean
  isToday?: boolean
  weekdayHeading?: boolean
}

export const DayContainer = styled.div<DayContainerType>(
  size(46),
  ({ isSelected, isToday, weekdayHeading, theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: isToday ? theme.colors.blackAlpha15 : undefined,
    border: isSelected ? `2px solid ${theme.textColors.primary}` : '2px solid transparent',
    borderRadius: 6,
    cursor: isSelected || weekdayHeading ? 'default' : 'pointer',
    transition: theme.transitions.allFast2,

    '&:hover': {
      border: !isSelected && !weekdayHeading ? `2px solid ${theme.colors.sandAlphaX66}` : '',
      boxShadow: !isSelected && !weekdayHeading ? `0 0 15px ${theme.colors.sandAlphaX66}` : '',
    },

    '&:focus': {
      outline: 0,
    },
  }),
)
