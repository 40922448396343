import { WorkoutsHistoryResponseItem } from '../../../api/history-workouts/getHistoryWorkouts'

import { simplifyDate } from './simplifyDate'

// if it's not mind or recovery, it's 'Active'
export const categoryToTopLevelCategory = (category?: string | null) =>
  category && !!category.match(/mind|recovery/gi) ? category : 'Active'

// convert finished calendarData to date-keyed dictionary of topLevelCategories
export const keyCalendarDataFinished = (calendarData: WorkoutsHistoryResponseItem[], timezone: string) =>
  calendarData
    .filter(({ workout_status }) => workout_status!.toLowerCase() === 'completed')
    .reduce((acc, { start_time, plan_workout }) => {
      const simpleDate = simplifyDate(start_time!, timezone)
      const topLevelCategory = categoryToTopLevelCategory(plan_workout?.category)
      return {
        ...acc,
        [simpleDate]: acc[simpleDate] ? [...new Set([...acc[simpleDate]!, topLevelCategory])] : [topLevelCategory],
      }
    }, {} as Record<string, string[]>)
