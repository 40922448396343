import styled from '@emotion/styled'
import { Color } from '../../../model/ThemeProvider/theme'

export const Dot = styled.div<{ color: Color }>(({ color, theme }) => ({
  position: 'relative',
  display: 'inline-block',
  background: theme.colors[color],
  width: 8,
  height: 8,
  borderRadius: '50%',
}))
