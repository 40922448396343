import styled from '@emotion/styled'
import dayjs from 'dayjs'
import * as R from 'ramda'
import React, { memo } from 'react'

import { H5 } from '../../../ui-kit'
import { alignItemsCenter, displayFlex, justifyContentCenter, marginTop } from '../../../ui-kit/utils'

import { ClassListItemNew } from './ClassListItemNew'
import EmptyClassesCalendar from './EmptyClassesCalendar'

const ClassesContainer = styled.div({
  justifySelf: 'end',
  width: '100%',
  maxWidth: 374,
})

// { 'YEAR-MONTH-DAY': data } e.g.: { '2022-07-15': {...} }
const ClassListContainer = ({ classesToRender, keyedPersonalSessions, ...props }: any) => {
  return (
    <>
      {Object.entries(classesToRender).map(([date, sessions], index) => (
        <section css={index !== 0 && marginTop(24)} key={date} {...props}>
          <H5>
            {(() => {
              const parsedDate = dayjs(date, 'YYYY-MM-DD')
              const diff = dayjs().diff(parsedDate, 'day')
              if (diff === 0) return 'Today'
              if (diff === 1) return 'Yesterday'
              return parsedDate.format('dddd, MMMM D')
            })()}
          </H5>
          {(sessions as any[]).map((entry, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ClassListItemNew {...entry} key={i} css={marginTop(12)} keyedPersonalSessions={keyedPersonalSessions} />
          ))}
        </section>
      ))}
    </>
  )
}

type ClassesListProps = {
  readonly selectedDate?: any
  readonly activeMonth?: any
  readonly activeYear?: any
  readonly formattedDate?: any
  readonly selectedClasses: any
  readonly keyedPersonalSessions: any
  readonly allClassesForThisMonth?: any
  readonly isThereValidClassesForThisMonth?: any
}

export const ClassesList = memo(
  ({
    selectedDate,
    activeMonth,
    activeYear,
    formattedDate,
    selectedClasses = {},
    keyedPersonalSessions,
    allClassesForThisMonth,
    isThereValidClassesForThisMonth,
  }: ClassesListProps) => {
    const isThereClassesForSelectedDate = Boolean(selectedDate && R.not(R.isEmpty(selectedClasses)))
    const isThereClassesForThisMonth = Boolean(!selectedDate && isThereValidClassesForThisMonth)
    const isThereClasses = isThereClassesForSelectedDate || isThereClassesForThisMonth

    const actualMonth = dayjs().month()
    const actualYear = dayjs().year()
    const activeDate = selectedDate || dayjs().date()
    const actualDate = dayjs().date()
    const showCompleteSessionLink =
      activeYear === actualYear ? (activeMonth === actualMonth ? activeDate >= actualDate : false) : false
    const showCompleteSessionMsg =
      activeYear === actualYear
        ? activeMonth === actualMonth
          ? true
          : activeMonth > actualMonth
          ? false
          : true
        : activeYear < actualYear
        ? true
        : false

    return (
      <ClassesContainer css={isThereClasses === false && [displayFlex, justifyContentCenter, alignItemsCenter]}>
        {isThereClasses ? (
          <ClassListContainer
            classesToRender={formattedDate ? selectedClasses : allClassesForThisMonth}
            keyedPersonalSessions={keyedPersonalSessions}
          />
        ) : (
          <EmptyClassesCalendar
            date={
              selectedDate
                ? dayjs(formattedDate).format('dddd, MMMM D')
                : dayjs().set('month', activeMonth).format('MMMM')
            }
            showCompleteSessionLink={showCompleteSessionLink}
            showCompleteSessionMsg={showCompleteSessionMsg}
          />
        )}
      </ClassesContainer>
    )
  },
)
