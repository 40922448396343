import styled from '@emotion/styled'

import { CURVES, TIMING } from '../../../constants'

export const ModalBlocker = styled.div<{ blur?: boolean }>(({ theme, blur }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: ['100vh', '-webkit-fill-available'],
  background: theme.colors.black60,
  backdropFilter: blur ? 'blur(16px)' : undefined,
  transition: `backdrop-filter ${TIMING.fast3}ms ${CURVES.sCurve}`,
}))
