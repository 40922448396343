import { axios } from 'system/axios'

type InviteUser = {
  owner: false
  email: string
  firstName: string
  lastName: string
}

export const inviteUser = (membershipId: string, invite: Partial<InviteUser>) => {
  invite.lastName = 'test'
  return axios.post<InviteUser>(`/membership/${membershipId}/invite-user`, invite, {
    baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  })
}
