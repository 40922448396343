import React, { VFC } from 'react'

import logoAmex from '../images/card-logo-amex.png'
import logoDiscover from '../images/card-logo-discover.png'
import logoJcb from '../images/card-logo-jcb.png'
import logoMastercard from '../images/card-logo-mastercard.png'
import logoVisa from '../images/card-logo-visa.png'

const PaymentCardLogo: VFC<{ readonly type: string }> = ({ type }) => {
  const logoImageUrl = (card_type: string) => {
    switch (card_type) {
      case 'amex':
        return logoAmex
      case 'discover':
        return logoDiscover
      case 'jcb':
        return logoJcb
      case 'mastercard':
        return logoMastercard
      case 'visa':
      default:
        return logoVisa
    }
  }

  return (
    <img
      css={{
        height: '25px',
        width: 'auto',
        objectFit: 'contain',
        borderRadius: '2px',
      }}
      src={logoImageUrl(type)}
      alt={type}
    />
  )
}

export default PaymentCardLogo
