export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export const toMs = ({
  milliseconds = 0,
  seconds = 0,
  minutes = 0,
  hours = 0,
  days = 0,
}: {
  milliseconds?: number
  seconds?: number
  minutes?: number
  hours?: number
  days?: number
}) => milliseconds + seconds * SECOND + minutes * MINUTE + hours * HOUR + days * DAY
