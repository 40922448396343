import styled from '@emotion/styled'

export const Metric1 = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.heading,
  fontSize: '3.75rem',
  lineHeight: 1,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  textTransform: 'uppercase',
}))
