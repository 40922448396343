import dayjs from 'dayjs'

import { categoryToTopLevelCategory } from '../../ui-kit/Calendar/utils/utils'

import { msToMinutes } from './msToMinutes'

export const reduceCalendarDataToTLCLastMonth = (
  acc: Record<string, { elapsedMin: number; thisTimeLastMonth: number }>,
  {
    date,
    elapsed_time,
    workout_category,
  }: { date?: string | null; elapsed_time: number | string | null; workout_category?: string | null },
) => {
  // note that the dayjs().date() "date" here refers to "day of the month"
  // ex. if today is the 27th of May, then dayjs().date() === 27
  const workoutDate = dayjs(date).date()
  const todayDate = dayjs().date()
  const countsTowardThisTimeLastMonth = workoutDate <= todayDate

  const topLevelCategory = categoryToTopLevelCategory(workout_category)
  const elapsedTime = typeof elapsed_time === 'number' ? elapsed_time : elapsed_time ? parseInt(elapsed_time) : 0
  const elapsedMinutes = isNaN(elapsedTime) ? 0 : msToMinutes(elapsedTime)

  return {
    ...acc,
    [topLevelCategory]: acc[topLevelCategory]
      ? {
          elapsedMin: acc[topLevelCategory]!.elapsedMin + elapsedMinutes,
          thisTimeLastMonth: countsTowardThisTimeLastMonth
            ? acc[topLevelCategory]!.thisTimeLastMonth + elapsedMinutes
            : acc[topLevelCategory]!.thisTimeLastMonth,
        }
      : {
          elapsedMin: elapsedMinutes,
          thisTimeLastMonth: countsTowardThisTimeLastMonth ? elapsedMinutes : 0,
        },
  }
}
