import { css } from '@emotion/react'

import { AppTheme } from '../../model/ThemeProvider/theme'

const globalStyle = (theme: AppTheme) =>
  css({
    html: {
      background: theme.backgroundPage,
      minHeight: ['100vh', '-webkit-fill-available'],
    },
    '*::selection': {
      background: theme.colors.sandAlphaX33,
    },
    body: {
      minHeight: ['100vh', '-webkit-fill-available'],
      margin: 0,
      color: theme.textColors.primary,
      fontFamily: theme.typography.family.default,
      lineHeight: theme.typography.lineHeight.main,
      WebkitFontSmoothing: 'antialiased',
      fontSize: theme.typography.size.base,
    },
    '#root': {
      minHeight: ['100vh', '-webkit-fill-available'],
    },
    p: {
      fontFamily: theme.typography.family.default,
    },
    'p + p': {
      paddingTop: '1rem',
    },
    a: {
      color: theme.linkColor,
      textDecoration: 'none',
    },
    'a:hover': {
      color: theme.linkColorHover,
    },
    img: {
      maxWidth: '100%',
    },
    button: {
      background: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      color: theme.textColors.primary,
    },
    ul: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    'h1, h2': {
      cursor: 'default',
    },
    'a h1, a h2': {
      cursor: 'pointer',
    },
  })

export default globalStyle
