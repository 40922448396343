import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { VFC } from 'react'
import { Link } from 'react-router-dom'

import { FormeLogo } from '../icons'
import { useNavigationContext } from '../model'
import { routing } from '../model/Routing/routing'

import { Fade } from './styled/containers'
import { Hamburger, HeaderContainer } from './styled/header'
import { pseudoFocus, transitionFast } from './styled/utility'

const LogoContainerLink = styled(Link)(
  () => ({
    display: 'inline-flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: '0 24px',
    opacity: 0.9,

    '&:hover, &:focus': {
      opacity: 1,
    },
  }),
  pseudoFocus,
  transitionFast,
)

const HeaderMainDesktop: VFC<{ readonly className?: string }> = ({ className }) => {
  const { isNavOpen, showNavigation } = useNavigationContext()
  const theme = useTheme()

  return (
    <Fade transition="slow" css={{ background: theme.backgroundBrand }} className={className}>
      <HeaderContainer css={{ borderBottom: '1px solid #808080' }}>
        <LogoContainerLink to={routing.home.generatePath()}>
          <FormeLogo color={theme.textColor} />
        </LogoContainerLink>
        <Hamburger isNavOpen={isNavOpen} onClick={showNavigation} />
      </HeaderContainer>
    </Fade>
  )
}

export default HeaderMainDesktop
