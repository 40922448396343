import styled from '@emotion/styled'
import React, { FC } from 'react'

import { transitionFast } from '../../../components/styled/utility'
import { Body2 } from '../../../ui-kit'

const SettingsLinkContainer = styled.a(
  ({ theme }) => ({
    width: '100%',
    height: 36,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.textColors.primary,

    '&:hover > p': {
      color: theme.colors.sand,
    },
  }),
  transitionFast,
)

export const SettingsLink: FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, ...props }) => (
  <SettingsLinkContainer className="testing-link-container" {...props}>
    <Body2>{children}</Body2>
  </SettingsLinkContainer>
)
