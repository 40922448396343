import styled from '@emotion/styled'
import UpNextLight from '../../images/up-next-light.png'
import { Body2, H3 } from '../../ui-kit'
import { textAlignCenter, width, displayBlock } from '../../ui-kit/utils'

import { DownloadAppLink, APP_LINK } from './DownloadAppLink'

const Background = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  minHeight: '100vh',
  width: '100%',
}))

const Container = styled.div({
  maxWidth: 290,
  margin: '0 auto',
  padding: '28px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const TextLink = styled.a(({ theme }) => ({
  textDecoration: 'underline',
  transition: theme.transitions.allFast2,
}))

export const DownloadApp = () => {
  return (
    <Background>
      <Container>
        <H3 css={{ marginBottom: 30 }}>Install The FORME App</H3>
        <img css={[displayBlock, width(225)]} src={UpNextLight} alt="Up Next Light phone illustration" />
        <DownloadAppLink />
        <Body2 css={textAlignCenter}>
          <TextLink href={APP_LINK} target="_blank" rel="noreferrer">
            Download
          </TextLink>{' '}
          or open the FORME app, and login for full access.
        </Body2>
      </Container>
    </Background>
  )
}
