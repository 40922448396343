import { dayjsWeekdayIdentifiers } from '../../utils/utils'
import { H5 } from '../Typography'

import { DayContainer } from './DayContainer'
import { Grid } from './Grid'

export const Title = () => (
  <Grid>
    {Object.values(dayjsWeekdayIdentifiers).map((weekdayIdentifier) => (
      <DayContainer weekdayHeading key={weekdayIdentifier}>
        <H5>{weekdayIdentifier}</H5>
      </DayContainer>
    ))}
  </Grid>
)
