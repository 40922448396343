import styled from '@emotion/styled'
import React, { VFC } from 'react'

import { Camera, TrashIcon } from '../../../icons'
import { IconButton } from '../../../ui-kit'

const PhotoWrapper = styled.div({
  width: 248,
  height: 248,
  margin: '0 auto 24px',
  position: 'relative',
})

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.gray7c,
  borderRadius: '50%',
  overflow: 'hidden',
  transition: theme.transitions.allMedium,
}))

const Image = styled.img<{ isShowing?: boolean }>(({ theme, isShowing }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  opacity: isShowing ? 1 : 0,
  pointerEvents: !isShowing ? 'none' : undefined,
  transition: isShowing ? theme.transitions.allMedium : 'none',
}))

const DeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  padding: 8,
  top: 10,
  right: 10,
  backgroundColor: theme.colors.black,
  borderRadius: '50%',
}))

export const Photo: VFC<{
  readonly uploadedProfilePhoto?: string | null
  readonly onError: () => void
  readonly imgSrcError?: boolean
  readonly onDeleteButtonClick: () => void
}> = ({ uploadedProfilePhoto, onError, imgSrcError, onDeleteButtonClick }) => (
  <PhotoWrapper>
    {uploadedProfilePhoto ? (
      <DeleteButton onClick={onDeleteButtonClick}>
        <TrashIcon />
      </DeleteButton>
    ) : null}
    <Container>
      {!uploadedProfilePhoto && <Camera css={{ maxWidth: 70 }} />}
      {uploadedProfilePhoto ? (
        <Image onError={onError} src={uploadedProfilePhoto} alt="Profile photo" isShowing={!imgSrcError} />
      ) : null}
    </Container>
  </PhotoWrapper>
)
