import React, { VFC } from 'react'

import { H3, Body3 } from '../Typography'
import { marginBottom, marginTop } from '../utils'

export const EmailPagesTitleBlock: VFC<{
  readonly title: string
  readonly message: string | null | undefined
}> = ({ title, message }) => (
  <div css={marginBottom(264)}>
    {title ? <H3>{title}</H3> : null}
    {message ? <Body3 css={marginTop('1rem')}>{message}</Body3> : null}
  </div>
)
