import React, { VFC } from 'react'

import { IconProps } from './types'

export const TrashIcon: VFC<IconProps> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5 8.66406H7.44444H27" stroke="#E9A695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.111 8.66623V6.22179C11.111 5.57348 11.3685 4.95173 11.827 4.4933C12.2854 4.03488 12.9071 3.77734 13.5554 3.77734H18.4443C19.0926 3.77734 19.7144 4.03488 20.1728 4.4933C20.6312 4.95173 20.8888 5.57348 20.8888 6.22179V8.66623M24.5554 8.66623V25.7773C24.5554 26.4256 24.2979 27.0474 23.8395 27.5058C23.3811 27.9642 22.7593 28.2218 22.111 28.2218H9.88878C9.24047 28.2218 8.61872 27.9642 8.1603 27.5058C7.70187 27.0474 7.44434 26.4256 7.44434 25.7773V8.66623H24.5554Z"
      stroke="#E9A695"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.5557 14.7773V22.1107" stroke="#E9A695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.4443 14.7773V22.1107" stroke="#E9A695" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
