import styled from '@emotion/styled'

import { letterSpacing } from '../utils'

export const Body3 = styled.p(({ theme }) => [
  {
    fontFamily: theme.typography.family.default,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    fontWeight: 400,
    margin: 0,
    padding: 0,
  },
  letterSpacing(1),
])
