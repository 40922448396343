import React, { VFC } from 'react'

import { IconProps } from './types'

const AngleBracketRight: VFC<IconProps & { readonly strokeColor?: string; readonly width: number }> = ({
  strokeColor,
  width,
  ...props
}) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    css={{
      width: width ? width : 8,
    }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 17L9 9L1 1"
      stroke={strokeColor || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AngleBracketRight
