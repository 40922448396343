import { useHistory } from 'react-router-dom'

import { VerticalContainer } from '../components/VerticalContainer'
import { bp } from '../components/styled/breakpoints'
import { Fade } from '../components/styled/containers'
import { marginBottom, textAlignCenter } from '../components/styled/utility'
import { routing } from '../model/Routing/routing'
import { NextPrevButton, H3, H4, Body3 } from '../ui-kit'

const OnboardingWelcome = () => {
  const { push } = useHistory()
  const onClickContinue = () =>
    push(
      routing.onboarding.fitnessAssessment.generatePath({
        questionNumber: 1,
      }),
    )

  return (
    <Fade>
      <VerticalContainer
        css={{
          padding: '110px 20px 36px',
          maxWidth: 420,
          [bp.sm]: {
            paddingTop: 85,
          },
        }}
      >
        <div css={[textAlignCenter, marginBottom(264)]}>
          <H3 css={[marginBottom(100)]}>Welcome</H3>
          <H4 css={[marginBottom('1rem')]}>Let&apos;s find you the right instructor and the right plan.</H4>
          <Body3>We&apos;d like to ask you a few fitness questions so we can get to know you better.</Body3>
        </div>
        <NextPrevButton css={{ margin: 0 }} onClick={onClickContinue} fullWidth large text="Get Started" />
      </VerticalContainer>
    </Fade>
  )
}

export default OnboardingWelcome
