import axios from 'axios'

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env

export const changePassword = (email: string) =>
  axios.post<string>(`https://${REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
    client_id: REACT_APP_AUTH0_CLIENT_ID,
    email,
    connection: 'Username-Password-Authentication',
  })
