import styled from '@emotion/styled'
import { uniq } from 'ramda'
import React, { memo } from 'react'

import { Body3 } from '../../../ui-kit'
import { zIndex, marginLeft, marginBottom, paddingLeft } from '../../../ui-kit/utils'

import { Dot } from './Dot'
import { ProgressHeaderContainer } from './Progress'

const DotContainer = styled.div({
  display: 'flex',
  gap: 30,
})

const DotGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const DotBlock = styled.div({
  position: 'relative',
  marginRight: 8,
})

export const ProgressHeader = memo(({ tlcAggregateMetrics }: { readonly tlcAggregateMetrics: any[] }) => {
  const colors = uniq(tlcAggregateMetrics.map((_) => _.color))
  return (
    <ProgressHeaderContainer css={[marginBottom(32)]}>
      <DotContainer>
        <DotGroup>
          <DotBlock css={paddingLeft(4)}>
            {colors.map((color, idx) => (
              <Dot key={color} color={color} css={[zIndex(3 - idx), marginLeft(-4)]} />
            ))}
          </DotBlock>
          <Body3>This month</Body3>
        </DotGroup>
        <DotGroup>
          <DotBlock>
            <Dot color="gray5f" />
          </DotBlock>
          <Body3>Last month</Body3>
        </DotGroup>
      </DotContainer>
    </ProgressHeaderContainer>
  )
})
