import { msToMinutes } from './msToMinutes'

export const reduceCalendarDataToCategoryElapsedMin = (
  acc: Record<string, number>,
  { elapsed_time, workout_category = 'Active' }: { elapsed_time?: number | string | null; workout_category?: any },
) => {
  const elapsedTime = typeof elapsed_time === 'number' ? elapsed_time : elapsed_time ? parseInt(elapsed_time) : 0
  const elapsedMinutes = isNaN(elapsedTime) ? 0 : msToMinutes(elapsedTime)

  return {
    ...acc,
    [workout_category]: acc[workout_category] ? acc[workout_category]! + elapsedMinutes : elapsedMinutes,
  }
}
