import styled from '@emotion/styled'

import { hoverOpacity } from '../../components/styled/utility'
import { AngleBracketRight } from '../../icons'
import { Subtitle1 } from '../Typography'
import { size } from '../utils'

const Container = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 12,
})

const ArrowContainer = styled.div({
  display: 'flex',
  gap: 4,
})

const Arrow = styled.div<{ flipHorizontal?: boolean }>(
  ({ flipHorizontal, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.progress}`,
    borderRadius: theme.variables.borderRadiusSmall,
    transform: flipHorizontal ? 'scaleX(-1)' : undefined,
    cursor: 'pointer',

    '&:focus': {
      outline: 0,
    },

    '*::selection': {
      background: 'transparent',
    },
  }),
  size(16),
  hoverOpacity,
)

export const Header = ({ onClickLeft, onClickRight, dayjsActiveMonthAndYear }: any) => (
  <Container>
    <Subtitle1>
      <span data-qa="calendar-month">{dayjsActiveMonthAndYear.format('MMMM')}</span>{' '}
      <span data-qa="calendar-year">{dayjsActiveMonthAndYear.format('YYYY')}</span>
    </Subtitle1>
    <ArrowContainer>
      <Arrow className="testing-calendar-left" flipHorizontal onClick={onClickLeft} tabIndex={0} role="button">
        <AngleBracketRight width={5} />
      </Arrow>
      <Arrow className="testing-calendar-right" onClick={onClickRight} tabIndex={0} role="button">
        <AngleBracketRight width={5} />
      </Arrow>
    </ArrowContainer>
  </Container>
)
