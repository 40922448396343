import styled from '@emotion/styled'

import { hoverOpacity } from '../../../components/styled/utility'

export const CustomFileInputContainer = styled.div([
  {
    position: 'relative',
    margin: '0 auto',

    button: {
      margin: 0,
      width: 280,
    },
    '> input': {
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  },
  hoverOpacity,
])
