import { setTimeout } from 'timers'

import styled from '@emotion/styled'
import { removeProfile } from 'api/membership/remove_profile'
import { routing } from 'model/Routing/routing'
import { useEffect, useState, VFC } from 'react'
import { acceptInvitationActivated } from 'system/user-onboarding-flow'
import { Button3 } from 'ui-kit'
import { Text } from 'ui-kit/Text'

import { inviteUser } from '../../api/membership/invite_user'
import HeaderModal from '../../components/HeaderModal'
import { ButtonSection, ButtonSolid } from '../../components/styled/button'
import { HeaderControlTextLinkContainer } from '../../components/styled/header'
import { useFamilyPlanProfilesQuery } from '../../queries/useFamilyPlanProfilesQuery'
import { useSelector } from '../../store'
import { RegularDialog, RegularDialogProps } from '../../ui-kit/RegularDialog'

import {
  AddProfileRow,
  Container,
  Content,
  Header,
  NotificationBadge,
  OwnProfileRow,
  ProfileRow,
  RemoveButton,
} from './components'

const TextStyledElement = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`

const Rows = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const AssignProfile = styled.div`
  width: 360px;
  padding-left: 8px;
  gap: 16px;
`
const InputStyled = styled.input`
  width: 315px;
  border: unset;
  border-bottom: 1px solid #bfbfbf;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
`

const RemoveProfileModal = styled.div`
  position: absolute;
  width: inherit;
  left: 0px;
  bottom: 0px;
  padding-bottom: 35px;
  z-index: 1;
  /* White */
  background: #ffffff;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 0px;
`

const RemoveMessage = styled.div`
  width: 327px;
  margin-bottom: 28px;
  margin-left: 25px;
`

const ConfirmButton = styled.button`
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  width: 327px;
  height: 56px;
  background: #ff6960;
  border-radius: 8px;
  color: #ffffff;
  border-color: #ff6960;
  margin-top: 28px;
  margin-left: 25px;
`

const RemoveText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;
`

const RemoveSubText = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: #262626;
  flex: none;
  order: 1;
  flex-grow: 0;
`

export const FamilyPlanProfilesDialog: VFC<RegularDialogProps> = (props) => {
  const [isAssigning, setIsAssigning] = useState(false)
  const [isEditState, setIsEditState] = useState(false)
  const [inviteAttempted, setInviteAttempted] = useState(false)
  const [isAssignSuccessful, setIsAssignSuccessful] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const user = useSelector((state) => state.user)
  const userId = user.membershipProfiles[0]?.userId
  const membershipId = user.membershipProfiles[0]?.membership?.id
  const targetProfile = {
    created_date: user.membershipProfiles[0]?.created_date,
    updated_date: user.membershipProfiles[0]?.updated_date,
    id: user.membershipProfiles[0]?.id,
    email: user.email,
    user: {
      avatar: user.image,
      first_name: user.first_name,
      last_name: user.last_name,
    },
  }
  const maxProfilesAllowed = user.membershipProfiles[0]?.membership?.class?.coveredProfiles
  const familyPlanProfilesQuery = useFamilyPlanProfilesQuery(membershipId)
  const familyPlanProfiles = familyPlanProfilesQuery?.data?.data
  const [profiles, setProfiles] = useState(familyPlanProfiles)
  const [errorMsgFromInvite, setErrorMsgFromInvite] = useState('')
  useEffect(() => {
    familyPlanProfiles?.length && setProfiles(familyPlanProfiles)
  }, [familyPlanProfiles])
  const accountOwnerProfile = profiles?.filter((profile) => profile.role === 'owner' && profile.userId === userId) || []
  const isAccountOwner = accountOwnerProfile?.length > 0
  const [profileToRemove, setProfileToRemove] = useState({})
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
    setInviteAttempted(false)
  }

  const handleFirstNameChange = (event: any) => {
    setFirstName(event.target.value)
    setInviteAttempted(false)
  }

  const sendInvite = () => {
    setTimeout(() => {
      setInviteAttempted(true)
    }, 1500)
    inviteUser(membershipId || '', { email: email, firstName: firstName })
      .then((response) => {
        if (response.status === 201) {
          setIsAssignSuccessful(true)
        }
      })
      .catch((error) => {
        const err = error.response.data.__error__
        resetForm()
        setInviteAttempted(true)
        setErrorMsgFromInvite(err)
      })
  }

  const resetForm = () => {
    setEmail('')
    setFirstName('')
    setInviteAttempted(false)
    setIsAssignSuccessful(false)
  }

  const disableSendInvite = !email || !firstName || (inviteAttempted && !isAssignSuccessful)

  const inviteAccepted = acceptInvitationActivated.get()
  const inviteUptoProfileCount = maxProfilesAllowed ? maxProfilesAllowed - 1 : 0
  const isMembershipActive = user.membershipProfiles[0]?.membership?.status === 'active'

  return (
    <RegularDialog {...props}>
      {!inviteAttempted ? (
        <HeaderModal
          controlLeft="ArrowLeft"
          headerTitle="Account"
          onClickControlLeft={props.onClose}
          controlRight={
            <HeaderControlTextLinkContainer className="testing-done" onClick={() => setIsEditState((v) => !v)}>
              {!isAssigning && (
                <Text style={{ textAlign: 'left' }} size="smallTextAlt">
                  {isEditState ? 'Save' : 'Edit'}
                </Text>
              )}
            </HeaderControlTextLinkContainer>
          }
        />
      ) : null}
      <NotificationBadge
        onClick={() => setInviteAttempted(false)}
        errorMsgFromInvite={errorMsgFromInvite}
        showEmailInviteSent={Boolean(isAssignSuccessful && isAssigning && inviteAttempted)}
        showSuccessfullyJoined={Boolean(inviteAccepted)}
        showError={Boolean(inviteAttempted && !isAssignSuccessful)}
      />
      <Header
        isAssigning={isAssigning}
        isAccountOwner={isAccountOwner}
        isMembershipActive={isMembershipActive}
        profiles={profiles}
        inviteUptoProfileCount={inviteUptoProfileCount}
      />
      {!isAssigning && !isAssignSuccessful && user ? (
        <Container>
          <OwnProfileRow
            loggedInProfileDetails={targetProfile}
            withRemoveButton={isEditState ? !isAccountOwner : undefined}
            onRemove={() => {
              setProfileToRemove(targetProfile)
              setShowRemoveConfirmation(true)
              setIsEditState(false)
            }}
          />
          <Rows>
            {profiles
              ? profiles.map(
                  (profile) =>
                    profile?.userId !== user.id && (
                      <ProfileRow
                        profileDetails={profile}
                        key={profile.id}
                        withRemoveButton={isEditState ? isAccountOwner : undefined}
                        onRemove={() => {
                          setShowRemoveConfirmation(true)
                          setProfileToRemove(profile)
                        }}
                      />
                    ),
                )
              : null}
            {isAccountOwner && profiles && inviteUptoProfileCount > 0 && isMembershipActive
              ? [...Array(inviteUptoProfileCount - profiles.length + 1)].map((profile, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <AddProfileRow key={i} onClick={() => setIsAssigning((t) => !t)} />
                ))
              : null}
            {isAccountOwner && profiles && !maxProfilesAllowed ? (
              <AddProfileRow onClick={() => setIsAssigning((t) => !t)} />
            ) : null}
          </Rows>
        </Container>
      ) : null}
      {isAssigning || isAssignSuccessful ? (
        <AssignProfile>
          <Content display={!isAssignSuccessful}>
            <TextStyledElement color="secondaryAlt">First Name</TextStyledElement>
            <InputStyled value={firstName} onChange={handleFirstNameChange} placeholder="enter guest first name" />
          </Content>
          <Content display={!isAssignSuccessful} style={{ marginTop: '16px' }}>
            <TextStyledElement color="secondaryAlt">Email</TextStyledElement>
            <InputStyled value={email} onChange={handleEmailChange} placeholder="enter guest email" />
          </Content>
          <ButtonSection style={{ marginTop: 360, width: '350px' }} hidden={isAssignSuccessful}>
            <ButtonSolid
              disabled={disableSendInvite}
              style={{
                cursor: disableSendInvite ? 'default' : 'pointer',
                background: disableSendInvite ? '#CCCCCC' : '#E3DCD0',
              }}
              onClick={() => {
                sendInvite()
              }}
            >
              <Button3>SEND INVITE</Button3>
            </ButtonSolid>
          </ButtonSection>
          <ButtonSection style={{ marginTop: 360, width: '350px' }} hidden={!inviteAttempted || !isAssignSuccessful}>
            <ButtonSolid
              style={{ background: 'unset' }}
              onClick={() => {
                setIsAssigning(false)
                setIsAssignSuccessful(false)
                setInviteAttempted(false)
              }}
            >
              <Button3>BACK TO PROFILES</Button3>
            </ButtonSolid>
            <ButtonSolid
              onClick={() => {
                resetForm()
              }}
            >
              <Button3>ADD ANOTHER PROFILE</Button3>
            </ButtonSolid>
          </ButtonSection>
        </AssignProfile>
      ) : null}
      {showRemoveConfirmation ? (
        <RemoveProfileModal>
          <div style={{ margin: 10, marginLeft: 335 }}>
            <RemoveButton onClick={() => setShowRemoveConfirmation(false)} />
          </div>
          {isAccountOwner ? (
            <RemoveMessage>
              <RemoveText>
                This user will no longer be part of your family plan and will lose access to all content and Live 1:1
                credits. Are you sure that you want to remove this user?
              </RemoveText>
            </RemoveMessage>
          ) : (
            <RemoveMessage>
              <RemoveText>Are you sure that you want to leave this family plan?</RemoveText>
              <RemoveSubText>
                Once you leave you will lose access to all content. To continue use of FORME’s content and services sign
                up for a new membership.
              </RemoveSubText>
            </RemoveMessage>
          )}
          <div style={{ marginLeft: 25 }}>
            <ProfileRow profileDetails={profileToRemove} />
          </div>
          <ConfirmButton
            onClick={() => {
              removeProfile(profileToRemove)
              setShowRemoveConfirmation(false)
              isAccountOwner && profiles ? setProfiles(profiles.filter((p) => p !== profileToRemove)) : setProfiles([])
              if (!isAccountOwner)
                setTimeout(() => {
                  window.location.replace(routing.home.generatePath())
                }, 500)
            }}
          >
            {isAccountOwner ? <Button3>REMOVE</Button3> : !isAccountOwner && <Button3>LEAVE</Button3>}
          </ConfirmButton>
        </RemoveProfileModal>
      ) : null}
    </RegularDialog>
  )
}
