import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const gridArea = (ga: CSSProperties['gridArea']) =>
  css({
    gridArea: ga,
  })

export const justifySelf = (js: CSSProperties['justifySelf']) =>
  css({
    justifySelf: js,
  })
