import React, { VFC } from 'react'

import { IconProps } from './types'

export const ArrowNextStep: VFC<IconProps> = (props) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m21.414 12.109-7.316 7.315-1.414-1.414 4.902-4.901H4v-2h13.586l-4.902-4.902 1.414-1.414 7.316 7.316Z"
      fill="#000"
    />
  </svg>
)
