import styled from '@emotion/styled'

import { transitionFast } from '../../components/styled/utility'

export const InputLabel = styled.label<{ isInvalidShowing?: boolean }>(
  ({ isInvalidShowing, theme }) => ({
    display: 'block',
    color: isInvalidShowing ? theme.colors.red2 : theme.textColors.secondary,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '22px',
    marginBottom: 4,
  }),
  transitionFast,
)
