import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { DialogManagerProvider } from 'lib/dialog-manager'
import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import { addAccessTokenInterceptor } from 'system/axios'

import AppAuthenticated from './AppAuthenticated'
import { InvitationManager } from './InvitationManager'
import UnauthenticatedRoutes from './UnauthenticatedRoutes'
import Blank from './components/Blank'
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback'
import { UIProvider } from './hooks'
import { useDispatch, useSelector } from './store'
import { getInvitationStatus } from './store/invite'
import { getCurrentUser, getUserExtended } from './store/user'
import history from './utils/history'

const AppContainer = styled.div({
  minHeight: ['100vh', '-webkit-fill-available'],
  position: 'relative',
  overflow: 'hidden',
})

function App() {
  const dispatch = useDispatch()
  const { isAuthenticated, isLoading = true, logout, getIdTokenClaims } = useAuth0()
  const userWantsToBeLoggedIn = useSelector((state) => state.user.userWantsToBeLoggedIn)

  useEffect(() => {
    addAccessTokenInterceptor(getIdTokenClaims)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser())
        .unwrap()
        .then(() => {
          dispatch(getUserExtended())
          dispatch(getInvitationStatus())
        })
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
  }, [])

  useEffect(() => {
    if (isAuthenticated && !userWantsToBeLoggedIn && !isLoading) {
      // console.log('(isAuthenticated && !userWantsToBeLoggedIn && !isLoading)');
      logout({ returnTo: process.env.REACT_APP_BASE_URL })
    }
    if (!isAuthenticated && userWantsToBeLoggedIn && !isLoading) {
      // console.log('(!isAuthenticated && userWantsToBeLoggedIn && !isLoading)');
      // TODO Auth0 refresh token #685
    }
  }, [isAuthenticated, userWantsToBeLoggedIn, isLoading, logout])

  if (isLoading) {
    return <Blank />
  }

  return (
    <Router history={history}>
      <DialogManagerProvider>
        <UIProvider>
          <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
            <AppContainer>
              <InvitationManager />
              {!isAuthenticated && <UnauthenticatedRoutes />}
              {isAuthenticated ? <AppAuthenticated /> : null}
            </AppContainer>
          </Sentry.ErrorBoundary>
        </UIProvider>
      </DialogManagerProvider>
    </Router>
  )
}

export default App
