import { createAsyncThunk } from '@reduxjs/toolkit'

import { axios } from '../../system/axios'

import { Invitation } from './types'

export const getInvitationStatus = createAsyncThunk('getInvitationStatus', async () => {
  const response = await axios.get<Invitation[]>('/invite')

  return response.data
})

export const acceptInvitation = createAsyncThunk('acceptInvitation', async () => {
  const response = await axios.post('/invite/accept')

  return response.data
})

export const declineInvitation = createAsyncThunk('declineInvitation', async () => {
  const response = await axios.post('/invite/decline')

  return response.data
})
