import { Switch } from 'react-router-dom'

import { renderUniversalRoutes } from './UniversalRoutes'
import { TitledRoute } from './components/TitledRoute'
import { routing } from './model/Routing/routing'
import { DownloadApp, Launch } from './views'

const UnauthenticatedRoutes = () => (
  <Switch>
    <TitledRoute path={routing.downloadApp.pattern} exact title="Download App" render={() => <DownloadApp />} />
    {renderUniversalRoutes()}
    <TitledRoute path={routing.home.pattern} render={() => <Launch />} title="Launch" />
  </Switch>
)

export default UnauthenticatedRoutes
