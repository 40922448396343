import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const overflow = (o: CSSProperties['overflow']) =>
  css({
    overflow: o,
  })

export const overflowY = (oy: CSSProperties['overflowY']) =>
  css({
    overflowY: oy,
  })

export const overflowYAuto = overflowY('auto')

export const overflowX = (ox: CSSProperties['overflowX']) =>
  css({
    overflowX: ox,
  })
