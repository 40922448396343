import React, { FC, useEffect, useState } from 'react'

import Error from '../components/Error'
import Fetching from '../components/Fetching'
import { useDispatch } from '../store'
import { getSurveyState } from '../store/onboarding'

const OnboardingLoader: FC = ({ children }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  /* Pass API Onboarding data into local app state Onboarding Context */
  useEffect(() => {
    dispatch(getSurveyState())
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch])

  if (error) {
    return <Error />
  }

  if (loading) {
    return <Fetching />
  }

  return <div>{children}</div>
}

export default OnboardingLoader
