import * as R from 'ramda'

import { conformValue } from './conformValue'

export const serverHeightToFormHeight = (heightInt: number) => {
  if (heightInt && !isNaN(parseInt(heightInt as any as string))) {
    const inBoundsHeight = R.compose(R.min(7 * 12), R.max(3 * 12))(heightInt)
    const feet = Math.floor(inBoundsHeight / 12)
    const inches = inBoundsHeight % 12
    return conformValue('height', `${feet} ${inches}`)
  } else {
    return heightInt
  }
}
