export const isViewportMobile = (viewportWidth: number) => {
  return viewportWidth < breakpoints.sm
}

type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xsMax' | 'smMax'

export const breakpoints = {
  xxs: 400,
  xs: 640,
  sm: 768,
  md: 990,
  lg: 1200,
  xl: 1400,
  xxl: 1700,
}

// "map over" breakpoints to set them up as media queries
export const bp = Object.fromEntries(
  Object.entries(breakpoints).map(([k, v]) => [k, `@media (min-width: ${v}px)`]),
) as Record<Size, string>

bp.xsMax = `@media (max-width: ${breakpoints.sm - 1}px)`
bp.smMax = `@media (max-width: ${breakpoints.md - 1}px)`
