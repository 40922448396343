import { useAuth0 } from '@auth0/auth0-react'
import { useDialogManager } from 'lib/dialog-manager'
import mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'store'
import { updateImage } from 'store/user'

import AccountForm from '../../components/AccountForm'
import { RegularDialog } from '../../ui-kit/RegularDialog'
import { PhotoModal, SettingsModal } from '../../views/Modals'

export const useProfileHeaderPresenter = () => {
  const { user } = useAuth0()
  const { showDialog } = useDialogManager()
  const dispatch = useDispatch()
  const { first_name, last_name, image } = useSelector((state) => state.user)

  const onAvatarClick = useCallback(() => {
    mixpanel.track('updateProfilePhotoFromLibrary')
    showDialog(PhotoModal)
  }, [showDialog])

  const onProfileClick = useCallback(() => {
    mixpanel.track('featured')
    showDialog((props) => (
      <RegularDialog {...props}>
        <AccountForm isPartOfDialog />
      </RegularDialog>
    ))
  }, [showDialog])

  const onSettingsClick = useCallback(() => {
    mixpanel.track('settings')
    showDialog(SettingsModal)
  }, [showDialog])

  const onAvatarError = useCallback(() => {
    dispatch(updateImage(null))
  }, [dispatch])

  return {
    firstName: first_name,
    lastName: last_name,
    email: user?.email,
    image,
    onProfileClick,
    onSettingsClick,
    onAvatarClick,
    onAvatarError,
  }
}
