import React, { VFC } from 'react'

import { IconProps } from './types'

export const DownloadIosApp: VFC<IconProps> = (props) => {
  return (
    <svg width="177" height="59" viewBox="0 0 177 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6912_34032)">
        <path
          d="M162.905 6.96797e-05H14.1031C13.5607 6.96797e-05 13.0248 6.96798e-05 12.4839 0.00301968C12.031 0.00596968 11.5818 0.0145394 11.1246 0.0217522C10.1314 0.0334073 9.14051 0.120542 8.16057 0.282399C7.182 0.44778 6.23409 0.75957 5.3489 1.20722C4.46479 1.65867 3.65695 2.24527 2.95469 2.94575C2.24874 3.64426 1.66028 4.45164 1.21193 5.33686C0.76235 6.22031 0.450606 7.167 0.287471 8.14425C0.122785 9.12027 0.0341658 10.1075 0.022409 11.0972C0.00868256 11.5494 0.007233 12.0032 0 12.4555V46.549C0.007233 47.007 0.00868256 47.4507 0.022409 47.9088C0.0341694 48.8984 0.122788 49.8856 0.287471 50.8616C0.450156 51.8394 0.761917 52.7867 1.21193 53.6705C1.66008 54.5528 2.24862 55.3569 2.95469 56.0515C3.65428 56.7551 4.46265 57.3421 5.3489 57.7901C6.23409 58.239 7.18189 58.5526 8.16057 58.7206C9.14067 58.8811 10.1315 58.9683 11.1246 58.9813C11.5818 58.9914 12.031 58.9971 12.4839 58.9971C13.0248 59.0001 13.5608 59.0001 14.1031 59.0001H162.905C163.436 59.0001 163.977 59.0001 164.508 58.9971C164.959 58.9971 165.421 58.9914 165.872 58.9813C166.863 58.969 167.852 58.8818 168.83 58.7206C169.812 58.5514 170.763 58.2379 171.653 57.7901C172.538 57.3419 173.346 56.7549 174.045 56.0515C174.749 55.3542 175.339 54.5507 175.792 53.6705C176.239 52.786 176.548 51.8389 176.708 50.8616C176.873 49.8855 176.965 48.8985 176.983 47.9088C176.988 47.4507 176.988 47.007 176.988 46.549C177 46.0131 177 45.4802 177 44.9356V14.0659C177 13.5257 177 12.9899 176.988 12.4555C176.988 12.0032 176.988 11.5494 176.983 11.0971C176.965 10.1074 176.873 9.12034 176.708 8.14419C176.547 7.16751 176.239 6.22089 175.792 5.3368C174.879 3.56248 173.432 2.11821 171.653 1.20709C170.763 0.760532 169.812 0.448824 168.83 0.282267C167.852 0.119696 166.863 0.0325311 165.872 0.0215457C165.421 0.0143477 164.959 0.00570418 164.508 0.00282793C163.977 -0.00012207 163.436 -0.00012207 162.905 -0.00012207V6.96797e-05Z"
          fill="white"
        />
        <path
          d="M12.491 57.7094C12.0404 57.7094 11.6006 57.7036 11.1535 57.6936C10.2272 57.6816 9.30311 57.6012 8.38875 57.453C7.53616 57.3066 6.71023 57.0342 5.9382 56.6449C5.17325 56.2588 4.47556 55.7525 3.87184 55.1454C3.25939 54.5455 2.7495 53.8497 2.36236 53.0856C1.97097 52.3165 1.7001 51.4921 1.55919 50.6412C1.40702 49.7269 1.32469 48.8023 1.31291 47.8756C1.30354 47.5645 1.29126 46.5288 1.29126 46.5288V12.4554C1.29126 12.4554 1.30434 11.4356 1.31299 11.136C1.32427 10.2107 1.40612 9.28759 1.55783 8.37468C1.699 7.52142 1.97008 6.69468 2.36168 5.92308C2.74739 5.15949 3.25446 4.46317 3.86322 3.8611C4.4713 3.25332 5.17123 2.74442 5.93748 2.35296C6.70773 1.96486 7.5321 1.6944 8.38297 1.55065C9.30033 1.40103 10.2276 1.32014 11.1571 1.30866L12.4918 1.29065H164.501L165.852 1.30938C166.773 1.3203 167.691 1.40046 168.6 1.5492C169.46 1.69476 170.293 1.96711 171.072 2.35728C172.607 3.14618 173.856 4.39429 174.644 5.92668C175.029 6.69295 175.296 7.51295 175.436 8.35883C175.589 9.27924 175.675 10.2096 175.693 11.1425C175.697 11.5602 175.697 12.0089 175.697 12.4554C175.709 13.0085 175.709 13.535 175.709 14.0658V44.9356C175.709 45.4715 175.709 45.9944 175.697 46.5215C175.697 47.0012 175.697 47.4406 175.691 47.8928C175.674 48.8091 175.589 49.7228 175.438 50.6267C175.3 51.4838 175.031 52.3146 174.64 53.09C174.25 53.8458 173.743 54.5356 173.137 55.1339C172.533 55.7442 171.834 56.2535 171.068 56.6421C170.291 57.0344 169.459 57.3078 168.6 57.453C167.686 57.602 166.762 57.6824 165.836 57.6936C165.402 57.7036 164.949 57.7094 164.508 57.7094L162.905 57.7123L12.491 57.7094Z"
          fill="black"
        />
        <path
          d="M36.6366 29.9435C36.6525 28.7124 36.9804 27.5053 37.5899 26.4345C38.1993 25.3637 39.0705 24.4641 40.1223 23.8193C39.4541 22.8676 38.5726 22.0845 37.5478 21.532C36.523 20.9796 35.3832 20.673 34.2188 20.6367C31.7349 20.3767 29.327 22.1188 28.0613 22.1188C26.771 22.1188 24.8223 20.6625 22.7238 20.7055C21.3664 20.7493 20.0435 21.1429 18.8841 21.848C17.7246 22.5532 16.7681 23.5458 16.1077 24.7292C13.247 29.6681 15.3808 36.9266 18.1211 40.9189C19.4921 42.8738 21.0944 45.0574 23.1911 44.98C25.2427 44.8951 26.009 43.6754 28.4855 43.6754C30.939 43.6754 31.6579 44.98 33.7971 44.9308C35.9986 44.8951 37.3857 42.9672 38.7086 40.9938C39.6937 39.6008 40.4517 38.0613 40.9545 36.4323C39.6755 35.8929 38.584 34.9899 37.8162 33.836C37.0483 32.6821 36.6381 31.3284 36.6366 29.9435Z"
          fill="white"
        />
        <path
          d="M32.5961 18.0111C33.7964 16.5741 34.3878 14.7272 34.2446 12.8624C32.4107 13.0545 30.7167 13.9285 29.5001 15.3104C28.9053 15.9854 28.4497 16.7708 28.1595 17.6215C27.8692 18.4722 27.7499 19.3717 27.8084 20.2684C28.7257 20.2778 29.6332 20.0796 30.4625 19.6886C31.2918 19.2976 32.0213 18.724 32.5961 18.0111Z"
          fill="white"
        />
        <path
          d="M62.5708 40.031H55.5695L53.8881 44.9818H50.9226L57.5542 26.6652H60.6352L67.2668 44.9818H64.2507L62.5708 40.031ZM56.2946 37.7465H61.8443L59.1085 29.7118H59.0319L56.2946 37.7465Z"
          fill="white"
        />
        <path
          d="M81.5888 38.3054C81.5888 42.4553 79.3614 45.1215 76.0001 45.1215C75.1486 45.1659 74.3018 44.9703 73.5566 44.5571C72.8114 44.1439 72.198 43.5298 71.7866 42.7851H71.723V49.3995H68.9741V31.6275H71.6349V33.8487H71.6854C72.1158 33.1075 72.7396 32.4966 73.4905 32.0809C74.2413 31.6652 75.0911 31.4602 75.9495 31.4878C79.3483 31.4878 81.5888 34.167 81.5888 38.3054ZM78.7634 38.3054C78.7634 35.6017 77.3622 33.8242 75.2244 33.8242C73.1241 33.8242 71.7114 35.6391 71.7114 38.3054C71.7114 40.9961 73.1241 42.7981 75.2244 42.7981C77.3622 42.7981 78.7634 41.0336 78.7634 38.3054Z"
          fill="white"
        />
        <path
          d="M96.3282 38.3054C96.3282 42.4552 94.1009 45.1215 90.7396 45.1215C89.8881 45.1659 89.0413 44.9703 88.2961 44.5571C87.5509 44.1439 86.9375 43.5298 86.5261 42.7851H86.4625V49.3995H83.7136V31.6275H86.3743V33.8487H86.4249C86.8553 33.1075 87.479 32.4966 88.2299 32.0809C88.9808 31.6652 89.8306 31.4602 90.689 31.4878C94.0879 31.4878 96.3282 34.167 96.3282 38.3054ZM93.5029 38.3054C93.5029 35.6017 92.1017 33.8242 89.9639 33.8242C87.8636 33.8242 86.4509 35.6391 86.4509 38.3054C86.4509 40.9961 87.8636 42.7981 89.9639 42.7981C92.1017 42.7981 93.5029 41.0335 93.5029 38.3054H93.5029Z"
          fill="white"
        />
        <path
          d="M106.07 39.8783C106.273 41.6947 108.043 42.8873 110.461 42.8873C112.778 42.8873 114.445 41.6946 114.445 40.0569C114.445 38.6351 113.439 37.7839 111.059 37.2005L108.678 36.6286C105.306 35.8162 103.74 34.2433 103.74 31.6908C103.74 28.5305 106.502 26.3598 110.423 26.3598C114.305 26.3598 116.965 28.5305 117.055 31.6908H114.28C114.114 29.8629 112.599 28.7596 110.384 28.7596C108.17 28.7596 106.655 29.8759 106.655 31.5007C106.655 32.7957 107.623 33.5576 109.99 34.141L112.014 34.6365C115.782 35.5252 117.348 37.0348 117.348 39.714C117.348 43.1408 114.611 45.287 110.257 45.287C106.184 45.287 103.434 43.1912 103.256 39.8782L106.07 39.8783Z"
          fill="white"
        />
        <path
          d="M123.281 28.4672V31.6275H125.827V33.7983H123.281V41.1603C123.281 42.304 123.79 42.837 124.91 42.837C125.212 42.8317 125.514 42.8105 125.814 42.7735V44.9313C125.311 45.0251 124.799 45.0675 124.287 45.058C121.576 45.058 120.519 44.0425 120.519 41.4526V33.7983H118.572V31.6275H120.519V28.4672H123.281Z"
          fill="white"
        />
        <path
          d="M127.302 38.3054C127.302 34.1036 129.784 31.4633 133.653 31.4633C137.536 31.4633 140.006 34.1036 140.006 38.3054C140.006 42.5186 137.549 45.1474 133.653 45.1474C129.759 45.1474 127.302 42.5186 127.302 38.3054ZM137.205 38.3054C137.205 35.4231 135.881 33.7219 133.653 33.7219C131.426 33.7219 130.103 35.436 130.103 38.3054C130.103 41.1992 131.426 42.8874 133.653 42.8874C135.881 42.8874 137.205 41.1992 137.205 38.3054H137.205Z"
          fill="white"
        />
        <path
          d="M142.272 31.6275H144.894V33.9005H144.958C145.135 33.1906 145.552 32.5633 146.139 32.1239C146.725 31.6845 147.446 31.4599 148.179 31.4878C148.496 31.4867 148.812 31.521 149.121 31.5901V34.154C148.721 34.0322 148.304 33.9762 147.886 33.9884C147.486 33.9722 147.088 34.0424 146.719 34.1942C146.349 34.346 146.017 34.5757 145.745 34.8676C145.473 35.1594 145.267 35.5066 145.142 35.8852C145.017 36.2638 144.976 36.6648 145.021 37.0608V44.9817H142.272L142.272 31.6275Z"
          fill="white"
        />
        <path
          d="M161.794 41.0595C161.425 43.4837 159.057 45.1474 156.028 45.1474C152.132 45.1474 149.714 42.5445 149.714 38.3687C149.714 34.18 152.145 31.4633 155.913 31.4633C159.618 31.4633 161.948 34.0013 161.948 38.0504V38.9896H152.489V39.1552C152.446 39.6467 152.507 40.1418 152.67 40.6076C152.833 41.0735 153.094 41.4995 153.434 41.8572C153.775 42.215 154.188 42.4964 154.646 42.6827C155.105 42.869 155.597 42.9559 156.092 42.9378C156.741 42.9985 157.393 42.8484 157.95 42.51C158.508 42.1716 158.94 41.6628 159.184 41.0594L161.794 41.0595ZM152.502 37.0738H159.197C159.222 36.6319 159.155 36.1897 159 35.7749C158.845 35.3601 158.606 34.9817 158.298 34.6634C157.989 34.3452 157.618 34.0939 157.208 33.9255C156.797 33.757 156.356 33.675 155.913 33.6845C155.465 33.6818 155.021 33.7676 154.607 33.9368C154.193 34.106 153.816 34.3554 153.499 34.6705C153.182 34.9856 152.931 35.3602 152.76 35.7727C152.589 36.1852 152.501 36.6274 152.502 37.0738V37.0738Z"
          fill="white"
        />
        <path
          d="M55.9502 12.8782C56.5265 12.837 57.1047 12.9238 57.6433 13.1324C58.1819 13.341 58.6673 13.6662 59.0646 14.0846C59.4619 14.5029 59.7611 15.004 59.9407 15.5516C60.1202 16.0992 60.1757 16.6798 60.103 17.2514C60.103 20.0631 58.5791 21.6793 55.9502 21.6793H52.7622V12.8782H55.9502ZM54.133 20.4346H55.797C56.2088 20.4592 56.6209 20.3919 57.0034 20.2375C57.3858 20.0832 57.7289 19.8458 58.0077 19.5426C58.2865 19.2394 58.494 18.878 58.6151 18.4848C58.7362 18.0915 58.7678 17.6763 58.7077 17.2693C58.7634 16.8639 58.7286 16.4512 58.6057 16.0609C58.4827 15.6705 58.2747 15.3121 57.9965 15.0113C57.7183 14.7105 57.3769 14.4747 56.9966 14.321C56.6164 14.1672 56.2067 14.0993 55.797 14.1219H54.133V20.4346Z"
          fill="white"
        />
        <path
          d="M61.6516 18.3554C61.6097 17.9189 61.6598 17.4785 61.7986 17.0625C61.9374 16.6464 62.1619 16.2639 62.4576 15.9394C62.7533 15.6149 63.1138 15.3557 63.5159 15.1783C63.918 15.0009 64.3529 14.9092 64.7926 14.9092C65.2323 14.9092 65.6671 15.0009 66.0692 15.1783C66.4714 15.3557 66.8318 15.6149 67.1276 15.9394C67.4233 16.2639 67.6478 16.6464 67.7866 17.0625C67.9254 17.4785 67.9755 17.9189 67.9336 18.3554C67.9763 18.7923 67.9268 19.2333 67.7884 19.65C67.6499 20.0668 67.4256 20.45 67.1298 20.7751C66.8339 21.1002 66.4732 21.36 66.0707 21.5378C65.6682 21.7156 65.2328 21.8074 64.7926 21.8074C64.3524 21.8074 63.917 21.7156 63.5145 21.5378C63.112 21.36 62.7512 21.1002 62.4554 20.7751C62.1596 20.45 61.9353 20.0668 61.7968 19.65C61.6584 19.2333 61.6089 18.7923 61.6516 18.3554ZM66.5816 18.3554C66.5816 16.9157 65.933 16.0738 64.7948 16.0738C63.6522 16.0738 63.0094 16.9157 63.0094 18.3554C63.0094 19.8066 63.6522 20.6421 64.7948 20.6421C65.933 20.6421 66.5816 19.8009 66.5816 18.3554H66.5816Z"
          fill="white"
        />
        <path
          d="M76.284 21.6792H74.9205L73.5439 16.7875H73.4399L72.0691 21.6792H70.7185L68.8826 15.0374H70.2158L71.409 20.1055H71.5072L72.8765 15.0374H74.1376L75.5069 20.1055H75.6109L76.7983 15.0374H78.1127L76.284 21.6792Z"
          fill="white"
        />
        <path
          d="M79.6567 15.0373H80.9221V16.0924H81.0203C81.187 15.7135 81.468 15.3958 81.8243 15.1837C82.1806 14.9716 82.5943 14.8756 83.0079 14.9091C83.332 14.8848 83.6574 14.9336 83.96 15.0517C84.2627 15.1698 84.5348 15.3544 84.7562 15.5916C84.9776 15.8289 85.1426 16.1127 85.239 16.4222C85.3355 16.7317 85.3609 17.0589 85.3133 17.3795V21.6791H83.9988V17.7086C83.9988 16.6413 83.5337 16.1105 82.5616 16.1105C82.3415 16.1002 82.1219 16.1376 81.9177 16.2199C81.7135 16.3023 81.5295 16.4277 81.3785 16.5876C81.2274 16.7474 81.1128 16.938 81.0425 17.1461C80.9722 17.3543 80.9479 17.5752 80.9712 17.7936V21.6792H79.6567L79.6567 15.0373Z"
          fill="white"
        />
        <path d="M87.408 12.4446H88.7224V21.6792H87.408V12.4446Z" fill="white" />
        <path
          d="M90.5497 18.3554C90.5079 17.9189 90.558 17.4785 90.6968 17.0624C90.8357 16.6463 91.0602 16.2638 91.3559 15.9393C91.6517 15.6148 92.0122 15.3556 92.4144 15.1782C92.8165 15.0008 93.2514 14.9091 93.6911 14.9091C94.1308 14.9091 94.5657 15.0008 94.9679 15.1782C95.37 15.3556 95.7305 15.6148 96.0263 15.9393C96.322 16.2638 96.5465 16.6463 96.6854 17.0624C96.8242 17.4785 96.8743 17.9189 96.8325 18.3554C96.8751 18.7923 96.8256 19.2334 96.6871 19.6501C96.5486 20.0668 96.3242 20.45 96.0284 20.7751C95.7325 21.1002 95.3718 21.36 94.9692 21.5378C94.5667 21.7156 94.1313 21.8074 93.6911 21.8074C93.2509 21.8074 92.8155 21.7156 92.413 21.5378C92.0104 21.36 91.6497 21.1002 91.3538 20.7751C91.058 20.45 90.8336 20.0668 90.6951 19.6501C90.5566 19.2334 90.5071 18.7923 90.5497 18.3554ZM95.4797 18.3554C95.4797 16.9157 94.8311 16.0738 93.6929 16.0738C92.5503 16.0738 91.9076 16.9157 91.9076 18.3554C91.9076 19.8067 92.5504 20.6421 93.6929 20.6421C94.8312 20.6421 95.4797 19.8009 95.4797 18.3554H95.4797Z"
          fill="white"
        />
        <path
          d="M98.2161 19.8009C98.2161 18.6053 99.1088 17.9161 100.693 17.8181L102.497 17.7144V17.1411C102.497 16.4396 102.032 16.0435 101.134 16.0435C100.4 16.0435 99.8917 16.3122 99.7458 16.7817H98.4732C98.6075 15.6409 99.6837 14.9092 101.195 14.9092C102.864 14.9092 103.806 15.7381 103.806 17.1411V21.6792H102.541V20.7458H102.437C102.226 21.0806 101.929 21.3536 101.578 21.5368C101.226 21.72 100.832 21.8069 100.436 21.7886C100.157 21.8176 99.874 21.7879 99.6066 21.7013C99.3393 21.6147 99.0931 21.4732 98.8839 21.2859C98.6747 21.0987 98.5072 20.8698 98.3922 20.614C98.2772 20.3582 98.2172 20.0812 98.2161 19.8009ZM102.497 19.2333V18.6781L100.871 18.7818C99.9538 18.843 99.5377 19.1541 99.5377 19.7396C99.5377 20.3374 100.058 20.6853 100.773 20.6853C100.982 20.7064 101.194 20.6853 101.395 20.6233C101.596 20.5612 101.783 20.4594 101.944 20.3239C102.105 20.1884 102.237 20.0221 102.332 19.8347C102.427 19.6474 102.483 19.4428 102.497 19.2333Z"
          fill="white"
        />
        <path
          d="M105.534 18.3554C105.534 16.2567 106.616 14.9272 108.299 14.9272C108.715 14.9081 109.128 15.0075 109.49 15.2138C109.851 15.4202 110.147 15.7249 110.341 16.0924H110.439V12.4446H111.754V21.6792H110.494V20.6298H110.39C110.181 20.9949 109.875 21.2958 109.507 21.5001C109.138 21.7043 108.72 21.8041 108.299 21.7886C106.604 21.7887 105.534 20.4592 105.534 18.3554ZM106.892 18.3554C106.892 19.7641 107.558 20.6118 108.671 20.6118C109.779 20.6118 110.464 19.7519 110.464 18.3612C110.464 16.9769 109.772 16.1047 108.671 16.1047C107.565 16.1047 106.892 16.9582 106.892 18.3554H106.892Z"
          fill="white"
        />
        <path
          d="M117.192 18.3554C117.15 17.9189 117.2 17.4785 117.339 17.0625C117.478 16.6464 117.702 16.2639 117.998 15.9394C118.294 15.6149 118.654 15.3557 119.056 15.1783C119.459 15.0009 119.893 14.9092 120.333 14.9092C120.773 14.9092 121.208 15.0009 121.61 15.1783C122.012 15.3557 122.372 15.6149 122.668 15.9394C122.964 16.2639 123.188 16.6464 123.327 17.0625C123.466 17.4785 123.516 17.9189 123.474 18.3554C123.517 18.7923 123.467 19.2333 123.329 19.65C123.19 20.0668 122.966 20.45 122.67 20.7751C122.374 21.1002 122.014 21.36 121.611 21.5378C121.209 21.7156 120.773 21.8074 120.333 21.8074C119.893 21.8074 119.458 21.7156 119.055 21.5378C118.653 21.36 118.292 21.1002 117.996 20.7751C117.7 20.45 117.476 20.0668 117.337 19.65C117.199 19.2333 117.149 18.7923 117.192 18.3554ZM122.122 18.3554C122.122 16.9157 121.474 16.0738 120.335 16.0738C119.193 16.0738 118.55 16.9157 118.55 18.3554C118.55 19.8066 119.193 20.6421 120.335 20.6421C121.474 20.6421 122.122 19.8009 122.122 18.3554Z"
          fill="white"
        />
        <path
          d="M125.238 15.0373H126.503V16.0924H126.601C126.768 15.7135 127.049 15.3958 127.405 15.1837C127.762 14.9716 128.175 14.8756 128.589 14.9091C128.913 14.8848 129.238 14.9336 129.541 15.0517C129.844 15.1698 130.116 15.3544 130.337 15.5916C130.559 15.8289 130.724 16.1127 130.82 16.4222C130.917 16.7317 130.942 17.0589 130.894 17.3795V21.6791H129.58V17.7086C129.58 16.6413 129.115 16.1105 128.143 16.1105C127.923 16.1002 127.703 16.1376 127.499 16.2199C127.295 16.3023 127.111 16.4277 126.96 16.5876C126.808 16.7474 126.694 16.938 126.624 17.1461C126.553 17.3543 126.529 17.5752 126.552 17.7936V21.6792H125.238V15.0373Z"
          fill="white"
        />
        <path
          d="M138.322 13.3837V15.0676H139.765V16.1717H138.322V19.5869C138.322 20.2827 138.609 20.5873 139.264 20.5873C139.431 20.5868 139.599 20.5767 139.765 20.5571V21.6489C139.529 21.691 139.29 21.7134 139.05 21.7159C137.588 21.7159 137.006 21.2031 137.006 19.9225V16.1716H135.949V15.0675H137.006V13.3837H138.322Z"
          fill="white"
        />
        <path
          d="M141.561 12.4446H142.863V16.1047H142.967C143.142 15.7222 143.431 15.4027 143.794 15.1898C144.158 14.9769 144.578 14.8809 144.998 14.9149C145.321 14.8974 145.643 14.9511 145.942 15.0721C146.241 15.1931 146.51 15.3785 146.729 15.615C146.948 15.8515 147.112 16.1333 147.21 16.4403C147.307 16.7473 147.335 17.0719 147.292 17.391V21.6792H145.976V17.7144C145.976 16.6535 145.481 16.1162 144.552 16.1162C144.326 16.0977 144.099 16.1287 143.886 16.207C143.674 16.2852 143.481 16.4089 143.321 16.5693C143.161 16.7296 143.039 16.9229 142.962 17.1355C142.884 17.3481 142.855 17.5749 142.875 17.8001V21.6792H141.561L141.561 12.4446Z"
          fill="white"
        />
        <path
          d="M154.957 19.8858C154.778 20.4929 154.391 21.0179 153.863 21.3692C153.336 21.7205 152.701 21.8756 152.07 21.8074C151.632 21.8189 151.196 21.7351 150.793 21.5618C150.389 21.3884 150.029 21.1297 149.736 20.8035C149.444 20.4773 149.226 20.0915 149.097 19.6729C148.969 19.2542 148.934 18.8128 148.994 18.3792C148.935 17.9442 148.971 17.5018 149.099 17.0819C149.227 16.662 149.444 16.2744 149.735 15.9454C150.026 15.6164 150.385 15.3536 150.787 15.1748C151.189 14.996 151.625 14.9054 152.065 14.9092C153.918 14.9092 155.036 16.1718 155.036 18.2574V18.7148H150.333V18.7883C150.312 19.032 150.343 19.2773 150.423 19.5085C150.503 19.7397 150.631 19.9517 150.798 20.1309C150.965 20.31 151.167 20.4524 151.393 20.5488C151.618 20.6453 151.861 20.6937 152.107 20.691C152.421 20.7286 152.74 20.6722 153.022 20.5288C153.304 20.3854 153.537 20.1616 153.691 19.8858L154.957 19.8858ZM150.333 17.7454H153.697C153.713 17.5225 153.683 17.2986 153.608 17.088C153.532 16.8775 153.414 16.6851 153.259 16.523C153.105 16.361 152.918 16.233 152.711 16.1472C152.504 16.0613 152.282 16.0197 152.057 16.0248C151.83 16.0219 151.604 16.0645 151.394 16.15C151.183 16.2354 150.992 16.3621 150.831 16.5224C150.671 16.6828 150.544 16.8736 150.458 17.0836C150.372 17.2937 150.33 17.5187 150.333 17.7454H150.333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6912_34032">
          <rect width="177" height="59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
