import styled from '@emotion/styled'
import { transitionFast } from '../../components/styled/utility'
import { RadioButton as RadioButtonIcon } from '../../icons'

const Container = styled.div({
  position: 'relative',
  width: 24,
  height: 24,
  flexShrink: 0,
})

const RadioInput = styled.input(() => ({
  position: 'absolute',
  opacity: 0,
  cursor: 'pointer',
  height: 0,
  width: 0,

  '& ~ div > svg': {
    opacity: 0,
  },

  '&:checked ~ div': {
    borderColor: 'transparent',
  },

  '&:checked ~ div > svg': {
    opacity: 1,
  },
}))

const RadioButtonContainer = styled.div(
  {
    position: 'absolute',
    border: `2px solid #808080`,
    zIndex: 2,
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    boxSizing: 'border-box',
    borderRadius: '50%',
  },
  transitionFast,
)

const RadioButtonStyled = styled(RadioButtonIcon)({
  position: 'absolute',
  top: -2,
  left: -2,
})

export const RadioButton = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) => {
  return (
    <Container>
      <RadioInput type="radio" {...props} />
      <RadioButtonContainer>
        <RadioButtonStyled />
      </RadioButtonContainer>
    </Container>
  )
}
