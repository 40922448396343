import { axios } from 'system/axios'

import { ProgramEntity } from '../entities/ProgramEntity'

export type Program = ProgramEntity & {
  workout_ids: number[]
  completed_workout_ids: number[]
}

export const getPrograms = () => axios.get<{ data: Program[] }>('/program')
