import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useContext, VFC } from 'react'

import { UIContext } from '../hooks'
import { ArrowLeft, FormeLogo, X } from '../icons'
import { useNavigationContext } from '../model'
import history from '../utils/history'

import { Fade, FadeIn } from './styled/containers'
import { Hamburger, HeaderCenter, HeaderContainer, HeaderControlContainer, HeaderControlInnerA } from './styled/header'

const Fade100 = styled(Fade)({
  height: '100%',
})

const HeaderMainMobile: VFC<{ readonly className?: string }> = ({ className }) => {
  const { showNavigation, isNavOpen } = useNavigationContext()
  const { arrowLeftTo, headerSubtitle, headerTitle, isAlternateHeader, isHeaderInitialized, xTo } =
    useContext(UIContext)!
  const theme = useTheme()

  return (
    <FadeIn isIn={isHeaderInitialized} className={className}>
      <HeaderContainer css={{ background: theme.backgroundBrand }}>
        <HeaderControlContainer>
          {arrowLeftTo ? (
            <Fade100>
              <HeaderControlInnerA onClick={() => history.push(arrowLeftTo)}>
                <ArrowLeft
                  theme={theme}
                  css={{
                    maxWidth: 24,
                  }}
                />
              </HeaderControlInnerA>
            </Fade100>
          ) : (
            <div
              style={{
                height: '100%',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <FormeLogo color={theme.textColor} isMobile />
            </div>
          )}
        </HeaderControlContainer>

        <HeaderCenter
          headerSubtitle={headerSubtitle}
          headerTitle={headerTitle}
          isAlternateHeader={isAlternateHeader}
          titleStyles={css({ fontSize: 16, letterSpacing: 0 })}
        />

        <HeaderControlContainer css={{ textAlign: 'right' }}>
          {xTo ? (
            <HeaderControlInnerA
              onClick={() => {
                if (document.referrer) {
                  history.goBack()
                } else {
                  history.push(xTo)
                }
              }}
            >
              <X />
            </HeaderControlInnerA>
          ) : (
            isHeaderInitialized && <Hamburger isNavOpen={isNavOpen} onClick={showNavigation} isMobile />
          )}
        </HeaderControlContainer>
      </HeaderContainer>
    </FadeIn>
  )
}

export default HeaderMainMobile
