import styled from '@emotion/styled'

import { PureButton } from './PureButton'

export const IconButton = styled(PureButton)({
  display: 'block',

  '& > svg': {
    display: 'block',
  },
})
