import React, { VFC } from 'react'

import { IconProps } from './types'

const Gear: VFC<IconProps & { readonly strokeColor?: string }> = ({ strokeColor = 'currentColor', ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.85711 1.14258H9.14282L9.46375 3.06817C9.80969 3.1707 10.1404 3.30863 10.4517 3.47777L12.0406 2.34287L13.6568 3.95911L12.5219 5.54798C12.6911 5.85928 12.829 6.19 12.9315 6.53593L14.8571 6.85687V9.14258L12.9315 9.46351C12.829 9.80945 12.6911 10.1402 12.5219 10.4515L13.6568 12.0403L12.0406 13.6566L10.4517 12.5217C10.1404 12.6908 9.80969 12.8287 9.46375 12.9313L9.14282 14.8569H6.85711L6.53617 12.9313C6.19024 12.8287 5.85952 12.6908 5.54822 12.5217L3.95936 13.6566L2.34311 12.0403L3.47802 10.4515C3.30887 10.1402 3.17094 9.80945 3.06842 9.46351L1.14282 9.14258V6.85686L3.06842 6.53593C3.17094 6.19 3.30887 5.85928 3.47801 5.54798L2.34311 3.95911L3.95935 2.34287L5.54822 3.47777C5.85952 3.30863 6.19024 3.1707 6.53617 3.06817L6.85711 1.14258Z"
      stroke={strokeColor}
      strokeLinejoin="round"
    />
    <circle cx="7.99995" cy="7.99958" r="2.28571" stroke={strokeColor} strokeLinejoin="round" />
  </svg>
)

export default Gear
