import styled from '@emotion/styled'
import React, { VFC } from 'react'

import { ButtonSolid } from '../../components/styled/button'
import { ArrowNextStep } from '../../icons'
import { Button2 } from '../../ui-kit'

const ButtonContent = styled.div({
  display: 'flex',
  justifyContent: 'left',
})

export const NextPrevButton: VFC<{
  readonly large?: boolean
  readonly onClick: () => void
  readonly text: string
  readonly fullWidth?: boolean
  readonly type?: 'prev' | 'next'
  readonly className?: string
}> = ({ large, onClick, text, fullWidth, type = 'next', className }) => (
  <ButtonSolid onClick={onClick} fullWidth={fullWidth} large={large} className={className}>
    <ButtonContent>
      {type === 'prev' && <ArrowNextStep css={{ transform: 'rotate(180deg)' }} />}
      <Button2>{text}</Button2>
      {type === 'next' && <ArrowNextStep />}
    </ButtonContent>
  </ButtonSolid>
)
