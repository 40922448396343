import styled from '@emotion/styled'
import { VFC } from 'react'

import HeaderModal from '../../components/HeaderModal'
import { ButtonSolid } from '../../components/styled/button'
import { useDialogManager } from '../../lib/dialog-manager'
import { useDispatch, useSelector } from '../../store'
import { acceptInvitation, declineInvitation } from '../../store/invite'
import { getCurrentUser } from '../../store/user'
import { RegularDialog, RegularDialogProps } from '../../ui-kit/RegularDialog'
import { getMembership } from '../../store/membership'
import { getModalConfig } from './utils'

const Content = styled.div`
  max-width: 500px;
  text-align: center;
  margin: 0px 20px 40px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
`

export const InvitationDialog: VFC<RegularDialogProps> = (props) => {
  const invitation = useSelector((state) => state.invite.data[0])
  const dispatch = useDispatch()
  const { hideCurrentDialog } = useDialogManager()

  const { title, text } = getModalConfig(invitation)

  return (
    <RegularDialog {...props} onClose={() => {}}>
      <HeaderModal headerTitle={title} />
      <Content>{text}</Content>
      <ButtonContainer>
        <ButtonSolid
          onClick={async () => {
            await dispatch(acceptInvitation()).unwrap()
            dispatch(getCurrentUser())
            dispatch(getMembership())
            hideCurrentDialog()
          }}
        >
          Join
        </ButtonSolid>
        <ButtonSolid
          onClick={() => {
            dispatch(declineInvitation())
            hideCurrentDialog()
          }}
        >
          Cancel
        </ButtonSolid>
      </ButtonContainer>
    </RegularDialog>
  )
}
