import dayjs from 'dayjs'

import { correctDayIndex } from '../../utils/utils'
import { marginBottom } from '../utils'

import { Day } from './Day'
import { Grid } from './Grid'
import { Header } from './Header'
import { Legend } from './Legend'
import { Title } from './Title'

const currentDate = dayjs().date()
const currentMonth = dayjs().month()
const currentYear = dayjs().year()

export const Calendar = ({
  activeMonth,
  activeYear,
  keyedCalendarDataFinished,
  keyedCalendarDataPlanned,
  onClickDate,
  onClickLeft,
  onClickRight,
  selectedDate,
  selectedMonth,
  selectedYear,
  ...props
}: any) => {
  const dayjsActiveMonthAndYear = dayjs().month(activeMonth).year(activeYear)
  const startOfMonthDay = dayjsActiveMonthAndYear.startOf('month').day()
  // adjust the calendar to have Monday as the left-most column, not Sunday
  const adjustedStartOfMonthDay = correctDayIndex(startOfMonthDay)
  const numberOfDays = dayjsActiveMonthAndYear.endOf('month').date()
  // pad the beginning with nulls so date 1 starts on the correct weekday
  const calendarDaysArray = [
    ...Array.from(Array(adjustedStartOfMonthDay), () => null),
    ...Array.from(Array(numberOfDays).keys(), (x) => x + 1),
  ]

  return (
    <div
      css={{
        width: 360,
      }}
      {...props}
    >
      <Header dayjsActiveMonthAndYear={dayjsActiveMonthAndYear} onClickLeft={onClickLeft} onClickRight={onClickRight} />
      <Title />
      <Grid css={marginBottom(25)}>
        {calendarDaysArray.map((date, index) => {
          const formattedDate = `${activeYear}-${dayjs().month(activeMonth).format('MM')}-${dayjs()
            .date(date || 0)
            .format('DD')}`
          const dayClassesFinished = keyedCalendarDataFinished[formattedDate] || []
          const dayClassesArePlanned = keyedCalendarDataPlanned[formattedDate]
          return date ? (
            <Day
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              date={date}
              dayClassesFinished={dayClassesFinished}
              dayClassesArePlanned={dayClassesArePlanned}
              isSelected={selectedMonth === activeMonth && selectedYear === activeYear && selectedDate === date}
              isToday={currentMonth === activeMonth && currentYear === activeYear && currentDate === date}
              onClick={() => onClickDate(date)}
              tabIndex="0"
              role="button"
            />
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index} />
          )
        })}
      </Grid>
      <Legend />
    </div>
  )
}
