import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { resetError, updateError } from 'store/error'

import { cancelMembership } from '../../api/membership/cancelMembership'

export const useApiCancel = () => {
  const dispatch = useDispatch()

  return useCallback(
    async (membershipId: string) => {
      try {
        dispatch(resetError())

        const response = await cancelMembership(membershipId)

        if (response?.data?.success) {
          // do nothing... success will advance the view's displayState
        } else {
          // TODO pass along server 412 error message instead of default message
          throw new Error('Sorry, there was a problem.')
        }
      } catch (e: any) {
        dispatch(updateError(e.message))
        throw new Error(e)
      }
    },
    [dispatch],
  )
}
