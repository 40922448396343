import { generatePath, matchPath } from 'react-router-dom'

const createRoute = <T = void, Q extends Record<string, string> | void = void>(
  pattern: string,
  { exact = true }: { exact?: boolean } = {},
) => ({
  pattern,
  generatePath: (params?: T, queryParams?: Q) => {
    const path = generatePath(pattern, params)
    const query = Object.entries(queryParams ?? {})
      .map(([k, v]) => `${k}=${v}`)
      .join('&')
    return `${path}${query ? `?${query}` : ''}`
  },
  isActive: (pathname: string) => Boolean(matchPath(pathname, { path: pattern, exact })),
})

export type InstructorRouteParams = { instructorId: string }

export const routing = {
  home: createRoute('/'),

  resetEmail: createRoute('/reset-email'),
  verifyEmail: createRoute('/verify-email'),
  pleaseVerifyEmail: createRoute('/please-verify-email'),
  downloadApp: createRoute('/download-app'),

  profile: {
    profile: createRoute('/'),
    resetPassword: createRoute('/profile/reset-password'),
    membership: createRoute('/profile/membership'),
    paymentInfo: createRoute('/profile/payment-info'),
    settings: createRoute('/profile/settings'),
    profiles: createRoute('/profile/profiles'),
  },

  onboarding: {
    index: createRoute('/onboarding', { exact: false }),
    membership: createRoute('/onboarding/membership'),
    welcome: createRoute('/onboarding/welcome'),
    createAccount: createRoute('/onboarding/create-account'),
    addCard: createRoute('/onboarding/add-card'),
    downloadApp: createRoute('/onboarding/download-app'),
    feedAlgorithm: createRoute('/onboarding/feed-algorithm'),
    fitnessAssessment: createRoute<{ questionNumber?: number }>('/onboarding/fitness-assessment/:questionNumber?'),
    curating: createRoute('/onboarding/curating'),
  },
}
