import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const width = (w: CSSProperties['width']) =>
  css({
    width: w,
  })

export const minWidth = (minW: CSSProperties['minWidth']) =>
  css({
    minWidth: minW,
  })

export const height = (h: CSSProperties['height']) =>
  css({
    height: h,
  })

export const size = (w: CSSProperties['width'], h: CSSProperties['height'] = w) => css([width(w), height(h)])
