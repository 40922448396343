import React, { VFC } from 'react'

import { IconProps } from './types'

const Calendar: VFC<IconProps & { readonly fill?: string }> = ({ fill = '#000', ...props }) => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 13.75C12.2312 13.75 11 14.9812 11 16.5V55C11 56.5188 12.2312 57.75 13.75 57.75H52.25C53.7688 57.75 55 56.5188 55 55V16.5C55 14.9812 53.7688 13.75 52.25 13.75H13.75ZM5.5 16.5C5.5 11.9437 9.19365 8.25 13.75 8.25H52.25C56.8064 8.25 60.5 11.9437 60.5 16.5V55C60.5 59.5564 56.8064 63.25 52.25 63.25H13.75C9.19365 63.25 5.5 59.5564 5.5 55V16.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 2.75C45.5188 2.75 46.75 3.98122 46.75 5.5V16.5C46.75 18.0188 45.5188 19.25 44 19.25C42.4812 19.25 41.25 18.0188 41.25 16.5V5.5C41.25 3.98122 42.4812 2.75 44 2.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2.75C23.5188 2.75 24.75 3.98122 24.75 5.5V16.5C24.75 18.0188 23.5188 19.25 22 19.25C20.4812 19.25 19.25 18.0188 19.25 16.5V5.5C19.25 3.98122 20.4812 2.75 22 2.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 27.5C5.5 25.9812 6.73122 24.75 8.25 24.75H57.75C59.2688 24.75 60.5 25.9812 60.5 27.5C60.5 29.0188 59.2688 30.25 57.75 30.25H8.25C6.73122 30.25 5.5 29.0188 5.5 27.5Z"
      fill={fill}
    />
  </svg>
)

export default Calendar
