import { WorkoutsHistoryResponseItem } from '../../../api/history-workouts/getHistoryWorkouts'

import { simplifyDate } from './simplifyDate'

// convert calendarData to date-keyed 'true's for all dates
// that have one or more classes with status 'planned'
export const keyCalendarDataPlanned = (calendarData: WorkoutsHistoryResponseItem[], timezone: string) =>
  calendarData
    // TODO Maybe not 'planned'
    .filter(({ workout_status }) => workout_status!.toLowerCase() === 'planned')
    .reduce((acc, { start_time }) => {
      const simpleDate = simplifyDate(start_time!, timezone)
      return {
        ...acc,
        [simpleDate]: true,
      }
    }, {} as Record<string, boolean>)
