import styled from '@emotion/styled'
import { DialogProps } from 'lib/dialog-manager'
import React, { FC, useEffect, useState } from 'react'

import { bp } from '../components/styled/breakpoints'
import { FadeIn } from '../components/styled/containers'
import { CURVES, TIMING } from '../constants'

import { overflowYAuto } from './utils'

export const ModalBlocker = styled.div<{ blur: boolean }>(({ theme, blur }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: ['100vh', '-webkit-fill-available'],
  background: theme.colors.black60,
  backdropFilter: blur ? 'blur(16px)' : undefined,
  transition: `backdrop-filter ${TIMING.fast3}ms ${CURVES.sCurve}`,
}))

export const ModalContainer = styled.div(({ theme }) => ({
  position: 'relative',
  background: theme.colors.white,
  overflow: 'hidden',
  width: '100%',

  minHeight: '100%',
  [bp.sm]: {
    minHeight: 'initial',

    margin: '60px 0',
    width: 'auto',
    minWidth: theme.variables.modalWidth,
    boxShadow: theme.shadows.glow,
    borderRadius: 16,
  },

  [bp.lg]: {
    marginTop: '120px 0',
  },
}))

export const ModalViewport = styled.div({
  display: 'flex',
  justifyContent: 'center',

  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: ['100vh', '-webkit-fill-available'],
  overflowY: 'scroll',

  alignItems: 'flex-start',
})

const Background = ({ onClick }: { readonly onClick: () => void }) => {
  const [blur, setBlur] = useState(false)
  useEffect(() => {
    setBlur(true)
  }, [])
  return <ModalBlocker blur={blur} onClick={onClick} />
}

const FullScreenContainer = styled.div(({ theme }) => ({
  position: 'relative',
  background: theme.colors.black,
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}))

export type RegularDialogProps = DialogProps & {
  readonly isFullScreen?: boolean
}

export const RegularDialog: FC<RegularDialogProps> = ({ children, isShown, onClose, isFullScreen }) => {
  const [isIn, setIsIn] = useState(false)

  const ContentContainer = isFullScreen ? FullScreenContainer : ModalContainer

  useEffect(() => {
    setIsIn(isShown)
  }, [isShown])

  return (
    <FadeIn isIn={isIn} zIndex={11}>
      <ModalViewport css={overflowYAuto}>
        <Background onClick={onClose} />
        <ContentContainer>{children}</ContentContainer>
      </ModalViewport>
    </FadeIn>
  )
}
