import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'

import { sendVerificationEmail } from '../api/auth/sendVerificationEmail'

const useVerificationEmail = () => {
  const { user } = useAuth0()
  const resendVerificationEmail = useCallback(async () => {
    try {
      const response = await sendVerificationEmail()

      if (response?.data) {
        alert(`A verification email has been sent to ${user?.email}`)
      } else {
        throw new Error()
      }
    } catch (e) {
      alert(`Sorry, there was a problem sending the verification email.`)
    }
  }, [user?.email])

  return { resendVerificationEmail }
}

export default useVerificationEmail
