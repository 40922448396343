import { css } from '@emotion/react'
import { CSSProperties } from 'react'

import { displayFlex } from './display'

export const flex = (fl: CSSProperties['flex']) =>
  css({
    flex: fl,
  })

export const alignItems = (ai: CSSProperties['alignItems']) =>
  css({
    alignItems: ai,
  })

export const alignItemsCenter = alignItems('center')

export const displayFlexColumn = css({
  display: 'flex',
  flexDirection: 'column',
})

export const flexDirectionRow = css({
  flexDirection: 'row',
})

export const justifyContent = (jt: CSSProperties['justifyContent']) =>
  css({
    justifyContent: jt,
  })

export const justifyContentCenter = justifyContent('center')

export const flexFullCenter = css([displayFlex, justifyContentCenter, alignItemsCenter])
