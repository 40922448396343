import styled from '@emotion/styled'

import { PureButton, Body2 } from '../../../ui-kit'
import { ReactNode } from 'react'

export const InfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  overflow: 'hidden',
  boxSizing: 'border-box',
  paddingRight: '1rem',
})

const Button = styled(PureButton)(({ theme }) => ({
  paddingRight: 25,
  transition: theme.transitions.allFast2,

  '&:last-child': {
    paddingRight: 0,
  },

  '&:hover': {
    color: theme.colors.sand,
  },
}))

export const InfoButtonText = styled(Body2)({
  lineHeight: '20px',
  fontWeight: 500,
  paddingLeft: 5,
})

const InfoButtonContent = styled.div({
  display: 'flex',
  alignItems: 'center',
})

type InfoButtonProps = { readonly children: ReactNode } & React.ComponentProps<typeof Button>

export const InfoButton = ({ children, ...props }: InfoButtonProps) => (
  <Button {...props}>
    <InfoButtonContent>{children}</InfoButtonContent>
  </Button>
)
