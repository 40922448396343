import styled from '@emotion/styled'

export const H4 = styled.h4(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: 18,
  lineHeight: '32px',
  fontWeight: 700,
  margin: 0,
  padding: 0,
}))
