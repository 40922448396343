import React from 'react'
import { AddImage, Content, ProfileContainer, TextStyled } from './styles'
import { AddProfile } from 'icons/AddProfile'

type AddProfileRowProps = {
  readonly onClick: () => void
}

export function AddProfileRow({ onClick }: AddProfileRowProps) {
  return (
    <ProfileContainer onClick={onClick}>
      <AddImage>
        <AddProfile />
      </AddImage>
      <Content>
        <TextStyled size="smallTextAlt">Add Profile</TextStyled>
      </Content>
    </ProfileContainer>
  )
}
