import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

export { validate } from './various/validate'
export { debounce } from './various/debounce'
export { msToMinutes } from './various/msToMinutes'
export { conformValue } from './various/conformValue'
export { correctDayIndex } from './various/correctDayIndex'
export { birthdayDayjsFormats } from './various/birthdayDayjsFormats'
export { dayjsWeekdayIdentifiers } from './various/dayjsWeekdayIdentifiers'
export { stringToFeetInchesArray } from './various/stringToFeetInchesArray'
export { formHeightToServerHeight } from './various/formHeightToServerHeight'
export { serverHeightToFormHeight } from './various/serverHeightToFormHeight'
export { reduceCalendarDataToTLCLastMonth } from './various/reduceCalendarDataToTLCLastMonth'
export { generateFitnessAssessmentAnswers } from './various/generateFitnessAssessmentAnswers'
export { reduceCalendarDataToTLCElapsedMin } from './various/reduceCalendarDataToTLCElapsedMin'
export { reduceCalendarDataToCategoryElapsedMin } from './various/reduceCalendarDataToCategoryElapsedMin'
export { billingCycleAnchorToUpcomingBillingDate } from './various/billingCycleAnchorToUpcomingBillingDate'
export { formatStartDate, formatValidThroughDate } from './various/date-formatting'
export { capitalizeFirstLetterOfString, capitalizeFirstLetterOfStrings } from './various/capitalizeFirstLetterOfString'
export { finishedThisMonth, finishedLastMonth, finishedLastMonthUpToToday } from './various/calendar-data-filters'

dayjs.extend(relativeTime)
