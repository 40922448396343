import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { MULTIPLIERS } from '../../constants'
import { AppTheme } from '../../model/ThemeProvider/theme'

import { bp } from './breakpoints'
import { marginTop, marginBottom } from './spacing'
import { transitionFast } from './utility'

/*
 * Utility
 */
export const fontSizer = (baseSize: number, multiplier = MULTIPLIERS.heading) =>
  css({
    fontSize: baseSize,

    [bp.xxs]: {
      fontSize: baseSize * multiplier.xs,
    },
    [bp.sm]: {
      fontSize: baseSize * multiplier.sm,
    },
    [bp.md]: {
      fontSize: baseSize * multiplier.md,
    },
    [bp.lg]: {
      fontSize: baseSize * multiplier.lg,
    },
    [bp.xl]: {
      fontSize: baseSize * multiplier.xl,
    },
    [bp.xxl]: {
      fontSize: baseSize * multiplier.xxl,
    },
  })

const fontSizerBody = (baseSize: number) => fontSizer(baseSize, MULTIPLIERS.body)

const themeColor = (color: string | undefined, theme: AppTheme) =>
  css({
    color: (color ? (theme.colors as any)[color] : undefined) || theme.colors.black,
  })

/*
 * Headings
 */
const headingBase = (theme: AppTheme) =>
  css({
    marginTop: 0,
    marginBottom: 0,
    fontFamily: theme.typography.family.heading,
    fontWeight: 500,
    letterSpacing: theme.typography.letterSpacing.heading,
    lineHeight: theme.typography.lineHeight.condensed,
    textTransform: 'uppercase',
  })

export const H1 = styled.h1<{ isAlternateHeader?: boolean }>(
  ({ theme }) => headingBase(theme),
  ({ theme }) => ({
    lineHeight: theme.typography.lineHeight.extraCondensed,
  }),
  ({ isAlternateHeader, theme }) =>
    fontSizer(isAlternateHeader ? theme.typography.size.h1alt : theme.typography.size.h1),
)

export const H1Special = styled.h1<{ color?: string }>(
  ({ theme }) => headingBase(theme),
  ({ theme }) => ({
    lineHeight: theme.typography.lineHeight.extraCondensed,
    cursor: 'default',
    textShadow: theme.typography.shadows.drop,
  }),
  ({ theme }) => fontSizer(theme.typography.size.h1special),
  ({ color, theme }) => themeColor(color, theme),
)

export const H1Large = styled(H1)<{ color: string }>(
  ({ theme }) => fontSizer(theme.typography.size.h1large),
  ({ color, theme }) => themeColor(color, theme),
)

const baseHeadingVerticalMargin = ({
  noMargin,
  noMarginTop,
  noMarginBottom,
}: {
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
}) => css(!noMargin && !noMarginTop && marginTop('md'), !noMargin && !noMarginBottom && marginBottom('xs'))

export const SubHeading = styled.h2<{
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
  isActive?: boolean
}>(
  ({ theme }) => headingBase(theme),
  ({ noMargin, noMarginTop, noMarginBottom }) => baseHeadingVerticalMargin({ noMargin, noMarginTop, noMarginBottom }),
  ({ color, isActive, theme }) => themeColor(isActive ? 'white' : color || 'whiteAlpha50', theme),
  ({ theme }) => fontSizer(theme.typography.size.subHeading),
  transitionFast,
)

export const SubHeadingLarge = styled.h2(
  ({ theme }) => headingBase(theme),
  ({ color, theme }) => themeColor(color || 'whiteAlpha75', theme),
  ({ theme }) => fontSizer(theme.typography.size.subHeadingLarge),
)

export const SubHeadingSmall = styled.h2<{
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
}>(
  ({ theme }) => headingBase(theme),
  ({ noMargin, noMarginTop, noMarginBottom }) => baseHeadingVerticalMargin({ noMargin, noMarginTop, noMarginBottom }),
  ({ theme }) => fontSizer(theme.typography.size.subHeadingSmall),
  transitionFast,
)

export const SubHeadingAlt = styled.h2<{
  noMargin?: boolean
  noMarginTop?: boolean
  noMarginBottom?: boolean
  color?: string
}>(
  ({ noMargin, noMarginTop, noMarginBottom }) => baseHeadingVerticalMargin({ noMargin, noMarginTop, noMarginBottom }),
  ({ theme }) => ({
    fontWeight: 500,
    letterSpacing: theme.typography.letterSpacing.condensed,
  }),
  ({ color, theme }) => themeColor(color, theme),
  ({ theme }) => fontSizer(theme.typography.size.subHeadingAlt),
)

export const DesktopHeaderNav = styled.h2<{
  noMargin?: boolean
  isActive?: boolean
}>(
  ({ noMargin }) => !noMargin && marginTop('md'),
  ({ noMargin }) => !noMargin && marginBottom('xs'),
  ({ isActive, theme }) => ({
    fontFamily: theme.typography.family.default,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 500,
    lineHeight: 1.5,
    textTransform: 'uppercase',
    color: isActive ? theme.headerLinkActive : theme.headerLink,
  }),
  transitionFast,
)

export const SubHeadingXSmall = styled(SubHeading)(({ theme }) => fontSizer(theme.typography.size.subheadingXSmall))

export const H5 = styled.h5(
  ({ theme }) => headingBase(theme),
  ({ theme }) => ({
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: theme.typography.lineHeight.extraCondensed,
    letterSpacing: theme.typography.letterSpacing.extended,
    cursor: 'default',
  }),
  ({ theme }) => fontSizer(theme.typography.size.h5),
)

export const SmallCaps = styled.span(
  ({ theme }) => ({
    letterSpacing: theme.typography.letterSpacing.extraExtraExtended,
    textTransform: 'uppercase',
    fontWeight: 500,
  }),
  ({ color, theme }) => themeColor(color || 'sand', theme),
  ({ theme }) => fontSizerBody(theme.typography.size.smallText),
)

export const SmallCapsAlt2 = styled.span((props) => ({
  color: props.theme.textColorSecondary,
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '16px',
  letterSpacing: '2px',
}))

/*
 * Body copy & general text
 */
const copySpacingAndHeightBase = (theme: AppTheme) =>
  css({
    letterSpacing: theme.typography.letterSpacing.condensed,
    lineHeight: theme.typography.lineHeight.main,
  })

export const P = styled.p<{ weight?: string }>(
  ({ weight }) => ({
    fontWeight: weight,
    marginTop: 0,
    marginBottom: 0,
  }),
  ({ theme }) => copySpacingAndHeightBase(theme),
  ({ theme }) => fontSizerBody(theme.typography.size.p),
)

export const Span = P.withComponent('span')

export const PAlt = styled.span<{ color?: string }>(
  ({ theme }) => copySpacingAndHeightBase(theme),
  ({ color, theme }) => themeColor(color, theme),
  ({ theme }) => fontSizerBody(theme.typography.size.PAlt),
)

export const SmallText = styled.span<{ color: string }>(
  ({ theme }) => copySpacingAndHeightBase(theme),
  ({ color, theme }) => themeColor(color, theme),
  ({ theme }) => fontSizerBody(theme.typography.size.smallText),
)

export const SmallTextAlt = styled.span<{ color?: string }>(
  ({ theme }) => copySpacingAndHeightBase(theme),
  ({ color, theme }) => themeColor(color, theme),
  ({ theme }) => fontSizerBody(theme.typography.size.smallTextAlt),
)
export const MBody1 = styled.span<{ color?: string; small?: boolean }>(
  ({ theme }) => ({ letterSpacing: theme.typography.letterSpacing.regular }),
  ({ color, theme }) => themeColor(color, theme),
  ({ small, theme }) => fontSizerBody(small ? theme.typography.size.mBody1 - 2 : theme.typography.size.mBody1),
)
export const NumericText = styled.h3<{ small?: boolean }>(
  {
    fontWeight: 200,
    cursor: 'default',
    margin: 0,
  },
  ({ small, theme }) => fontSizerBody(small ? theme.typography.size.numericTextSmall : theme.typography.size.h3),
)

export const InlineLinkA = styled.a(
  ({ theme }) => ({
    color: theme.colors.sand,
    cursor: 'pointer',
  }),
  transitionFast,
)

export const TextLinkSpan = styled.span<{
  color?: string
}>(
  ({ theme }) => ({
    letterSpacing: theme.typography.letterSpacing.condensed,
    cursor: 'pointer',
  }),
  ({ theme }) => copySpacingAndHeightBase(theme),
  ({ color, theme }) => themeColor(color, theme),
  { fontWeight: 500 },
  ({ theme }) => fontSizerBody(theme.typography.size.textsm),
)
