import { Redirect, Switch } from 'react-router-dom'

import { renderAuthenticatedRoutes } from './AuthenticatedRoutes'
import OnboardingLoader from './components/OnboardingLoader'
import PrivateRoute from './components/PrivateRoute'
import { onboardingRedirects } from './model/Routing/redirects'
import { routing } from './model/Routing/routing'
import {
  MembershipSetupFullPage,
  OnboardingCreateAccount,
  OnboardingCurating,
  OnboardingFitnessAssessment,
  OnboardingWelcome,
} from './views'
import { AddCardStep } from './views/onboarding/AddCardStep'
import { DownloadAppStep } from './views/onboarding/DownloadAppStep'
import { FeedAlgorithmStep } from './views/onboarding/FeedAlgorithmStep'

const AuthenticatedRoutesOnboarding = () => (
  <OnboardingLoader>
    <Switch>
      {onboardingRedirects.map(({ matchPath, redirectPath }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PrivateRoute component={() => <Redirect to={redirectPath} />} exact key={index} path={matchPath} />
      ))}
      <PrivateRoute
        path={routing.onboarding.membership.pattern}
        exact
        component={MembershipSetupFullPage}
        title="Membership Setup"
      />
      <PrivateRoute
        path={routing.onboarding.createAccount.pattern}
        component={OnboardingCreateAccount}
        title="Create Account"
      />
      <PrivateRoute path={routing.onboarding.addCard.pattern} component={AddCardStep} title="Add card" />
      <PrivateRoute
        path={routing.onboarding.downloadApp.pattern}
        exact
        component={DownloadAppStep}
        title="Download app"
      />
      <PrivateRoute
        path={routing.onboarding.feedAlgorithm.pattern}
        exact
        component={FeedAlgorithmStep}
        title="Feed algorithm"
      />
      <PrivateRoute path={routing.onboarding.welcome.pattern} component={OnboardingWelcome} title="Welcome" />
      <PrivateRoute
        path={routing.onboarding.fitnessAssessment.pattern}
        component={OnboardingFitnessAssessment}
        title="Onboarding"
      />
      <PrivateRoute path={routing.onboarding.curating.pattern} component={OnboardingCurating} title="Onboarding" />
      {renderAuthenticatedRoutes()}
    </Switch>
  </OnboardingLoader>
)

export default AuthenticatedRoutesOnboarding
