import styled from '@emotion/styled'
import React, { VFC } from 'react'

import { ButtonUnstyled } from 'components/styled/button'
import { Close } from 'icons'

const Wrapper = styled(ButtonUnstyled)`
  padding: 3px;
`

const Icon = styled(Close)`
  width: 24px;
  height: 24px;
`

export const RemoveButton: VFC<{ readonly onClick: () => void }> = ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <Icon />
  </Wrapper>
)
