import { useDialogManager } from 'lib/dialog-manager'
import React, { ChangeEvent, useState, VFC } from 'react'
import { useDispatch, useSelector } from 'store'
import { updateError } from 'store/error'
import { updateCommercial } from 'store/membership'

import { CommercialMembershipBusinessType, MembershipCommercialDetail } from '../api/entities/MembershipEntity'
import HeaderModal from '../components/HeaderModal'
import { ButtonSection, ButtonSolid } from '../components/styled/button'
import { constrain360, Fade } from '../components/styled/containers'
import { FormCustomSelect, FormInput } from '../components/styled/form'
import { paddingTop, paddingVertical } from '../components/styled/spacing'
import { blockAndDim } from '../components/styled/utility'
import { Button3 } from '../ui-kit'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'

// const mockCommercial = {
//   business_name: 'Test Gym',
//   business_type: 'gym',
//   master_pin: '12345',
// };

const BusinessModal: VFC<RegularDialogProps> = (props) => {
  const dispatch = useDispatch()
  const { commercial_detail: commercial, id } = useSelector(
    (state) => state.membership.primaryMembership || { commercial_detail: {}, id: '' },
  )

  const [isPosting, setIsPosting] = useState(false)
  const [open, setOpen] = useState(false)
  const [form, setForm] = useState<MembershipCommercialDetail>(commercial || {})

  const { hideCurrentDialog } = useDialogManager()

  const getOnBlur = (name: keyof MembershipCommercialDetail) => (e: ChangeEvent<HTMLInputElement>) =>
    setForm({
      ...form,
      [name]: e.target.value,
    })

  return (
    <RegularDialog {...props}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Business details" onClickControlLeft={hideCurrentDialog} />
      <Fade>
        <div css={[blockAndDim(isPosting), constrain360, paddingVertical('lg')]}>
          <div>
            <FormInput
              labeltext="Business Name"
              onChangeCallback={getOnBlur('business_name')}
              onBlurCallback={getOnBlur('business_name')}
              val={form.business_name || ''}
            />
            <FormCustomSelect
              isSelectDropdownShowing={open}
              toggleIsSelectDropdownShowing={() => setOpen(!open)}
              onChangeCallback={() => setOpen(false)}
              handleCustomDropdownChange={(_, value) => {
                setOpen(false)
                setForm({
                  ...form,
                  business_type: value as CommercialMembershipBusinessType,
                })
              }}
              id="business_type"
              labeltext="Business Type"
              options={['gym', 'hospitality']}
              val={form.business_type || ''}
            />
            <FormInput
              labeltext="Master PIN"
              onChangeCallback={getOnBlur('master_pin')}
              onBlurCallback={getOnBlur('master_pin')}
              val={form.master_pin || ''}
            />
            <FormInput
              labeltext="Lift PIN"
              onChangeCallback={getOnBlur('lift_pin')}
              onBlurCallback={getOnBlur('lift_pin')}
              val={form.lift_pin || ''}
            />
            <ButtonSection css={paddingTop('sm')}>
              <ButtonSolid
                onClick={async () => {
                  if (!id || isPosting) return
                  setIsPosting(true)
                  try {
                    await dispatch(updateCommercial({ id, data: form })).unwrap()
                    setIsPosting(false)
                    hideCurrentDialog()
                  } catch (err) {
                    const message = (err as unknown as Error).message
                    dispatch(updateError(message))
                  }
                }}
              >
                <Button3>Save</Button3>
              </ButtonSolid>
            </ButtonSection>
          </div>
        </div>
      </Fade>
    </RegularDialog>
  )
}

export default BusinessModal
