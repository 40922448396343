import React, { VFC } from 'react'

import { IconProps } from './types'

const Pencil: VFC<IconProps & { readonly strokeColor?: string }> = ({ strokeColor = 'currentColor', ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.4344 1.85547L13.8588 4.27983L6.03279 12.1059L3.35323 12.3611L3.60842 9.68149L11.4344 1.85547Z"
      stroke={strokeColor}
      strokeLinejoin="round"
    />
    <path d="M9.01013 4.2793L11.4345 6.70366" stroke={strokeColor} />
  </svg>
)

export default Pencil
