import React, { VFC } from 'react'

import { IconProps } from './types'

export const AddProfile: VFC<IconProps> = () => (
  <svg
    style={{
      position: 'relative',
      left: '31.25%',
      right: '31.25%',
      top: '31.25%',
      bottom: '31.25%',
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C12.4142 3 12.75 3.33579 12.75 3.75L12.75 20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25L11.25 3.75C11.25 3.33579 11.5858 3 12 3Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 11.5858 3.33579 11.25 3.75 11.25H20.25C20.6642 11.25 21 11.5858 21 12C21 12.4142 20.6642 12.75 20.25 12.75H3.75C3.33579 12.75 3 12.4142 3 12Z"
      fill="white"
    />
  </svg>
)
