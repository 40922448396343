export const parseToJSON = (str: string | null) => {
  try {
    if (str) {
      return JSON.parse(str)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(str)
  }

  return {}
}

export const stringifyFromJSON = (json: Record<string, any> | string) => {
  try {
    return JSON.stringify(json)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return ''
  }
}
