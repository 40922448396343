import { useAuth0 } from '@auth0/auth0-react'
import { useDialogManager } from 'lib/dialog-manager'
import React, { useCallback, useState, VFC } from 'react'

import { changePassword } from '../api/auth0/changePassword'
import HeaderModal from '../components/HeaderModal'
import { ButtonSection, ButtonSolid } from '../components/styled/button'
import { constrain360, Fade } from '../components/styled/containers'
import { paddingTop, paddingVertical } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import { blockAndDim } from '../components/styled/utility'
import { Button3 } from '../ui-kit'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'

const ResetPassword: VFC<RegularDialogProps> = (props) => {
  const { user } = useAuth0()

  const [isPosting, setIsPosting] = useState(false)

  const { hideCurrentDialog } = useDialogManager()

  const onClickSendEmail = useCallback(() => {
    const postAuth0ResetPassword = async () => {
      try {
        // use isPosting to block user interactions
        // e.g. prevent repeat clicks during network delays
        setIsPosting(true)

        // talk to Auth0
        const response = await changePassword(user!.email!)

        if (response?.data) {
          alert(response.data)
          hideCurrentDialog()
        } else {
          alert('Sorry, there was a problem with the request.')
          setIsPosting(false)
        }
      } catch (e) {
        alert('Sorry, there was a problem with the request.')
        setIsPosting(false)
      }
    }

    if (user?.email) {
      postAuth0ResetPassword()
    } else {
      alert('Sorry, there was an issue with the process.')
      setIsPosting(false)
    }
  }, [hideCurrentDialog, user])

  return (
    <RegularDialog {...props}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Reset Password" onClickControlLeft={hideCurrentDialog} />
      <Fade>
        <div css={[blockAndDim(isPosting), constrain360, paddingVertical('lg')]}>
          <div>
            <P>
              To reset your password, tap &quot;Send Email&quot; below. We will send you an email to confirm the
              password change.
            </P>

            <ButtonSection css={paddingTop('sm')}>
              <ButtonSolid onClick={onClickSendEmail}>
                <Button3>Send Email</Button3>
              </ButtonSolid>
            </ButtonSection>
          </div>
        </div>
      </Fade>
    </RegularDialog>
  )
}

export default ResetPassword
