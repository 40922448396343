import React, { VFC } from 'react'

import { IconProps } from './types'

export const Close: VFC<IconProps & { readonly fill?: string }> = ({ fill = 'black', ...props }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4523 8.54809C23.8862 8.98201 23.8862 9.68553 23.4523 10.1194L10.119 23.4528C9.68504 23.8867 8.98152 23.8867 8.5476 23.4528C8.11369 23.0189 8.11369 22.3153 8.5476 21.8814L21.8809 8.54809C22.3149 8.11418 23.0184 8.11418 23.4523 8.54809Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5476 8.54809C8.98152 8.11418 9.68504 8.11418 10.119 8.54809L23.4523 21.8814C23.8862 22.3153 23.8862 23.0189 23.4523 23.4528C23.0184 23.8867 22.3149 23.8867 21.8809 23.4528L8.5476 10.1194C8.11369 9.68553 8.11369 8.98201 8.5476 8.54809Z"
        fill={fill}
      />
    </svg>
  )
}
