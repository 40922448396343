import React, { VFC } from 'react'

import { ButtonSolid } from '../../components/styled/button'
import { Button3 } from '../Typography'

import { EmailPagesContainer } from './Container'
import { EmailPagesTitleBlock } from './TitleBlock'

export const EmailGoThroughPage: VFC<{
  readonly isIn?: boolean
  readonly title: string
  readonly message: string | null | undefined
  readonly onClick: () => void
  readonly buttonText: string
}> = ({ isIn, title, message, onClick, buttonText }) => (
  <EmailPagesContainer isIn={isIn}>
    <EmailPagesTitleBlock title={title} message={message} />
    <ButtonSolid onClick={onClick} fullWidth large>
      <Button3>{buttonText}</Button3>
    </ButtonSolid>
  </EmailPagesContainer>
)
