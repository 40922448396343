import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'

import PaymentCardLogo from '../../components/PaymentCardLogo'

import { ButtonUnstyled } from './button'
import { paddingBottom, paddingTop } from './spacing'
import { H1, P, SmallTextAlt, SmallCaps } from './typography'
import { positionRelative } from './utility'

const MembershipSectionTitle = styled(H1)(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  textTransform: 'none',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '100%',
  letterSpacing: 0.5,
  color: '#ffffff',
  marginBottom: '1em',
  paddingBottom: '0.5em',
  paddingTop: '1.5em',
  borderBottom: '1px solid #whiteAlphaX4',
}))

export const MembershipSectionHeader = ({ text }: { readonly text: ReactNode }) => (
  <MembershipSectionTitle>{text}</MembershipSectionTitle>
)

export const MembershipTextLink = ({ children, onClick, ...props }: { children: ReactNode; onClick: () => void }) => (
  <ButtonUnstyled onClick={onClick} css={[paddingTop('sm'), paddingBottom('md')]} {...props}>
    <SmallCaps>{children}</SmallCaps>
  </ButtonUnstyled>
)
MembershipTextLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

const MembershipSectionCopy = styled(P)()

export const MembershipSectionText = ({ text, color }: { readonly text: ReactNode; readonly color: string }) => (
  <MembershipSectionCopy
    css={{
      color: color,
    }}
  >
    {text}
  </MembershipSectionCopy>
)

export const MembershipPaymentMethod = ({
  cardBrand,
  expMonth,
  expYear,
  last4,
}: {
  readonly cardBrand: string
  readonly expMonth: number
  readonly expYear: number
  readonly last4: string
}) => {
  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: 10,
        }}
      >
        <PaymentCardLogo type={cardBrand} />
      </div>
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <div
          css={{
            display: 'flex',
            padding: '8px 0 3px',
          }}
        >
          <SmallTextAlt css={[positionRelative]}>
            Ending in {last4}, Expires {expMonth}/{expYear}
          </SmallTextAlt>
          <div
            css={{
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-right',
              paddingLeft: 19,
            }}
          />
        </div>
      </div>
    </div>
  )
}
