import styled from '@emotion/styled'
import { UserIconLarge } from 'icons/UserIconLarge'
import React, { VFC } from 'react'
import { Text } from 'ui-kit/Text'

import { RemoveButton } from './RemoveButton'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-top: 1px solid ${(_) => _.theme.colors.grayBf};
  border-bottom: 1px solid ${(_) => _.theme.colors.grayBf};
`

const Image = styled.img`
  height: 104px;
  width: 104px;
  border-radius: 50%;
  object-fit: cover;
`

const ImageDiv = styled.div``

const Content = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const TextStyled = styled(Text)`
  margin: 3px 0;
`
export const OwnProfileRow: VFC<{
  readonly loggedInProfileDetails: any
  readonly withRemoveButton?: boolean
  readonly onRemove: () => void
}> = ({ loggedInProfileDetails, withRemoveButton, onRemove }) => (
  <Container>
    {loggedInProfileDetails?.user?.avatar ? (
      <Image src={loggedInProfileDetails?.user?.avatar} alt="" />
    ) : (
      <ImageDiv>
        <UserIconLarge />
      </ImageDiv>
    )}
    <Content>
      <TextStyled size="smallTextAlt">You</TextStyled>
      <TextStyled>
        {' '}
        {loggedInProfileDetails?.first_name} {loggedInProfileDetails?.last_name}{' '}
      </TextStyled>
      <TextStyled size="smallTextAlt" color="secondaryAlt">
        {new Date(loggedInProfileDetails?.updated_date).toDateString()}
      </TextStyled>
    </Content>
    {withRemoveButton ? <RemoveButton onClick={onRemove} /> : null}
  </Container>
)
