import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { VFC } from 'react'

import { PureButton } from '../Buttons'
import { H4 } from '../Typography'

const TabButton = styled(PureButton)<{ borderSize?: number; active?: boolean }>(
  ({ theme, borderSize = 3, active = false }) => ({
    flex: 1,
    height: '100%',
    position: 'relative',
    paddingLeft: '1rem',
    paddingRight: '1rem',

    '&:after': {
      content: '""',
      width: '100%',
      height: borderSize,
      position: 'absolute',
      left: 0,
      bottom: -borderSize,
      zIndex: 2,

      backgroundColor: active ? theme.textColors.primary : 'transparent',
      borderRadius: borderSize / 2,
      transition: theme.transitions.allFast2,
    },
  }),
)

const TabContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '100%',
  width: '100%',
})

export const Tab: VFC<{
  readonly onClick: () => void
  readonly text: string
  readonly active?: boolean
  readonly borderSize?: number
  readonly className?: string
}> = ({ active, onClick, text, borderSize, className }) => {
  const theme = useTheme()

  const textColor = active ? theme.textColors.primary : theme.textColors.secondary

  return (
    <TabButton active={active} onClick={onClick} borderSize={borderSize} className={className}>
      <TabContentWrapper>
        <H4 css={{ lineHeight: 1, color: textColor }}>{text}</H4>
      </TabContentWrapper>
    </TabButton>
  )
}
