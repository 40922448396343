import dayjs, { Dayjs } from 'dayjs'

/*
 * Calendar data used in Progress
 */

const compareMonthAndYear = (a: Dayjs, b: Dayjs) => {
  return a.month() === b.month() && a.year() === b.year()
}

const isDatestrThisMonth = (dateStr: string) => {
  const dayjsDate = dayjs(dateStr)
  const today = dayjs()
  return compareMonthAndYear(dayjsDate, today)
}

const isDatestrLastMonth = (dateStr: string) => {
  const dayjsDate = dayjs(dateStr)
  const lastMonth = dayjs().subtract(1, 'month')
  return compareMonthAndYear(dayjsDate, lastMonth)
}

type Args = {
  start_time: string | null
  workout_status: string | null
}

export const finishedThisMonth = ({ start_time, workout_status }: Args) =>
  workout_status && workout_status.toLowerCase() === 'completed' && start_time && isDatestrThisMonth(start_time)

export const finishedLastMonth = ({ start_time, workout_status }: Args) =>
  workout_status && workout_status.toLowerCase() === 'completed' && start_time && isDatestrLastMonth(start_time)

export const finishedLastMonthUpToToday = ({ start_time, workout_status }: Args) =>
  workout_status &&
  workout_status.toLowerCase() === 'completed' &&
  start_time &&
  isDatestrLastMonth(start_time) &&
  dayjs(start_time).date() <= dayjs().date()
