import styled from '@emotion/styled'

import { bp } from './styled/breakpoints'

const AppContainer = styled.div<{
  haveSandBackground?: boolean
  excludeHeader?: boolean
}>(({ haveSandBackground, excludeHeader = false, theme }) => {
  const heightStyles = excludeHeader
    ? {
        minHeight: `calc(100vh - ${theme.variables.headerHeightMobile}px)`,
        [bp.sm]: {
          minHeight: `calc(100vh - ${theme.variables.headerHeightDesktop}px)`,
        },
      }
    : { minHeight: ['100vh', '-webkit-fill-available'] }
  return {
    ...heightStyles,
    backgroundColor: haveSandBackground ? theme.colors.mainPageBackground : 'transparent',
  }
})

export default AppContainer
