import styled from '@emotion/styled'
import { useDialogManager } from 'lib/dialog-manager'
import React, { useEffect, useRef, useState, VFC } from 'react'
import { useSelector } from 'store'

import HeaderModal from '../components/HeaderModal'
import { ButtonSolid, ButtonTextLink } from '../components/styled/button'
import { Fade, constrain360 } from '../components/styled/containers'
import { MembershipPaymentMethod } from '../components/styled/membership'
import { marginBottom, paddingBottom } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import { hoverOpacityFast } from '../components/styled/utility'
import { Button3, H3 } from '../ui-kit'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'

import RemovePaymentMethod from './RemovePaymentMethod'
import UpdatePaymentMethod from './UpdatePaymentMethod'

const UpdateBadge = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;

  width: 100%;
  height: 72px;
  padding: 20px 10px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #29cc6a;

  text-align: center;
  font-size: 13px;
  line-height: 165%;
  letter-spacing: -0.52px;

  opacity: 0;
  visibility: hidden;

  &.visible {
    @keyframes fadeout {
      20% {
        opacity: 1;
        visibility: visible;
      }

      80% {
        opacity: 1;
        visibility: visible;
      }

      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    animation: 4s linear fadeout;
  }
`

const PaymentInfo: VFC<RegularDialogProps> = (props) => {
  const { showDialog, hideCurrentDialog } = useDialogManager()

  const paymentMethod = useSelector((state) => state.billing.paymentMethod)

  const profiles = useSelector((state) => state.user.membershipProfiles)

  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)

  const owner = profiles[0]?.owner

  const paymentMethodRef = useRef<string | null | undefined>(paymentMethod?.id)
  const [showUpdateBadge, setShowUpdateBadge] = useState(false)

  useEffect(() => {
    if (paymentMethodRef.current !== paymentMethod?.id) {
      setShowUpdateBadge(true)
    }

    paymentMethodRef.current = paymentMethod?.id
  }, [paymentMethod])

  return (
    <RegularDialog {...props} css={{ minHeight: 300 }}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Account" onClickControlLeft={hideCurrentDialog} />
      <UpdateBadge className={showUpdateBadge ? 'visible' : ''} onAnimationEnd={() => setShowUpdateBadge(false)}>
        Your payment method has been updated.
      </UpdateBadge>
      <Fade>
        <div css={[constrain360, paddingBottom('lg')]}>
          <div style={{ minHeight: 300 }}>
            {paymentMethod ? (
              <Fade>
                <H3 css={[marginBottom('xs')]}>Payment Info</H3>
                <MembershipPaymentMethod
                  cardBrand={paymentMethod?.card?.brand}
                  expMonth={paymentMethod?.card?.exp_month}
                  expYear={paymentMethod?.card?.exp_year}
                  last4={paymentMethod?.card?.last4}
                />
                <div
                  css={{
                    display: 'flex',
                    gap: '32px',
                    marginTop: '24px',
                  }}
                >
                  <ButtonTextLink onClick={() => showDialog(UpdatePaymentMethod)}>Update</ButtonTextLink>
                  {owner ? null : <ButtonTextLink onClick={() => setShowRemoveConfirm(true)}>Remove</ButtonTextLink>}
                </div>
              </Fade>
            ) : (
              <Fade>
                <H3 css={[marginBottom('xs')]}>Payment Info</H3>
                <div
                  css={{
                    paddingBottom: 24,
                    borderBottom: '1px solid #BFBFBF',
                  }}
                >
                  <P>There is no credit card associated with your account.</P>
                  {owner ? null : (
                    <P>
                      All charges from your account will be charged to the credit card associated with the parent
                      account.
                    </P>
                  )}
                </div>
                <ButtonSolid
                  fullWidth
                  css={[
                    hoverOpacityFast,
                    {
                      marginTop: 32,
                    },
                  ]}
                  onClick={() => showDialog(UpdatePaymentMethod)}
                >
                  <Button3>+ Add Card</Button3>
                </ButtonSolid>
              </Fade>
            )}
          </div>
        </div>
      </Fade>
      {showRemoveConfirm ? <RemovePaymentMethod onClose={() => setShowRemoveConfirm(false)} /> : null}
    </RegularDialog>
  )
}

export default PaymentInfo
