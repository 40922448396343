import React, { memo } from 'react'

import { ProgressMetricsGroup } from '../../../ui-kit'

import { Column, ProgressBlock } from './Progress'
import { ProgressHeader } from './ProgressHeader'

type CategoryProgressListProps = {
  readonly categoryAggregateMetrics: any
}

export const CategoryProgressList = memo(({ categoryAggregateMetrics }: CategoryProgressListProps) => (
  <Column>
    <ProgressHeader tlcAggregateMetrics={categoryAggregateMetrics} />
    <ProgressBlock>
      <ProgressMetricsGroup metrics={categoryAggregateMetrics} />
    </ProgressBlock>
  </Column>
))
