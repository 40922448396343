export const useSuccessMessageQueryParams = (query: string) => {
  const urlParams = new URLSearchParams(query)

  const success = urlParams.get('success')

  return {
    success,
    message: urlParams.get('message'),
    isSucceed: success === 'true',
    hasFailed: success === 'false',
  }
}
