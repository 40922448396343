import * as R from 'ramda'

import { stringToSplitNumbersArray } from './stringToSplitNumbersArray'
import { maxFeet, minFeet } from './validation-constraints'

export const stringToFeetInchesArray = (str?: string | null) => {
  const feetAndInches = stringToSplitNumbersArray(str)
  const feet = R.compose(R.min<number>(maxFeet), R.max<number>(minFeet))(Number(feetAndInches[0]))
  const inches = R.compose(R.min(11 as number), R.max(0 as number))(Number(feetAndInches[1]))
  return !!feetAndInches.length ? ([feet, inches] as const) : str
}
