import { axios } from 'system/axios'
import { Object } from 'ts-toolbelt'

import { PersonalTrainerEntity } from '../entities/PersonalTrainerEntity'
import { PersonalWorkoutEntity } from '../entities/PersonalWorkoutEntity'

type PersonalTrainer = PersonalTrainerEntity & {
  avatar: string | null
  pay_rate: number
}

export type PersonalWorkout = Object.Overwrite<
  PersonalWorkoutEntity & { date: string; duration: number },
  { personal_trainer: PersonalTrainer }
>

export const getPersonalWorkouts = () => axios.get<PersonalWorkout[]>('/personal-session/client')
