import dayjs from 'dayjs'
import { useDialogManager } from 'lib/dialog-manager'
import React, { useEffect, useState, VFC } from 'react'
import { useDispatch, useSelector } from 'store'

import HeaderModal from '../components/HeaderModal'
import { Fade, Section, constrain360, paddingVerticalContainerDefault } from '../components/styled/containers'
import { MembershipTextLink } from '../components/styled/membership'
import { marginTop, paddingTop } from '../components/styled/spacing'
import { InlineLinkA, P } from '../components/styled/typography'
import { hoverOpacityFast } from '../components/styled/utility'
import { H2 } from '../ui-kit'
import { RegularDialog, RegularDialogProps } from '../ui-kit/RegularDialog'
import { marginBottom } from '../ui-kit/utils'
import { formatStartDate, formatValidThroughDate } from '../utils/utils'

import CancelMembership from './CancelMembership'
import { MembershipSetupModal } from './MembershipSetupContainer'
import { getMembership } from '../store/membership'
// import RestoreMembership from './RestoreMembership';
// import UncancelMembership from './UncancelMembership';

const DISPLAY_STATE = {
  /*
   * from MembershipStatus enum in /cloud/api/src/data-mappers/enums.ts
   */
  // payment info captured, but stripe subscription has not been created and no billing attempt has occurred
  future: 'future',
  // the latest_invoice is paid and in good standing
  active: 'active',
  paused: 'paused',
  suspended: 'suspended',
  // the subscription will not be renewed, but is still considered active
  will_expire: 'will_expire',
  // the subscription expired. service has been terminated
  expired: 'expired',

  /*
   * used in this view, but not included in enums.ts
   */
  apiGetMembershipError: 'apiGetMembershipError',
  loading: 'loading',
  none: 'none',
  restored: 'restored',
}

const reactivationMessage = (
  <P>
    Please reach out to <InlineLinkA href="mailto:support@formelife.com">support@formelife.com</InlineLinkA> to
    reactivate it.
  </P>
)

const MembershipStatus: VFC<RegularDialogProps> = (props) => {
  const dispatch = useDispatch()
  const { showDialog, hideCurrentDialog } = useDialogManager()

  const errorMessage = useSelector((state) => state.error.errorMessage)
  const isMembershipStatusKnown = useSelector((state) => state.membership.isMembershipStatusKnown)

  const { membershipProfiles } = useSelector((state) => state.user)
  const { owner, nextBillingDate: valid_through_date, detach_date } = membershipProfiles?.[0] || {}
  const primaryMembership = useSelector((state) => state.membership.primaryMembership)

  const [displayState, setDisplayState] = useState(DISPLAY_STATE.loading)
  const [loading, setLoading] = useState(true)

  // fetch membership on view load (make sure we're up to date)
  useEffect(() => {
    dispatch(getMembership()).finally(() => setLoading(false))
  }, [dispatch])

  // update presentation state
  useEffect(() => {
    if (loading) {
      return
    }

    if (errorMessage) {
      setDisplayState(DISPLAY_STATE.apiGetMembershipError)
      return
    }

    if (!primaryMembership) {
      isMembershipStatusKnown && setDisplayState(DISPLAY_STATE.none)
      return
    }

    setDisplayState(primaryMembership.status)
  }, [errorMessage, isMembershipStatusKnown, loading, primaryMembership, setDisplayState])

  const startDate = primaryMembership?.created_date ? formatStartDate(new Date(primaryMembership.created_date)) : '...'

  const validThroughDate = valid_through_date ? valid_through_date : '...'

  const activationWithinThirtyDays = primaryMembership?.created_date
    ? dayjs(primaryMembership?.created_date).diff(dayjs(), 'day') > -30
    : null

  const expiry_date = detach_date || primaryMembership?.expiry_date

  return (
    <RegularDialog {...props}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Account" onClickControlLeft={hideCurrentDialog} />
      <Fade>
        <div css={[constrain360, paddingVerticalContainerDefault]}>
          <Section css={paddingTop('xs')}>
            <H2 css={[marginBottom(16)]}>Membership</H2>
            {(() => {
              switch (displayState) {
                case DISPLAY_STATE.loading:
                  return <P>Loading...</P>
                case DISPLAY_STATE.none:
                  return (
                    <>
                      <P color="whiteAlpha50">You have no active membership associated with this account.</P>
                      <MembershipTextLink
                        css={[marginTop('sm'), hoverOpacityFast]}
                        onClick={() => showDialog(MembershipSetupModal)}
                      >
                        Set Up Membership
                      </MembershipTextLink>
                    </>
                  )
                case DISPLAY_STATE.future:
                  return (
                    <>
                      <P>Membership will start soon!</P>
                      <P color="whiteAlpha50">Your membership will begin the same day your hardware is installed.</P>
                    </>
                  )
                case DISPLAY_STATE.active:
                  return (
                    <>
                      <P>Member since {startDate}. Thank you!</P>
                      {valid_through_date ? (
                        <P color="whiteAlpha50">Your next billing date will be {validThroughDate}.</P>
                      ) : null}
                      {expiry_date ? (
                        <P>Your account is active until {formatValidThroughDate(new Date(expiry_date))}.</P>
                      ) : null}
                      {owner ? (
                        activationWithinThirtyDays ? (
                          <P color="whiteAlpha50">
                            Contact support to make changes:{' '}
                            <InlineLinkA href="mailto:support@formelife.com">support@formelife.com</InlineLinkA>
                          </P>
                        ) : (
                          <MembershipTextLink
                            onClick={() =>
                              showDialog((dialogProps) => (
                                <CancelMembership
                                  {...dialogProps}
                                  onCancel={() => {
                                    setDisplayState(DISPLAY_STATE.will_expire)
                                  }}
                                />
                              ))
                            }
                          >
                            Cancel membership
                          </MembershipTextLink>
                        )
                      ) : null}
                    </>
                  )
                case DISPLAY_STATE.paused:
                  return (
                    <>
                      <P>Your Membership is paused.</P>
                      {reactivationMessage}
                    </>
                  )
                case DISPLAY_STATE.suspended:
                  return (
                    <>
                      <P>Your Membership is suspended.</P>
                      {reactivationMessage}
                    </>
                  )
                case DISPLAY_STATE.expired:
                  return (
                    <>
                      {valid_through_date ? (
                        <P color="whiteAlpha50">Your membership lapsed on {validThroughDate}.</P>
                      ) : null}
                      {!valid_through_date && (
                        <P color="whiteAlpha50">
                          Sorry, there was a problem when trying to access the membership information.
                        </P>
                      )}
                      {/* TODO: 3/24/23 This functionality needs to be refactored 
                      <MembershipTextLink
                        onClick={() =>
                          showDialog((props) => (
                            <RestoreMembership
                              onRestore={(membership) => {
                                updateMembership(membership);
                                setDisplayState(DISPLAY_STATE.active);
                              }}
                              {...props}
                            />
                          ))
                        }>
                        Restore membership
                      </MembershipTextLink> */}
                    </>
                  )
                case DISPLAY_STATE.will_expire:
                  return (
                    <>
                      <P color="whiteAlpha50">
                        We processed your cancelation request.{' '}
                        {valid_through_date
                          ? `Please enjoy your
                        membership until it ends on ${validThroughDate}.`
                          : ''}
                      </P>
                      {/* TODO: 3/24/23 This functionality needs to be refactored 
                      <MembershipTextLink
                        onClick={() =>
                          showDialog((props) => (
                            <UncancelMembership
                              onRestore={() => {
                                updateMembership({
                                  ...primaryMembership,
                                  status: 'active',
                                } as Membership);
                                setDisplayState(DISPLAY_STATE.active);
                              }}
                              {...props}
                            />
                          ))
                        }>
                        Restore membership
                      </MembershipTextLink> */}
                    </>
                  )
                case DISPLAY_STATE.apiGetMembershipError:
                default:
                  return (
                    <P>
                      Please reach out to{' '}
                      <InlineLinkA href="mailto:support@formelife.com">customer service</InlineLinkA> regarding your
                      account.
                    </P>
                  )
              }
            })()}
          </Section>
        </div>
      </Fade>
    </RegularDialog>
  )
}

export default MembershipStatus
