import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { OwnProfile } from 'api/profile/types/OwnProfile'
import { Object as TSObject } from 'ts-toolbelt'

import { getFromTheLocalStorage, parseToJSON } from '../../utils'
import { formatValidThroughDate } from '../../utils/utils'

import { getCurrentUser, getUserExtended } from './actions'

type UserState = TSObject.Optional<
  TSObject.Overwrite<
    OwnProfile,
    {
      id: string | null
      first_name: string | null
      last_name: string | null
      gender: string | null
      phone: string | null
    }
  >,
  | 'email'
  | 'roles'
  | 'desired_categories'
  | 'emergency_address'
  | 'emergency_contact_first_name'
  | 'emergency_contact_last_name'
  | 'emergency_contact_relationship'
  | 'emergency_contact_phone'
  | 'concierge_id'
  | 'has_lift'
> & {
  configDictionary: unknown
  onboardingData: unknown
  isInitialLogin: boolean
  isRedirectingToSignIn: boolean
  redirectAfterLogoutLocation: string
  userJustCompletedFitnessAssessment: boolean
  userWantsToBeLoggedIn: boolean
}

const emptyState = {
  concierge: null,
  date_of_birth: null,
  // email: null, -- defer to Auth0.user.email
  first_name: null,
  gender: null,
  height: 1,
  id: null,
  image: null,
  last_name: null,
  max_heart_rate: null,
  has_active_membership: false,
  onboarding_fitness_survey_completed: false,
  onboarding_profile_completed: false,
  resting_heart_rate: null,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  vo2_max: null,
  weight: 1,
  zip_code: '',
  membershipProfiles: [],
  require_cc: true,

  configDictionary: {},
  isInitialLogin: false,
  // help sign in & create account error pages redirect Launch -> Auth0
  isRedirectingToSignIn: false,
  onboardingData: {},
  // help reset password flow redirect /reset-email (auth) -> Launch -> /reset-email (unauth)
  redirectAfterLogoutLocation: '',
  userJustCompletedFitnessAssessment: false,
  userWantsToBeLoggedIn: false,

  phone: null,
}

const lsString = getFromTheLocalStorage('userState') || ''
const lsState = parseToJSON(lsString) as UserState

const initialState: UserState = Object.keys(lsState) ? lsState : emptyState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    mergeUserProfile: (state, action) => {
      return { ...state, ...action.payload }
    },
    initiateUserLogin: (state) => {
      state.userWantsToBeLoggedIn = true
      state.isInitialLogin = true
    },
    setUserWantsToBeLoggedIn: (state, action) => {
      state.userWantsToBeLoggedIn = action.payload
    },
    clearUserData: () => {
      return { ...emptyState }
    },
    updateOwnerBillingDate: (state, action) => {
      if (state.membershipProfiles?.[0]) {
        state.membershipProfiles[0].nextBillingDate = action.payload
      }
    },
    updateImage: (state, action: PayloadAction<string | null>) => {
      state.image = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      return { ...state, ...action.payload }
    })

    builder.addCase(getUserExtended.fulfilled, (state, action) => {
      const { nextBillingDate } = action.payload

      if (state.membershipProfiles?.[0]) {
        state.membershipProfiles[0].nextBillingDate = formatValidThroughDate(new Date(nextBillingDate))
      }
    })
  },
})

export const {
  mergeUserProfile,
  initiateUserLogin,
  setUserWantsToBeLoggedIn,
  clearUserData,
  updateOwnerBillingDate,
  updateImage,
} = userSlice.actions
