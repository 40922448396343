import { Auth0Provider } from '@auth0/auth0-react'
import { Auth0ProviderOptions } from '@auth0/auth0-react/dist/auth0-provider'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactQueryProvider } from 'system/react-query'

import App from './App'
import { DeepLinksProvider } from './hooks'
import { ThemeProvider } from './model'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import history from './utils/history'

// Use the router's history module to replace the url
const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/
Sentry.init({
  dsn: 'https://e6480e41be2c44bc9b25d3a1a098aaeb@o613991.ingest.sentry.io/5749537',
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENV,
  sampleRate: process.env.REACT_APP_SENTRY_ENV === 'localhost' ? 0.0 : 1.0,
  tracesSampleRate: process.env.REACT_APP_SENTRY_ENV === 'production' ? 0.1 : 1.0,
})

// eslint-disable-next-line no-console
console.log(
  `%cApp Version: %c${process.env.REACT_APP_VERSION}`,
  'font-weight: bold; font-size: 18px;',
  'font-size: 16px;',
)

// @TODO return ThemeToggleProvider or implement this logic inside ThemeProvider`
ReactDOM.render(
  <Auth0Provider
    cacheLocation="localstorage"
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    onRedirectCallback={onRedirectCallback}
    redirectUri={window.location.origin}
    useCookiesForTransactions
    useRefreshTokens
  >
    <ReactQueryProvider>
      <Provider store={store}>
        <DeepLinksProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </DeepLinksProvider>
      </Provider>
    </ReactQueryProvider>
  </Auth0Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
