import { createAsyncThunk } from '@reduxjs/toolkit'
import { axios } from 'system/axios'
import { OnboardingResults, Survey, SurveyResults } from './types'

const activationSurveyId = '00000000-0000-0000-0000-100000000012'
const surveyName = 'account_activation'

export const setSurveyResults = createAsyncThunk('setSurveyResults', async (results: OnboardingResults) => {
  const response = await axios.post<SurveyResults>(`/user/me/survey/${activationSurveyId}/results`, results)

  return response.data.results
})

export const getSurveyState = createAsyncThunk('getSurvey', async () => {
  const responses = await Promise.all([
    axios.get<SurveyResults>(`/user/me/survey/${activationSurveyId}/results/latest`),
    axios.get<Survey>(`/survey?name=${surveyName}`),
  ])

  return {
    results: (responses[0].data as SurveyResults).results,
    steps: (responses[1].data as Survey).steps,
  }
})
