import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const position = (pos: CSSProperties['position']) =>
  css({
    position: pos,
  })

export const positionRelative = position('relative')

export const positionAbsolute = position('absolute')

export const top = (t: CSSProperties['top']) =>
  css({
    top: t,
  })

export const top0 = top(0)

export const left = (l: CSSProperties['left']) =>
  css({
    left: l,
  })

export const left0 = left(0)

export const zIndex = (idx: CSSProperties['zIndex']) =>
  css({
    zIndex: idx,
  })
