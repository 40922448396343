import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const noMargin = ({ isImportant }: { isImportant?: boolean } = {}) =>
  css({
    margin: `0 ${isImportant ? ' !important' : ''}`,
  })

export const margin = (marg: CSSProperties['margin']) =>
  css({
    margin: marg,
  })

export const marginRight = (margR: CSSProperties['marginRight']) =>
  css({
    marginRight: margR,
  })

export const marginLeft = (margL: CSSProperties['marginLeft']) =>
  css({
    marginLeft: margL,
  })

export const marginBottom = (margB: CSSProperties['marginBottom']) =>
  css({
    marginBottom: margB,
  })

export const marginTop = (margT: CSSProperties['marginTop']) =>
  css({
    marginTop: margT,
  })
