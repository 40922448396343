import { Theme } from '@emotion/react'
import React, { VFC } from 'react'

import { IconProps } from './types'

const ArrowLeft: VFC<IconProps & { readonly theme: Theme }> = ({ theme, ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10 8L4 14L10 20" stroke={theme.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 14H5" stroke={theme.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ArrowLeft
