import styled from '@emotion/styled'

import { Gear, Pencil } from '../../../icons'
import { useProfileHeaderPresenter } from '../../../presenter'
import { PureButton, Body3, Avatar } from '../../../ui-kit'
import { marginBottom, size, textOverflowEllipsis } from '../../../ui-kit/utils'

import { InfoContainer, InfoButton, InfoButtonText } from './Info'
import { ProfileName } from './Name'
import { ComponentProps } from 'react'

const HeaderContainer = styled.header({
  display: 'flex',
  marginBottom: 38,
})

const PhotoButton = styled(PureButton)(
  {
    borderRadius: '50%',
    overflow: 'hidden',
  },
  size(104),
)

export const ProfileHeader = (props: ComponentProps<typeof HeaderContainer>) => {
  const { firstName, lastName, email, image, onProfileClick, onSettingsClick, onAvatarClick, onAvatarError } =
    useProfileHeaderPresenter()

  return (
    <HeaderContainer {...props}>
      <InfoContainer>
        <ProfileName>{`${firstName} ${lastName}`}</ProfileName>
        <Body3 css={[marginBottom(12), textOverflowEllipsis]}>{email}</Body3>
        <div>
          <InfoButton className="testing-profile-link" onClick={onProfileClick}>
            <Pencil />
            <InfoButtonText>Profile</InfoButtonText>
          </InfoButton>
          <InfoButton className="testing-profile-link" onClick={onSettingsClick}>
            <Gear />
            <InfoButtonText>Settings</InfoButtonText>
          </InfoButton>
        </div>
      </InfoContainer>
      <PhotoButton className="testing-profile-photo-button" onClick={onAvatarClick}>
        <Avatar
          firstName={firstName ?? undefined}
          lastName={lastName ?? undefined}
          image={image ?? undefined}
          onError={onAvatarError}
        />
      </PhotoButton>
    </HeaderContainer>
  )
}
