import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'store'
import { hasCompletedOnboardingSelector } from 'store/user'

import { PageHeader } from '../../components/PageHeader'
import { VerticalContainer } from '../../components/VerticalContainer'
import { bp } from '../../components/styled/breakpoints'
import { Fade } from '../../components/styled/containers'
import { paddingTop } from '../../components/styled/spacing'
import { P } from '../../components/styled/typography'
import { blockAndDim, marginBottom, textAlignCenter } from '../../components/styled/utility'
import { routing } from '../../model/Routing/routing'
import { NextPrevButton } from '../../ui-kit'
import history from '../../utils/history'
import CollectCreditCard from '../CollectCreditCard'
import { setPaymentMethod } from '../../store/billing'

export const AddCardStep = () => {
  const dispatch = useDispatch()

  const hasCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)

  const paymentMethod = useSelector((state) => state.billing.paymentMethod)

  const [isUpdating, setIsUpdating] = useState(false)

  const onPaymentMethodCaptured = async (paymentMethodId: string) => {
    if (paymentMethod?.id === paymentMethodId) return
    setIsUpdating(true)
    try {
      await dispatch(setPaymentMethod(paymentMethodId))

      const route = hasCompletedOnboarding ? routing.home : routing.onboarding.feedAlgorithm
      history.push(route.generatePath())
    } catch (e) {
      setIsUpdating(false)
    }
  }

  const [isUserGuest, setIsUserGuest] = useState(false)
  const [enableSkipOption, setEnableSkipOption] = useState(false)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (user?.membershipProfiles) {
      const guest = user?.membershipProfiles?.filter((profile) => user?.id === profile.userId && !profile?.owner)
      setIsUserGuest(guest.length > 0)
      setEnableSkipOption(isUserGuest || !user?.require_cc || false)
    }
  }, [isUserGuest, user?.id, user?.membershipProfiles, user?.require_cc])

  return (
    <Fade css={[blockAndDim(isUpdating)]}>
      <VerticalContainer
        css={{
          padding: '110px 20px 36px',
          maxWidth: 420,
          [bp.sm]: {
            paddingTop: 85,
          },
        }}
      >
        <div css={[textAlignCenter, marginBottom(264)]}>
          <PageHeader withBackButton={!hasCompletedOnboarding} withSkipButton={enableSkipOption}>
            ACTIVATE ACCOUNT
          </PageHeader>
          <Fade>
            <P>
              In order to complete your account setup we require a credit card on file.{' '}
              <b>Your credit card will not be charged</b> until you have signed up for a membership or purchased FORME
              products.
            </P>
            <div css={[css({ textAlign: 'initial' })]}>
              <CollectCreditCard
                css={paddingTop('lg')}
                onPaymentMethodCaptured={onPaymentMethodCaptured}
                submitButtonText="Activate account"
                CustomSubmitButton={({ onSubmit }) => (
                  <NextPrevButton css={{ marginTop: 30 }} onClick={onSubmit} fullWidth large text="ACTIVATE ACCOUNT" />
                )}
              />
            </div>
          </Fade>
        </div>
      </VerticalContainer>
    </Fade>
  )
}
