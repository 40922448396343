import React, { VFC } from 'react'

import { IconProps } from './types'

const Checkmark: VFC<IconProps & { readonly color?: string; readonly width?: number }> = ({
  color,
  width,
  ...props
}) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    css={{
      width: width ? width : 15,
    }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="1.41421"
      y1="5"
      x2="5"
      y2="8.58579"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="13"
      y1="1.41421"
      x2="5.41421"
      y2="9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

// Leave SVG for dark theme
// eslint-disable-next-line import/no-unused-modules
export default Checkmark
