import { lockBody, releaseBody } from 'lib/body-lock'
import React, { useState, useContext, createContext, useMemo, useCallback, FC } from 'react'

const NavigationContext = createContext({
  isNavOpen: false,
  showNavigation: () => {},
  hideNavigation: () => {},
})

export const useNavigationContext = () => useContext(NavigationContext)

export const NavigationProvider: FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const showNavigation = useCallback(() => {
    lockBody()
    setIsNavOpen(true)
  }, [setIsNavOpen])

  const hideNavigation = useCallback(() => {
    releaseBody()
    setIsNavOpen(false)
  }, [setIsNavOpen])

  const contextValue = useMemo(
    () => ({
      isNavOpen,
      showNavigation,
      hideNavigation,
    }),
    [showNavigation, hideNavigation, isNavOpen],
  )

  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>
}
