import { createSlice } from '@reduxjs/toolkit'

type State = {
  errorMessage: Error | string | null
}

const initialState: State = {
  errorMessage: null,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    updateError: (state, action) => {
      state.errorMessage = action.payload
    },
    resetError: (state) => {
      state.errorMessage = null
    },
  },
})

export const { updateError, resetError } = errorSlice.actions
