import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearUserData } from 'store/user'

import { TIMING } from '../../constants'
import { useSuccessMessageQueryParams } from '../../hooks'
import { routing } from '../../model/Routing/routing'

const FAILURE_MESSAGE = 'Please sign in again and tap "resend email" to get a new verification link.'

export const useVerifyEmailPresenter = () => {
  const dispatch = useDispatch()
  const [isFadingOut, setIsFadingOut] = useState(false)
  const {
    location: { search },
    push,
  } = useHistory()
  const { message: searchMessage, isSucceed, hasFailed } = useSuccessMessageQueryParams(search)

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  const title = isSucceed ? 'Your account has been verified' : 'Verification email link has expired'

  useEffect(() => {
    if (!isSucceed && !hasFailed) {
      push(routing.home.generatePath())
    }
  }, [push, isSucceed, hasFailed])

  const message = isSucceed ? searchMessage : FAILURE_MESSAGE

  const onClick = () => {
    if (isAuthenticated && isSucceed) {
      setIsFadingOut(true)
      setTimeout(() => {
        loginWithRedirect({ fragment: 'signIn' })
      }, TIMING.medium)
    } else {
      dispatch(clearUserData())
      logout({ returnTo: window.location.origin })
    }
  }

  return {
    isIn: !isFadingOut,
    onClick,
    message,
    title,
  }
}
