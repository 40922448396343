import { css } from '@emotion/react'
import dayjs from 'dayjs'
import dayjsTimezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import mixpanel from 'mixpanel-browser'
import * as R from 'ramda'
import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'store'

import AppContainer from '../../components/AppContainer'
import { bp } from '../../components/styled/breakpoints'
import { Fade, constrainMobile360 } from '../../components/styled/containers'
import { paddingVertical } from '../../components/styled/spacing'
import { P } from '../../components/styled/typography'
import { overflowHidden, textAlignCenter } from '../../components/styled/utility'
import { useModalLaunching, useUISetPageTitle } from '../../hooks'
import { usePCIContext } from '../../model'
import { useOwnHistoryWorkoutsQuery } from '../../queries/useOwnHistoryWorkoutsQuery'
import { usePersonalWorkoutsQuery } from '../../queries/usePersonalWorkoutsQuery'
import { Calendar } from '../../ui-kit'
import { simplifyDate } from '../../ui-kit/Calendar/utils/simplifyDate'
import {
  CATEGORY_MAPPER,
  categoryToTopLevelCategoryPlusCustoms,
  keyCalendarData,
  keyCalendarDataFinished,
  keyCalendarDataPlanned,
} from '../../ui-kit/Calendar/utils/utils'
import { SmoothTabs } from '../../ui-kit/Tabs/SmoothTabs'
import { marginBottom, margin } from '../../ui-kit/utils'
import {
  finishedLastMonth,
  finishedLastMonthUpToToday,
  finishedThisMonth,
  msToMinutes,
  reduceCalendarDataToCategoryElapsedMin,
  reduceCalendarDataToTLCElapsedMin,
  reduceCalendarDataToTLCLastMonth,
} from '../../utils/utils'

import { CategoryProgressList } from './components/CategoryProgressList'
import { ClassesList } from './components/ClassesList'
import { ProfileHeader } from './components/Header'
import { Overview } from './components/Overview'
import { Progress } from './components/Progress'
import { TLCProgressList } from './components/TLCProgressList'
import { SessionType } from './session_type_enum'
// import { mockOwnHistoryWorkouts, mockPersonalSessions } from './mock'

//TODO-EB: Move such initializations to a bootstrap file
dayjs.extend(dayjsTimezone)
dayjs.extend(utc)

const fadeCss = css([
  constrainMobile360,
  margin('0 auto'),
  overflowHidden,

  {
    padding: '32px 16px',

    [bp.sm]: {
      paddingTop: 44,
      maxWidth: 814,
    },
  },
])

const tabsCss = css([
  marginBottom(40),
  {
    height: 50,

    [bp.sm]: {
      height: 62,
    },
  },
])

const convertCalendarDataToEntry = ({ keyedInstructors, profile }: any, calendarDataEntry: any) => {
  const isCustomSession = Boolean(calendarDataEntry.session_type === SessionType['Planned Session'])
  const isLiveSession = Boolean(calendarDataEntry.session_type === SessionType['Personal Training'])
  const isSoloSession = Boolean(calendarDataEntry.session_type === SessionType['Free Forme'])

  const trainer = (() => {
    const { trainer: pwTrainer } = calendarDataEntry.plan_workout ?? {}
    if (isLiveSession) {
      return calendarDataEntry?.personal_workout?.personal_trainer
    } else if (calendarDataEntry?.plan_workout?.owner) {
      return calendarDataEntry?.plan_workout?.owner
    }

    // TODO-EB: As it was investigated, we currently search a trainer in "keyedInstructors"
    //  to only be able to get the "trainer.face_image" property, which is currently not contained
    //  in "trainer" properties of the `/user/${userId}/history-workout` endpoint response.
    //  We need to make the history endpoint return us "face_image" property
    //  and then we'll be able to completely get rid of the "keyedInstructors" property in this component
    return keyedInstructors[pwTrainer?.id] || pwTrainer
  })()

  const trainerImage = trainer?.face_image?.cdn_url || trainer?.avatar || trainer?.image

  const name = calendarDataEntry?.plan_workout?.name
  const workoutCategory = calendarDataEntry?.plan_workout?.category

  return {
    name: isSoloSession ? 'Just Lift' : name,
    personalWorkoutId: calendarDataEntry.personal_workout_id,
    instructorFirstName: trainer?.first_name,
    instructorLastName: trainer?.last_name,
    metrics: {
      elapsed_time: calendarDataEntry.elapsed_time,
      calories_used: calendarDataEntry.calories_used,
      weight_lifted: calendarDataEntry.weight_lifted,
    },
    status: calendarDataEntry.status,
    to: null,
    topLevelCategory: isCustomSession
      ? CATEGORY_MAPPER['custom session']
      : categoryToTopLevelCategoryPlusCustoms(workoutCategory),
    type: isCustomSession
      ? CATEGORY_MAPPER['custom session']
      : isLiveSession
      ? CATEGORY_MAPPER.live
      : CATEGORY_MAPPER.active,
    profileFirstName: profile.first_name,
    avatars: [
      (isLiveSession || isSoloSession) && {
        image: profile.image,
        firstName: profile.first_name,
        lastName: profile.last_name,
      },
      !isSoloSession && {
        image: trainerImage,
        firstName: trainer?.first_name,
        lastName: trainer?.last_name,
      },
    ].filter(Boolean),
    startTime: calendarDataEntry.start_time,
  }
}

// remove '0 min' entries
// remove not completed
const sessionValidityFilter = ({
  workout_status,
  elapsed_time,
}: {
  workout_status?: string | null
  elapsed_time?: number | string | null
}) => elapsed_time && workout_status && msToMinutes(elapsed_time) > 0 && workout_status.toLowerCase() === 'completed'

export const Profile = memo(() => {
  const { keyedInstructors } = usePCIContext()!

  // uncomment for mock data
  // const personalSessions = mockPersonalSessions
  const { data: personalSessions } = usePersonalWorkoutsQuery()

  const keyedPersonalSessions = personalSessions ? R.indexBy(R.prop('id'), personalSessions.data) : undefined

  useEffect(() => {
    mixpanel.track('profile')
    mixpanel.track('overview')
  }, [])

  useUISetPageTitle('Profile')

  const profile = useSelector((state) => state.user)
  const { timezone } = profile

  const [activeMonth, setActiveMonth] = useState(dayjs().month())
  const [activeYear, setActiveYear] = useState(dayjs().year())

  const date = dayjs()
    .tz(timezone)
    .year(activeYear)
    .month(activeMonth)
    .date(1)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)

  const startDate = date.subtract(1, 'months').toISOString()
  const endDate = date.add(1, 'months').toISOString()

  // uncomment for mock data
  // const responseData = mockOwnHistoryWorkouts
  // const isLoading = false
  const { data: { data: responseData = { data: [] } } = {}, isLoading } = useOwnHistoryWorkoutsQuery([
    startDate,
    endDate,
  ])

  const keyedCalendarData = keyCalendarData(responseData.data, timezone)

  const calendarData = responseData.data

  const keyedCalendarDataFinished = keyCalendarDataFinished(responseData.data, timezone)

  const keyedCalendarDataPlanned = keyCalendarDataPlanned(responseData.data, timezone)

  const [selectedDate, setSelectedDate] = useState<any>()
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null)
  const [selectedYear, setSelectedYear] = useState<number | null>(null)

  const enhancedCalendarData = calendarData.map((calendarDataEntry) => ({
    ...calendarDataEntry,
    workout_category: calendarDataEntry.plan_workout?.category,
  }))

  const convertCalendarDataToEntryBound = convertCalendarDataToEntry.bind(null, {
    keyedInstructors,
    profile,
  })

  let formattedDate, selectedClasses

  if (selectedDate && selectedMonth) {
    formattedDate = `${selectedYear}-${dayjs().month(selectedMonth).format('MM')}-${dayjs()
      .date(selectedDate)
      .format('DD')}`

    selectedClasses = keyedCalendarData[formattedDate]
      ?.filter(sessionValidityFilter)
      .map(convertCalendarDataToEntryBound)
      /*
          { 'YEAR-MONTH-DAY': data } e.g.: { '2022-07-15': {...} }
        */
      .reduce((aggr: Record<string, any[]>, data) => {
        const formatted = simplifyDate(data.startTime, timezone)
        aggr[formatted] = aggr[formatted] || []
        aggr[formatted]!.push(data)
        return aggr
      }, {})
  }

  const activeFullDate = dayjs().set('year', activeYear).set('month', activeMonth)

  /*
      { 'THIS_YEAR-THIS_MONTH-ANY_DAY': data } e.g.: { '2022-07-15': {...} }
    */
  const allClassesForThisMonthObj: Record<string, any[]> = R.pickBy(
    (val, key) => key.startsWith(activeFullDate.format('YYYY-MM')),
    keyedCalendarData,
  )

  const allClassesForThisMonthFiltered = Object.entries(allClassesForThisMonthObj).reduce(
    (aggr, [value, classSessions]) => {
      const validFilteredSessions = classSessions.filter(sessionValidityFilter).map(convertCalendarDataToEntryBound)

      if (validFilteredSessions.length) {
        aggr[value] = validFilteredSessions
      }

      return aggr
    },
    {} as Record<string, any>,
  )
  const isThereValidClassesForThisMonth = R.not(R.isEmpty(allClassesForThisMonthFiltered))

  useModalLaunching()

  /*
   * Data for Progress
   */
  const tlcThisMonthMetrics = enhancedCalendarData
    .filter(finishedThisMonth)
    .reduce(reduceCalendarDataToTLCElapsedMin, {})
  const tlcLastMonthMetrics = enhancedCalendarData
    .filter(finishedLastMonth)
    .reduce(reduceCalendarDataToTLCLastMonth, {})
  const categoryThisMonthMetrics = enhancedCalendarData
    .filter(finishedThisMonth)
    .reduce(reduceCalendarDataToCategoryElapsedMin, {})
  const categoryLastMonthMetrics = enhancedCalendarData
    .filter(finishedLastMonth)
    .reduce(reduceCalendarDataToCategoryElapsedMin, {})
  const categoryThisTimeLastMonth = enhancedCalendarData
    .filter(finishedLastMonthUpToToday)
    .reduce(reduceCalendarDataToCategoryElapsedMin, {})

  // tlc = Top Level Category: 'active' | 'recovery' | 'mind'
  const tlcAggregateMetrics = [
    {
      heading: 'Active',
      thisMonth: tlcThisMonthMetrics['Active'] || 0,
      lastMonth: tlcLastMonthMetrics['Active']?.elapsedMin || 0,
      thisTimeLastMonth: tlcLastMonthMetrics['Active']?.thisTimeLastMonth || 0,
      color: 'orange',
    },
    {
      heading: 'Recovery',
      thisMonth: tlcThisMonthMetrics['Recovery'] || 0,
      lastMonth: tlcLastMonthMetrics['Recovery']?.elapsedMin || 0,
      thisTimeLastMonth: tlcLastMonthMetrics['Recovery']?.thisTimeLastMonth || 0,
      color: 'green',
    },
    {
      heading: 'Mindful',
      thisMonth: tlcThisMonthMetrics['Mind'] || 0,
      lastMonth: tlcLastMonthMetrics['Mind']?.elapsedMin || 0,
      thisTimeLastMonth: tlcLastMonthMetrics['Mind']?.thisTimeLastMonth || 0,
      color: 'magenta',
    },
  ].filter(({ thisMonth, lastMonth }) => !!thisMonth || !!lastMonth)

  const categoryAggregateMetrics = R.compose(
    R.values,
    R.mapObjIndexed(({ thisMonth = 0, lastMonth = 0, thisTimeLastMonth = 0 }, key) => ({
      color: 'progress',
      heading: key,
      lastMonth,
      thisMonth,
      thisTimeLastMonth,
    })),
  )(
    R.reduce(R.mergeDeepLeft, {} as any, [
      R.map<any, any[]>((x) => ({ thisMonth: x }), categoryThisMonthMetrics),
      R.map<any, any[]>((x) => ({ lastMonth: x }), categoryLastMonthMetrics),
      R.map<any, any[]>((x) => ({ thisTimeLastMonth: x }), categoryThisTimeLastMonth),
    ]),
  )

  return (
    <AppContainer haveSandBackground excludeHeader>
      <Fade css={[fadeCss]}>
        <ProfileHeader />
        <SmoothTabs
          styles={{ header: tabsCss }}
          onChange={(tabKey) => mixpanel.track(tabKey)}
          tabs={[
            {
              key: 'overview',
              title: 'Overview',
              content: (
                <Overview>
                  <Calendar
                    css={marginBottom(30)}
                    activeMonth={activeMonth}
                    activeYear={activeYear}
                    keyedCalendarDataFinished={keyedCalendarDataFinished}
                    keyedCalendarDataPlanned={keyedCalendarDataPlanned}
                    onClickDate={(_date: any) => {
                      if (selectedDate === _date && selectedMonth === activeMonth && selectedYear === activeYear) {
                        setSelectedDate(null)
                        setSelectedMonth(null)
                        setSelectedYear(null)
                      } else {
                        setSelectedDate(_date)
                        setSelectedMonth(activeMonth)
                        setSelectedYear(activeYear)
                      }
                    }}
                    onClickLeft={() => {
                      const prevMonth = activeMonth - 1
                      prevMonth < 0 && setActiveYear(activeYear - 1)
                      setActiveMonth(prevMonth < 0 ? 11 : prevMonth)
                      setSelectedDate(null)
                    }}
                    onClickRight={() => {
                      const nextMonth = activeMonth + 1
                      nextMonth > 11 && setActiveYear(activeYear + 1)
                      setActiveMonth(nextMonth > 11 ? 0 : nextMonth)
                      setSelectedDate(null)
                    }}
                    selectedDate={selectedDate}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                  />
                  {isLoading ? (
                    <P css={[textAlignCenter, paddingVertical('md')]}>Loading...</P>
                  ) : (
                    <ClassesList
                      selectedDate={selectedDate}
                      activeMonth={activeMonth}
                      activeYear={activeYear}
                      formattedDate={formattedDate}
                      selectedClasses={selectedClasses}
                      keyedPersonalSessions={keyedPersonalSessions ?? {}}
                      allClassesForThisMonth={allClassesForThisMonthFiltered}
                      isThereValidClassesForThisMonth={isThereValidClassesForThisMonth}
                    />
                  )}
                </Overview>
              ),
            },
            {
              key: 'progress',
              title: 'Progress',
              content: (
                <Progress>
                  {isLoading ? (
                    <P css={[textAlignCenter, paddingVertical('md')]} style={{ flex: 1 }}>
                      Loading...
                    </P>
                  ) : (
                    <>
                      <TLCProgressList tlcAggregateMetrics={tlcAggregateMetrics} />
                      <CategoryProgressList categoryAggregateMetrics={categoryAggregateMetrics} />
                    </>
                  )}
                </Progress>
              ),
            },
          ]}
        />
      </Fade>
    </AppContainer>
  )
})
