import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const noPadding = ({ isImportant }: { isImportant?: boolean } = {}) =>
  css({
    padding: `0 ${isImportant ? ' !important' : ''}`,
  })

export const padding = (pad: CSSProperties['padding']) =>
  css({
    padding: pad,
  })

export const paddingBottom = (padB: CSSProperties['paddingBottom']) =>
  css({
    paddingBottom: padB,
  })

export const paddingTop = (padT: CSSProperties['paddingTop']) =>
  css({
    paddingTop: padT,
  })

export const paddingRight = (padR: CSSProperties['paddingRight']) =>
  css({
    paddingRight: padR,
  })

export const paddingLeft = (padL: CSSProperties['paddingLeft']) =>
  css({
    paddingLeft: padL,
  })
