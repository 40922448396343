import { deepLinks } from '../../hooks'

import { routing } from './routing'

export const emailLanderPaths = [routing.resetEmail.generatePath(), routing.verifyEmail.generatePath()]

export const authenticatedRedirects = [
  {
    matchPath: [...Object.values(deepLinks), '/profile/membership-setup'],
    redirectPath: routing.profile.membership.generatePath(),
  },
  {
    matchPath: '/up-next',
    redirectPath: routing.home.generatePath(),
  },
  {
    matchPath: '/reset-password',
    redirectPath: routing.profile.resetPassword.generatePath(),
  },
  {
    matchPath: '/payment',
    redirectPath: routing.profile.paymentInfo.generatePath(),
  },
  {
    matchPath: '/settings',
    redirectPath: routing.profile.settings.generatePath(),
  },
  {
    matchPath: '/payment-info',
    redirectPath: routing.profile.paymentInfo.generatePath(),
  },
  {
    matchPath: '/profiles',
    redirectPath: routing.profile.profiles.generatePath(),
  },
]

export const onboardingRedirects = [
  {
    matchPath: [...Object.values(deepLinks), '/onboarding/activate', '/onboarding/membership-setup'],
    redirectPath: routing.onboarding.membership.generatePath(),
  },
]
