import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const aspectRation1 = css({
  aspectRatio: '1',
})

export const objectFit = (of: CSSProperties['objectFit']) =>
  css({
    objectFit: of,
  })

export const objectFitCover = objectFit('cover')
