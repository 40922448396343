import { axios } from 'system/axios'

export type Profile = {
  id: string
  role: 'owner' | 'admin' | 'guest'
  userId: string
}

export const getFamilyPlanProfiles = (membershipId: string) =>
  axios.get<Profile[]>(`v2/membership/${membershipId}/profiles`, {
    baseURL: process.env.REACT_APP_API_URL,
    params: { join: ['users'] },
  })
