import React, { VFC } from 'react'

import { IconProps } from './types'

const X: VFC<IconProps & { readonly color?: string }> = ({ color = 'sand', ...props }) => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    css={{
      width: 12,
      height: 12,
    }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.89866 0.292893C1.50814 -0.0976311 0.874975 -0.0976311 0.484451 0.292893C0.0939264 0.683417 0.0939264 1.31658 0.484451 1.70711L2.86355 4.08621L0.292893 6.65686C-0.0976313 7.04739 -0.0976309 7.68055 0.292893 8.07108C0.683418 8.4616 1.31658 8.4616 1.70711 8.07108L4.27776 5.50042L6.84841 8.07107C7.23894 8.46159 7.8721 8.46159 8.26262 8.07107C8.65315 7.68054 8.65315 7.04738 8.26262 6.65685L5.69198 4.08621L8.07107 1.70711C8.46159 1.31659 8.46159 0.683425 8.07107 0.292901C7.68054 -0.0976234 7.04738 -0.0976235 6.65685 0.292901L4.27776 2.67199L1.89866 0.292893Z"
      fill={color}
    />
  </svg>
)

export default X
