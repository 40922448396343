import { useTheme } from '@emotion/react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { ChangeEvent, FC, ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import countryList from 'react-select-country-list'
import { useSelector } from 'store'
import { hasCompletedOnboardingSelector } from 'store/user'

import { Button, ButtonSection, ButtonSolid, SubButtonTextLink } from '../components/styled/button'
import { FadeIn } from '../components/styled/containers'
import { FormCustomSelect, FormInput, ValidationContainer } from '../components/styled/form'
import { MembershipSectionText } from '../components/styled/membership'
import { blockAndDim } from '../components/styled/utility'
import { routing } from '../model/Routing/routing'
import { InputLabel, InputContainer, Button3 } from '../ui-kit'
import history from '../utils/history'
import usStates from '../utils/us-states.json'
import { conformValue, validate } from '../utils/utils'
import { ValidationRequirement } from '../utils/various/validation-constraints'

type SetupIntentFormProps = {
  readonly optionalMarkupAboveButton?: ReactNode
  readonly submitButtonText?: string
  readonly className?: string
  readonly withFullAddress?: boolean
  readonly CustomSubmitButton?: FC<{ onSubmit: () => void }>
  readonly onCollectingCardInfo: () => void
  readonly onPaymentMethodIdChange: (id: string) => void
}

type FormState = Record<
  string,
  {
    val?: string
    validationRequirement?: ValidationRequirement
    validationMessage?: string
    isSelectDropdownShowing?: boolean
    options?: string[]
  }
>

export function SetupIntentForm({
  optionalMarkupAboveButton,
  submitButtonText,
  className,
  withFullAddress,
  CustomSubmitButton,
  onCollectingCardInfo,
  onPaymentMethodIdChange,
}: SetupIntentFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const theme = useTheme()
  const email = useSelector((state) => state.user.email)
  const hasCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const setupIntentClientSecret = useSelector((state) => state.billing.setupIntentClientSecret)

  const [stripeIsBusy, setStripeIsBusy] = useState(false)
  const countries = useMemo(() => countryList().getData(), [])
  // NOTE: A lot of the following was cloned from form.js,
  // I'm trying to recreate the style/behaviors we use for native (non-stripe) form inputs

  const [forceAllNotPristine, setForceAllNotPristine] = useState(false)
  const [formState, setFormState] = useState<FormState>({
    billing_name: {
      val: '',
      validationRequirement: 'non-empty-alpha',
      validationMessage: `please enter the cardholder's name`,
    },
    ...(withFullAddress
      ? {
          addressLine1: {
            val: '',
            validationMessage: 'this field should not be empty',
          },
          addressLine2: {
            val: '',
            validationRequirement: 'optional',
          },
          city: {
            val: '',
            validationMessage: 'this field should not be empty',
          },
          state: {
            isSelectDropdownShowing: false,
            validationMessage: 'this field should not be empty',
          },
        }
      : {}),
    postal_code: {
      val: '',
      validationRequirement: 'postal-code',
      validationMessage: 'please enter your postal code',
    },
    country: {
      val: 'US',
      isSelectDropdownShowing: false,
      validationMessage: 'please enter your country',
    },
  })

  const handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value
    setFormState({
      ...formState,
      [evt.target.name]: {
        ...formState[evt.target.name],
        val: conformValue(formState[evt.target.name]?.validationRequirement, value)!,
      },
    })
  }

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value
    setFormState({
      ...formState,
      [evt.target.name]: {
        ...formState[evt.target.name],
        val: value,
      },
    })
  }

  const handleStateDropdownSelect = () =>
    setFormState((form) => ({
      ...form,
      state: {
        ...form.state,
        isSelectDropdownShowing: !form.state?.isSelectDropdownShowing,
      },
    }))

  const handleStateDropdownChange = (_: string, newDropdownValue: string) =>
    setFormState((form) => ({
      ...form,
      state: {
        ...form.state,
        isSelectDropdownShowing: false,
        val: usStates.find(({ name }) => name === newDropdownValue)!.abbreviation,
      },
    }))

  const handleCountryDropdownSelect = () =>
    setFormState((form) => ({
      ...form,
      country: {
        ...form.country,
        isSelectDropdownShowing: !form.country?.isSelectDropdownShowing,
      },
    }))

  const handleCountryDropdownChange = (_: string, newDropdownValue: string) => {
    setFormState((form) => ({
      ...form,
      country: {
        ...form.country,
        isSelectDropdownShowing: false,
        val: countries.find(({ label }) => label === newDropdownValue)!.value,
      },
    }))
  }

  const [isValidCC, setIsValidCC] = useState(true)
  const [isPristineCC, setIsPristineCC] = useState(true)
  const [validationMessageCC, setValidationMessageCC] = useState('enter a valid credit card number')
  const isInvalidCCShowing = (forceAllNotPristine || !isPristineCC) && !isValidCC

  const [isValidExp, setIsValidExp] = useState(true)
  const [isPristineExp, setIsPristineExp] = useState(true)
  const [validationMessageExp, setValidationMessageExp] = useState('enter a valid expiration date')
  const isInvalidExpShowing = (forceAllNotPristine || !isPristineExp) && !isValidExp

  const [isValidCVV, setIsValidCVV] = useState(true)
  const [isPristineCVV, setIsPristineCVV] = useState(true)
  const [validationMessageCVV, setValidationMessageCVV] = useState('enter a valid security number')
  const isInvalidCVVShowing = (forceAllNotPristine || !isPristineCVV) && !isValidCVV

  const [stripeError, setStripeError] = useState<string | boolean | undefined>(false)

  // form validation
  useEffect(() => {
    let isMounted = true

    if (!isMounted) {
      return
    }

    if (!stripe || !elements) {
      return
    }

    const cardNumberElement = elements.getElement('cardNumber')
    cardNumberElement!.on('change', function (event) {
      setIsPristineCC(false)
      if (event.complete) {
        setIsValidCC(true)
      } else if (event.error) {
        setIsValidCC(false)
        setValidationMessageCC(event.error.message)
      }
    })

    const cardExpElement = elements.getElement('cardExpiry')
    cardExpElement!.on('change', function (event) {
      setIsPristineExp(false)
      if (event.complete) {
        setIsValidExp(true)
      } else if (event.error) {
        setIsValidExp(false)
        setValidationMessageExp(event.error.message)
      }
    })

    const cardCvcElement = elements.getElement('cardCvc')
    cardCvcElement!.on('change', function (event) {
      setIsPristineCVV(false)
      if (event.complete) {
        setIsValidCVV(true)
      } else if (event.error) {
        setIsValidCVV(false)
        setValidationMessageCVV(event.error.message)
      }
    })

    return () => {
      isMounted = false
    }
  }, [elements, stripe])

  // validate and submit the CC info
  const handleSubmit = async (event?: SyntheticEvent) => {
    // Block native form submission.
    event?.preventDefault()

    if (!stripe || !elements) {
      //console.log('stripe not yet loaded');
      return
    }

    const isEverythingValid = Object.values(formState)
      .map((_) => validate(_.validationRequirement, _.val))
      .every((_) => Boolean(_) === true)

    if (
      !isEverythingValid ||
      !isValidCC ||
      !isValidExp ||
      !isValidCVV ||
      isPristineCC ||
      isPristineExp ||
      isPristineCVV
    ) {
      if (isPristineCC) {
        setIsValidCC(false)
      }
      if (isPristineExp) {
        setIsValidExp(false)
      }
      if (isPristineCVV) {
        setIsValidCVV(false)
      }
      setForceAllNotPristine(true)
      return
    }

    const cardNumberElement = elements.getElement(CardNumberElement)

    setStripeIsBusy(true)
    setStripeError(false)
    const result = await stripe.confirmCardSetup(setupIntentClientSecret!, {
      payment_method: {
        card: cardNumberElement!,
        billing_details: {
          name: formState.billing_name?.val,
          email: email,
          address: {
            line1: formState.line1?.val,
            line2: formState.line2?.val,
            city: formState.city?.val,
            state: formState.state?.val,
            postal_code: formState.postal_code?.val,
            country: formState.country?.val,
          },
        },
      },
    })
    setStripeIsBusy(false)

    if (result.error) {
      setStripeError(result.error.message)

      // return to the collection state
      onCollectingCardInfo()
    } else {
      onPaymentMethodIdChange(result.setupIntent.payment_method!)
    }
  }

  const CARD_OPTION = {
    style: {
      base: {
        color: theme.textColors.primary,
        fontSize: '16px',
        '::placeholder': {
          color: theme.textColors.secondary,
        },
      },
      invalid: {
        color: theme.colors.red2,
      },
    },
  }

  return (
    <form onSubmit={handleSubmit} css={blockAndDim(stripeIsBusy)} className={className}>
      {stripeError ? <MembershipSectionText text={`${stripeError}`} color={theme.colors.red2} /> : null}
      <FormInput
        id="billing_name"
        labeltext="Name On Card"
        autocomplete="given-name"
        val={formState.billing_name?.val || ''}
        onBlurCallback={handleBlur}
        onChangeCallback={handleChange}
        validationMessage={formState.billing_name?.validationMessage}
        validationRequirement={formState.billing_name?.validationRequirement}
        isForceNotPristine={forceAllNotPristine}
      />

      <InputContainer isInvalidShowing={isInvalidCCShowing}>
        <InputLabel isInvalidShowing={isInvalidCCShowing} htmlFor="">
          Credit Card Number
        </InputLabel>
        <div
          css={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '6px 5px 0 7px',
            height: '28px',
            bottom: -4,
            left: -5,
            marginTop: -4,
            width: '75%',
            background: 'transparent',
            border: 'none',
            outline: 'none',
          }}
        >
          <div css={{ width: '100%', margin: 'auto 0' }}>
            <CardNumberElement options={CARD_OPTION} />
          </div>
        </div>
      </InputContainer>
      <FadeIn isIn={isInvalidCCShowing}>
        <ValidationContainer id="testing-validation-cc" isInvalidShowing={isInvalidCCShowing}>
          {validationMessageCC}
        </ValidationContainer>
      </FadeIn>

      <InputContainer isInvalidShowing={isInvalidExpShowing}>
        <InputLabel isInvalidShowing={isInvalidExpShowing} htmlFor="">
          Expiration Date
        </InputLabel>
        <div
          css={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '6px 5px 0 7px',
            height: '28px',
            bottom: -4,
            left: -5,
            marginTop: -4,
            width: '75%',
            background: 'transparent',
            border: 'none',
            outline: 'none',
          }}
        >
          <div css={{ width: '100%', margin: 'auto 0' }}>
            <CardExpiryElement options={CARD_OPTION} />
          </div>
        </div>
      </InputContainer>
      <FadeIn isIn={isInvalidExpShowing}>
        <ValidationContainer id="testing-validation-exp" isInvalidShowing={isInvalidExpShowing}>
          {validationMessageExp}
        </ValidationContainer>
      </FadeIn>

      <InputContainer isInvalidShowing={isInvalidCVVShowing}>
        <InputLabel isInvalidShowing={isInvalidCVVShowing} htmlFor="">
          Security Code
        </InputLabel>
        <div
          css={{
            position: 'relative',
            display: 'flex',
            alignTtems: 'center',
            justifyContent: 'stretch',
            padding: '6px 5px 0 7px',
            height: '28px',
            bottom: -4,
            left: -5,
            marginTop: -4,
            width: '75%',
            background: 'transparent',
            border: 'none',
            outline: 'none',
          }}
        >
          <div css={{ width: '100%', margin: 'auto 0' }}>
            <CardCvcElement options={CARD_OPTION} />
          </div>
        </div>
      </InputContainer>
      <FadeIn isIn={isInvalidCVVShowing}>
        <ValidationContainer id="testing-validation-cvv" isInvalidShowing={isInvalidCVVShowing}>
          {validationMessageCVV}
        </ValidationContainer>
      </FadeIn>

      {withFullAddress ? (
        <>
          <FormInput
            id="addressLine1"
            labeltext="Billing Address"
            autocomplete="address"
            val={formState.addressLine1?.val || ''}
            onBlurCallback={handleBlur}
            onChangeCallback={handleChange}
            isForceNotPristine={forceAllNotPristine}
            validationMessage={formState.addressLine1?.validationMessage}
            validationRequirement={formState.addressLine1?.validationRequirement}
          />
          <FormInput
            id="addressLine2"
            labeltext="Address line 2"
            autocomplete="address"
            val={formState.addressLine2?.val || ''}
            onBlurCallback={handleBlur}
            onChangeCallback={handleChange}
            isForceNotPristine={forceAllNotPristine}
            validationMessage={formState.addressLine2?.validationMessage}
            validationRequirement={formState.addressLine2?.validationRequirement}
          />
          <FormInput
            id="city"
            labeltext="City"
            autocomplete="city"
            val={formState.city?.val || ''}
            onBlurCallback={handleBlur}
            onChangeCallback={handleChange}
            isForceNotPristine={forceAllNotPristine}
            validationMessage={formState.city?.validationMessage}
            validationRequirement={formState.city?.validationRequirement}
          />
          <FormCustomSelect
            id="state"
            labeltext="State"
            val={usStates.find((_) => _.abbreviation === formState.state?.val)?.name || ''}
            handleCustomDropdownChange={handleStateDropdownChange}
            isSelectDropdownShowing={formState.state?.isSelectDropdownShowing}
            placeholder="Select state"
            onChangeCallback={() => {}}
            options={usStates.map((_) => _.name)}
            toggleIsSelectDropdownShowing={handleStateDropdownSelect}
            isForceNotPristine={forceAllNotPristine}
            validationMessage={formState.state?.validationMessage}
            validationRequirement={formState.state?.validationRequirement}
          />
        </>
      ) : null}
      <FormCustomSelect
        id="country"
        labeltext="Country"
        val={countries.find((_) => _.value === formState.country?.val)?.value || ''}
        handleCustomDropdownChange={handleCountryDropdownChange}
        isSelectDropdownShowing={formState.country?.isSelectDropdownShowing}
        placeholder="Select Country"
        onChangeCallback={() => {}}
        options={countries.map((_) => _.label)}
        toggleIsSelectDropdownShowing={handleCountryDropdownSelect}
        isForceNotPristine={forceAllNotPristine}
        validationMessage={formState.country?.validationMessage}
        validationRequirement={formState.country?.validationRequirement}
      />
      <FormInput
        id="postal_code"
        labeltext="Zip Code"
        autocomplete="postal-code"
        val={formState.postal_code?.val || ''}
        onBlurCallback={handleBlur}
        onChangeCallback={handleChange}
        validationMessage={formState.postal_code?.validationMessage}
        validationRequirement={formState.postal_code?.validationRequirement}
        isForceNotPristine={forceAllNotPristine}
      />

      {optionalMarkupAboveButton}

      {CustomSubmitButton ? (
        <CustomSubmitButton onSubmit={handleSubmit} />
      ) : (
        <ButtonSection>
          {hasCompletedOnboarding ? (
            <ButtonSolid onClick={handleSubmit} fullWidth>
              <Button3>{submitButtonText}</Button3>
            </ButtonSolid>
          ) : (
            <>
              <Button onClick={handleSubmit}>{submitButtonText}</Button>
              <SubButtonTextLink
                onClick={() => {
                  setTimeout(() => {
                    history.push(routing.onboarding.welcome.generatePath())
                  }, 0)
                }}
              >
                Do This Later
              </SubButtonTextLink>
            </>
          )}
        </ButtonSection>
      )}
    </form>
  )
}
