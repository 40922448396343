import { SerializedStyles, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { FC, VFC } from 'react'

import { Button1 } from '../../ui-kit'
import { textTransformUppercase } from '../../ui-kit/utils'

import { bp } from './breakpoints'
import { SubHeadingAlt, SubHeadingSmall } from './typography'
import { hoverOpacity, hoverOpacityFast, pseudoFocus, textAlignCenter, transitionFast } from './utility'

export const HeaderContainer = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  height: theme.variables.headerHeightMobile,
  zIndex: 1,
  [bp.sm]: {
    height: theme.variables.headerHeightDesktop,
  },
}))

// headerSubtitle - accommodating positioning
// column - for desktop (make it a column; desktop is horizontal nav)
export const HeaderCenterContainer = styled.div<{
  column?: boolean
  headerSubtitle?: string
}>(({ column, headerSubtitle }) => ({
  position: 'relative',
  top: headerSubtitle ? 10 : undefined,
  display: 'flex',
  flexDirection: column ? 'column' : undefined,
  flex: column ? 1 : undefined,
  alignItems: 'center',
  justifyContent: column ? 'center' : 'space-between',
}))

export const HeaderCenter: VFC<{
  readonly headerTitle: string
  readonly headerSubtitle?: string
  readonly isAlternateHeader?: boolean
  readonly titleStyles?: SerializedStyles
}> = ({ headerTitle, headerSubtitle, isAlternateHeader, titleStyles }) => {
  const theme = useTheme()
  return (
    <HeaderCenterContainer column headerSubtitle={headerSubtitle}>
      <Button1 as="h1" id="testing-header-title" css={[textAlignCenter, textTransformUppercase, titleStyles]}>
        {headerTitle}
      </Button1>
      {headerSubtitle && isAlternateHeader ? (
        <SubHeadingAlt
          noMargin
          css={{
            marginTop: -3,
            textTransform: 'none',
          }}
        >
          {headerSubtitle}
        </SubHeadingAlt>
      ) : (
        headerSubtitle && (
          <SubHeadingSmall
            noMargin
            css={{
              lineHeight: theme.typography.lineHeight.extraCondensed,
              marginTop: -2,

              [bp.lg]: {
                marginTop: -3,
              },
              [bp.xl]: {
                marginTop: -4,
              },
            }}
          >
            {headerSubtitle}
          </SubHeadingSmall>
        )
      )}
    </HeaderCenterContainer>
  )
}

const headerControlWidth = 64
const headerControlWidthXs = 80

export const HeaderControlContainer = styled.div({
  width: headerControlWidth,
  overflow: 'hidden',

  [bp.xxs]: {
    width: headerControlWidthXs,
  },
})
export const HeaderControlInnerA: FC<{
  readonly shiftLeft?: boolean
  readonly onClick?: () => void
  readonly className?: string
}> = ({ children, shiftLeft, ...props }) => (
  <a
    css={[
      {
        position: 'relative',
        left: shiftLeft ? -29 : undefined,
        display: 'inline-flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        padding: '0 20px',
        cursor: 'pointer',
      },
      pseudoFocus,
      hoverOpacityFast,
    ]}
    tabIndex={0}
    role="button"
    {...props}
  >
    {children}
  </a>
)

const HamburgerContainer = styled.div<{ isNavOpen?: boolean }>(
  ({ isNavOpen }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: headerControlWidth,
    height: '100%',
    cursor: 'pointer',
    opacity: isNavOpen ? 1 : 0.9,

    '&:hover, &:focus': {
      opacity: 1,
    },
    [bp.xxs]: {
      width: headerControlWidthXs,
    },
  }),
  pseudoFocus,
  transitionFast,
)

const HamburgerInner = styled.div<{ isMobile?: boolean }>(({ isMobile }) => ({
  width: isMobile ? 17 : 26,
  height: isMobile ? 14 : 21,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignSelf: 'center',
}))

const HamburgerLine = styled.span<{ isMobile?: boolean }>(({ theme, isMobile }) => ({
  display: 'block',
  width: '100%',
  height: isMobile ? 2 : 3,
  borderRadius: '1px',
  background: theme.textColor,
}))

export const HeaderControlTextLinkContainer: FC<{
  readonly onClick?: () => void
  readonly className?: string
}> = ({ children, ...props }) => (
  <div
    tabIndex={0}
    role="button"
    css={[
      {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto !important', // TODO fix
        height: '100%',
        padding: '0 20px',
        justifyContent: 'center',
        cursor: 'pointer',
      },
      hoverOpacity,
      pseudoFocus,
    ]}
    {...props}
  >
    {children}
  </div>
)

export const Hamburger: VFC<{
  readonly isNavOpen?: boolean
  readonly onClick?: () => void
  readonly isMobile?: boolean
}> = ({ isNavOpen, onClick, isMobile }) => (
  <HamburgerContainer isNavOpen={isNavOpen} onClick={onClick} tabIndex={0} role="button">
    <HamburgerInner isMobile={isMobile}>
      <HamburgerLine isMobile={isMobile} />
      <HamburgerLine isMobile={isMobile} />
      <HamburgerLine isMobile={isMobile} />
    </HamburgerInner>
  </HamburgerContainer>
)
