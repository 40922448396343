import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { AppTheme } from '../model/ThemeProvider/theme'

type ColorKeys = keyof Theme['textColors']
type SizeKeys = keyof Omit<AppTheme['typography']['size'], 'lockups'>

export const Text = styled.span<{
  color?: ColorKeys
  size?: SizeKeys
}>(
  ({ color, size, theme }) => `
    color: ${theme.textColors[color ?? 'primary']};
    font-size: ${theme.typography.size[size ?? 'base']}px;
  `,
)
