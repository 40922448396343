import { useEffect } from 'react'

import { InvitationDialog } from './dialogs'
import { useDialogManager } from './lib/dialog-manager'
import { useSelector } from './store'

export function InvitationManager() {
  const invitations = useSelector((state) => state.invite.data)
  const { showDialog } = useDialogManager()

  useEffect(() => {
    if (invitations.length) {
      showDialog(InvitationDialog)
    }
  }, [invitations, showDialog])

  return null
}
