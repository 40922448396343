import blueimp from 'blueimp-load-image'

export const fixPhotoOrientation = (file: File, cb: (file: File) => void) => {
  if (/^image\/jpe?g$/.test(file.type)) {
    blueimp(
      file,
      (img) => {
        ;(img as HTMLCanvasElement).toBlob(
          (blob) =>
            cb(
              new File([blob!], file.name, {
                type: file.type,
              }),
            ),
          file.type,
        )
      },
      { meta: true, orientation: true, canvas: true },
    )
  } else {
    cb(file)
  }
}
