import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { wait } from '@testing-library/user-event/dist/utils'
import React, { FC, useEffect, useState, VFC } from 'react'
import { useDispatch, useSelector } from 'store'

import { Section } from '../components/styled/containers'
import { paddingVertical } from '../components/styled/spacing'
import { P } from '../components/styled/typography'
import { textAlignCenter } from '../components/styled/utility'

import { SetupIntentForm } from './SetupIntentForm'
import { clearSetupIntentClientSecret, createStripeSetupIntent } from '../store/billing'

const DISPLAY_STATE = {
  loading: 'loading',
  collectingCardInfo: 'collectingCardInfo',
  finished: 'finished',
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)

const CollectCreditCard: VFC<{
  onPaymentMethodCaptured: (paymentMethodId: string) => void
  optionalMarkupAboveButton?: React.ReactNode
  submitButtonText: string
  className?: string
  withFullAddress?: boolean
  CustomSubmitButton?: FC<{ onSubmit: () => void }>
}> = React.memo(
  ({
    onPaymentMethodCaptured,
    optionalMarkupAboveButton,
    submitButtonText,
    className,
    withFullAddress,
    CustomSubmitButton,
  }) => {
    const dispatch = useDispatch()
    const setupIntentClientSecret = useSelector((state) => state.billing.setupIntentClientSecret)
    const waitingForSetupIntentCapture = useSelector((state) => state.billing.waitingForSetupIntent)

    const [displayState, setDisplayState] = useState(DISPLAY_STATE.loading)
    const [paymentMethodId, setPaymentMethodId] = useState('')

    useEffect(() => {
      dispatch(clearSetupIntentClientSecret())
    }, [dispatch])

    /*
     * determine whether to set display state to:
     *   - finished: we have a non-empty paymentMethodId
     *   - collectingCardInfo: because setupIntentClientSecret is ready
     */
    useEffect(() => {
      if (waitingForSetupIntentCapture) {
        return
      }

      if (paymentMethodId !== '') {
        // we finished!
        setDisplayState(DISPLAY_STATE.finished)
        // see this function in MembershipSetupContainer, where it is passed via props
        onPaymentMethodCaptured(paymentMethodId)
        return
      }

      if (setupIntentClientSecret !== null) {
        setDisplayState(DISPLAY_STATE.collectingCardInfo)
        return
      } else {
        wait(1000) // TODO-EB: Temporary fix to make retrying process more gentle
          .then(() => {
            dispatch(createStripeSetupIntent())
          })
      }
    }, [
      dispatch,
      onPaymentMethodCaptured,
      paymentMethodId,
      setDisplayState,
      setupIntentClientSecret,
      waitingForSetupIntentCapture,
    ])

    return (
      <Section>
        {(() => {
          switch (displayState) {
            case DISPLAY_STATE.loading:
              return <P css={[textAlignCenter, paddingVertical('md')]}>Loading...</P>
            case DISPLAY_STATE.collectingCardInfo:
              return (
                <Elements stripe={stripePromise}>
                  <SetupIntentForm
                    optionalMarkupAboveButton={optionalMarkupAboveButton}
                    submitButtonText={submitButtonText}
                    className={className}
                    withFullAddress={withFullAddress}
                    CustomSubmitButton={CustomSubmitButton}
                    onCollectingCardInfo={() => setDisplayState(DISPLAY_STATE.collectingCardInfo)}
                    onPaymentMethodIdChange={setPaymentMethodId}
                  />
                </Elements>
              )
            case DISPLAY_STATE.finished:
              return <P css={[textAlignCenter, paddingVertical('md')]}>Finished!</P>
            default:
              return null
          }
        })()}
      </Section>
    )
  },
)

export default CollectCreditCard
