import { SerializedStyles } from '@emotion/react'
import React, { ReactNode, useCallback, useState, VFC } from 'react'
import { usePrevious } from 'utils/hooks/usePrevious'

import { show } from '../../components/styled/utility'
import { TIMING } from '../../constants'
import { Tab, TabsHead } from '../index'

export const SmoothTabs: VFC<{
  readonly tabs: { key: string; title: string; content: ReactNode }[]
  readonly onChange?: (tabKey: string) => void
  readonly styles?: { header?: SerializedStyles }
}> = ({ tabs, onChange, styles }) => {
  const [activeTabKey, setActiveTabKeyKey] = useState(tabs[0]?.key)
  const prevActiveTabKey = usePrevious(activeTabKey)
  const [isTransitioning, setIsTransitioning] = useState(false)

  const setTab = useCallback(
    (tabKey: string) => {
      if (activeTabKey === tabKey || isTransitioning) return
      setActiveTabKeyKey(tabKey)
      setIsTransitioning(true)
      onChange?.(tabKey)
      setTimeout(() => setIsTransitioning(false), TIMING.medium)
    },
    [activeTabKey, isTransitioning, onChange],
  )

  const getContentStyles = useCallback(
    (tabKey: string) => [
      show(activeTabKey === tabKey && !isTransitioning),
      {
        height:
          (isTransitioning && tabKey === prevActiveTabKey) || (!isTransitioning && tabKey === activeTabKey)
            ? 'initial'
            : 0,
      },
    ],
    [activeTabKey, isTransitioning, prevActiveTabKey],
  )

  return (
    <div>
      <TabsHead css={styles?.header}>
        {tabs.map((tab) => (
          <Tab key={tab.key} text={tab.title} active={activeTabKey === tab.key} onClick={() => setTab(tab.key)} />
        ))}
      </TabsHead>
      {tabs.map((tab) => (
        <div key={tab.key} css={getContentStyles(tab.key)}>
          {tab.content}
        </div>
      ))}
    </div>
  )
}
