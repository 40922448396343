import * as R from 'ramda'

// https://gist.github.com/tommmyy/daf61103d6022cd23d74c71b0e8adc0d (modified)
export const debounce = R.curry((fn, delayInMs) => {
  let timeout: any

  return (...args: any[]) => {
    const later = () => {
      timeout = null
      R.apply(fn, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, delayInMs)
  }
})
