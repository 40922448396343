import { useContext, useEffect } from 'react'

import { isViewportMobile } from '../components/styled/breakpoints'
import { debounce } from '../utils/utils'

import { UIContext } from './'

const RESIZE_DEBOUNCE_DELAY = 200

export const useWindowResize = () => {
  const { actions } = useContext(UIContext) || {}
  const { setIsMobile, setInnerWidth } = actions || {}

  useEffect(() => {
    let isMobile = isViewportMobile(window.innerWidth)
    setIsMobile?.(isMobile)

    window.addEventListener(
      'resize',
      debounce(() => {
        const isResizedViewportMobile = isViewportMobile(window.innerWidth)
        if (isResizedViewportMobile !== isMobile) {
          isMobile = isResizedViewportMobile
          setIsMobile?.(isResizedViewportMobile)
        }
        setInnerWidth?.(window.innerWidth)
      }, RESIZE_DEBOUNCE_DELAY),
    )
  }, [setIsMobile, setInnerWidth])
}
