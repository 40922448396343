import styled from '@emotion/styled'

import { bp } from '../../../components/styled/breakpoints'

export const Progress = styled.section({
  [bp.sm]: {
    display: 'flex',
  },
})

export const Column = styled.div({
  flex: 1,
})

export const ProgressHeaderContainer = styled.header()

export const ProgressBlock = styled.div({
  width: '100%',
  maxWidth: 355,
})
