import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { TIMING, TRANSITIONS } from '../../constants'
import { AppTheme } from '../../model/ThemeProvider/theme'

import { bp } from './breakpoints'

export const zIndex = (z: number) =>
  css({
    zIndex: z,
  })

const cssTransition = ({
  transition = 'allMedium',
  delay,
}: {
  transition?: keyof typeof TRANSITIONS
  delay?: keyof typeof TIMING
}) =>
  css({
    transition: TRANSITIONS[transition],
    transitionDelay: delay && `${TIMING[delay]}ms`,
  })

export const transitionMedium = css({
  transition: TRANSITIONS.allMedium,
})

export const transitionFast = css({
  transition: TRANSITIONS.allFast2,
})

export const cursorPointer = css({ cursor: 'pointer' })

export const desktopOnly = css({
  [bp.xsMax]: {
    display: 'none',
  },
})

export const mobileOnly = css({
  [bp.sm]: {
    display: 'none',
  },
})

export const overflowHidden = css({
  overflow: 'hidden',
})

const clampEllipsesLines = (numLines: number) =>
  css(overflowHidden, {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: numLines,
  })

export const secondLineEllipses = css(clampEllipsesLines(2))
export const thirdLineEllipses = css(clampEllipsesLines(3))
export const ellipses = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const hoverOpacityBase = css({
  opacity: 0.88,
  '&:hover, &:focus': { opacity: 1 },
})

export const hoverOpacity = css([transitionMedium, hoverOpacityBase])
export const hoverOpacityFast = css([transitionFast, hoverOpacityBase])

export const pseudoFocus = css({
  '&:focus': {
    outline: 0,
  },
})

export const pseudoFocusLightBackground = (theme: AppTheme) =>
  css({
    '&:focus': {
      outline: 0,
      background: theme.colors.whiteAlphaX15,
    },
  })

export const width100 = css({
  width: '100%',
})

export const height100 = css({
  height: '100%',
})

// eslint-disable-next-line no-shadow, import/no-unused-modules
export const maxHeight = (height: number) =>
  css({
    maxHeight: height,
  })

export const flexVertical = css({
  display: 'flex',
  flexDirection: 'column',
})

export const justifyContentCenter = css({
  justifyContent: 'center',
})

export const alignItemsCenter = css({
  alignItems: 'center',
})
export const alignItemsFlexStart = css({
  alignItems: 'FlexStart',
})

export const displayFlexDesktop = css({
  [bp.sm]: {
    display: 'flex',
  },
})

export const flex1desktop = css({
  [bp.sm]: {
    flex: 1,
  },
})

export const alignLeftDesktop = css({
  [bp.sm]: {
    textAlign: 'left',
  },
})

export const displayFlex = css({
  display: 'flex',
})
export const flexCenterCenter = css(displayFlex, justifyContentCenter, alignItemsCenter)
export const displayBlock = css({
  display: 'block',
})

export const displayNone = css({
  display: 'none !important',
})
export const positionRelativeTop = (top: number) =>
  css({
    position: 'relative',
    top: top,
  })
export const positionRelative = css({
  position: 'relative',
})
export const textAlignCenter = css({
  textAlign: 'center',
})
export const textAlignRight = css({
  textAlign: 'right',
})

export const positionAbsoluteAll = css({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})
export const positionAbsolute = (
  options: {
    top?: number | string
    right?: number | string
    bottom?: number | string
    left?: number | string
  } = {},
) => {
  const { top, right, bottom, left } = options
  return css({
    position: 'absolute',
    top: top,
    right: right,
    bottom: bottom,
    left: left,
  })
}

export const objectFitCover = css({
  objectFit: 'cover',
})

const dim = (isDimmed: boolean) =>
  css([
    {
      opacity: isDimmed ? 0.7 : 1,
    },
    transitionMedium,
  ])
export const blockPointerEvents = (isBlocked: boolean) =>
  css([
    {
      pointerEvents: isBlocked ? 'none' : undefined,
    },
  ])
export const blockAndDim = (isBlocked: boolean) => css([dim(isBlocked), blockPointerEvents(isBlocked)])

export const isShowingBase = (isShowing?: boolean) =>
  css([
    {
      opacity: isShowing ? 1 : 0,
      pointerEvents: !isShowing ? 'none' : undefined,
    },
  ])

const isHidingBlockBase = (isHiding: boolean) => css([isHiding && { display: 'none' }])

export const show = (isShowing?: boolean, transition?: keyof typeof TRANSITIONS, delay?: keyof typeof TIMING) =>
  css([isShowingBase(isShowing), cssTransition({ delay, transition })])

export const isShowingFast = (isShowing?: boolean) => css([isShowingBase(isShowing), transitionFast])

export const hideBlock = (isHiding: boolean) => css(isHidingBlockBase(isHiding))

const borderGradientBase = (theme: AppTheme) =>
  css({
    position: 'relative',
    background: `linear-gradient(to bottom, ${theme.colors.grayGradientTop}, ${theme.colors.grayGradientBottom})`,
    padding: theme.variables.borderWidth,
    borderRadius: theme.variables.borderRadius,

    // :before is used by borderGradientBaseActive
    '&:before, &:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
    },
    '&:before': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: theme.colors.sand,
      borderRadius: theme.variables.borderRadius,
      opacity: 0,
      transition: theme.transitions.allFast2,
    },
    // :after shows underneath the image (for instance, before it is loaded)
    '&:after': {
      top: theme.variables.borderWidth,
      left: theme.variables.borderWidth,
      right: theme.variables.borderWidth,
      bottom: theme.variables.borderWidth,
      background: theme.colors.black,
      borderRadius: theme.variables.borderRadius,
      zIndex: 0,
    },
    // This requires an !important if children need something other than relative.
    // For instance, in CompletedOverlayContainer.
    '> div, > img': {
      position: 'relative',
      zIndex: 1,
    },
    '> img': {
      borderRadius: theme.variables.borderRadius,
      display: 'block',
    },
  })

const borderGradientBaseActive = (isActive: boolean, theme: AppTheme) =>
  css({
    '&:before': {
      background: isActive ? theme.colors.sand : undefined,
      opacity: isActive ? 1 : undefined,
    },
  })

const borderGradientBaseHovers = (theme: AppTheme) =>
  css({
    '> div, > img': {
      position: 'relative',
      zIndex: 1,
    },
    '&:hover': {
      '&:before': {
        background: theme.colors.sand,
        opacity: 1,
      },
    },
  })

const glowAndDropShadow = (theme: AppTheme) =>
  css({
    boxShadow: `${theme.shadows.glow}, ${theme.shadows.drop}`,
  })

// eslint-disable-next-line import/no-unused-modules
export const glowSubtle = (theme: AppTheme) =>
  css({
    boxShadow: `${theme.shadows.glowSubtle}`,
  })

export const Border = styled.div<{
  isActive: boolean
  hovers: boolean
  glow: boolean
}>(
  ({ theme }) => borderGradientBase(theme),
  ({ isActive, theme }) => borderGradientBaseActive(isActive, theme),
  ({ hovers, theme }) => hovers && borderGradientBaseHovers(theme),
  ({ glow, theme }) => glow && glowAndDropShadow(theme),
)

export const BorderAlt = styled.div(
  ({ theme }) => borderGradientBase(theme),
  ({ theme }) => glowSubtle(theme),
)

export const lineHeightZero = css({ lineHeight: 0 })

export const marginBottom = (marginB: number | string) =>
  css({
    marginBottom: marginB,
  })

export const marginTop = (marginT: number) =>
  css({
    marginTop: marginT,
  })

export const marginLeft = (marginL: number | string) =>
  css({
    marginLeft: marginL,
  })
