import { FadeMain } from './styled/containers'
import { P } from './styled/typography'

const ErrorBoundaryFallback = () => (
  <FadeMain wide>
    <P color="whiteAlpha50" css={{ marginTop: '5em', textAlign: 'center' }}>
      Sorry, an error has occurred.
    </P>
  </FadeMain>
)

export default ErrorBoundaryFallback
