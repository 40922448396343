import React, { useContext } from 'react'

import AccountForm from '../components/AccountForm'
import { PageHeader } from '../components/PageHeader'
import { FadeMain } from '../components/styled/containers'
import { DeepLinksContext, deepLinks } from '../hooks'
import { routing } from '../model/Routing/routing'
import { newUserFlowToBeActivated, acceptInvitationActivated } from '../system/user-onboarding-flow'
import history from '../utils/history'

const OnboardingCreateAccount = () => {
  const { activeDeepLink } = useContext(DeepLinksContext)!
  const onSuccessExitLocation = (() => {
    return activeDeepLink === deepLinks.activate ||
      activeDeepLink === deepLinks.membership ||
      activeDeepLink === deepLinks.membershipSetup
      ? routing.onboarding.membership.generatePath()
      : newUserFlowToBeActivated.get() || acceptInvitationActivated.get()
      ? routing.onboarding.addCard.generatePath()
      : routing.onboarding.welcome.generatePath()
  })()

  const onExitLocation = () => history.push(onSuccessExitLocation)

  return (
    <FadeMain extraTopPadding>
      <PageHeader>Create Account</PageHeader>
      <AccountForm onSuccess={onExitLocation} />
    </FadeMain>
  )
}

export default OnboardingCreateAccount
