import { WorkoutsHistoryResponseItem } from '../../../api/history-workouts/getHistoryWorkouts'

import { simplifyDate } from './simplifyDate'

// convert calendarData to date-keyed dictionary
export const keyCalendarData = (calendarData: WorkoutsHistoryResponseItem[], timezone: string) =>
  calendarData.reduce((acc, singleClass) => {
    const simpleDate = simplifyDate(singleClass.start_time!, timezone)
    return {
      ...acc,
      [simpleDate]: acc[simpleDate] ? [...acc[simpleDate]!, singleClass] : [singleClass],
    }
  }, {} as Record<string, WorkoutsHistoryResponseItem[]>)
