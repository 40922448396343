import { createSlice } from '@reduxjs/toolkit'

import { acceptInvitation, declineInvitation, getInvitationStatus } from './actions'
import { Invitation } from './types'

type State = {
  data: Invitation[]
}

const initialState: State = {
  data: [],
}

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvitationStatus.fulfilled, (state, action) => {
      state.data = action.payload
    })

    builder.addCase(acceptInvitation.fulfilled, (state) => {
      state.data = []
    })

    builder.addCase(declineInvitation.fulfilled, (state) => {
      state.data = []
    })
  },
})
