import { axios } from 'system/axios'

type Profile = {
  created_date: string
  updated_date: string
  id: string
  userId: string
  membershipId: string
  owner: boolean
  allowedClients: []
  featureTags: any
  user: any
}

export const removeProfile = (targetProfile: Partial<Profile>) => {
  const targetId = targetProfile.id
  axios.patch<Profile>(`/profile/${targetId}`, { membershipId: null })
}
