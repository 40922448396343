import styled from '@emotion/styled'
import React, { FC } from 'react'

import { bp } from '../../components/styled/breakpoints'
import { FadeIn } from '../../components/styled/containers'

const Container = styled(FadeIn)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  boxSizing: 'border-box',
  maxWidth: 420 + 32,
  width: '100%',
  height: '100vh',
  padding: '80px 16px 64px',
  margin: '0 auto',

  textAlign: 'center',

  [bp.sm]: {
    justifyContent: 'center',
    paddingTop: 64,
  },
})

export const EmailPagesContainer: FC<{
  readonly isIn?: boolean
  readonly className?: string
}> = ({ isIn, className, children }) => (
  <Container isIn={isIn} className={className}>
    {children}
  </Container>
)
