import styled from '@emotion/styled'
import dayjs from 'dayjs'
import daysjsDuration from 'dayjs/plugin/duration'
import * as R from 'ramda'
import React, { Fragment, VFC } from 'react'

import { PlainColorKeys } from '../../model/ThemeProvider/theme'
import { Body2 } from '../Typography'
import { displayFlex, gridArea, justifyContent, lineHeight, marginTop, textTransformUppercase } from '../utils'

import { ProgressBar } from './Bar'

dayjs.extend(daysjsDuration)

const GroupContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
})

const NumericText = styled(Body2)({
  fontWeight: 200,
  lineHeight: 1.75,
  minWidth: 50,
  paddingLeft: 12,
})

const ActiveMins = styled.span(({ theme }) => [
  textTransformUppercase,
  {
    fontFamily: theme.typography.family.heading,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: theme.colors.gray7c,
    paddingRight: 12,
  },
])

const formatMetrics = (minutes: number) => {
  const format = minutes > 59 ? 'H[h] m[m]' : 'm[m]'

  const duration = dayjs.duration(minutes, 'minutes')

  return duration.format(format)
}

type ProgressMetric = {
  color: PlainColorKeys
  heading: string
  lastMonth: number
  thisMonth: number
}

export const ProgressMetricsGroup: VFC<{
  readonly metrics: ProgressMetric[]
  readonly withMinutesWord?: boolean
}> = ({ metrics, withMinutesWord = false }) => {
  const highestNumber = metrics.reduce((acc, { thisMonth, lastMonth }) => R.max(acc, R.max(thisMonth, lastMonth)), 0)

  return (
    <GroupContainer>
      {metrics.map(({ color, heading, lastMonth, thisMonth }, i) => {
        const row = i * 3 + 1
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <Body2
              css={[
                gridArea(`${row} / 1`),
                lineHeight(1),
                marginTop(i === 0 ? 0 : 32),

                !withMinutesWord && [
                  displayFlex,
                  justifyContent('space-between'),
                  {
                    gridColumn: '1 / span 2',
                  },
                ],
              ]}
            >
              <span>
                {heading} {withMinutesWord ? 'Minutes' : null}
              </span>
              {!withMinutesWord && i === 0 && <ActiveMins>ACTIVE MINS</ActiveMins>}
            </Body2>
            <ProgressBar
              color={color}
              percentageComplete={(thisMonth / highestNumber) * 100}
              css={gridArea(`${row + 1} / 1`)}
            />
            <NumericText css={gridArea(`${row + 1} / 2`)}>{formatMetrics(thisMonth)}</NumericText>
            <ProgressBar
              color="black"
              percentageComplete={(lastMonth / highestNumber) * 100}
              css={gridArea(`${row + 2} / 1`)}
            />
            <NumericText css={gridArea(`${row + 2} / 2`)}>{formatMetrics(lastMonth)}</NumericText>
          </Fragment>
        )
      })}
    </GroupContainer>
  )
}
