import styled from '@emotion/styled'
import { Text } from 'ui-kit/Text'

export const ProfileContainer = styled.div`
  display: flex;
  cursor: pointer;
`

export const AddImage = styled.div`
  width: 64px;
  height: 64px;
  background: #000000;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const Content = styled.div<{ display?: boolean }>`
  padding-left: 16px;
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 200px;
`

export const TextStyled = styled(Text)`
  margin: 2px 0;
`

export const Container = styled.div`
  padding: 0 24px;
  overflow-wrap: anywhere;
  max-width: 325px;
`
