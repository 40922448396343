import styled from '@emotion/styled'

import { bp } from '../../../components/styled/breakpoints'

export const Overview = styled.section({
  display: 'grid',
  gridTemplateColumns: '1fr',

  [bp.sm]: {
    gridTemplateColumns: '360px auto',
  },
})
