import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'store'
import { Button3 } from 'ui-kit'

import { ButtonSolid } from '../components/styled/button'
import { Fade } from '../components/styled/containers'
import { P } from '../components/styled/typography'
import { removePaymentMethod } from '../store/billing'

const Modal = styled.div`
  position: absolute;
  width: inherit;
  height: 340px;
  left: 0px;
  bottom: 0px;
  width: 375px;
  /* White */
  background: #ffffff;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 0px;
  padding: 0 16px;
  box-sizing: border-box;
`

const Title = styled.div`
  margin: 32px 0 16px;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.8px;
  text-transform: capitalize;
`

const ButtonContainer = styled.div`
  margin-top: 32px;

  button + button {
    margin-top: 8px;
  }
`

function RemovePaymentMethod({ onClose }: { readonly onClose: () => void }) {
  const dispatch = useDispatch()

  const paymentMethod = useSelector((state) => state.billing.paymentMethod)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    !paymentMethod && setTimeout(() => onClose(), 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  const onRemovePaymentMethod = async () => {
    if (!paymentMethod) return

    setLoading(true)
    await dispatch(removePaymentMethod(paymentMethod?.id))
    setLoading(false)
  }

  return (
    <Modal>
      <Fade>
        <Title>Remove payment method</Title>
        <div>
          {loading ? (
            <P>Loading...</P>
          ) : paymentMethod ? (
            <>
              <P>
                Any future charges from your account will be charged to the payment method associated with the parent
                account.
              </P>
              <ButtonContainer
                style={{
                  textTransform: 'uppercase',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  letterSpacing: '0.01em',
                }}
              >
                <ButtonSolid fullWidth onClick={onRemovePaymentMethod}>
                  <Button3>Remove</Button3>
                </ButtonSolid>
                <ButtonSolid fullWidth onClick={onClose}>
                  <Button3>Cancel</Button3>
                </ButtonSolid>
              </ButtonContainer>
            </>
          ) : (
            <P>Finished!</P>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

export default RemovePaymentMethod
