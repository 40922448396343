export const getFromTheLocalStorage = (key: string | null) => {
  try {
    if (key) {
      return window.localStorage.getItem(key)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  return null
}

export const setToTheLocalStorage = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
