import styled from '@emotion/styled'

export const Caption = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.heading,
  fontSize: '0.875rem',
  lineHeight: 1.14,
  fontWeight: 500,
  letterSpacing: 2,
  margin: 0,
  padding: 0,
}))
