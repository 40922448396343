import styled from '@emotion/styled'

import { bp } from './styled/breakpoints'

export const VerticalContainer = styled.div({
  display: 'flex',
  minHeight: ['100vh', '-webkit-fill-available'],
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '0 auto',
  height: '100%',
  [bp.sm]: {
    justifyContent: 'start',
  },
})
