import styled from '@emotion/styled'
import { DownloadIosApp } from '../../icons'
import { displayBlock } from '../../ui-kit/utils'

export const APP_LINK = 'https://apps.apple.com/us/app/forme-the-best-in-home-fitness/id1532950399'

const DownloadAppButton = styled.a(({ theme }) => ({
  marginBottom: 38,
  display: 'block',
  transition: theme.transitions.allFast2,

  '&:hover': {
    opacity: 0.8,
  },
}))

export const DownloadAppLink = () => (
  <DownloadAppButton href={APP_LINK} target="_blank" rel="noreferrer">
    <DownloadIosApp css={displayBlock} />
  </DownloadAppButton>
)
