import styled from '@emotion/styled'

export const Body2 = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 400,
  margin: 0,
  padding: 0,
}))
