import styled from '@emotion/styled'

export const Body1 = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '1.3125rem',
  lineHeight: 2,
  fontWeight: 400,
  margin: 0,
  padding: 0,
}))
