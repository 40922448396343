const setBodyStyle = (style: string) => ((document.body as any).style = style)

export const lockBody = () =>
  setBodyStyle(`
    height: 100vh;
    height: -webkit-fill-available;
    overflow: hidden;
  `)

export const releaseBody = () =>
  setBodyStyle(`
    height: auto;
    overflow: auto;
  `)
