import { createSlice } from '@reduxjs/toolkit'

import { Membership } from '../../api/membership/getMembership'

import { getMembership, updateCommercial } from './actions'

type State = {
  isMembershipStatusKnown: boolean
  membership: Membership | null
  primaryMembership: Membership | null
  userHasMembership: boolean | null
}

const initialState: State = {
  isMembershipStatusKnown: false,
  membership: null,
  primaryMembership: null,
  userHasMembership: null,
}

export const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    updateMembership: (state, action) => {
      const membership = action.payload

      state.membership = membership
      state.primaryMembership = membership || null
      state.userHasMembership = !!membership
      state.isMembershipStatusKnown = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCommercial.fulfilled, (state, action) => {
      if (state.membership) {
        state.membership.commercial_detail = action.payload.commercial_detail
      }
      if (state.membership) {
        state.primaryMembership = state.membership
      }
    })

    builder.addCase(getMembership.fulfilled, (state, action) => {
      const membership = action.payload

      state.membership = membership
      state.primaryMembership = membership || null
      state.userHasMembership = !!membership
      state.isMembershipStatusKnown = true
    })
  },
})

export const { updateMembership } = membershipSlice.actions
