import styled from '@emotion/styled'
import React, { memo, VFC } from 'react'

import { PlainColorKeys } from '../../model/ThemeProvider/theme'
import { width, positionAbsolute, top0, left0 } from '../utils'

const Bar = styled.div<{ backgroundColor?: PlainColorKeys }>(({ theme, backgroundColor }) => ({
  position: 'relative',
  height: 3,
  borderRadius: 1.5,
  backgroundColor: theme.colors[backgroundColor || 'blackAlpha10'],
}))

export const ProgressBar: VFC<{
  color: PlainColorKeys
  percentageComplete: number
  className?: string
}> = memo(({ color, percentageComplete = 0, className }) => {
  return (
    <Bar className={className}>
      <Bar backgroundColor={color} css={[positionAbsolute, width(`${percentageComplete}%`), top0, left0]} />
    </Bar>
  )
})
