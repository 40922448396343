import { Invitation } from '../../store/invite/types'

const EMPTY_CONFIG = {
  title: '',
  text: '',
}

export const getModalConfig = (invitation: Invitation | undefined) => {
  if (!invitation) {
    return EMPTY_CONFIG
  }

  const { membership } = invitation
  const isCommercial = membership.class.type === 'commercial'

  if (isCommercial) {
    const commercialName = membership.commercial_detail?.business_name

    return {
      title: 'Commercial Account Invitation',
      text: commercialName
        ? `Would you like to join ${commercialName}’s commercial account?`
        : 'Would you like to join the commercial account?',
    }
  } else {
    const { first_name, last_name } = membership.profiles[0]?.fullUser || {}
    const fullName = first_name && last_name ? `${first_name} ${last_name}` : null

    return {
      title: 'Family Account Invitation',
      text: fullName
        ? `Would you like to join ${fullName}’s family account?`
        : 'Would you like to join the family account?',
    }
  }
}
