import { axios } from 'system/axios'

import { TrainerEntity } from '../entities/TrainerEntity'

export type TrainerFullImage = { url: string; cdn_url: string }
export type TrainerIntroVideo = { web_url: string; mobile_url: string }

export type Trainer = TrainerEntity & {
  full_image: TrainerFullImage | null
  intro_video: TrainerIntroVideo | null
}

export const getTrainers = () => axios.get<Trainer[]>('/trainer')
