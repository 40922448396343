import { Redirect, Switch } from 'react-router-dom'
import { Profile } from 'views/Profile'

import { renderAuthenticatedRoutes } from './AuthenticatedRoutes'
import PrivateRoute from './components/PrivateRoute'
import { authenticatedRedirects } from './model/Routing/redirects'
import { routing } from './model/Routing/routing'

const AuthenticatedRoutesInterior = () => (
  <Switch>
    {authenticatedRedirects.map(({ matchPath, redirectPath }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <PrivateRoute component={() => <Redirect to={redirectPath} />} exact key={index} path={matchPath} />
    ))}
    <PrivateRoute path={routing.home.pattern} exact component={Profile} title="Profile" />
    <PrivateRoute
      path={[
        routing.profile.membership.pattern,
        routing.profile.resetPassword.pattern,
        routing.profile.paymentInfo.pattern,
        routing.profile.settings.pattern,
        routing.profile.profile.pattern,
        routing.profile.profiles.pattern,
      ]}
      exact
      component={Profile}
      title="Profile"
    />
    {renderAuthenticatedRoutes()}
  </Switch>
)

export default AuthenticatedRoutesInterior
