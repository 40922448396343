import { Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { createContext, FC, useCallback } from 'react'

import globalStyle from '../../components/styled/global'
import { usePersistedReducer } from '../../hooks'
import { stringifyFromJSON, parseToJSON, getFromTheLocalStorage, setToTheLocalStorage } from '../../utils'

import * as themes from './theme'

type ThemeKind = 'light' | 'dark'

type ToggleThemeFn = ({ theme }: { theme: ThemeKind }) => void

const ThemeContext = createContext<ToggleThemeFn | null>(null)
const INITIAL_STATE: ThemeKind = 'light'

// @TODO uncomment when we will implement toggle theme switcher
// export const useToggleTheme = () => useContext(ThemeContext)

/**
 * @TODO remove this dataFixer after several months on production
 * It substitutes old theme logic from {} to string
 */
const themeDataFixer = () => {
  const currentTheme = parseToJSON(getFromTheLocalStorage('theme'))

  // scenario when user doesn't have theme yet
  // or when we have new theme data
  if (!currentTheme || typeof currentTheme === 'string') return

  // Set light theme for all users while we don't have theme toggles
  setToTheLocalStorage('theme', stringifyFromJSON(INITIAL_STATE))
}

export const ThemeProvider: FC = ({ children }) => {
  themeDataFixer()

  function reducer(state: ThemeKind, action: { type: 'toggleTheme'; payload: ThemeKind }) {
    switch (action.type) {
      case 'toggleTheme':
        return action.payload // light || dark
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = usePersistedReducer(reducer, 'theme', INITIAL_STATE)

  const toggleTheme: ToggleThemeFn = useCallback(
    ({ theme }) =>
      dispatch({
        type: 'toggleTheme',
        payload: theme,
      }),
    [dispatch],
  )

  return (
    <ThemeContext.Provider value={toggleTheme}>
      {/* eslint-disable-next-line import/namespace */}
      <EmotionThemeProvider theme={themes[state]}>
        <Global styles={globalStyle} />
        {children}
      </EmotionThemeProvider>
    </ThemeContext.Provider>
  )
}
