import { renderUniversalRoutes } from './UniversalRoutes'
import PrivateRoute from './components/PrivateRoute'
import { routing } from './model/Routing/routing'
import { PleaseVerifyEmail } from './views'

export const renderAuthenticatedRoutes = () => [
  <PrivateRoute
    key={routing.pleaseVerifyEmail.pattern}
    path={routing.pleaseVerifyEmail.pattern}
    component={PleaseVerifyEmail}
    title="Please Verify Email"
  />,
  ...renderUniversalRoutes(),
]
