import styled from '@emotion/styled'
import { useDialogManager } from 'lib/dialog-manager'
import React, { VFC } from 'react'
import { useSelector } from 'store'

import { studioTypeMap } from '../../../api/entities/MembershipProfileEntity'
import HeaderModal from '../../../components/HeaderModal'
import { constrain360, Fade } from '../../../components/styled/containers'
import { RegularDialog, RegularDialogProps } from '../../../ui-kit/RegularDialog'
import { paddingBottom } from '../../../ui-kit/utils'

// const mockStudios: Studio[] = [{
//   id: 'test-1',
//   product_type: 'studio',
// }, {
//   id: 'test-2',
//   product_type: 'studio_lift'
// }, {
//   id: 'test-3',
//   product_type: 'studio'
// }]

const StudioCard = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;

  &:not(:first-child) {
    border-top: 1px solid #bfbfbf;
  }
`

export const StudiosModal: VFC<RegularDialogProps> = (props) => {
  const { hideCurrentDialog } = useDialogManager()
  const profiles = useSelector((state) => state.membership.membership?.profiles)
  const ownProfile = profiles?.find(({ role }) => role === 'owner')
  const studios = ownProfile?.studios

  return (
    <RegularDialog {...props} css={{ minHeight: 300 }}>
      <HeaderModal controlLeft="ArrowLeft" headerTitle="Studios" onClickControlLeft={hideCurrentDialog} />
      <Fade>
        <div css={[constrain360, paddingBottom('lg')]}>
          <div style={{ minHeight: 300 }}>
            <div>
              {studios?.map(({ id, product_type }) => {
                return (
                  <StudioCard key={id}>
                    <div>{id}</div>
                    <div>{studioTypeMap[product_type]}</div>
                  </StudioCard>
                )
              })}
            </div>
          </div>
        </div>
      </Fade>
    </RegularDialog>
  )
}
