/*
 * Note: A small but steady stream of requirements have led to a series of bolted on
 * solutions. This file has accumulated technical debt. An overall, encompassing
 * approach needs to be designed.
 *
 * The members-web-app has several different layers or types of routing. The routing in
 * this file is "AppAuthenticated routing," given that it's the internal app
 * routing that occurs once a user has authenticated and reached
 * src/AppAuthenticated.js. See https://github.com/Formelife/members-web-app/wiki#routing
 * for the broader discussion.
 *
 * But basically, this file is going to say, "Okay, now that you're authenticated,
 * let's see where you need to go. Have you verified your email? If not, then you
 * need to go to please-verify-email. Have you finished onboarding but you're
 * trying to reach an onboarding page? Then I'll send you to the interior of the
 * app. Conversely, are you trying to reach the interior of the app, but you
 * haven't finished onboarding yet? Then I'll send you back to onboarding. Are you
 * on genesis path? Then I'll set you as being on activate flow (but not if you've
 * already completed Membership Setup and Order Confirmation). Are you on activate
 * flow? Then I'll send you to Membership Setup, or (if you've already onboarded
 * and you've already finished Membership Setup) Order Confirmation." There's a
 * little bit more to it than that, but that's the big idea.
 *
 * There are three useEffects:
 * - useEffect (1) - should genesis trigger activate?
 * - useEffect (2) - main useRouting logic
 *
 * In order for the main logic to occur in useEffect (2), we need to know multiple
 * pieces of information, like if the user is verified, if they're still
 * onboarding, and if they have a membership set up yet.
 *
 * If the user is genesis, then useEffect (1) is going to trigger activate flow if
 * the user hasn't already finished their membership and done order confirmation.
 */
import { useDialogManager } from 'lib/dialog-manager'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'store'
import { mergeUserProfile } from 'store/user'
import { MembershipSetupModal } from 'views/MembershipSetupContainer'

import { emailLanderPaths } from '../model/Routing/redirects'
import { routing } from '../model/Routing/routing'
import { newUserFlowToBeActivated, acceptInvitationActivated } from '../system/user-onboarding-flow'
import history from '../utils/history'

import { isDeepLinkToActivateFlow } from './useDeepLinks'

import { DeepLinksContext, deepLinks, useIsEmailVerified } from './'
import { createStripeSetupIntent, getPaymentMethod } from '../store/billing'
import { getMembership } from '../store/membership'

const useRouting = () => {
  newUserFlowToBeActivated.init()
  acceptInvitationActivated.init()
  const newUserFlowActivated = newUserFlowToBeActivated.get()
  const invitationAccepted = acceptInvitationActivated.get()

  const dispatch = useDispatch()

  const pathname = history.location.pathname
  const { showDialog } = useDialogManager()

  const { isEmailVerified } = useIsEmailVerified()
  const {
    actions: { resetDeepLink, setDeepLink },
    activeDeepLink,
  } = useContext(DeepLinksContext)!
  const {
    id: userId,
    isInitialLogin,
    onboarding_fitness_survey_completed,
    onboarding_profile_completed,
    userJustCompletedFitnessAssessment,
    require_cc,
    membershipProfiles,
  } = useSelector((state) => state.user)

  const { isMembershipStatusKnown, userHasMembership } = useSelector((state) => state.membership)
  const [loading, setLoading] = useState(true)
  const paymentMethod = useSelector((state) => state.billing.paymentMethod)

  const [readyToDisplay, setReadyToDisplay] = useState(false)

  const [isWaitingForMoreGenesisInfo, setIsWaitingForMoreGenesisInfo] = useState(false)

  useEffect(() => {
    try {
      dispatch(getMembership()).then(() => {
        dispatch(createStripeSetupIntent())
      })
    } catch (e: any) {
      throw Error(e)
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getPaymentMethod()).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  /*
   * useEffect (1) - should genesis trigger activate?
   */
  useEffect(() => {
    // only enable this genesis checking for the initial login
    if (isInitialLogin || isWaitingForMoreGenesisInfo) {
      // use this flag to give us time to wait until we have the information we need
      setIsWaitingForMoreGenesisInfo(!userId)

      // make sure we have all the information we need
      if (userId) {
        setDeepLink(deepLinks.activate)
      }
    }
  }, [isInitialLogin, isWaitingForMoreGenesisInfo, setDeepLink, userHasMembership, userId])

  /*
   * useEffect (2) - main useRouting logic
   */
  useEffect(() => {
    /*
     * By the time the user is at useRouting.js, we know they're authenticated
     * (since useRouting is called from AppAuthenticated.js).
     * See https://github.com/Formelife/members-web-app/wiki#routing
     *
     * But don't apply any routing until:
     * - we know whether or not their email has been verified
     * - we have user data
     * - we know if the user has a membership (this info is in user)
     */
    if (isEmailVerified !== null && userId && isMembershipStatusKnown && !loading) {
      // note: deep-link-to-something is different from navigating-to-something
      // "deep link to" means there's an activeDeepLink, "navigate to" means manual browser address bar change
      if (emailLanderPaths.includes(pathname)) {
        // do nothing, let user naturally reach email lander (via react-router)
      } else if (!isEmailVerified) {
        // block the user's access if they haven't confirmed their email address yet
        if (!routing.pleaseVerifyEmail.isActive(pathname)) {
          history.push(routing.pleaseVerifyEmail.generatePath())
        }
      } else if (!onboarding_profile_completed) {
        // Route to Create Account
        setTimeout(() => {
          if (!routing.onboarding.createAccount.isActive(pathname)) {
            // eslint-disable-next-line no-console
            console.log('routing to create account..')
            history.push(routing.onboarding.createAccount.generatePath())
          }
        }, 1000)
      } else if (!paymentMethod?.id && require_cc) {
        // Route to Add Payment
        if (!routing.onboarding.addCard.isActive(pathname)) {
          // eslint-disable-next-line no-console
          console.log('routing to add card..')
          history.push(routing.onboarding.addCard.generatePath())
        }
      } else if (!onboarding_fitness_survey_completed && isDeepLinkToActivateFlow(pathname)) {
        // do nothing, let user naturally reach the deep link (via react-router)
      } else if (!onboarding_fitness_survey_completed && isDeepLinkToActivateFlow(activeDeepLink)) {
        // [user is still onboarding] Route to Membership Setup deep link

        // user is successfully following the deep link
        resetDeepLink()

        if (!routing.onboarding.membership.isActive(pathname) && !userHasMembership) {
          history.push(routing.onboarding.membership.generatePath())
        }
      } else if (!onboarding_fitness_survey_completed) {
        // Route to Fitness Assessment

        if (isInitialLogin || !routing.onboarding.index.isActive(pathname)) {
          // immediately after login, route to Fitness Assessment
          // or let the user navigate anywhere within onboarding
          history.push(
            routing.onboarding.fitnessAssessment.generatePath({
              questionNumber: 1,
            }),
          )
        }
      } else if (userJustCompletedFitnessAssessment) {
        // The first time the user leaves onboarding, route to Featured
        // note: this case used to be distinct from regular Sign Ins,
        // but now those two cases are handled the same way.
        const route = newUserFlowActivated ? routing.profile.profiles.generatePath() : routing.home.generatePath()
        history.push(route)
        // turn flag off
        dispatch(
          mergeUserProfile({
            userJustCompletedFitnessAssessment: false,
          }),
        )
      } else if (routing.onboarding.index.isActive(pathname)) {
        // (User has completed onboarding) if they try to navigate back to onboarding, don't let them
        history.push(routing.home.generatePath())
      } else if (isDeepLinkToActivateFlow(activeDeepLink)) {
        // (User has completed onboarding) 'activate flow' deep link

        if (!isWaitingForMoreGenesisInfo) {
          // at this point, we have all the information we need to route them to the Membership Setup

          // note: opening the nested modal (after history.push) is handled by Profile.js -> useModalLaunching()

          // user is successfully following the deep link
          resetDeepLink()

          const route = routing.profile.membership
          if (!route.isActive(pathname) && !userHasMembership) {
            history.push(route.generatePath())
          }
        }
      } else if (!paymentMethod?.id && require_cc) {
        // (User has completed onboarding), retrieve card if not added and in invite flow
        showDialog(MembershipSetupModal)
      }

      if (!isEmailVerified || !isWaitingForMoreGenesisInfo) {
        // now that initial routing logic is complete, let AppAuthenticated.js display the page
        setReadyToDisplay(true)
      }

      // now that isInitialLogin has been used for routing, turn it back off
      dispatch(mergeUserProfile({ isInitialLogin: false }))
    }
  }, [
    activeDeepLink,
    userId,
    isEmailVerified,
    isInitialLogin,
    isMembershipStatusKnown,
    isWaitingForMoreGenesisInfo,
    onboarding_fitness_survey_completed,
    onboarding_profile_completed,
    pathname,
    resetDeepLink,
    userHasMembership,
    userJustCompletedFitnessAssessment,
    newUserFlowActivated,
    invitationAccepted,
    showDialog,
    require_cc,
    dispatch,
    membershipProfiles.length,
    paymentMethod?.id,
    loading,
  ])

  return readyToDisplay
}

export default useRouting
