import { createSlice } from '@reduxjs/toolkit'

import { createStripeSetupIntent, getPaymentMethod, removePaymentMethod, setPaymentMethod } from './actions'
import { StripePaymentMethod } from './types'

type State = {
  paymentMethod: StripePaymentMethod | null
  setupIntentClientSecret: string | null
  waitingForSetupIntent: boolean
}

const initialState: State = {
  paymentMethod: null,
  setupIntentClientSecret: null,
  waitingForSetupIntent: false,
}

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    updateSetupIntentClientSecret: (state, action) => {
      state.setupIntentClientSecret = action.payload
    },
    clearSetupIntentClientSecret: (state) => {
      state.setupIntentClientSecret = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removePaymentMethod.fulfilled, (state, action) => {
      if (action.payload) {
        state.paymentMethod = null
      }
    })

    builder.addCase(getPaymentMethod.fulfilled, (state, action) => {
      state.paymentMethod = action.payload
    })

    builder.addCase(setPaymentMethod.fulfilled, (state, action) => {
      state.paymentMethod = action.payload
    })

    builder.addCase(createStripeSetupIntent.pending, (state) => {
      state.waitingForSetupIntent = true
    })
    builder.addCase(createStripeSetupIntent.rejected, (state) => {
      state.waitingForSetupIntent = false
    })
    builder.addCase(createStripeSetupIntent.fulfilled, (state, action) => {
      state.waitingForSetupIntent = false
      state.setupIntentClientSecret = action.payload
    })
  },
})

export const { updateSetupIntentClientSecret, clearSetupIntentClientSecret } = billingSlice.actions
