import { VFC } from 'react'

import { IconProps } from './types'

export const UserIconLarge: VFC<IconProps> = () => (
  <svg width="128" height="128" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" fill="#A09F8D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9276 35.928C24.9487 34.9069 26.3335 34.3333 27.7775 34.3333H36.6663C38.1103 34.3333 39.4951 34.9069 40.5161 35.928C41.5372 36.949 42.1108 38.3338 42.1108 39.7778V42C42.1108 42.5523 41.6631 43 41.1108 43C40.5585 43 40.1108 42.5523 40.1108 42V39.7778C40.1108 38.8642 39.7479 37.9881 39.1019 37.3422C38.456 36.6962 37.5799 36.3333 36.6663 36.3333H27.7775C26.8639 36.3333 25.9878 36.6962 25.3419 37.3422C24.6959 37.9881 24.333 38.8642 24.333 39.7778V42C24.333 42.5523 23.8853 43 23.333 43C22.7807 43 22.333 42.5523 22.333 42V39.7778C22.333 38.3338 22.9066 36.949 23.9276 35.928Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2228 23C30.3205 23 28.7783 24.5421 28.7783 26.4444C28.7783 28.3468 30.3205 29.8889 32.2228 29.8889C34.1251 29.8889 35.6672 28.3468 35.6672 26.4444C35.6672 24.5421 34.1251 23 32.2228 23ZM26.7783 26.4444C26.7783 23.4376 29.2159 21 32.2228 21C35.2296 21 37.6672 23.4376 37.6672 26.4444C37.6672 29.4513 35.2296 31.8889 32.2228 31.8889C29.2159 31.8889 26.7783 29.4513 26.7783 26.4444Z"
      fill="white"
    />
  </svg>
)
