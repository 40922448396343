import styled from '@emotion/styled'

import { bp } from '../../components/styled/breakpoints'

export const SubheadLarge = styled.p(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  color: theme.textColors.primary,
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 700,
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  margin: 0,
  padding: 0,

  [bp.sm]: {
    fontSize: '50px',
  },
}))
