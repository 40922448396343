import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { clearUserData } from 'store/user'

import { TIMING } from '../../constants'
import { useVerificationEmail } from '../../hooks'

export const usePleaseVerifyEmailPresenter = () => {
  const dispatch = useDispatch()
  const { logout } = useAuth0()
  const { resendVerificationEmail } = useVerificationEmail()

  const [isPosting, setIsPosting] = useState(false)
  const [isFadingOut, setIsFadingOut] = useState(false)

  const onPrimaryButtonClick = () => {
    setIsFadingOut(true)
    setTimeout(() => {
      dispatch(clearUserData())
      logout({ returnTo: window.location.origin })
    }, TIMING.medium)
  }

  const onSecondaryButtonClick = () => {
    setIsPosting(true)
    resendVerificationEmail().finally(() => {
      setIsPosting(false)
    })
  }

  return {
    isPosting,
    isFadingOut,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
  }
}
