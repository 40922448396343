import { newUserFlowFlag, triggerAcceptInvite } from './external-values'

let newUserVal: boolean | undefined = undefined
let accpetInviteVal: boolean | undefined = undefined

export const newUserFlowToBeActivated = {
  get: () => newUserVal,
  init: () => {
    if (newUserVal !== undefined) return

    const searchParams = new URLSearchParams(window.location.search)
    newUserVal = searchParams.get(newUserFlowFlag) === 'true'
    // eslint-disable-next-line no-console
    console.log('ecomm: ', searchParams.get(newUserFlowFlag))
  },
}

export const acceptInvitationActivated = {
  get: () => accpetInviteVal,
  init: () => {
    if (accpetInviteVal !== undefined) return

    const searchParams = new URLSearchParams(window.location.search)
    accpetInviteVal = searchParams.get(triggerAcceptInvite) === 'true'
    // eslint-disable-next-line no-console
    console.log('acceptInvite: ', searchParams.get(triggerAcceptInvite))
  },
}
