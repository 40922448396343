import styled from '@emotion/styled'

export const H3 = styled.h3(({ theme }) => ({
  fontFamily: theme.typography.family.default,
  fontSize: '1.3125rem',
  lineHeight: 1.52,
  fontWeight: 700,
  margin: 0,
  padding: 0,
}))
