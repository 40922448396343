import { SerializedStyles, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { mergeUserProfile } from 'store/user'

import { patchOwnProfile } from '../api/profile/patchOwnProfile'
import { Fade } from '../components/styled/containers'
import { H1Special, PAlt } from '../components/styled/typography'
import { height100, objectFitCover, positionAbsoluteAll, width100 } from '../components/styled/utility'
import { CURVES } from '../constants'
import slideshow2 from '../images/home-slideshow-2.jpg'

const timeoutDuration = 2800
const zoomDuration = 7000
const fadeDuration = 1900
const fadeDurationShort = fadeDuration * 0.5

const CuratingP = styled(PAlt)({
  marginBottom: '0.2em',
})
const FullViewport = styled.div({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  overflow: 'hidden',
})

const zoom = keyframes`
  0 {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`
const Group = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  bottom: 0,
  opacity: isActive ? 1 : 0,
  transition: `all ${fadeDuration}ms ease-in-out`,
}))
const VerticalCenter = styled.div({
  position: 'relative',
})

type ImgProps = { beginAnimation: boolean }

const Img = styled.img<ImgProps>(({ beginAnimation }) => [
  height100,
  objectFitCover,
  positionAbsoluteAll,
  width100,
  {
    animation: beginAnimation && `${zoom} ${zoomDuration}ms ${CURVES.sCurve} forwards`,
  } as unknown as SerializedStyles,
])

const OnboardingCurating = () => {
  const dispatch = useDispatch()

  const [isGroup1Active, setIsGroup1Active] = useState(true)
  const [isGroup2Active, setIsGroup2Active] = useState(true)
  const [isGroup3Active, setIsGroup3Active] = useState(true)
  const [group2BeginAnimation, setGroup2BeginAnimation] = useState(false)
  const [group3BeginAnimation, setGroup3BeginAnimation] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsGroup1Active(false)
      setIsGroup2Active(false)
      setGroup3BeginAnimation(true)
      setTimeout(() => {
        setIsGroup3Active(false)
        setIsGroup1Active(false)
        setIsGroup2Active(true)
        setGroup2BeginAnimation(true)
        setTimeout(() => {
          setIsGroup2Active(false)
          setIsGroup3Active(false)
          // setIsGroup1Active(true);
          setTimeout(() => {
            const patchUserMe = async () => {
              try {
                const { data: patchedUserData } = await patchOwnProfile({
                  onboarding_fitness_survey_completed: true,
                })
                dispatch(
                  mergeUserProfile({
                    ...patchedUserData,
                    userJustCompletedFitnessAssessment: true,
                  }),
                )
              } catch (e) {}
            }
            patchUserMe()
          }, fadeDurationShort)
        }, timeoutDuration * 0.9)
      }, timeoutDuration * 0.9)
    }, timeoutDuration * 0.6)
  }, [dispatch])

  return (
    <Fade>
      <FullViewport>
        {/* Groups are 'backward', last first */}
        <Group isActive={isGroup3Active}>
          <Img beginAnimation={group3BeginAnimation} src={slideshow2} alt="" />
          <VerticalCenter>
            <div>
              <CuratingP>Curating</CuratingP>
              <H1Special>Classes</H1Special>
            </div>
          </VerticalCenter>
        </Group>
        <Group isActive={isGroup2Active}>
          <Img beginAnimation={group2BeginAnimation} src={slideshow2} alt="" />
          <VerticalCenter>
            <CuratingP>Curating</CuratingP>
            <H1Special>Sessions</H1Special>
          </VerticalCenter>
        </Group>
        <Group isActive={isGroup1Active}>
          <Img beginAnimation src={slideshow2} alt="" />
          <VerticalCenter>
            <CuratingP>Curating</CuratingP>
            <H1Special>Instructors</H1Special>
          </VerticalCenter>
        </Group>
      </FullViewport>
    </Fade>
  )
}

export default OnboardingCurating
